import { useEffect, useState } from 'react';
import {
	getContracts,
	getContractsActive,
	getContractsByUserId,
} from '../utils/utils';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useRoles } from './useRoles';

/**
 * Hook used to return a list of active contracts
 * @returns contractsId; id of contractActive
 * @returns contracts: list of active Contracts
 */

const useContracts = (callBackFilter) => {
	const [contractsId, setContractsId] = useState([]);
	const [query, setQuery] = useState();
	const [contractsActive, setContracts] = useState([]);
	const { roles, user } = useRoles();
	const [filter, setFilter] = useState('');
	useEffect(() => {
		if (roles) {
			if (roles.includes('cogsManager')) {
				const query = getContracts();
				setQuery(query);
			} else {
				const query = getContractsByUserId(user.uid);
				setQuery(query);
			}
		}
	}, [roles, user]);

	const [contracts] = useCollection(query, {
		snapshotListenOptions: {
			includeMetadataChanges: true,
		},
	});

	useEffect(() => {
		if (contracts) {
			const docs = contracts.docs.map((item) => {
				return { ...item.data(), id: item.id };
			});
			const contractsActive = getContractsActive(docs, (data) => {
				return {
					nContract: data.mainObj.agreementNumber,
					endDate: data.mainObj.endDate,
					startDate: data.mainObj.startDate,
					dealCalculation: data.mainObj.dealCalculations,
					id: data.id,
					contractsInfo: data,
				};
			});
			if (filter && filter.trim().length > 0) {
				const contractsFilter = contractsActive.filter((item) => {
					const mainStation = item.contractsInfo.mainObj.selectedRowsAqh[0].Station;
					const nameContract = item.contractsInfo.contractName;

					return (
						mainStation.includes(filter.trim()) ||
						nameContract.includes(filter.trim()) ||
						item.nContract.toString().includes(filter.trim())
					);
				});
				const ids = contractsFilter.map((ctr) => ctr.id);
				setContractsId(ids);
				setContracts(contractsFilter);
			} else {
				const ids = contractsActive.map((ctr) => ctr.id);
				setContractsId(ids);
				setContracts(contractsActive);
			}
		}
	}, [contracts, filter]);

	return {
		contractsId,
		contracts: contractsActive,
		setFilter,
	};
};
export default useContracts;
