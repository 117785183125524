/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import DataTable from './components/DataTable';
import FilterForm from './components/Filter';
import { db } from '../../firebase/config';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { stationRef } from '../../utils';
import useAuthContext from '../../hooks/useAuthContext';
import { useRecoilState } from 'recoil';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import LoadingFilters from './components/LoadingFilters';
import {
	customStaGroupDataArray,
	importCsvData,
	initialFilterStationAtom,
	salesArray,
	selectedRowsArray,
	staGroupsArray,
	stationArray,
} from './utils/constants';

const MainBox = styled(Box)(({ theme }) => ({}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const StationList = () => {
	const [stationList, setStationList] = useRecoilState(stationArray);
	const [customStaGroupData, setCustomStaGroupData] = useRecoilState(
		customStaGroupDataArray
	);
	const [importCsv, setImportCsv] = useRecoilState(importCsvData);
	const [selectedRows, setSelectedRows] = useRecoilState(selectedRowsArray);
	const [staGroups, setStaGroups] = useRecoilState(staGroupsArray);
	const [sales, setSales] = useRecoilState(salesArray);
	const collRef = collection(db, stationRef);
	const groupRef = collection(db, 'customStaGroups');
	const salesRef = collection(db, 'sales');
	const { user } = useAuthContext();
	const [refreshKey, setRefreshKey] = useState(0);
	const [openLoading, setOpenLoading] = useState(false);
	const [updateStation, setUpdateStation] = useState(false);
	const [olderFilters, setOlderFilters] = useRecoilState(
		initialFilterStationAtom
	);
	useEffect(() => {
		//get groups
		const groups = query(groupRef, where('userId', '==', user.uid));
		const unsub = onSnapshot(groups, (snapshot) => {
			const staGroups = snapshot.docs.map((doc) => ({
				...doc.data(),
				id: doc.id,
			}));
			setStaGroups(staGroups);
		});

		//get sales
		const salesQuery = query(salesRef);
		const unsubSnap = onSnapshot(salesQuery, (snapshot) => {
			const sales = snapshot.docs.map((doc) => doc.data());
			setSales(sales);
		});

		return unsub && unsubSnap;
	}, []);
	useEffect(() => {
		try {
			const filter = localStorage.getItem('filters');
			const rows = localStorage.getItem('selectRows');
			const station = localStorage.getItem('selectRows');
			if (filter && filter.length > 0) {
				setOlderFilters(JSON.parse(filter));
				if (stationList.length === 0) {
					setUpdateStation(true);
				}
			}
			if (rows && rows.length > 0) {
				setSelectedRows(JSON.parse(rows));
			}
			if (station && station.length > 0 && stationList.length === 0) {
				setStationList(JSON.parse(station));
				setUpdateStation(true);
			}
		} catch (error) {
			console.log(error);
		}
	}, []);
	const refreshData = () => {
		setRefreshKey((oldValue) => oldValue + 1);
	};

	return (
		<MainBox>
			<Grid container>
				<Grid item xs={12} sm={7} md={8}>
					<FilterForm
						refDb={collRef}
						setStationList={setStationList}
						selectedRows={selectedRows}
						setSelectedRows={setSelectedRows}
						staGroups={staGroups || []}
						sales={sales || []}
						customStaGroupData={customStaGroupData}
						setCustomStaGroupData={setCustomStaGroupData}
						importCsv={importCsv}
						setImportCsv={setImportCsv}
						refreshKey={refreshKey}
						olderFilters={olderFilters}
						updateStations={updateStation}
					/>
				</Grid>
				<Grid item xs={12}>
					<DataTable
						stationList={[...customStaGroupData, ...stationList]}
						setSelectedRows={setSelectedRows}
						selectedRows={selectedRows}
						onRefresh={refreshData}
					/>
				</Grid>
				<Dialog
					open={openLoading}
					TransitionComponent={Transition}
					keepMounted
					aria-describedby="alert-dialog-slide-description"
					sx={{
						'& .MuiDialog-paper': {
							minHeight: '40px',
							minWidth: '200px',
						},
					}}
				>
					<DialogContent>
						<LoadingFilters />
					</DialogContent>
				</Dialog>
			</Grid>
		</MainBox>
	);
};

export default StationList;
