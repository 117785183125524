import { collection, doc, query, where } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import moment from 'moment';

// define the collections
const CONTRACTS = 'contracts';
const ORDERS = 'orderCards';

export const getContractsByUserId = (userId) => {
	if (!userId || userId.trim().length <= 0) return null;
	return query(
		collection(db, CONTRACTS),
		where('state', '==', 'signed by tommy'),
		where('uid', '==', userId)
	);
};

export const getContracts = () => {
	return query(
		collection(db, CONTRACTS),
		where('state', '==', 'signed by tommy')
	);
};

export const getUserById = (userId) => {
	if (!userId || userId.trim().length <= 0) return null;
	return doc(db, 'users', userId);
};

export const getRoles = (userInfo) => {
	if ('role' in userInfo) {
		if (Array.isArray(userInfo.role)) {
			return userInfo.role.map((item) => item.value);
		}
		return [userInfo.role];
	}
	return [];
};

export const getNotesById = (itemId, contractId) => {
	if (!itemId || !contractId) return null;
	return doc(db, `contracts/${contractId}/notes/${itemId}`);
};

export const getQueryNotesAccepted = (contractId) => {
	if (!contractId) return;
	const contractRef = doc(db, 'contracts', contractId);
	return collection(contractRef, 'notesAccepted');
};
// Get contracts active
export const getContractsActive = (contracts, callback) => {
	if (contracts.length <= 0) return [];
	const docsFilter = contracts.filter((doc) => {
		try {
			if (doc.mainObj.endDate) {
				if (doc.mainObj.endDate !== 'n/a' && doc.mainObj.endDate !== 'N/A') {
					const endDate = doc.mainObj.endDate.seconds * 1000;
					return moment(endDate).isSameOrAfter(moment(), 'day');
				}
			}
			return false;
		} catch (error) {
			return false;
		}
	});
	if (!callback) return docsFilter;

	return docsFilter.map((doc) => {
		return callback(doc);
	});
};

// Get the traveler by ID
export const getTravelerById = (travelerId) => {
	if (!travelerId) return null;
	return doc(db, 'traveler', travelerId);
};

// Handle the notes
export const getNote = (info) => {
	const note = {
		note: info,
		sender: 'manager',
		date: moment().format(),
		seen: false,
	};
	return note;
};

// Get the certificates by ID
export const getCertificatesById = (certId, contractId) => {
	if (!certId || !contractId) return null;
	return doc(db, `contracts/${contractId}/certificates/${certId}`);
};

// Get Reward Cards
export const getOrderCards = () => {
	return query(collection(db, ORDERS));
};
