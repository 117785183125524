/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DocusealForm } from '@docuseal/react';
import { Box } from '@mui/material';

const SigningPage = () => {
	const { state } = useLocation();
	const [signURL, setSignURL] = useState(null);
	const [email, setEmail] = useState(null);

	useEffect(() => {
		if (state?.signURL && state?.email) {
			setSignURL(state.signURL);
			setEmail(state.email);
		} else {
			console.error('Signing URL or email not provided');
		}
	}, [state]);

	return (
		<Box sx={{ display: 'flex' }}>
			<Box sx={{ m: 'auto', textAlign: 'center' }}>
				<p>
					Please review the documents and sign to complete the onboarding process.
				</p>
				{signURL && email ? (
					<DocusealForm
						src={signURL}
						email={email}
						onComplete={(data) => console.log('Form completed', data)}
					/>
				) : (
					<p>Loading signing form...</p>
				)}
			</Box>
		</Box>
	);
};

export default SigningPage;
