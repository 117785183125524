/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import { db } from '../../../firebase/config';
import {
	collection,
	query,
	where,
	onSnapshot,
	addDoc,
	deleteDoc,
	getDocs,
	getDoc,
	doc,
	updateDoc,
} from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import {
	createOptionObjectData,
	compressDataIfNecessary,
	dateTransform,
	validateOptionErrors,
	validateVacationCredits,
	sortedOptions,
} from '../utils/utils';
import { CircularProgress } from '@mui/material';

export default function AlertDialogSlide({
	getLiveTableData,
	open,
	setOpen,
	dates,
	allInputs,
	userId,
	tableData,
	selectedRowsAqh,
	setOpenVacationTripsModal,
	isPublish,
	messageProposalId,
	disabledByDates,
	setProposalId,
	setGrossWeekly,
	cptRatio,
	setOptionData,
	user,
	setIsNewOne,
	setProposalObj,
	openPublishQuoteModal,
	setIsPublish,
	lowerDeal,
	maxValues,
	openOverrideModal,
	bridgeObj,
	dealTerms,
	setOpenAirportsModal,
	rolloverCredits,
	rolloverData,
	setOpenRolloverModal,
	hasBridgeDeal,
	tagProposal,
}) {
	const [noOfEditRows, setNoOfEditRows] = React.useState(0);
	const aqhSavedOptionsRef = collection(db, 'aqhSavedOptions');
	const [aqhSavedOptions, setAqhSavedOptions] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [grossWeeklyImpressions, setGrossWeeklyImpressions] = useState(0);
	const [loadingSaveProposal, setLoadingSaveProposal] = useState(false);

	useEffect(() => {
		const getOptionsSaved = async () => {
			const optionsData = query(aqhSavedOptionsRef, where('userId', '==', userId));

			const unsub = onSnapshot(optionsData, (snapshot) => {
				const result = snapshot.docs.map((doc) => {
					return { ...doc.data(), id: doc.id };
				});
				// sort options by sortedOptions function
				const sortedResult = sortedOptions(result);
				setAqhSavedOptions(sortedResult);
			});

			return unsub;
		};

		getOptionsSaved();
	}, []);

	const deleteSavedOptions = async () => {
		try {
			const aqhSavedOptionsRef = collection(db, 'aqhSavedOptions');
			const q = query(aqhSavedOptionsRef, where('userId', '==', userId));
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach(async (doc) => {
				await deleteDoc(doc.ref);
			});
		} catch (error) {
			console.log('Error deleting options: ', error);
		}
	};

	useEffect(() => {
		// if publish quote set open true
		// save proposal to show publish quote after
		if (open) {
			handleSave();
		}
	}, [open]);

	useEffect(() => {
		let totalWeeklyGross = [];
		Object.keys(getLiveTableData).filter((key) =>
			totalWeeklyGross.push(Number(getLiveTableData[key].impressions))
		);
		const totalWeeklyGrossSum = totalWeeklyGross.reduce(
			(partialSum, nextValue) => partialSum + nextValue,
			0
		);
		setGrossWeekly(totalWeeklyGrossSum);
		setGrossWeeklyImpressions(Number(totalWeeklyGrossSum));
	}, [getLiveTableData]);

	const grossTotal = useMemo(() => {
		let total = [];
		Object.keys(getLiveTableData).filter((key) =>
			total.push(Number(getLiveTableData[key].gross))
		);
		total = total.reduce((a, b) => a + b, 0);
		return Number(total).toFixed(2);
	}, [getLiveTableData]);

	const cpt = useMemo(() => {
		return allInputs[0].cogs > 0
			? Number(allInputs[0].revenue / allInputs[0].cogs).toFixed(2)
			: 0;
	}, [allInputs[0]]);

	const sumOfRatio = useMemo(() => {
		let filterInputs = allInputs.filter((input) => input.name !== 'Vacations');
		let sumOfRevenues = filterInputs.reduce(
			(n, { revenue }) => n + Number(revenue),
			0
		);
		let sumOfCogs = filterInputs.reduce((n, { cogs }) => n + Number(cogs), 0);
		return sumOfCogs > 0 ? Number(sumOfRevenues / sumOfCogs).toFixed(2) : 0;
	}, [allInputs]);

	const remainingTotal = useMemo(() => {
		let sumOfRevenues = allInputs.reduce(
			(n, { revenue }) => n + Number(revenue),
			0
		);
		return Number(grossTotal - sumOfRevenues).toFixed(2);
	}, [allInputs]);

	const getOrSaveProposalWithAqhSavedOptions = async (mainObj) => {
		if (aqhSavedOptions && aqhSavedOptions.length === 0) {
			setIsNewOne(true);
			// if tableData is greater than 1MB, compress tableData and save it un
			const dataObject = {
				userId: user.uid,
				tableData,
				dates,
				grossTotal,
				grossWeeklyImpressions,
				remainingTotal,
				cpt,
				sumOfRatio,
				selectedRowsAqh,
				cptRatio,
				allInputs,
				lowerDeal,
				maxValues,
				dealTerms,
			};
			// verify if dataObject is greater than 1MB
			const compressedObject = compressDataIfNecessary(dataObject);
			const createObject = createOptionObjectData(
				null,
				user.uid,
				compressedObject.tableData,
				dates,
				grossTotal,
				grossWeeklyImpressions,
				remainingTotal,
				cpt,
				sumOfRatio,
				selectedRowsAqh,
				allInputs,
				lowerDeal,
				maxValues,
				compressedObject.compressed,
				dealTerms
			);
			setOptionData(createObject);
			const docRef = collection(db, 'savedProposals');
			const { id } = await addDoc(docRef, {
				...mainObj,
				aqhSavedOptions: [createObject],
			});

			setProposalId(id);
			setOpenVacationTripsModal(true);
		} else {
			const docRef = collection(db, 'savedProposals');
			const { id } = await addDoc(docRef, mainObj);
			setProposalId(id);
			if (isPublish) {
				const result = await validateOptionErrors(aqhSavedOptions);
				const vacationsResult = await validateVacationCredits(aqhSavedOptions);
				// if one saved option in the proposal has vacations and errors, show airports modal
				if (vacationsResult.includes(true) && result.includes(true)) {
					setOpenAirportsModal(true);
				} else if (!vacationsResult.includes(true) && result.includes(true)) {
					// If dealTerms is true, show Rollover modal
					if (dealTerms !== null) {
						setOpenRolloverModal(true);
					} else {
						// if one saved option in the proposal has errors, show manager override modal
						openOverrideModal(true);
					}
				} else if (vacationsResult.includes(true) && !result.includes(true)) {
					// if one saved option in the proposal has vacations, show airports modal
					setOpenAirportsModal(true);
				} else {
					// If dealTerms is true, show Rollover modal
					if (dealTerms !== null) {
						setOpenRolloverModal(true);
					} else {
						// open publish quote modal
						openPublishQuoteModal(true);
					}
				}
			}
			enqueueSnackbar('proposal saved!', { variant: 'success' });
			setIsPublish(false);
		}
	};

	const handleSave = async () => {
		if (selectedRowsAqh.length > 0) {
			try {
				setLoadingSaveProposal(true);
				const date = dateTransform(new Date());
				const userDoc = await getDoc(doc(db, 'users', userId));
				const revisionNumberRef = doc(db, 'revisionNumber', 'x4LoYuaH4NCIFLLD56Kb');
				const revisionDoc = await getDoc(revisionNumberRef);
				const revNumber = revisionDoc.data().current + 1;
				if (userDoc.exists()) {
					const initials =
						userDoc.data()?.firstName?.charAt(0) +
						userDoc.data()?.lastName?.charAt(0);
					const companyInfo = selectedRowsAqh.map((row) => {
						return {
							name: row.Ownername,
							dma: row.NDMAName,
						};
					});

					const name = `${companyInfo[0].name} - ${companyInfo[0].dma} - ${date} - ${initials} - ${revNumber}`;

					let mainObj = {
						type: 'proposal',
						name,
						aqhSavedOptions: aqhSavedOptions ? aqhSavedOptions : [],
						userId,
						proposalState: 'unread',
						bridgeDeal: bridgeObj,
						hasBridgeDeal: hasBridgeDeal ? hasBridgeDeal : '',
						dealTerms,
						tagProposal: tagProposal,
					};

					setProposalObj(mainObj);

					await getOrSaveProposalWithAqhSavedOptions(mainObj);
					// delete user saved options after user save proposal
					await deleteSavedOptions();
					await updateDoc(revisionNumberRef, {
						current: revNumber,
					});
					setOpen(false);
					setLoadingSaveProposal(false);
					enqueueSnackbar('proposal saved!', { variant: 'success' });
					localStorage.removeItem('filters');
					localStorage.removeItem('selectRows');
					localStorage.removeItem('aqhCalculator');
				}
			} catch (error) {
				setLoadingSaveProposal(false);
				setOpen(false);
				enqueueSnackbar(error.message, { variant: 'error' });
				console.log(error);
			}
		}
	};

	React.useEffect(() => {
		let getData = {};
		if (Object.keys(getLiveTableData).length > 0) {
			Object.entries(getLiveTableData).forEach(([key, value]) => {
				let stationName = String(key).split('@')[0];
				getData[`${stationName}@${value.dayPartName}`] = value;
			});
			setNoOfEditRows(Object.keys(getData).length);
		}
	}, [getLiveTableData]);

	return (
		<div>
			<Button
				className="save"
				size="small"
				variant="contained"
				sx={{ backgroundColor: '#A5D6A7' }}
				onClick={() => handleSave()}
				disabled={
					disabledByDates ||
					noOfEditRows === 0 ||
					messageProposalId !== null ||
					loadingSaveProposal
				}
				startIcon={loadingSaveProposal && <CircularProgress size={18} />}
			>
				Save Proposal
			</Button>
		</div>
	);
}
