/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Typography,
	Button,
	Paper,
	TextField,
	Grid,
} from '@mui/material';
import { MainBox } from '../../utils/themes';
import OrderTable from './OrderTable';
import { updateDoc, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/config';
import moment from 'moment';

const OrderNotes = ({ open, closeModal, order }) => {
	const [error, setError] = useState(false);
	const [note, setNote] = useState('');
	const [orderNotes, setOrderNotes] = useState([]);

	useEffect(() => {
		if (order && order.id) {
			const orderRef = doc(db, 'orderCards', order.id);
			const unsubscribe = onSnapshot(orderRef, (snapshot) => {
				if (snapshot.exists()) {
					// Sort notes by timestamp
					const sortedNotes = (snapshot.data().notes || []).sort((a, b) => {
						return b.timestamp - a.timestamp;
					});
					setOrderNotes(sortedNotes);
				}
			});
			return () => unsubscribe();
		}
	}, [order]);

	const sendNote = async () => {
		if (note && order && order.id) {
			const newNote = {
				date: moment().format('DD/MM/YYYY'),
				note: note,
				timestamp: Date.now(),
			};

			const orderRef = doc(db, 'orderCards', order.id);

			// Get the current notes from Firestore
			const orderSnap = await getDoc(orderRef);
			const currentNotes = orderSnap.exists() ? orderSnap.data().notes || [] : [];

			// Add the new note to the current notes
			const updatedNotes = [...currentNotes, newNote];

			// Update the notes in Firestore
			try {
				await updateDoc(orderRef, {
					notes: updatedNotes,
				});
				setNote('');
			} catch (error) {
				console.error('Error updating document: ', error);
			}
		} else {
			setError(true);
		}
	};
	return (
		<Dialog
			open={open}
			onClose={closeModal}
			PaperProps={{
				style: { overflow: 'hidden' },
			}}
			maxWidth={'md'}
			fullWidth={true}
		>
			<DialogTitle>Notes</DialogTitle>
			<DialogContent>
				<Typography> Date: {moment().format('DD/MM/YYYY')}</Typography>
				<Typography> Time: {moment().format('HH:MM')}</Typography>

				<Paper elevation={2} sx={{ marginTop: 5 }}>
					<TextField
						id="outlined-multiline-static"
						label="Note"
						multiline
						error={error}
						rows={5}
						fullWidth={true}
						value={note}
						onChange={(e) => {
							setNote(e.target.value);
							setError(false);
						}}
						helperText={error ? 'Please fill in this field' : ''}
					/>
				</Paper>
				<MainBox>
					<Grid item xs={2}>
						<Button onClick={sendNote} variant="contained">
							Send
						</Button>
					</Grid>
					<OrderTable history={orderNotes || []} />
				</MainBox>
			</DialogContent>
		</Dialog>
	);
};

export default OrderNotes;
