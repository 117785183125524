import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const Prospector = ({ researchData }) => {
	return (
		<Grid container spacing={3}>
			{researchData?.length > 0 ? (
				researchData?.map((v, i) => (
					<Grid key={i} item xs={3}>
						<Paper sx={{ p: 2 }}>
							<Box sx={{ display: 'flex' }}>
								<Typography variant="body1" color="initial" sx={{ flexGrow: 1 }}>
									Title{' '}
								</Typography>
								<Typography variant="body1" color="initial">
									<b>{v.title}</b>
								</Typography>
							</Box>
							<Box sx={{ display: 'flex' }}>
								<Typography variant="body2" color="initial" sx={{ flexGrow: 1 }}>
									Username{' '}
								</Typography>
								<Typography variant="body2" color="initial">
									<b>{v.name}</b>{' '}
								</Typography>
							</Box>
							<Box sx={{ display: 'flex' }}>
								<Typography variant="body2" color="initial" sx={{ flexGrow: 1 }}>
									Data length{' '}
								</Typography>
								<Typography variant="body2" color="initial">
									<b>{v?.data?.length > 10 ? v?.data?.length : `0${v?.data?.length}`}</b>
								</Typography>
							</Box>
						</Paper>
					</Grid>
				))
			) : (
				<Box p={3}>No data found!</Box>
			)}
		</Grid>
	);
};

export default Prospector;
