import React, { useState } from 'react';
import { useTheme, ThemeProvider } from '@mui/material';
import { MainBox, getTheme } from '../../utils/themes';
import MUIDataTable from 'mui-datatables';
import { optionsTableRewards } from './utils';
import { Grid } from '@mui/material';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { Box } from '@mui/material';
import OrderNotes from './OrderNotes';

const ListRewardCards = ({ orderCardsRecords }) => {
	const [order, setOrder] = useState();
	const [open, setOpen] = useState(false);
	const closeModal = () => {
		setOrder();
		setOpen(false);
	};
	const theme = useTheme();
	const columns = [
		{
			name: 'dealNumber',
			label: 'Deal #',
		},

		{
			name: 'cardDueDate',
			label: 'Card Due Date',
		},
		{
			name: 'balance',
			label: 'balance',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => {
					return value ? `$ ${value}` : '$ 0';
				},
			},
		},
		{
			name: 'numberOfCards',
			label: 'Number Of Cards',
		},
		{
			name: 'denomination',
			label: 'Denomination',
		},
		{
			name: 'status',
			label: 'Status',
		},
		{
			name: 'notes',
			label: 'Notes',
			options: {
				customBodyRender: (_value, tableMeta) => {
					const value = orderCardsRecords[tableMeta.rowIndex];

					return (
						<div>
							<Grid item>
								<Box>
									<SpeakerNotesIcon
										sx={{ cursor: 'pointer' }}
										onClick={() => {
											setOrder(value);
											setOpen(true);
										}}
									></SpeakerNotesIcon>
								</Box>
							</Grid>
						</div>
					);
				},
			},
		},
	];

	return (
		<>
			<MainBox
				sx={{
					boxShadow: (theme) => theme.shadows[4],
					borderRadius: 2,
					paddingX: 1.2,
					marginX: -1,
				}}
			>
				<ThemeProvider theme={getTheme(theme)}>
					<MUIDataTable
						data={orderCardsRecords || []}
						columns={columns}
						options={{
							...optionsTableRewards,
							selectToolbarPlacement: 'replace',
							responsive: 'standard',
							selectableRows: 'none',
						}}
					></MUIDataTable>
					<OrderNotes open={open} closeModal={closeModal} order={order} />
				</ThemeProvider>
			</MainBox>
		</>
	);
};
export default ListRewardCards;
