import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import React from 'react'
import { useCollection } from '../../../hooks/useCollection'

const Data = ({ data, i }) => {
    const { documents: aqhData } = useCollection("aqhData")
    const LightenDarkenColor = (col, amt) => {
        var num = parseInt(col, 16)
        var r = (num >> 16) + amt
        var b = ((num >> 8) & 0x00ff) + amt
        var g = (num & 0x0000ff) + amt
        var newColor = g | (b << 8) | (r << 16)
        return newColor.toString(16)
    }
    return (
        <Box sx={{ px: 3, py: 1, textAlign: "center" }}>
            <Typography variant="h5" p={1} fontWeight="bold" textTransform={"capitalize"} className="grid-item__title">
                Data {data}
            </Typography>
            <Box
                sx={{
                    mb: 2, height: document.getElementsByClassName("dataComponent")[i]?.clientHeight - 70,
                    overflowY: aqhData ? "auto" : "hidden",
                    "&::-webkit-scrollbar": {
                        width: 7,
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f1f1f1",
                    },
                    "::-webkit-scrollbar-thumb": {
                        backgroundColor: theme => theme.palette.primary.main,
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: (theme) =>
                            `#${LightenDarkenColor(theme.palette.primary.main.slice(1), 19)}`,
                    },
                }}
            >
                {aqhData ? (
                    aqhData
                        .map((c, i) => (<Box key={i + Math.floor(Math.random() * 9999)}>
                            <Typography variant="subtitle2" pt={1}>
                                Lorem {c.name} {data}
                            </Typography>
                        </Box>
                        ))
                ) : (
                    <Box textAlign='center' pt={3}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </Box >
    )
}

export default Data
