import * as React from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';

export default function Loading() {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			minHeight="100vh"
			bgcolor="#0082A6"
		>
			<Stack spacing={2} direction="row" alignItems="center">
				<CircularProgress style={{ color: 'white' }} />
				<h4>Loading</h4>
			</Stack>
		</Box>
	);
}
