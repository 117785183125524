import { collectionGroup } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../../firebase/config';
import { useEffect, useState } from 'react';

const useNotes = (contractsId) => {
	const [query, setQuery] = useState();
	const [notes, setNotes] = useState([]);
	const [value] = useCollection(query);
	useEffect(() => {
		if (value) {
			const data = value.docs
				.map((item) => {
					return {
						...item.data(),
						id: item.id,
						contractId: item.ref.parent.parent.id,
					};
				})
				.filter((item) => contractsId.includes(item.contractId));
			setNotes(data);
		}
	}, [contractsId, value]);

	useEffect(() => {
		if (contractsId && contractsId.length > 0) {
			setQuery(collectionGroup(db, 'notes'));
		}
	}, [contractsId]);
	return { notes };
};

export default useNotes;
