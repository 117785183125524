import { Alert, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MainBox } from '../../utils/themes';
import moment from 'moment';
const travelerTypeObject = {
	contestWinner: 'Contest Winner',
	stationEmployee: 'Station Employee',
	client: 'Client',
	other: 'Other',
};
const TravelerInfo = ({ traveler }) => {
	const [name, setName] = useState('');
	const [address, setAddress] = useState('');
	const [addressTwo, setAddressTwo] = useState('');
	const [gender, setGender] = useState();
	const [dob, setDob] = useState();
	const [state, setState] = useState('');
	const [city, setCity] = useState('');
	const [phone, setPhone] = useState();
	const [altPhone, setAltPhone] = useState();
	const [email, setEmail] = useState('');
	const [travelerType, setTravelerType] = useState('');

	useEffect(() => {
		if (traveler) {
			const nameTraveler = traveler.firstName + traveler.lastName;
			setAddress(traveler.address);
			setAddressTwo(traveler.addressTwo);
			setState(traveler.state);
			setCity(traveler.city);
			setName(nameTraveler);
			setGender(traveler.gender);
			setPhone(traveler.phone);
			setAltPhone(traveler.altPhone);
			setEmail(traveler.email);

			if (traveler.dob) {
				setDob(moment(traveler.dob).format('DD/MM/YYYY'));
			} else {
				setDob(null);
			}

			const travelerType = travelerTypeObject[traveler.travelerType];
			if (travelerType.toLowerCase() === 'other') {
				setTravelerType(traveler.conditional);
			} else {
				setTravelerType(travelerType);
			}
		}
	}, [traveler]);
	return (
		<>
			{!traveler ? (
				<Box>
					<Alert severity="warning" variant="outlined">
						{' '}
						Traveler not added
					</Alert>
				</Box>
			) : (
				<MainBox>
					<MainBox>
						<Stack direction={'row'} spacing={4}>
							<Typography sx={{ fontWeight: 'bold' }}>Name Traveler: </Typography>

							<Typography>{name}</Typography>
						</Stack>
					</MainBox>
					<Divider></Divider>
					<MainBox>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Box>
									<Stack direction={'row'} spacing={2}>
										<Typography sx={{ fontWeight: 'bold' }}>Direccion: </Typography>
										<Typography>{address}</Typography>
									</Stack>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box>
									<Stack direction={'row'} spacing={2}>
										<Typography sx={{ fontWeight: 'bold' }}>
											Direccion 2 (Optional):
										</Typography>
										<Typography>{addressTwo}</Typography>
									</Stack>
								</Box>
							</Grid>
						</Grid>
					</MainBox>

					<Divider></Divider>
					<MainBox>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Box>
									<Stack direction={'row'} spacing={2}>
										<Typography sx={{ fontWeight: 'bold' }}>State: </Typography>
										<Typography>{state}</Typography>
									</Stack>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box>
									<Stack direction={'row'} spacing={2}>
										<Typography sx={{ fontWeight: 'bold' }}>City :</Typography>
										<Typography>{city}</Typography>
									</Stack>
								</Box>
							</Grid>
						</Grid>
					</MainBox>

					{(gender || dob) && (
						<>
							{' '}
							<Divider></Divider>
							<MainBox>
								<Grid container spacing={2}>
									{gender && (
										<Grid item xs={6}>
											<Box>
												<Stack direction={'row'} spacing={2}>
													<Typography sx={{ fontWeight: 'bold' }}>Gender: </Typography>
													<Typography>{gender}</Typography>
												</Stack>
											</Box>
										</Grid>
									)}
									{dob && (
										<Grid item xs={6}>
											<Box>
												<Stack direction={'row'} spacing={2}>
													<Typography sx={{ fontWeight: 'bold' }}>
														Date Of Birth :
													</Typography>
													<Typography>{dob}</Typography>
												</Stack>
											</Box>
										</Grid>
									)}
								</Grid>
							</MainBox>
						</>
					)}
					<Divider></Divider>
					<MainBox>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Box>
									<Stack direction={'row'} spacing={2}>
										<Typography sx={{ fontWeight: 'bold' }}>Phone: </Typography>
										<Typography>{phone}</Typography>
									</Stack>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box>
									<Stack direction={'row'} spacing={2}>
										<Typography sx={{ fontWeight: 'bold' }}>Alt Phone:</Typography>
										<Typography>{altPhone}</Typography>
									</Stack>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box>
									<Stack direction={'row'} spacing={2}>
										<Typography sx={{ fontWeight: 'bold' }}>Email: </Typography>
										<Typography>{email}</Typography>
									</Stack>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box>
									<Stack direction={'row'} spacing={2}>
										<Typography sx={{ fontWeight: 'bold' }}>Traveler Type:</Typography>
										<Typography>{travelerType}</Typography>
									</Stack>
								</Box>
							</Grid>
						</Grid>
					</MainBox>
				</MainBox>
			)}
		</>
	);
};
export default TravelerInfo;
