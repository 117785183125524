import React, { useState, useEffect, useRef } from "react";
import * as echarts from "echarts";

export default function EChart({ option, style, event }) {
  const chart = useRef(null);
  const [chartElement, setChartElement] = useState(chart);
  useEffect(() => {
    if (chartElement?.current) {
      const instance = echarts.init(chartElement.current);
      instance.on("click", event);
      setChartElement(instance);
    } else {
      chartElement.on("click", event);
      chartElement.setOption(option);
    }
  }, [option, chartElement, event]);

  return (
    <div ref={chart} style={{ height: "100%", width: "100%", ...style }} />
  );
}
