/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
	Button,
	TextField,
	Grid,
	Box,
	Typography,
	Autocomplete,
	CircularProgress,
	Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import { db } from '../../../firebase/config';
import {
	addDoc,
	collection,
	doc,
	getDocs,
	limit,
	query,
	setDoc,
	updateDoc,
	where,
} from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { baseURL, getUniqueArr } from '../../../utils';

const RatingsBox = styled(Box)(({ theme }) => ({
	border: '1px solid rgb(128 128 128 / 33%)',
	background: '#F8F8F8',
	borderRadius: '8px',
	boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
	padding: '1.2rem',
	minHeight: '250px',
}));

const UpdateRatings = () => {
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [seasons, setSeasons] = useState([]);
	const [currentSeason, setCurrentSeason] = useState(null);
	const [pastSeason, setPastSeason] = useState(null);
	const seasonsRef = collection(db, 'seasons');

	useEffect(() => {
		const getSeasons = async () => {
			try {
				const q = query(seasonsRef, limit(1));
				const seasonsDocs = await getDocs(q);
				let seasonsResults = [];
				seasonsDocs.forEach((doc) => {
					seasonsResults.push({ ...doc.data(), seasonId: doc.id });
				});
				setSeasons(seasonsResults);
			} catch (error) {
				console.log(error);
			}
		};
		getSeasons();
	}, []);

	const handleChange = (name, value) => {
		if (name === 'current') {
			setCurrentSeason(value);
		} else {
			setPastSeason(value);
		}
	};

	const handleSave = async () => {
		if (currentSeason && pastSeason) {
			setLoading(true);
			const data = {
				currentRef: currentSeason,
				pastRef: pastSeason,
			};
			try {
				const resp = await axios.post(baseURL + '/updateSeasons', {
					...data,
				});
				if (resp.status === 200) {
					enqueueSnackbar('Seasons updated successfully', { variant: 'success' });
					setLoading(false);
				} else {
					enqueueSnackbar('Something went wrong', { variant: 'error' });
					setLoading(false);
				}
			} catch (error) {
				console.log(error);
				enqueueSnackbar('Something went wrong', { variant: 'error' });
				setLoading(false);
			}
		}
	};

	//TODO: add process to new season

	return (
		<RatingsBox sx={{ mx: 'auto', width: { md: '60%' }, p: 2 }}>
			<Box sx={{ textAlign: 'center', pb: 5 }}>
				<Typography variant="h6" fontWeight="600">
					Update Ratings
				</Typography>
			</Box>
			<Grid container spacing={2} sx={{ p: 2 }}>
				<Grid
					item
					xs={6}
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Box>
						<Typography variant="subtitle1">Current Ratings</Typography>
						<Autocomplete
							options={seasons[0]?.allSeasons || []}
							getOptionLabel={(option) => option}
							getOptionDisabled={(option) => option === pastSeason}
							isOptionEqualToValue={(option, value) => option === value}
							id="currentSeason"
							size="small"
							sx={{ backgroundColor: 'white' }}
							value={currentSeason}
							onChange={(_, newValue) => handleChange('current', newValue)}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder={seasons[0]?.currentSeason || 'Current Season'}
									variant="outlined"
								/>
							)}
						/>
					</Box>
				</Grid>

				<Grid
					item
					xs={6}
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Box>
						<Typography variant="subtitle1">Past Ratings</Typography>
						<Autocomplete
							options={seasons[0]?.allSeasons || []}
							getOptionLabel={(option) => option}
							getOptionDisabled={(option) => option === currentSeason}
							isOptionEqualToValue={(option, value) => option === value}
							id="user"
							size="small"
							sx={{ backgroundColor: 'white' }}
							value={pastSeason}
							onChange={(_, newValue) => handleChange('past', newValue)}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder={seasons[0]?.pastSeason || 'Past Season'}
									variant="outlined"
								/>
							)}
						/>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={2} sx={{ mt: 7 }}>
				<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						color="success"
						variant="contained"
						onClick={handleSave}
						disabled={loading || !currentSeason || !pastSeason}
						sx={{ minWidth: '100px' }}
						startIcon={
							loading ? (
								<CircularProgress
									style={{ width: '20px', height: '20px', marginRight: '10px' }}
								/>
							) : (
								<SaveIcon />
							)
						}
					>
						Save
					</Button>
				</Grid>
			</Grid>
		</RatingsBox>
	);
};

export default UpdateRatings;
