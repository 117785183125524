import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyCGBV5D4iOwpBkrbbYYuPj-25vxJKs9hDo",
  authDomain: "sunfuncloud.firebaseapp.com",
  projectId: "sunfuncloud",
  storageBucket: "sunfuncloud.appspot.com",
  messagingSenderId: "236156324612",
  appId: "1:236156324612:web:bbb3036d9334c5bf229e51",
  measurementId: "G-E4F67CYVX5"
};

initializeApp(firebaseConfig)

const db = getFirestore()
const auth = getAuth()
const storage = getStorage()

export { db, auth, storage }
