import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@mui/material';
import { formatKey } from './utils';

const ClientDialog = ({ client, open, onClose }) => {
	if (!client) return null;

	return (
		<Dialog
			open={open}
			onClose={onClose}
			sx={{
				'& .MuiDialog-paper': {
					minWidth: '40%',
					maxHeight: '50%',
				},
			}}
		>
			<DialogTitle>Client Information</DialogTitle>
			<DialogContent dividers>
				<div className="overflow-x-auto">
					<table className="min-w-full divide-y divide-gray-200">
						<tbody className="bg-white divide-y divide-gray-200">
							{Object.keys(client)
								.map((key) => (
									<tr key={key}>
										<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
											<strong>{formatKey(key)}: </strong>
										</td>
										<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											{client[key]}
										</td>
									</tr>
								))
								.sort((a, b) => a.key.localeCompare(b.key))}
						</tbody>
					</table>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ClientDialog;
