import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { IconButton, TextField, Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import { CloseOutlined } from '@mui/icons-material';
import { db } from '../firebase/config';
import { updateDoc, doc } from 'firebase/firestore';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
    openModal,
    onClose,
    value,
    documentId,
    onRefresh
}) {
    const [valueData, setValueData] = useState("")
    const [scrollHere, setScroll] = useState(0)
    useEffect(() => {
        const getData = () => {
            setValueData(value)
        }
        openModal && getData()
    }, [openModal, onClose, value])

    useEffect(() => {
        if (openModal) {
            const y = window.pageYOffset;
            setScroll(y)
        }
    }, [openModal])

    const handleCloseModal = () => {
        onClose()
        setTimeout(() => {
            window.scrollTo({ top: scrollHere, behavior: 'auto' })
        }, 1)
    }

    const updateClaimedValue = async() => {
        const docRef = doc(db, "08242022", documentId)
        await updateDoc(docRef, {
            claimed: valueData
        })
        onRefresh()
        onClose()
        setTimeout(() => {
            window.scrollTo({ top: scrollHere, behavior: 'auto' })
        }, 1)
    } 

    return (
        <Dialog
            open={openModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseModal}
            aria-describedby="alert-dialog-slide-description"
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: "400px",
                    minHeight: "200px"
                }
            }}
        >
            <DialogTitle sx={{ pb: 0 }}>Edit Claimed Details</DialogTitle>

            <IconButton color="primary" size='small'
                sx={{
                    width: "fit-content",
                    position: "absolute",
                    right: "3%",
                    top: "3.5%",
                }}
                onClick={handleCloseModal}
            >
                <CloseOutlined />
            </IconButton>
            <DialogContent sx={{ alignItems:"center"}}>
                  {
                    <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                        <TextField
                        label='Claimed'
                        variant='outlined'
                        size='medium'
                        name='claimedTextField'
                        value={valueData}
                        onChange={(e) => {
                            setValueData(e.target.value)
                        }}
                        fullWidth
                        />
                    
                      <Button
                       size='small'
                       variant='contained'
                       sx={{ backgroundColor: '#A5D6A7' }}
                       onClick={updateClaimedValue}
                       disabled={valueData === ""}
                      >
                       Save
                       </Button>
                    </Stack>
                  }                
            </DialogContent>
        </Dialog>
    );
}
