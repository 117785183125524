import { styled, createTheme } from '@mui/material/styles';

import { Box } from '@mui/material';
import { Badge } from '@mui/material';

export const MainBox = styled(Box)(({ theme }) => ({
	margin: '20px auto',
	'.MuiPaper-root': {
		background: 'transparent',
		boxShadow: 'none',
		border: 0,
	},
}));

export const getTheme = (theme) =>
	createTheme({
		components: {
			MuiTable: {
				styleOverrides: {
					root: {
						textTransform: 'capitalize',
						borderCollapse: 'inherit',
						borderSpacing: '0px 5px',
					},
					paper: {
						backgroundColor: 'green',
					},
				},
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					root: {
						color: '#000',
						textTransform: 'capitalize',
						minWidth: '60px',
					},
				},
			},
			MuiTableFooter: {
				styleOverrides: {
					root: {
						'& .MuiToolbar-root': {
							color: '#000',
						},
					},
				},
			},
			MuiTableHead: {
				styleOverrides: {
					root: {
						'& > tr > th > span': {
							justifyContent: 'center',
						},
						'& > tr > th': {
							border: 0,
							color: '#fff',
							height: 50,
							backgroundColor: '#1976D2 !important',

							'&:first-of-type': {
								borderTopLeftRadius: 10,
							},
							'&:last-of-type': {
								borderTopRightRadius: 10,
							},
						},

						'& > tr > th:first-of-type': {
							'& span.MuiCheckbox-root': {
								color: 'rgb(246 246 246)',
								padding: '5px 4px',
								'>svg': {
									width: '1.2rem',
									height: '1.1rem',
								},
							},
						},
						'& > tr > th:last-of-type': {
							paddingRight: '10px',
						},

						'& th:nth-of-type(n+9)': {
							minWidth: '80px',
							textAlign: 'center',
						},
					},
				},
			},
			MuiTableBody: {
				styleOverrides: {
					root: {
						'& > tr': {
							marginBottom: '5px',
							height: 50,
							'&:nth-of-type(even)': {
								backgroundColor: 'rgba(0, 0, 0, 0.04)',
							},
							':nth-of-type(odd):hover': {
								backgroundColor: '#fff',
							},
							'&.Mui-selected': {
								backgroundColor: '#96BE98',
								color: '#fff',
								':hover': {
									backgroundColor: '#96BE98',
								},
								'> td': {
									color: '#fff',
								},
							},
							'& > td:first-of-type': {
								// backgroundColor: '#fff',
								'& span.MuiCheckbox-root': {
									padding: '5px 4px',
									'&.Mui-checked': {
										color: '#96BE98',
									},
									'>svg': {
										width: '1.2rem',
										height: '1.1rem',
									},
								},
							},
							'& > td:nth-of-type(n+9)': {
								textAlign: 'center',
							},
						},
					},
				},
			},
			MuiToolbar: {
				defaultProps: {
					style: { paddingLeft: '8px' },
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						'& h6': {
							paddingLeft: '10px',
							fontWeight: 'bold',
						},
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						padding: '1px 4px',
						borderBottom: 0,
						maxWidth: 400,
						textOverflow: 'ellipsis',
						textAlign: 'center',
					},
					head: {
						paddingTop: '10px',
						paddingBottom: '10px',
					},
					body: {
						fontSize: '12.5px',
						'& p': {
							fontSize: '12.5px',
							cursor: 'pointer',
						},
					},
				},
			},
		},
	});

export const optionsTable = {
	filterType: 'dropdown',
	pagination: true,
	rowsPerPage: 25,
	rowsPerPageOptions: [25, 50, 100],
	print: false,
	download: false,
	viewColumns: false,
	search: false,
	filter: false,
	sort: true,
	delete: false,
	selectableRows: 'none',
};

export const UploadFileTheme = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

export const CustomBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		border: `2px solid ${theme.palette.background.paper}`,
		padding: '0 4px',
		background: 'red',
	},
}));
