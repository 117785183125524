import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { db } from '../../../firebase/config';
import { doc, updateDoc } from 'firebase/firestore';
import { CircularProgress } from '@mui/material';

const IsBridgeComponent = ({
	setIsBridgeDealModal,
	isBridgeDealModal,
	setHasBridgeDeal,
	hasBridgeDeal,
	proposalId,
	setProposalId,
	setOpenBridgeDealModal,
}) => {
	const [loading, setLoading] = React.useState(false);
	const [loadingNo, setLoadingNo] = React.useState(false);
	// if rep responds yes, update proposal
	const handleYes = () => {
		setLoading(true);
		try {
			// if proposal exists, update it
			if (proposalId && proposalId !== '') {
				const proposalRef = doc(db, 'savedProposals', proposalId);
				updateDoc(proposalRef, {
					hasBridgeDeal: 'Yes',
				});
				setHasBridgeDeal('Yes');
				// reload proposal data after delete bridge deal
				setProposalId(proposalId);
			} else {
				// if proposal doesn't exist, set hasBridgeDeal to yes
				setHasBridgeDeal('Yes');
			}
		} catch (error) {
			console.log(error);
			setIsBridgeDealModal(false);
		} finally {
			setLoading(false);
			setIsBridgeDealModal(false);
			setOpenBridgeDealModal(true);
		}
	};
	// if rep responds no, update proposal
	const handleNo = async () => {
		setLoadingNo(true);
		try {
			// if proposal exists, update it
			if (proposalId && proposalId !== '') {
				const proposalRef = doc(db, 'savedProposals', proposalId);
				await updateDoc(proposalRef, {
					hasBridgeDeal: 'No',
				});
				setHasBridgeDeal('No');
				// reload proposal data after delete bridge deal
				setProposalId(proposalId);
			} else {
				// if proposal doesn't exist, set hasBridgeDeal to no
				setHasBridgeDeal('No');
			}
		} catch (error) {
			console.log(error);
			setIsBridgeDealModal(false);
		} finally {
			setLoadingNo(false);
			setIsBridgeDealModal(false);
		}
	};

	const handleCloseModal = (event, reason) => {
		// if click outside the modal or press esc key, don't close the modal
		if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
			return;
		}
		setIsBridgeDealModal(false);
	};

	return (
		<Dialog open={isBridgeDealModal} onClose={handleCloseModal}>
			<DialogTitle aria-describedby="alert-dialog-slide-description">
				Confirmation
			</DialogTitle>
			<DialogContent>Is there a bridge component to this deal?</DialogContent>
			<DialogActions>
				<Button
					startIcon={
						loading && <CircularProgress style={{ color: 'white' }} size={18} />
					}
					variant="contained"
					color="success"
					onClick={handleYes}
				>
					Yes
				</Button>
				<Button
					startIcon={
						loadingNo && <CircularProgress style={{ color: 'white' }} size={18} />
					}
					variant="contained"
					color="error"
					onClick={handleNo}
				>
					No
				</Button>
				{/* </div> */}
			</DialogActions>
		</Dialog>
	);
};

export default IsBridgeComponent;
