/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from './components/TabPanel';
import Contracts from './components/Contracts';
import Templates from './components/Templates';
import Prospector from './components/Prospector';
import Proposals from './components/Proposals';
import ProposalApprovals from './components/ProposalApprovals';
import {
	Autocomplete,
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TextField,
} from '@mui/material';
import {
	Search as SearchIcon,
	UploadFile as UploadFileIcon,
} from '@mui/icons-material';
import {
	getDocs,
	collection,
	query,
	where,
	onSnapshot,
} from 'firebase/firestore';
import { useCollection } from '../../hooks/useCollection';
import useAuthContext from '../../hooks/useAuthContext';
import { db } from '../../firebase/config';
import { styled } from '@mui/material/styles';
import UploadNewComponent from './components/UploadNew';

const DocumentBox = styled(Box)(({ theme }) => ({
	'& .MuiFormControl-root': {
		margin: 0,
	},
	'& .MuiAutocomplete-root': {
		width: '220px',
	},
}));

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function Documents() {
	const [value, setValue] = useState(0);
	const [searcbValue, setSearchValue] = useState('');
	const [contractState, setContractState] = useState([]);
	const [templateState, setTemplateState] = useState([]);
	const { user } = useAuthContext();
	const [contracts, setContracts] = useState([]);
	const [researchvalue, setResearchValue] = useState(null);
	const [researchData, setResearchData] = useState({
		data: null,
		loading: false,
	});
	const [dataToShow, setDataToShow] = useState([]);
	const researchRef = collection(db, 'selected-08242022');
	const contractsRef = collection(db, 'contracts');
	const { documents: templates } = useCollection('templates');
	const [uploadModal, setUploadModal] = useState(false);

	// bring the contracts documents form firebase that belong to the user
	useEffect(() => {
		const handleContracts = async () => {
			try {
				const querySnapshot = query(contractsRef, where('email', '==', user.email));
				onSnapshot(querySnapshot, (snapshot) => {
					let results = [];
					snapshot.docs.forEach((doc) => {
						results.push({ ...doc.data(), id: doc.id });
					});
					setContracts(results);
				});
			} catch (err) {
				console.log(err);
			}
		};
		handleContracts();
	}, []);

	const handleChange = (e, newValue) => {
		setValue(newValue);
		setResearchValue(null);
	};

	const keypressHandle = (event) => {
		if (event.key === 'Enter') {
			// Cancel the default action, if needed
			event.preventDefault();
			handleSearch();
		}
	};

	const handleSearch = () => {
		researchData.data &&
			setDataToShow((prev) =>
				researchvalue
					? researchData.data
							?.filter((v) => researchvalue.title === v.title)
							?.filter((v) =>
								v.name?.toLowerCase()?.startsWith(searcbValue?.toLowerCase())
							)
					: researchData.data?.filter((v) =>
							v.name?.toLowerCase()?.startsWith(searcbValue?.toLowerCase())
					  )
			);
		contracts &&
			setContractState((prev) =>
				contracts?.filter((v) =>
					v.contractName?.toLowerCase()?.startsWith(searcbValue?.toLowerCase())
				)
			);
	};

	useEffect(() => {
		searcbValue === '' && value === 0 && handleSearch();
	}, [searcbValue]);

	useEffect(() => {
		contracts && setContractState(contracts || []);
	}, [contracts]);

	const handleResearchData = async () => {
		let result = [];
		setResearchData((prev) => ({ data: prev.data, loading: true }));
		const docsSnap = await getDocs(researchRef);
		docsSnap.forEach((doc) => {
			result.push({
				title: doc.data().title,
				name: doc.data().firstName + ' ' + doc.data().lastName,
				parentId: doc.id,
				data: Object.keys(doc.data())
					.filter(
						(key) =>
							key !== 'title' &&
							key !== 'lastName' &&
							key !== 'firstName' &&
							key !== 'employeeId' &&
							key !== 'userId'
					)
					.map((key) => ({ ...doc.data()[key], id: key })),
			});
		});
		return setResearchData({ loading: false, data: result });
	};

	useEffect(() => {
		handleResearchData();
	}, []);

	useEffect(() => {
		templates && setTemplateState(templates);
	}, [templates]);

	const handleSelectChange = (newValue) => {
		if (newValue) {
			setResearchValue(newValue);
			setDataToShow(researchData.data?.filter((v) => newValue.title === v.title));
		} else {
			setResearchValue(null);
			setDataToShow(null);
		}
	};

	useEffect(() => {
		setSearchValue('');
	}, [value]);

	return (
		<DocumentBox sx={{ width: '100%' }}>
			<Box
				sx={{
					height: '60px',
					display: 'flex',
					alignItems: 'flex-end',
					gap: '1.5rem',
				}}
			>
				<Box sx={{ borderBottom: 1, borderColor: 'divider', width: 'fit-content' }}>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
					>
						<Tab label="Contracts" {...a11yProps(0)} />
						<Tab label="Templates" {...a11yProps(1)} />
						<Tab label="Prospector" {...a11yProps(2)} />
						<Tab label="Proposals" {...a11yProps(3)} />
						<Tab label="Proposal Approvals" {...a11yProps(4)} />
					</Tabs>
				</Box>
				{value === 0 ? (
					<FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
						<OutlinedInput
							id="outlined-search"
							type={'text'}
							size="small"
							placeholder="Search..."
							onKeyUp={keypressHandle}
							value={searcbValue}
							onChange={(e) => setSearchValue(e.target.value)}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										edge="end"
										onClick={handleSearch}
									>
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				) : value === 1 ? (
					<UploadNewComponent
						open={uploadModal}
						setOpen={setUploadModal}
						user={user}
					/>
				) : (
					value === 2 && (
						<Box component={'form'} sx={{ display: 'flex', gap: '0.5rem' }}>
							<Autocomplete
								options={
									researchData?.data?.map(({ title, parentId }) => ({
										title,
										parentId,
									})) || []
								}
								getOptionLabel={(option) => option?.title || ''}
								isOptionEqualToValue={(option, value) =>
									option.parentId === value.parentId
								}
								id="researchData"
								size="small"
								value={researchvalue}
								onChange={(_, newValue) => handleSelectChange(newValue)}
								renderOption={(props, option) => {
									return (
										<li {...props} key={option.parentId}>
											{option.title}
										</li>
									);
								}}
								renderInput={(params) => (
									<TextField {...params} label="Saved Data" variant="outlined" />
								)}
							/>
							<FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
								<OutlinedInput
									id="outlined-name-search"
									type={'text'}
									size="small"
									placeholder="Name"
									onKeyUp={keypressHandle}
									value={searcbValue}
									onChange={(e) => setSearchValue(e.target.value)}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle search visibility"
												edge="end"
												onClick={handleSearch}
											>
												<SearchIcon />
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</Box>
					)
				)}
			</Box>
			<TabPanel value={value} index={0}>
				<Contracts
					contractsData={contractState}
					setContractData={setContractState}
					user={user}
				/>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Templates templatesData={templateState} uid={user?.uid} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<Prospector researchData={dataToShow} />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<Proposals uid={user?.uid} />
			</TabPanel>
			<TabPanel value={value} index={4}>
				<ProposalApprovals />
			</TabPanel>
		</DocumentBox>
	);
}
