import React, { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../../firebase/config';
import { collection, query as filter, limit, where } from 'firebase/firestore';
import { Box, Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const SelectedMainStation = ({ setMainStation, mainStation }) => {
	const [query, setQuery] = useState();
	const [infoStation, setInfoStation] = useState({});
	const [nameStation, setNameStation] = useState();
	const [stationLetters, setStationLetters] = useState();
	const [station, setStation] = useState('');
	const [options, setOptions] = useState([]);
	const [data] = useCollection(query);
	useEffect(() => {
		if (mainStation.nameStation) setNameStation(mainStation.nameStation || '');
		else setNameStation('');
		if (mainStation.stationLetters) setStationLetters(mainStation.stationLetters);
		else setStationLetters(null);
		return () => {
			setNameStation('');
			setStationLetters(null);
		};
	}, [mainStation]);
	useEffect(() => {
		if (station && station.trim().length > 0) {
			const ref = collection(db, '08242022');
			const refFilter = filter(
				ref,
				where('Station', '>=', station.toUpperCase()),
				where('Station', '<=', station.toUpperCase() + '\uf8ff'),
				limit(10)
			);
			setQuery(refFilter);
		}
	}, [station]);

	useEffect(() => {
		setInfoStation({ stationLetters: stationLetters, nameStation: nameStation });
	}, [nameStation, stationLetters]);

	useEffect(() => {
		if (data && data.docs && data.docs.length > 0) {
			const stations = data.docs;
			const options = stations.reduce((acum, current) => {
				const info = current.data();
				if (!(info.Station in acum))
					acum[info.Station] = { label: info.Station, value: info.Station };
				return acum;
			}, {});
			setOptions(Object.values(options));
		}
	}, [data]);

	useEffect(() => {
		setMainStation(infoStation);
	}, [infoStation, setMainStation]);
	return (
		<>
			<Box>
				<div>
					<Typography paddingBottom={2} variant="h6">
						Station Name
					</Typography>
				</div>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField
							placeholder="Write Station Name"
							label="Station Name"
							value={nameStation || ''}
							onChange={(event) => setNameStation(event.target.value)}
						></TextField>
					</Grid>
					<Grid item xs={6}>
						<Autocomplete
							id="combo-box-demo"
							placeholder="Select Station"
							options={options}
							value={
								stationLetters ? { label: stationLetters, value: stationLetters } : null
							}
							getOptionLabel={(option) => {
								return option.label;
							}}
							style={{ width: '100%' }}
							onChange={(_, newValue) => {
								const value = newValue ? newValue.value : null;
								setStationLetters(value);
							}}
							renderInput={(params) => (
								<TextField
									onChange={(event) => setStation(event.target.value)}
									{...params}
									label="Main Station"
								/>
							)}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default SelectedMainStation;
