/* eslint-disable react-hooks/exhaustive-deps */
import MUIDataTable from 'mui-datatables';
import { Box, Typography } from '@mui/material';
import {
	styled,
	createTheme,
	ThemeProvider,
	useTheme,
} from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { statesKeys } from '../utils/constants';
import DetailsModal from '../../SalesProspector/components/DetailsModal';
import DealDetailsModal from '../../../components/DealDetailsModal';
import EditClaimedModal from '../../../components/EditClaimedModal';
import EChart from '../../Dashboard/components/EChart';
import { colorGraphLine, addDashSimulcast } from '../../../utils/index';
import { aqhTotalByAddress } from '../../SalesProspector/utils/utils';
import {
	Verified as VerifiedIcon,
	Cancel as CancelIcon,
	Edit as EditIcon,
} from '@mui/icons-material';
import { newAQH } from '../../AQHCalculator/utils/constants';
import { db } from '../../../firebase/config';
import { collection, query, getDocs, limit } from 'firebase/firestore';
import { transformSeason, seasonsGraphValuesX } from '../utils/utils';

const MainBox = styled(Box)(({ theme }) => ({
	margin: '20px auto',
	'.MuiPaper-root': {
		background: 'transparent',
		boxShadow: 'none',
		border: 0,
	},
}));

const getTheme = (theme) =>
	createTheme({
		components: {
			// Name of the component ⚛️
			MuiTable: {
				styleOverrides: {
					root: {
						textTransform: 'capitalize',
						// border: "none",
						borderCollapse: 'inherit',
						borderSpacing: '0px 5px',
					},
					paper: {
						backgroundColor: 'green',
					},
				},
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					root: {
						color: '#000',
						textTransform: 'capitalize',
						minWidth: '60px',
					},
				},
			},
			MuiTableFooter: {
				styleOverrides: {
					root: {
						// display: "none",
						'& .MuiToolbar-root': {
							color: '#000',
						},
					},
				},
			},
			MuiTableHead: {
				styleOverrides: {
					root: {
						'& > tr > th': {
							border: 0,
							color: '#fff',
							backgroundColor: '#1976D2 !important',
						},
						'& > tr > th:nth-of-type(2)': {
							paddingLeft: '15px',
						},
						'& > tr > th:first-of-type': {
							// display: "none",
							// visibility: 'hidden',
							// paddingLeft: '15px',
							'& span.MuiCheckbox-root': {
								color: 'rgb(246 246 246)',
								padding: '5px 4px',
								'>svg': {
									width: '1.2rem',
									height: '1.1rem',
								},
							},
						},
					},
				},
			},
			MUIDataTableHeadCell: {
				styleOverrides: {
					root: {
						'& .MuiTableSortLabel-icon': {
							color: 'rgb(255 255 255) !important',
						},
					},
				},
			},
			MuiTableBody: {
				styleOverrides: {
					root: {
						'& > tr': {
							marginBottom: '5px',
							'& > td:nth-of-type(2)': {
								paddingLeft: '10px',
							},
							'&:nth-of-type(even)': {
								backgroundColor: 'rgba(0, 0, 0, 0.04)',
							},
							':nth-of-type(odd):hover': {
								backgroundColor: '#fff',
							},
							'&.Mui-selected': {
								backgroundColor: '#96BE98',
								color: '#fff',
								':hover': {
									backgroundColor: '#96BE98',
								},
								'> td': {
									color: '#fff',
								},
							},
							'& > td:first-of-type': {
								// width: "50px",
								backgroundColor: '#fff',
								'& span.MuiCheckbox-root': {
									padding: '5px 4px',
									'&.Mui-checked': {
										color: '#96BE98',
									},
									'>svg': {
										width: '1.2rem',
										height: '1.1rem',
									},
								},
							},
						},
					},
				},
			},
			MuiToolbar: {
				styleOverrides: {
					// root: {
					//     color: '#000',
					// },
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						padding: '1px 4px',
						borderBottom: 0,
					},
					body: {
						fontSize: '12.5px',
						'& p': {
							fontSize: '12.5px',
							cursor: 'pointer',
						},
					},
				},
			},
		},
	});

const lineChartOptions = (stations, allSeasons) => {
	// get the titles for the x axis from the season names
	const seasonsValuesX = seasonsGraphValuesX(allSeasons);
	// transform the season names
	const seasonNames = allSeasons.map((season) => transformSeason(season));
	// get the last season name
	const lastSeasonName = seasonNames[seasonNames.length - 1];
	// get the data for the series
	const seriesData = seasonNames.map((season) => {
		// if the season is the last season, get the data from the last season
		if (season === lastSeasonName) {
			return stations[lastSeasonName] || 0;
		} else {
			// if the season is not the last season, get the data from the season
			return stations[season]?.['MF 6a-7p'] || 0;
		}
	});

	return {
		xAxis: {
			type: 'category',
			data: seasonsValuesX, // get all seasons transformed for the x axis
			show: false,
		},
		yAxis: {
			type: 'value',
			show: false,
		},
		series: [
			{
				data: seriesData, // get all the data for the series
				type: 'line',
				animation: false,
			},
		],
		color: colorGraphLine,
	};
};

const DataTable = ({
	stationList,
	selectedRows,
	setSelectedRows,
	onRefresh,
}) => {
	const theme = useTheme();
	const [rowsSelectedIndexes, setRowsSelectedIndexes] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [openDealModal, setOpenDealModal] = useState(false);
	const [openClaimedModal, setOpenClaimedModal] = useState(false);
	const [isDeal, setIsDeal] = useState(false);
	const [isStation, setIsStation] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const initialQueryDetails = { title: '', value: '', name: '' };
	const initialDealDetails = { title: '', name: '' };
	const [queryDetails, setQueryDetails] = useState(initialQueryDetails);
	const [dealDetails, setDealDetails] = useState(initialDealDetails);
	const [claimedValue, setClaimedValue] = useState('');
	const [selectedClaimedId, setSelectedClaimedId] = useState('');
	const [rowsShow, setRowsShow] = useState(25); //define 25 rows per page as default
	const seasonsRef = collection(db, 'seasons');
	const [currentRef, setCurrentRef] = useState(null);
	const [pastRatingsRef, setPastRatingsRef] = useState(null);
	const [allSeasons, setAllSeasons] = useState([]);
	const [stationData, setStationData] = useState(null);

	// get a current and past seasons collection
	useEffect(() => {
		const getSeasons = async () => {
			try {
				const q = query(seasonsRef, limit(1));
				const seasonsDocs = await getDocs(q);
				let seasonsResults = [];
				seasonsDocs.forEach((doc) => {
					seasonsResults.push({ ...doc.data(), seasonId: doc.id });
				});
				if (seasonsResults.length === 0) {
					return;
				}
				// handle current and past seasons names
				let currentSeason = seasonsResults[0]?.currentSeason;
				let pastSeason = seasonsResults[0]?.pastSeason;
				let allSeasons = seasonsResults[0]?.allSeasons;
				// replace the season names with the correct format
				currentSeason = transformSeason(currentSeason);
				// Transfor pastSeason "spr-year" a "springYear" for example
				pastSeason = transformSeason(pastSeason);
				// get the current and past seasons references
				setStationData(currentSeason);
				setCurrentRef(currentSeason);
				setPastRatingsRef(pastSeason);
				setAllSeasons(allSeasons);
			} catch (error) {
				console.log(error);
			}
		};
		getSeasons();
	}, []);

	const initialSelectAllDetails = {
		checked: false,
		value: '',
		checkedBy: '',
		total: 0,
	};
	const [selectAllDetails, setSelectAllDetails] = useState(
		initialSelectAllDetails
	);
	const handleSelectAll = (checked, value, total, checkedBy) => {
		setSelectAllDetails({ checked, value, total, checkedBy });
	};

	// handle AQH value the way it's done in AQH Calculator
	const handleAQH = (stations, pastSeason) => {
		// get current AQH value
		const currentAQH = stations?.Aqh ?? 0;
		// get the previous value of AQH from the past season in pastRatingsRef
		const previousAQH = stations?.[pastSeason]?.['MF 6a-7p'] ?? 0;
		// get format value
		const format = stations?.FmtName1 ?? '';
		// get station value
		const station = stations?.Station ?? '';
		// calculate the new AQH value using the "newAQH" function created before
		const newAQHValue = newAQH(currentAQH, previousAQH, format, station);
		// return AQH value
		return newAQHValue;
	};

	const columns = [
		{
			name: 'trendLine',
			label: 'Trend Line',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => {
					return {
						style: {
							minWidth: '70px',
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="flex-start"
						maxHeight="50px"
						maxWidth="70px"
						paddingTop={'8px'}
						onClick={(_) => {
							setStationData(currentRef); // take the current season
							let stationObject = stationList.find(
								(s) => tableMeta.rowData[12] === s.id
							);
							setOpenModal(true);
							setIsStation(true);
							setQueryDetails({
								name: 'TrendLine',
								title: 'Trend Line',
								graphData: 'dayParts',
								value: value,
								detailsObj: stationObject,
							});
						}}
					>
						<EChart
							option={lineChartOptions(
								stationList.find((s) => tableMeta.rowData[12] === s.id),
								allSeasons
							)}
						/>
					</Box>
				),
			},
		},
		{
			name: 'aqh',
			label: 'AQH',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => (
					<Typography>
						{handleAQH(
							stationList.find((s) => tableMeta.rowData[12] === s.id),
							pastRatingsRef
						)}
					</Typography>
				),
			},
		},
		{
			name: 'owner',
			label: 'Owner',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => (
					<Typography
						onClick={(_) => {
							let ownerObject = stationList.find(
								(s) => s.Ownername === value && tableMeta.rowData[12] === s.id
							);
							setOpenModal(true);
							setIsOwner(true);
							setQueryDetails({
								name: 'Ownername',
								title: 'Owner',
								value: value,
								detailsObj: ownerObject,
							});
						}}
					>
						{value}
					</Typography>
				),
			},
		},
		{
			name: 'call',
			label: 'Call',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => {
					return {
						style: {
							minWidth: '80px',
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Typography
						onClick={(_) => {
							let stationObject = stationList.find(
								(s) => s.Station === value && tableMeta.rowData[12] === s.id
							);
							setOpenModal(true);
							setIsStation(true);
							setQueryDetails({
								name: 'Station',
								title: 'Station',
								value: value,
								url: stationObject.Web,
								detailsObj: stationObject,
							});
						}}
					>
						{value}
					</Typography>
				),
			},
		},
		{
			name: 'status',
			label: 'Deal',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="flex-start"
						width="60px"
						py={0.5}
						onClick={(_) => {
							let stationObject = stationList.find(
								(s) => tableMeta.rowData[12] === s.id
							);
							setOpenDealModal(true);
							setIsDeal(true);
							setDealDetails({
								name: 'Deal',
								title: 'Deal',
								statusDeal: 'Deal',
								detailsObj: stationObject,
							});
						}}
					>
						{value ? (
							<VerifiedIcon color="success" fontSize="medium" />
						) : (
							<CancelIcon color="error" fontSize="medium" />
						)}
					</Box>
				),
			},
		},
		{
			name: 'city',
			label: 'City',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'state',
			label: 'State',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'market',
			label: 'DMA',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'clusterAqh',
			label: 'Cluster AQH',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'format',
			label: 'Format',
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: 'dial',
			label: 'Dial',
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: 'claimed',
			label: 'Claimed',
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta) => (
					<Typography
						onClick={(_) => {
							if (tableMeta.rowData[12]) {
								setOpenClaimedModal(true);
								setSelectedClaimedId(tableMeta.rowData[12]);
								setClaimedValue(tableMeta.rowData[11]);
							}
						}}
					>
						{value} {<EditIcon fontSize="medium" />}
					</Typography>
				),
			},
		},
		{
			name: 'id',
			label: 'ID',
			options: {
				filter: true,
				sort: true,
				display: false,
			},
		},
	];
	const calculateCluster = (stationList) => {
		if (stationList && stationList.length > 0) {
			let getGroupedAqh = aqhTotalByAddress(stationList);
			let getSalesByAqhGroup = stationList.map((station) => {
				return { ...station, ClusterAqh: getGroupedAqh[station.Maddr1] || 0 };
			});
			return getSalesByAqhGroup;
		}
	};

	const activeDeal = (dealDetails) => {
		const str = 'endDateSchedule';

		if (
			dealDetails[str] !== undefined &&
			dealDetails[str] !== 'N/A' &&
			dealDetails[str] !== 'n/a'
		) {
			const dealDate = new Date(dealDetails[str]);
			const today = new Date();
			// Set dates time to midnight
			dealDate.setHours(0, 0, 0, 0);
			today.setHours(0, 0, 0, 0);
			// Check if deal date is greater than or equal to today
			if (dealDate >= today) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	useEffect(() => {
		// this use Effect refresh selected rows indexes to []
		// after remove all selected rows from parent component
		if (selectedRows.length === 0) {
			setRowsSelectedIndexes([]);
		}
	}, [selectedRows]);

	const formatWithSimulcast = (format, simulcast) => {
		if (simulcast) {
			return format + '/' + addDashSimulcast(simulcast);
		} else {
			return format;
		}
	};

	const data = useMemo(() => {
		const allStations = calculateCluster(stationList || []);
		const getList =
			allStations &&
			allStations.length > 0 &&
			allStations.map((list, idx) => {
				return {
					status: activeDeal(list.dealDetails),
					owner: list['Ownername'],
					call: list.Station,
					city: list.Mcity,
					state: statesKeys[list.Mstate],
					market: list.NDMAName,
					format: formatWithSimulcast(list.FmtName1, list.Simulcast),
					dial: list.Freq,
					aqh: list.Aqh === undefined ? '...' : list.Aqh,
					clusterAqh: list.ClusterAqh === undefined ? '-' : list.ClusterAqh,
					claimed: list['claimed'] === undefined ? '' : list['claimed'],
					id: list.id,
				};
			});

		if (!!selectedRows.length) {
			let subIds = new Set(selectedRows.map(({ id }) => id));
			let result = [...allStations.keys()].filter((i) =>
				subIds.has(allStations[i].id)
			);
			setRowsSelectedIndexes(result);
		}
		return getList;
	}, [selectedRows, stationList]);

	const options = {
		filterType: 'dropdown',
		print: 'false',
		pagination: true,
	};
	return (
		<MainBox
			sx={{
				boxShadow: (theme) => theme.shadows[4],
				borderRadius: 2,
				paddingX: 1.2,
				marginX: -1,
			}}
		>
			<ThemeProvider theme={getTheme(theme)}>
				<MUIDataTable
					title={'Stations'}
					data={data || []}
					columns={columns}
					options={{
						...options,
						// handle rows per page change
						onChangeRowsPerPage: (numberOfRows) => {
							setRowsShow(numberOfRows);
						},
						// handle current row per page
						rowsPerPage: rowsShow,
						rowsPerPageOptions: [25, 50, 100],
						rowsSelected: rowsSelectedIndexes,
						//define custom toolbar to remove delete button
						customToolbarSelect: () => {
							//removed delete button from toolbar
							return null;
						},
						selectToolbarPlacement: 'replace',
						selectableRowsHeader: true,
						onRowSelectionChange: (
							currentRowsSelected,
							allRowsSelected,
							rowsSelected
						) => {
							const filterSelected = rowsSelected.map((rowNo) => stationList[rowNo]);
							setRowsSelectedIndexes(rowsSelected);
							localStorage.setItem('selectRows', JSON.stringify(filterSelected));
							setSelectedRows(filterSelected);
						},
					}}
				/>
				<DetailsModal
					openModal={openModal}
					setOpenModal={setOpenModal}
					queryDetails={queryDetails}
					isStation={isStation}
					isOwner={isOwner}
					onClose={() => {
						setOpenModal(false);
						isStation && setIsStation(false);
						isOwner && setIsOwner(false);
						setQueryDetails(initialQueryDetails);
						setSelectAllDetails(initialSelectAllDetails);
					}}
					handleSelectAll={handleSelectAll}
					selectAllDetails={selectAllDetails}
					salesList={stationList}
					stationData={stationData}
					setStationData={setStationData}
				/>
				<DealDetailsModal
					openDealModal={openDealModal}
					setOpenDealModal={setOpenDealModal}
					queryDealDetails={dealDetails}
					isDeal={isDeal}
					onClose={() => {
						setOpenDealModal(false);
						isDeal && setIsDeal(false);
						setDealDetails(initialDealDetails);
						setSelectAllDetails(initialSelectAllDetails);
					}}
					handleSelectAll={handleSelectAll}
					selectAllDetails={selectAllDetails}
					salesList={stationList}
				/>
				<EditClaimedModal
					openModal={openClaimedModal}
					documentId={selectedClaimedId}
					value={claimedValue}
					onClose={() => {
						setOpenClaimedModal(false);
						setSelectedClaimedId(null);
					}}
					onRefresh={onRefresh}
				></EditClaimedModal>
			</ThemeProvider>
		</MainBox>
	);
};

export default DataTable;
