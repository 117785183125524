import React, { useState } from 'react';
import { Typography, Box, Tabs, Tab } from '@mui/material';
import TabPanel from '../Documents/components/TabPanel';
import { styled } from '@mui/material/styles';
import RegisteredOrders from './components/RewardCards/RegisteredOrders';
import CurrentOrders from './components/RewardCards/CurrentOrders';
import NextOrders from './components/RewardCards/NextOrders';
import Clients from './components/RewardCards/Clients';

const DocumentBox = styled(Box)(({ theme }) => ({
	'& .MuiFormControl-root': {
		margin: 0,
	},
	'& .MuiAutocomplete-root': {
		width: '220px',
	},
}));

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const RewardCards = () => {
	const [value, setValue] = useState(0);

	const handleChange = (e, newValue) => {
		setValue(newValue);
	};

	return (
		<DocumentBox sx={{ width: '100%' }}>
			<Typography variant="h4">Reward Cards</Typography>

			<Box
				sx={{
					height: '60px',
					display: 'flex',
					alignItems: 'flex-end',
					gap: '1.5rem',
				}}
			>
				<Box sx={{ borderBottom: 1, borderColor: 'divider', width: 'fit-content' }}>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
					>
						<Tab label="Registered orders" {...a11yProps(0)} />
						<Tab label="Current orders" {...a11yProps(1)} />
						<Tab label="Next orders" {...a11yProps(2)} />
						<Tab label="Clients" {...a11yProps(3)} />
					</Tabs>
				</Box>
			</Box>
			<TabPanel value={value} index={0}>
				<RegisteredOrders />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<CurrentOrders />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<NextOrders />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<Clients />
			</TabPanel>
		</DocumentBox>
	);
};
export default RewardCards;
