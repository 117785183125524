import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogTitle,
	Fade,
	DialogContent,
	IconButton,
} from '@mui/material';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { useSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Fade direction="up" ref={ref} {...props} />;
});

export default function DeleteModal({
	actionModalOpen,
	setActionModalOpen,
	document,
	setDocument,
	action,
	setAction,
	onCloseAction,
}) {
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const handleClose = () => {
		setActionModalOpen(false);
		onCloseAction();
		setDocument(null);
		setAction(null);
		setLoading(false);
	};

	const toLowerCaseString = (str) => {
		return str.toLowerCase();
	};
	// archive message
	const archiveAction = async () => {
		try {
			setLoading(true);
			const colRef = doc(db, 'reviewMessages', document);
			await updateDoc(colRef, {
				state: 'archived',
			});
			handleClose();
			enqueueSnackbar('Message archived!', { variant: 'success' });
		} catch (error) {
			handleClose();
			enqueueSnackbar(error.message, { variant: 'error' });
			console.log(error.message);
		}
	};

	// delete message
	const deleteAction = async () => {
		try {
			setLoading(true);
			const colRef = doc(db, 'reviewMessages', document);
			await deleteDoc(colRef);
			handleClose();
			enqueueSnackbar('Message deleted!', { variant: 'success' });
		} catch (error) {
			handleClose();
			enqueueSnackbar(error.message, { variant: 'error' });
			console.log(error.message);
		}
	};

	return (
		<div>
			<Dialog
				open={actionModalOpen}
				TransitionComponent={Transition}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
				sx={{
					'& .MuiDialog-paper': {
						minWidth: '300px',
					},
				}}
			>
				<DialogTitle sx={{ userSelect: 'none' }}>
					{action ? action : ''} Message
				</DialogTitle>
				<IconButton
					color="primary"
					size="small"
					sx={{
						width: 'fit-content',
						position: 'absolute',
						right: '3%',
						top: '3.5%',
					}}
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<Typography>
						Are you sure you want to {action ? toLowerCaseString(action) : ''} this
						message?
					</Typography>
				</DialogContent>

				<DialogActions>
					<Button variant="outlined" color="error" onClick={handleClose}>
						Cancel
					</Button>
					{action === 'Archive' ? (
						<Button
							onClick={archiveAction}
							variant="contained"
							color="warning"
							startIcon={loading && <CircularProgress color="error" size={18} />}
							disabled={loading}
						>
							Archive
						</Button>
					) : (
						<Button
							onClick={deleteAction}
							variant="contained"
							color="error"
							startIcon={loading && <CircularProgress color="error" size={18} />}
							disabled={loading}
						>
							Delete
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
}
