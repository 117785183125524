
import React, { useState } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import Data from "./components/dataComp.js";
import EChart from "./components/EChart.js";
import Proposal from "./components/proposal.js";
import { optionEchart1, optionEchart2, style } from "./constants.js";
import { Box, Button, Typography } from '@mui/material'
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css"


const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

/**
 * This layout demonstrates how to sync multiple responsive layouts to localstorage.
 */
export default function Dashboard() {

    const [state, setState] = useState({
        layouts: JSON.parse(JSON.stringify(originalLayouts))
    })

    const resetLayout = () => {
        setState({ layouts: {} });
    }

    const onLayoutChange = (layout, layouts) => {
        saveToLS("layouts", layouts);
        setState({ layouts });
    }

    return (
        <div>
            <Button size="small" sx={{ ml: 1 }} onClick={() => resetLayout()}>Reset Layout</Button>
            <ResponsiveReactGridLayout
                className="layout"
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                rowHeight={120}
                layouts={state.layouts}
                onLayoutChange={(layout, layouts) =>
                    onLayoutChange(layout, layouts)
                }
                draggableHandle=".grid-item__title"
                isResizable={true}
                resizeHandles={["se"]}
            >
                {/* row 1 */}
                <Box key="1s" className="proposal" data-grid={{ w: 3, h: 2, x: 0, y: 0, minW: 3, minH: 2, }} style={style}>
                    <Proposal i={0} data={"a"} />
                </Box>
                <Box key="2s" className="dataComponent" data-grid={{ w: 3, h: 2, x: 3, y: 0, minW: 3, minH: 2, }} style={style}>
                    <Data data={"a"} i={0} />
                </Box>
                <Box key="3s" data-grid={{ w: 6, h: 2, x: 6, y: 0, minW: 6, minH: 2, }} style={style}>
                    <Typography
                        variant="h5"
                        className="grid-item__title"
                        p={1}
                        textAlign="center"
                        fontWeight="bold"
                    >
                        Area Chart
                    </Typography>
                    <EChart
                        option={optionEchart1}
                    />
                </Box>
                {/* row 2 */}
                <Box key="4s" data-grid={{ w: 6, h: 2, x: 0, y: 1, minW: 6, minH: 2, }} style={style}>
                    <Typography
                        variant="h5"
                        className="grid-item__title"
                        p={1}
                        textAlign="center"
                        fontWeight="bold"
                    >
                        Line Chart
                    </Typography>
                    <EChart
                        option={optionEchart2}
                    />
                </Box>
                <Box key="5s" className="proposal" data-grid={{ w: 3, h: 2, x: 6, y: 1, minW: 2, minH: 2, }} style={style}>
                    <Proposal i={1} data={"b"} />
                </Box>
                <Box key="6s" className="dataComponent" data-grid={{ w: 3, h: 2, x: 9, y: 1, minW: 2, minH: 2, }} style={style}>
                    <Data data={"b"} i={1} />
                </Box>
            </ResponsiveReactGridLayout>
        </div>
    );
}

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

function saveToLS(key, value) {
    if (global.localStorage) {
        global.localStorage.setItem(
            "rgl-8",
            JSON.stringify({
                [key]: value
            })
        );
    }
}