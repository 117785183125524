import { Avatar, Box, Typography } from '@mui/material'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useCollection } from '../../../hooks/useCollection'

function Notifications({ noti, index, uid }) {
    const { documents: allUsers } = useCollection("users")
    const [sender, setSender] = useState(null)
    useEffect(() => {
        if (allUsers) {
            setSender(allUsers.find(({ id }) => id === noti.sender_id))
        }
    }, [allUsers])

    return (<Box sx={{ mb: 1 }} key={index}>
        <Box sx={{ display: "flex", pr: 2, borderRadius: theme => theme.spacing(1), border: noti.seen && `1px solid #d4d4d4`, backgroundColor: theme => !noti.seen && (theme.palette.primary.main + "4a") }}>
            <Box sx={{ py: 2, pl: 2 }}>
                <Avatar src={(sender && sender?.photoURL) || (sender?.email?.toUpperCase() || "")} alt='receiver_profile' sx={{ mr: 2 }} />
            </Box>
            <Box sx={{ py: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Typography variant='body1'>{noti.title}</Typography>
                    {!(noti.sender_id === uid) && <>
                        <Typography variant='body1'>from</Typography>
                        <Typography variant='body1' fontWeight={"bold"}>{sender?.firstName ? sender["firstName"] + " " + sender?.lastName : sender?.email?.split("@")[0] || ""}</Typography>
                    </>}
                    <Typography variant='body1'>at {
                        (format(new Date(noti?.createdAt && noti?.createdAt?.toDate()), "yyyy-MM-dd") ===
                            format(new Date(), "yyyy-MM-dd"))
                            ? format(noti?.createdAt?.toDate(), "hh:mm a") :
                            noti?.createdAt?.toDate()?.toDateString()}</Typography>
                </Box>
                <Typography variant='body2' component={"div"} sx={{ maxWidth: "80vw", display: "flex", gap: 0.5 }}>&nbsp;&nbsp;-<div dangerouslySetInnerHTML={{ __html: noti.received_message }}>

                </div></Typography>
            </Box>
        </Box>
    </Box>
    )
}

export default Notifications