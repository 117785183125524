const _ = require('lodash');

// verify if the value is not empty
const isNotEmpty = (value) =>
	value !== '' && value !== '$ 0' && value !== '$ -' && value !== 'N/A';

// Validates if the values exist and are different from 0.00
const getRatioSale = (val1, val2) => {
	if (val1 === '' || val1 === '0.00') {
		return val2;
	} else if (val2 === '' || val2 === '0.00') {
		return val1;
	} else if (val1 !== '' && val2 !== '') {
		return val1;
	}
};

// validates if the values exist and are different from $ 0 or $ -
const getCPTAndRenewalSale = (val1, val2) => {
	// if val1 is not empty and val2 is empty, return val1
	if (isNotEmpty(val1) && !isNotEmpty(val2)) {
		return val1;
	} else if (isNotEmpty(val2) && !isNotEmpty(val1)) {
		// if val2 is not empty and val1 is empty, return val2
		return val2;
	} else if (isNotEmpty(val1) && isNotEmpty(val2)) {
		// if both are not empty, for now return val1
		return val1;
	}
	// if both are empty, return $ 0
	return '0';
};

// Combine sales by product
export const combineSales = (salesFiltered) => {
	if (salesFiltered.length === 0) {
		return {};
	}
	// remove duplicates
	const uniqueObj = _.uniqBy(salesFiltered, 'Current Product');
	const combinedSale = {};
	// loop through the uniqueObj
	for (const sale of uniqueObj) {
		// loop through the keys of the sale object
		for (const key of Object.keys(sale)) {
			// if the key does not exist in the combinedSale object, add it
			if (!combinedSale[key]) {
				combinedSale[key] = sale[key];
			} else if (key === 'Current Product') {
				// if the key is Current Product, add a comma and the value
				combinedSale[key] += `,${sale[key]}`;
			} else if (key === 'Current Renewal $' || key === 'Previous $') {
				if (combinedSale[key] !== sale[key]) {
					// Handle the case when values are different
					// For now it will take the value of the first object
					combineSales[key] = sale[key][0];
				}
				// if the key is Current CPT or Previous CPT, call the function getCPTAndRenewalSale
			} else if (key === 'Current CPT' || key === 'Previous CPT') {
				combinedSale[key] = getCPTAndRenewalSale(combinedSale[key], sale[key]);
			} else if (key === 'Previous Ratio' || key === 'Current Ratio') {
				// if the key is Current Ratio or Previous Ratio, call the function getRatioSale
				combinedSale[key] = getRatioSale(combinedSale[key], sale[key]);
			}
		}
	}

	return combinedSale;
};

// Transform seasons
export const transformSeason = (season) => {
	// if season is not a string or is empty return null
	if (!season || typeof season !== 'string') {
		return null;
	}
	// match the season with the regular expression
	const matches = season.match(/(fall|spr)-(\d{4})/);
	// if there are no matches return null
	if (!matches) {
		return null;
	}
	// return the season with the correct format
	const [, seasonType, year] = matches;
	return `${seasonType === 'spr' ? 'spring' : seasonType}${year}`;
};

// Season for graph
export const seasonsGraphValuesX = (seasons) => {
	return seasons.map((season) => {
		// Get the type of season
		const seasonType = season.startsWith('fall') ? 'Fall' : 'Spring';
		// Get the year
		const year = season.slice(-4); // Get the last 4 characters
		// return the season with the correct format
		return `${seasonType} ${year}`;
	});
};

// capitalize station name
export const capitalizeStations = (stations) => {
	return stations.map((obj) => {
		return {
			...obj,
			station: obj.station.toUpperCase(),
		};
	});
};
