import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {
	Button,
	CircularProgress,
	DialogActions,
	TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { useSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function SaveDialog({
	openModal,
	setOpenModal,
	selectedRows,
	profile,
	user,
}) {
	let { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState('');

	const propertyNames = [
		'Ownername',
		'Station',
		'Mcity',
		'Mstate',
		'NDMAName',
		'FmtName1',
		'Freq',
		'Aqh',
	];

	const handleClose = (fromSave = true) => {
		if (fromSave) {
			setTitle('');
		}
		setOpenModal(false);
		setLoading(false);
	};

	const handleSave = async () => {
		setLoading(true);
		try {
			let { firstName, lastName, employeeId } = profile;
			let convertToObject = {
				title,
				firstName,
				lastName,
				employeeId,
				userId: user.uid,
			};
			selectedRows.forEach((row) => {
				let obj = {};
				propertyNames.map((name) => Object.assign(obj, { [name]: row[name] }));
				return Object.assign(convertToObject, { [row.id]: obj });
			});
			const docRef = collection(db, 'selected-08242022');
			await addDoc(docRef, convertToObject);
			enqueueSnackbar('Data saved successfully!', { variant: 'success' });
			handleClose();
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.message, { variant: 'error' });
			console.log(error);
		}
	};

	return (
		<div>
			<Dialog
				open={openModal}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => handleClose(false)}
				aria-describedby="alert-dialog-slide-description"
				sx={{
					'& .MuiDialog-paper': {
						minWidth: '400px',
					},
				}}
			>
				<DialogTitle sx={{ pb: 0 }}>Save Selected Data</DialogTitle>
				<DialogContent>
					<Box
						sx={{
							my: 2,
							'& >*': {
								my: 2,
							},
						}}
					></Box>
					<TextField
						label={`Title`}
						variant="outlined"
						size="small"
						name={'title'}
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						fullWidth
					/>
				</DialogContent>

				<DialogActions>
					<Button variant="outlined" onClick={() => setOpenModal(false)}>
						Close
					</Button>
					<Button
						onClick={handleSave}
						variant="contained"
						startIcon={loading && <CircularProgress size={18} />}
						disabled={loading || title.length < 4}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
