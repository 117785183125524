import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { CircularProgress, Dialog, DialogActions, DialogTitle, Fade, FormControl, IconButton, Input, InputAdornment } from '@mui/material';
import { doc, deleteDoc } from "firebase/firestore";
import { db } from '../../../firebase/config';


const MainModalBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "500px",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[24],
    padding: theme.spacing(3),
    "& > *": {
        marginBottom: "15px"
    },
    "& > .header": {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
    },
    "& > .content": {
        "& > .listItem": {
            display: "flex",
            gap: "5px",
            justifyContent: "space-between",
            alignItems: 'center',
            background: "#42a5f554",
            padding: "5px",
            marginBottom: "10px",
            "& > p": {
                flex: 1,
                paddingLeft: "5px",
            },
            "& > button": {
                "&:hover": {
                    background: "rgb(211 47 47 / 12%)",
                }
            }
        }
    }

}))


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="up" ref={ref} {...props} />;
});

const ConfirmDialog = ({ setAQhDeleteId, aQhDeleteId }) => {
    const [loading, setLoading] = useState(false)
    const handleClose = () => setAQhDeleteId(null);

    const handleDelete = async () => {
        console.log({ aQhDeleteId })
        setLoading(true)
        try {
            await deleteDoc(doc(db, "aqhData", aQhDeleteId))
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
        handleClose()
    }

    const paperProps = { sx: { boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px" } }
    const backDropProps = { sx: { backgroundColor: "rgb(0 0 0 / 10%)" } }

    return (
        <div>
            <Dialog
                open={!!aQhDeleteId}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    "& .MuiDialog-paper": {
                        minWidth: "300px",
                    }
                }}
                PaperProps={paperProps}
                BackdropProps={backDropProps}
            >
                <DialogTitle sx={{ userSelect: "none" }}>Are You sure?</DialogTitle>
                <DialogActions>
                    <Button variant="outlined" color='error' onClick={handleClose}>No</Button>
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        color='error'
                        startIcon={loading && <CircularProgress color="error" size={18} />}
                        disabled={loading}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


const AqhDataItem = ({ data, setAQhDeleteId }) => {
    return (
        <Box className="listItem">
            <Typography id="modal-modal-description" sx={{ flex: 1 }}>
                {data.name}
            </Typography>
            <IconButton color="error" size="small" onClick={() => setAQhDeleteId(data.id)}>
                <CloseIcon />
            </IconButton>
        </Box>
    )
}


export default function DeleteModal({ openDeleteModal, setOpenDeleteModal, aqhDataSaved }) {

    const [aQhDeleteId, setAQhDeleteId] = useState(null)

    const handleOpen = () => setOpenDeleteModal(true);
    const handleClose = () => setOpenDeleteModal(false);

    return (
        <div>
            <Button
                onClick={handleOpen}
                className='delete'
                size='small'
                variant='contained'
                sx={{ backgroundColor: '#EF9A9A' }}
            >
                Delete
            </Button>
            <Modal
                open={openDeleteModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <MainModalBox>
                    <Box className="header" sx={{}}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Delete Reports
                        </Typography>
                        <FormControl sx={{ m: 1, width: '20ch' }} variant="outlined">
                            <Input
                                id="outlined-adornment-password"
                                label="Password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon sx={{ marginBottom: "5px", marginRight: "4px" }} />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Box>
                    <Box className="content" sx={{ mb: 2 }}>
                        {(aqhDataSaved && aqhDataSaved?.length > 0) ?
                            aqhDataSaved.map((data, idx) => (
                                <AqhDataItem
                                    setAQhDeleteId={setAQhDeleteId}
                                    data={data}
                                    key={data.id + idx}
                                />
                            ))
                            :
                            <Typography sx={{ flex: 1, textAlign: 'center', fontWeight: "bold" }}>
                                No Saved Aqh Data Available
                            </Typography>
                        }
                        <ConfirmDialog setAQhDeleteId={setAQhDeleteId} aQhDeleteId={aQhDeleteId} />
                    </Box>
                </MainModalBox>
            </Modal>
        </div >
    );
}
