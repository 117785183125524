/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	useEffect,
	useMemo,
	useState,
	useCallback,
	useRef,
} from 'react';
import LiveTableContainer from './components/LiveTableContainer';
import DayPartTable from './components/DayPartTable';
import { styled } from '@mui/material/styles';
import PublishQuoteModal from './components/PublishQuoteModal';
import DeleteModal from './components/DeleteModal';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
	Box,
	Grid,
	TextField,
	IconButton,
	Tooltip,
	Button,
	DialogTitle,
	CircularProgress,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useLocation } from 'react-router-dom';
import { useCollection } from '../../hooks/useCollection';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import GeneratePDF from '../Pdf/GeneratePdf';
import useAuthContext from '../../hooks/useAuthContext';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import { CloseOutlined } from '@mui/icons-material';
import SaveProposal from './components/SaveProposal';
import { useSnackbar } from 'notistack';
import VacationsModal from './components/VacationsModal';
import ManagerOverrideModal from './components/ManagerOverrideModal';
import DenyDealModal from './components/DenyDealModal';
import AirportsModal from './components/AirportsModal';
import RolloverCreditsModal from './components/RolloverCreditsModal';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RewardsModal from './components/RewardsModal';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router-dom';
import {
	format,
	addWeeks,
	isMonday,
	previousFriday,
	isFriday,
	subWeeks,
	nextMonday,
	differenceInCalendarWeeks,
	startOfDay,
} from 'date-fns';
import { db } from '../../firebase/config';
import {
	collection,
	query,
	where,
	getDocs,
	doc,
	updateDoc,
	onSnapshot,
	getDoc,
	limit,
} from 'firebase/firestore';
import {
	getUniqueArr,
	splitArrayIntoChunks,
	separateSimulcastArr,
	addDashSimulcast,
	sendEmailMessage,
	sendTextMessage,
} from '../../utils';
import {
	cptConstant,
	rowHeadersName,
	initialDateState,
	newAQH,
} from './utils/constants';
import {
	getProposalBridgeDeal,
	getHasBridgeDeal,
	getProposalOptions,
	updateOrCreateOptionData,
	validateOptionErrors,
	validateVacationCredits,
	sortedOptions,
	necessaryDataObject,
	decompressDayParts,
	decompressDayPartsField,
	proposalStatusReview,
} from './utils/utils';
import { FormControl } from '@mui/material';
import {
	tableRowHeadersName,
	getNumberOfDays,
} from '../SalesProspector/utils/constants';
import { useRecoilValue } from 'recoil';
import { initialFilterStationAtom } from '../StationList/utils/constants';
import { filterDataList } from '../StationList/utils/constants';
import BridgeSchedule from './components/BridgeSchedule';
import IsBridgeComponent from './components/IsBridgeComponent';

const _ = require('lodash');
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const updateDocument = async (state, messageId, user, proposalId, isButton) => {
	const colRef = doc(db, 'reviewMessages', messageId);
	const proposalRef = doc(db, 'savedProposals', proposalId);
	// Todo send message with aprovation message

	if (isButton) {
		// update repState only if user use approve or deny button
		await updateDoc(colRef, {
			repState: 'unread',
		});
	}
	await updateDoc(colRef, {
		state: state === 'approved' || state === 'denied' ? state : 'read',
		reviewer: user?.email,
	});
	await updateDoc(proposalRef, {
		proposalState: state === 'approved' || state === 'denied' ? state : 'read',
	});
};

const MainBox = styled(Box)(() => ({
	marginBottom: '20px',
	'& .MuiGrid-container': {
		marginBottom: '20px',
	},
	'& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
		{
			WebkitAppearance: 'none',
			MozAppearance: 'none',
			appearance: 'none',
		},
}));

const TopGrid = styled(Grid)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'flex-end',
}));

const ActionButtons = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '10px',

	'& button': {
		minWidth: '130px',
		marginRight: '5px',
		borderRadius: theme.spacing(1),
		padding: '2px 5px',
		'&:hover': {
			'&.publish': {
				backgroundColor: '#0081ea',
			},
			'&.save': {
				backgroundColor: '#6ea671',
			},
			'&.delete': {
				backgroundColor: '#d38686',
			},
		},
	},
}));

const AQHCalculator = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const getData = () => {
		const localObject = JSON.parse(localStorage.getItem('aqhCalculator'));
		const selectedRows = JSON.parse(localStorage.getItem('selectRows'));
		if (location?.state?.limitEdits) {
			return {
				data: {
					...location.state.data,
					disabledOptions: location?.state?.disabledOptions,
					limitEdits: location?.state?.limitEdits,
				},
			};
		}
		if (location.state) {
			if (!location.state?.data?.useLocalStorage) {
				localStorage.removeItem('aqhCalculator');
				return { data: location.state?.data };
			} else {
				if (location.state?.data) {
					const copyData = { ...location.state?.data };
					if (copyData.dataList) delete copyData.dataList;
					if (copyData.selectedRowsAqh) delete copyData.selectedRowsAqh;
					localStorage.setItem('aqhCalculator', JSON.stringify(copyData));
					return { data: location.state?.data };
				}
				if (!localObject) return { data: null };
				return {
					data: {
						...localObject,
						dataList: selectedRows,
						selectedRowsAqh: selectedRows,
					},
				};
			}
		} else {
			if (!localObject) return { data: null };
			return {
				data: {
					...localObject,
					dataList: selectedRows,
					selectedRowsAqh: selectedRows,
				},
			};
		}
	};
	const { data } = useMemo(() => getData(), [location]);
	if (!data) {
		window.location.href = '/dashboard/AQH';
	}
	const rowsData = data.selectedRowsAqh
		? data.selectedRowsAqh
		: data.aqhSavedOptions[0].selectedRowsAqh
		? data.aqhSavedOptions[0].selectedRowsAqh
		: [];
	const [selectedRowsAqh, setSelectedRowsAqh] = useState(rowsData);
	const previousDealsData = data.previousDealsData;
	const dealTerms = data?.dealTerms || null;
	const dataList = data.dataList;
	const messageId = data?.messageId || null;
	const limitEdits = data?.limitEdits || Infinity;
	const messageProposalId = data?.messageProposalId || null;
	const [aqhSavedOptions, setAqhSavedOptions] = useState([]);
	const [optionIndex, setOptionIndex] = useState(1);
	const [tableData, setTableData] = useState([]);
	const [openPublishModal, setOpenPublishModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [updateAqh, setUpdatedAqh] = useState([]);
	const [cptRatio, handleCptRatioChange] = useState([1, 685, 4]);
	const [dateState, setDateState] = useState(initialDateState);
	const [openPrint, setOpenPrint] = useState(false);
	const [openPdf, setOpenPdf] = React.useState(false);
	const [mainObj, setMainObj] = React.useState(null);
	const [noOfEditRows, setNoOfEditRows] = React.useState(0);
	const [dataToSave, setDataToSave] = React.useState({});
	const { user } = useAuthContext();
	const [userState, setUserState] = React.useState({});
	let { startDate, endDate, noOfWeeks } = dateState;
	const [lowerDeal, setLowerDeal] = React.useState(false);
	const [maxValues, setMaxValues] = useState(false);
	const [open, setOpen] = useState(false);
	const [mainStation, setMainStation] = useState('');
	const [proposalTable, setProposalTable] = useState([]);
	const [message, setMessage] = useState('');
	const [toEmail, setToEmail] = useState(null);
	const { enqueueSnackbar } = useSnackbar();
	const [simulcast, setSimulcast] = React.useState([]);
	const [newSelectedRows, setNewSelectedRows] = React.useState([]);
	const [isProposal, setIsProposal] = React.useState(false);
	const [userId, setUserId] = React.useState('');
	const [grossWeekly, setGrossWeekly] = React.useState('');
	const [openCreditsModal, setOpenCreditsModal] = React.useState(false);
	const [vacationCredits, setVacationCredits] = React.useState(null);
	const [rewards, setRewards] = React.useState(null);
	const [currentVacationCredits, setCurrentVacationCredits] =
		React.useState(null);
	const [currentRewards, setCurrentRewards] = React.useState(null);
	const [proposalId, setProposalId] = React.useState('');
	const [optionId, setOptionId] = React.useState('');
	const [isPublish, setIsPublish] = React.useState(false);
	const [isManager, setIsManager] = React.useState(false);
	const [openStartDate, setOpenStartDate] = React.useState(false);
	const [openEndDate, setOpenEndDate] = React.useState(false);
	const [proposalState, setProposalState] = React.useState('unread');
	const [openOverrideModal, setOpenOverrideModal] = React.useState(false);
	const [openDenyModal, setOpenDenyModal] = React.useState(false);
	const [openRewardsModal, setOpenRewardsModal] = React.useState(false);
	const [wasApproved, setWasApproved] = React.useState(false);
	const [disabledByDates, setDisabledByDates] = React.useState(true);
	const [isAqhOption, setIsAqhOption] = React.useState(false);
	const [isEditAqhOption, setIsEditAqhOption] = React.useState(false);
	const [optionData, setOptionData] = React.useState({});
	const [isNewOne, setIsNewOne] = React.useState(false);
	const [proposalObj, setProposalObj] = React.useState(null);
	const [openVacationTripsModal, setOpenVacationTripsModal] =
		React.useState(false);
	const [openAlert, setOpenAlert] = React.useState(true);
	const filtersAtom = useRecoilValue(initialFilterStationAtom);
	const filterDataListAtom = useRecoilValue(filterDataList);
	const [bridgeObj, setBridgeObj] = React.useState(null);
	const [disableBridgeDeal, setDisableBridgeDeal] = React.useState(false);
	const [publishProcess, setPublishProcess] = React.useState(false);
	const [openAirportsModal, setOpenAirportsModal] = useState(false);
	const [rolloverCredits, setRolloverCredits] = useState(null);
	const [openRolloverModal, setOpenRolloverModal] = useState(false);
	const [rolloverData, setRolloverData] = useState({});
	const [loadingApprove, setLoadingApprove] = useState(false);
	const [isBridgeDealModal, setIsBridgeDealModal] = useState(false);
	const [hasBridgeDeal, setHasBridgeDeal] = useState('');
	const [openBridgeDealModal, setOpenBridgeDealModal] = useState(false);
	const [openDeleteBridgeModal, setOpenDeleteBridgeModal] = useState(false);
	const [tagProposal, setTagProposal] = useState('');
	const initilAllInputs = [
		{ name: 'Vacations', revenue: '', cogs: '', min: cptRatio[1] },
		{ name: 'visa', revenue: '', cogs: '', min: cptRatio[2] },
		{ name: 'amazon', revenue: '', cogs: '', min: cptRatio[2] },
		{ name: 'bill payer', revenue: '', cogs: '', min: cptRatio[2] },
		{ name: 'others', revenue: '', cogs: '', min: cptRatio[2] },
	];

	const [allInputs, setAllInputs] = useState(initilAllInputs);
	// useRef to ensure effects run only once
	const hasRunRef = useRef(false);
	const { documents: aqhData } = useCollection('aqhData');
	const seasonsRef = collection(db, 'seasons');
	const [currentRef, setCurrentRef] = useState(null);
	const [pastRatingsRef, setPastRatingsRef] = useState(null);
	// send review message to manager
	const sendReviewEmailMessage = async (message, email) => {
		await sendEmailMessage(`Deal Response`, message, email, user.uid);
	};
	// update repMessage state to read
	const updateRepMessageState = async (proposalId) => {
		if (proposalId) {
			setProposalId(proposalId);
		}
		if (user && user.email && proposalId) {
			const docRef = collection(db, 'reviewMessages');
			const q = query(
				docRef,
				where('employeeEmail', '==', user.email),
				where('proposalId', '==', proposalId)
			);
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				updateDoc(doc.ref, {
					repState: 'read',
				});
			});
		}
	};

	// get a current and past seasons collection
	useEffect(() => {
		const getSeasons = async () => {
			const q = query(seasonsRef, limit(1));
			const seasonsDocs = await getDocs(q);
			let seasonsResults = [];
			seasonsDocs.forEach((doc) => {
				seasonsResults.push({ ...doc.data(), seasonId: doc.id });
			});
			setCurrentRef(seasonsResults[0]?.currentSeason);
			setPastRatingsRef(seasonsResults[0]?.pastSeason);
		};
		getSeasons();
	}, []);
	// get AqhSavedOptions if is a proposal saved in the database
	useEffect(() => {
		if (data.type === 'proposal' && data.id) {
			const proposalRef = doc(db, 'savedProposals', data.id);
			const getData = async () => {
				const docSnap = await getDoc(proposalRef);
				if (docSnap.exists() && docSnap.data()?.aqhSavedOptions) {
					// sort options by sortedOptions function
					const sortedResult = sortedOptions(docSnap.data()?.aqhSavedOptions);
					setAqhSavedOptions(sortedResult);
					// set option index to add new option
					const lastOptionIndex = sortedResult[sortedResult.length - 1]?.optionIndex;
					if (lastOptionIndex !== undefined && !isNaN(lastOptionIndex)) {
						setOptionIndex(lastOptionIndex + 1);
					}
				}
			};
			getData();
		}
	}, []);

	const openModal = async () => {
		if (openVacationTripsModal) {
			await verifyCreditsStep();
		}
	};

	useEffect(() => {
		if (proposalId) {
			const getData = async () => {
				const options = await getProposalOptions(proposalId);
				setAqhSavedOptions(options);
				// set option index to add new option
				const lastOptionIndex = options[options.length - 1]?.optionIndex;
				if (lastOptionIndex !== undefined && !isNaN(lastOptionIndex)) {
					setOptionIndex(lastOptionIndex + 1);
				}
				// disable bridge schedule button if one options was already accepten from the proposal.
				if (options && options.length > 0) {
					const acceptacion = [];
					options.forEach((option) => {
						if (option.accepted) {
							acceptacion.push(true);
						} else {
							acceptacion.push(false);
						}
					});
					if (
						(acceptacion.length > 0 && acceptacion.includes(true)) ||
						wasApproved
					) {
						setDisableBridgeDeal(true);
					}
				}
				// get bridge deal from first option to show in bridge schedule otherwise show empty bridge schedule
				const bridgeDeal = await getProposalBridgeDeal(proposalId);
				setBridgeObj(bridgeDeal);
				// check if there is a bridge agreement in the proposal, when rep answered yes or no
				const hasBridgeDeal = await getHasBridgeDeal(proposalId);
				if (hasBridgeDeal) {
					setHasBridgeDeal(hasBridgeDeal);
				} else {
					setHasBridgeDeal('');
				}
			};
			getData();
		}
	}, [proposalId]);

	useEffect(() => {
		const getData = () => {
			if (
				data?.type === 'proposal' &&
				data.aqhSavedOptions &&
				data.aqhSavedOptions.length > 0
			) {
				const savedDates = {
					startDate: {
						value: new Date(data.aqhSavedOptions[0].dates.startDate.seconds * 1000),
						isChanged: true,
					},
					endDate: {
						value: new Date(data.aqhSavedOptions[0].dates.endDate.seconds * 1000),
						isChanged: true,
					},
					noOfWeeks: {
						value: data.aqhSavedOptions[0].dates.noOfWeeks,
						isChanged: true,
					},
				};
				updateRepMessageState(data.id ? data.id : null);
				setDateState(savedDates);
				setAllInputs(data.aqhSavedOptions[0].allInputs);
				const resultAqhData = [data.aqhSavedOptions[0].dayParts];
				var dataOptionTable = resultAqhData.map(function (obj) {
					return Object.keys(obj)
						.sort()
						.map(function (key) {
							return obj[key];
						});
				});
				setProposalTable(...dataOptionTable);
				setIsProposal(true);
				setWasApproved(
					data.proposalState ? data.proposalState === 'approved' : false
				);
				setProposalState(data.proposalState ? data.proposalState : 'unread');
				setAqhSavedOptions(data.aqhSavedOptions);
				// show first vacation credits or rewards
				if (data.aqhSavedOptions[0].vacationCredits) {
					setVacationCredits(data.aqhSavedOptions[0].vacationCredits);
				} else if (data.aqhSavedOptions[0].rewards) {
					setRewards(data.aqhSavedOptions[0].rewards);
				}
				// set option index to add new option
				const lastOptionIndex =
					data.aqhSavedOptions[data.aqhSavedOptions.length - 1]?.optionIndex;
				if (lastOptionIndex !== undefined && !isNaN(lastOptionIndex)) {
					setOptionIndex(lastOptionIndex + 1);
				}
				// verify if proposal has bridge deal in the first option
				if (data?.bridgeDeal) {
					setBridgeObj(data.bridgeDeal);
				}
				// verify if exist a bridge deal in the proposal
				if (data?.hasBridgeDeal) {
					setHasBridgeDeal(data?.hasBridgeDeal);
				} else {
					setHasBridgeDeal('');
				}
				//Verified tag exists
				if (data?.tagProposal) {
					setTagProposal(data?.tagProposal);
				} else {
					setTagProposal('');
				}
			}
		};
		getData();
	}, [data]);

	useEffect(() => {
		if (dateState.startDate.value && dateState.noOfWeeks.value > 0) {
			setOpenAlert(false);
			setDisabledByDates(false);
		} else if (dateState.endDate.value && dateState.noOfWeeks.value > 0) {
			setOpenAlert(false);
			setDisabledByDates(false);
		} else if (dateState.endDate.value && dateState.startDate.value) {
			setOpenAlert(false);
			setDisabledByDates(false);
		} else {
			setOpenAlert(true);
			setDisabledByDates(true);
		}
	}, [
		dateState.startDate.value,
		dateState.noOfWeeks.value,
		dateState.endDate.value,
	]);

	useEffect(() => {
		const getData = async () => {
			if (messageId) {
				// fetch live message details changes
				const docRef = doc(db, 'reviewMessages', messageId);
				const document = await getDoc(docRef);
				if (document.exists()) {
					// update document state to "read" when admin open the message.
					updateDocument(
						document.data()?.state,
						messageId,
						user,
						messageProposalId,
						false
					);
					setToEmail(document.data()?.employeeEmail);
					setMessage(document.data()?.comments || 'empty message');
				}
			} else if (data?.denyReason && data?.denyReason !== '') {
				setMessage(data?.denyReason);
			}
		};
		getData();
	}, [data?.denyReason, messageId, messageProposalId, user]);

	useEffect(() => {
		// set calculator data with proposalId from message
		if (messageProposalId) {
			setProposalId(messageProposalId);
			const docRef = doc(db, 'savedProposals', messageProposalId);
			const unsub = onSnapshot(docRef, (doc) => {
				if (
					doc.exists() &&
					doc.data()?.aqhSavedOptions &&
					doc.data()?.aqhSavedOptions.length > 0
				) {
					// decompress dayparts if needed
					const decompressedDayParts = decompressDayParts(
						doc.data()?.aqhSavedOptions
					);
					const firstOption = decompressedDayParts[0];
					if (
						firstOption?.dates &&
						firstOption?.dates?.startDate &&
						firstOption?.dates?.noOfWeeks &&
						firstOption?.dates?.endDate
					) {
						const savedDates = {
							startDate: {
								value: new Date(firstOption?.dates?.startDate.seconds * 1000),
								isChanged: true,
							},
							endDate: {
								value: new Date(firstOption?.dates?.endDate?.seconds * 1000),
								isChanged: true,
							},
							noOfWeeks: {
								value: firstOption?.dates?.noOfWeeks,
								isChanged: true,
							},
						};
						setDateState(savedDates);
					}

					if (firstOption.allInputs) {
						setAllInputs(firstOption?.allInputs);
					}

					if (firstOption?.dayParts) {
						const values = Object.values(firstOption?.dayParts);
						setTableData(values);
					}

					if (doc.data()?.aqhSavedOptions) {
						// sort options by sortedOptions function
						const sortedResult = sortedOptions(doc.data()?.aqhSavedOptions);
						setAqhSavedOptions(sortedResult);
						// set option index to add new option
						const lastOptionIndex =
							sortedResult[sortedResult.length - 1]?.optionIndex;
						if (lastOptionIndex !== undefined && !isNaN(lastOptionIndex)) {
							setOptionIndex(lastOptionIndex + 1);
						}
					}
					if (doc.data()?.bridgeDeal) {
						setBridgeObj(doc.data()?.bridgeDeal);
					}
					if (doc.data()?.hasBridgeDeal) {
						setHasBridgeDeal(doc.data()?.hasBridgeDeal);
					} else {
						setHasBridgeDeal('');
					}
				}
			});
			return () => unsub();
		}
	}, [messageProposalId]);

	// useCallback to memoize functions
	const getMainStation = useCallback(
		(data) => {
			let copyDataArr = [];
			if (data?.length > 0) {
				data.map((c) => {
					let newObj = {};
					rowHeadersName.map((name) =>
						Object.assign(newObj, {
							[name]:
								name === 'Station' || name === 'Format'
									? c[name]
									: {
											orignalValue: c[name] ? c[name] : c?.Simulcast ? c?.Simulcast : 0,
											noOfSpots: 0,
									  },
						})
					);
					return copyDataArr.push(Object.values(newObj));
				});
			}
			// Get simulcast stations
			const simulcastStations = copyDataArr.map((nested) =>
				nested.map((element) =>
					typeof element['orignalValue'] === 'string' ? element['orignalValue'] : ''
				)
			);
			// Filter empty simulcast
			const filterEmptySimulcast = simulcastStations.flatMap((simulcast) =>
				simulcast.filter(Boolean)
			);
			// Get the most frequent item
			const mostFreq = _.head(
				_(filterEmptySimulcast).countBy().entries().maxBy(_.last)
			);
			setMainStation(mostFreq);
		},
		[rowHeadersName]
	);

	const separateSimulcast = useCallback(() => {
		const simulcasts = selectedRowsAqh.map((row) =>
			addDashSimulcast(row?.Simulcast)
		);
		let simulcastArr = [];
		simulcasts.forEach((simulcast) => {
			if (!simulcast) {
				return [];
			}
			if (simulcast.length > 7) {
				simulcastArr.push(...simulcast.split('/'));
			} else {
				simulcastArr.push(simulcast);
			}
		});
		const simulcastObj = simulcastArr.map((s) => s).join(',');
		setSimulcast(simulcastObj);
	}, [selectedRowsAqh]);

	const processSelectedRowsAqh = useCallback(() => {
		// Searches for simulcast stations and generates a new list of objects to use
		if (selectedRowsAqh && selectedRowsAqh.length > 0) {
			// We filter the stations that come from the filter and do not have ratings
			// Verify if it is a new proposal or one that is being loaded
			const filteredWithOutPastBookRows =
				data.type === 'proposal'
					? selectedRowsAqh
					: selectedRowsAqh.filter((v) => v.pastBook);
			// Get all stations from selectedRowsAqh
			let allStations = filteredWithOutPastBookRows.map((v) => v.Station);
			// Get all simulcast stations from selectedRowsAqh
			let getSimulcasts = filteredWithOutPastBookRows.map((v) =>
				addDashSimulcast(v?.Simulcast)
			);
			let simulcastStations = separateSimulcastArr(getSimulcasts);

			const newStations =
				simulcastStations.length > 0 && dataList
					? [...new Set([...allStations, ...simulcastStations])]
					: [...new Set(allStations)];

			const extractedNewStations = dataList
				? newStations.map((data) => {
						let getStations = dataList.find((s) => s['Station'] === data);
						return { ...getStations };
				  })
				: filteredWithOutPastBookRows;

			//verify if undefined exists in NewSelectedRows
			const filteredNewSelected =
				data.type === 'proposal'
					? extractedNewStations
					: extractedNewStations.filter(
							(element) => Object.keys(element).length !== 0 && element.pastBook
					  );

			// return necessary data object
			const necessaryData = filteredNewSelected.map((data) =>
				necessaryDataObject(data)
			);
			setNewSelectedRows(necessaryData);
		}
	}, [selectedRowsAqh, data.type, dataList]);

	useEffect(() => {
		if (
			!hasRunRef.current &&
			currentRef &&
			pastRatingsRef &&
			newSelectedRows &&
			newSelectedRows.length > 0
		) {
			// set hasRunRef to true to avoid re-run the effect
			hasRunRef.current = true;

			separateSimulcast();
			const ref = collection(db, currentRef);
			const pastRef = collection(db, pastRatingsRef);
			let allStations = newSelectedRows.map((v) => v.Station);
			allStations = [...new Set(allStations)];

			//verify if undefined exists in stations array
			const filteredStations = allStations.filter((x) => x !== undefined);
			let spliSalesIntoSmallArr = splitArrayIntoChunks(10, filteredStations);
			let batchArr = [];
			let batchArrPast = [];

			spliSalesIntoSmallArr.map(async (batch, idx) => {
				const q = query(ref, where('Station', 'in', batch));
				const pastQ = query(pastRef, where('Station', 'in', batch));

				let resultsPast = [];
				// Past Ratings
				const querySnapshot = await getDocs(pastQ);
				querySnapshot.docs.forEach((doc) => {
					resultsPast.push({ ...doc.data(), id: doc.id });
				});
				batchArrPast = [...batchArrPast, ...resultsPast];

				// Current Ratings
				const currQuerySnapshot = await getDocs(q);
				let results = [];
				currQuerySnapshot.docs.forEach((doc) => {
					results.push({ ...doc.data(), id: doc.id });
				});
				batchArr = [...batchArr, ...results];

				let ratingsArr = batchArr.map((data) => {
					let getAqh = batchArrPast?.find(
						(sprObj) => sprObj['Station'] === data['Station']
					);
					return { ...data, pastBook: getAqh };
				});

				if (spliSalesIntoSmallArr.length === idx + 1) {
					// use a filterd newSelectedRows
					let mergeData = newSelectedRows.map((data) => {
						let getAqh = ratingsArr?.find(
							(sprObj) => sprObj['Station'] === data['Station']
						);
						return {
							...getAqh,
							Simulcast: data.Simulcast ? addDashSimulcast(data.Simulcast) : '',
							pastBook: getAqh.pastBook,
						};
					});
					setUpdatedAqh(getUniqueArr(mergeData, ['Station', 'id']));
					getMainStation(getUniqueArr(mergeData, ['Station', 'id']));
				}
			});
		}
	}, [
		currentRef,
		pastRatingsRef,
		newSelectedRows,
		getMainStation,
		separateSimulcast,
	]);

	useEffect(() => {
		processSelectedRowsAqh();
	}, [selectedRowsAqh, processSelectedRowsAqh]);

	// useEffect to tableData
	useEffect(() => {
		const getTableData = () => {
			//Check the main station to show your aqh
			const mainStationVerify = (simulcast) => {
				if (mainStation !== simulcast) {
					return 0;
				} else {
					return 'S ' + simulcast;
				}
			};
			//Check the main station to display the proper color
			const mainStationColor = (simulcast) => {
				if (mainStation !== simulcast) {
					return 'mainStation';
				} else {
					return 'simulcastStation';
				}
			};

			let dataArr = [];
			if (updateAqh?.length > 0) {
				updateAqh.map((c) => {
					let obj = {};
					rowHeadersName.map((name) =>
						Object.assign(obj, {
							[name]:
								name === 'Station' || name === 'Format'
									? c[name]
									: {
											orignalValue: c[name]
												? newAQH(c[name], c.pastBook[name], c.Format, c.Station)
												: c?.Simulcast
												? mainStationVerify(c?.Simulcast)
												: 0,
											noOfSpots: 0,
											colorDayPart: mainStationColor(c?.Simulcast),
									  },
						})
					);
					return dataArr.push(Object.values(obj));
				});
			}
			if (proposalTable?.length > 0) {
				// Create a Map from proposalTable
				const proposalMap = new Map(proposalTable.map((item) => [item[0], item]));

				// Update proposalTable with new data dataArr
				dataArr.forEach((item) => {
					const key = item[0]; // Station
					if (proposalMap.has(key)) {
						const proposalItem = proposalMap.get(key);
						item.slice(2).forEach((data, index) => {
							// Check if the daypart already exists in proposalTable
							if (typeof proposalItem[index + 2] === 'object') {
								proposalItem[index + 2] = {
									...proposalItem[index + 2], // Preserve other properties
									orignalValue:
										data.orignalValue || proposalItem[index + 2].orignalValue, // Update orignalValue
									// Keep the noOfSpots if it exists, otherwise use the noOfSpots from data
									noOfSpots:
										proposalItem[index + 2].noOfSpots !== undefined
											? proposalItem[index + 2].noOfSpots
											: data.noOfSpots,
								};
							} else {
								proposalItem[index + 2] = data;
							}
						});
					} else {
						// If the station does not exist in proposalTable, add it
						proposalTable.push(item);
					}
				});
				setTableData(proposalTable);
			} else {
				// this remove a infinite loop that I found with this useEffect.
				if (!messageId) {
					if (
						previousDealsData &&
						previousDealsData.length > 0 &&
						dataArr &&
						dataArr.length > 0
					) {
						previousDealsData.forEach((deal) => {
							// get table index to replace it after find same daypart on saved previous deal data
							const index = dataArr.findIndex((item) => item[0] === deal.station);
							// There are cases that the records have uppercase letters by mistake,
							// they are transformed to lowercase letters
							deal.dayPart = deal.dayPart.toLowerCase();
							if (index !== -1) {
								if (deal?.dayPart) {
									// two ifs handle unique dayparts from previous deals
									if (
										deal?.dayPart === '6a-12m' ||
										deal?.dayPart === '12m-6a' ||
										deal?.dayPart === '6a-12n'
									) {
										const dayPartIndex =
											tableRowHeadersName.findIndex(
												(daypart) => daypart === `MSu ${deal?.dayPart}`
											) + 2;
										const SSDayPartIndex =
											tableRowHeadersName.findIndex(
												(daypart) => daypart === `SS ${deal?.dayPart}`
											) + 2;
										if (
											dayPartIndex !== -1 &&
											typeof dataArr[index][dayPartIndex] === 'object'
										) {
											// previous deals has Minutes per weeks so we need to divide by 7 to get the number of spots
											dataArr[index][dayPartIndex].noOfSpots = deal?.noOfSpots / 7 || 0;
										} else if (
											SSDayPartIndex !== -1 &&
											typeof dataArr[index][SSDayPartIndex] === 'object'
										) {
											// previous deals has Minutes per weeks so we need to divide by 2 to get the number of spots
											dataArr[index][SSDayPartIndex].noOfSpots = deal?.noOfSpots / 2 || 0;
										}
									} else {
										// this get the index of the daypart from the table from previous deals adding MF, Sat or Sun to the daypart
										const dayPartIndex =
											tableRowHeadersName.findIndex(
												(daypart) => daypart === `MF ${deal?.dayPart}`
											) + 2;
										const satDayPartIndex =
											tableRowHeadersName.findIndex(
												(daypart) => daypart === `Sat ${deal?.dayPart}`
											) + 2;
										const sunDayPartIndex =
											tableRowHeadersName.findIndex(
												(daypart) => daypart === `Sun ${deal?.dayPart}`
											) + 2;
										// set the number of spots from previous deal
										if (
											dayPartIndex !== -1 &&
											typeof dataArr[index][dayPartIndex] === 'object'
										) {
											// previous deals has Minutes per weeks so we need to divide by 5 to get the number of spots
											dataArr[index][dayPartIndex].noOfSpots = deal?.noOfSpots / 5 || 0;
										} else if (
											satDayPartIndex !== -1 &&
											typeof dataArr[index][satDayPartIndex] === 'object'
										) {
											dataArr[index][satDayPartIndex].noOfSpots = deal?.noOfSpots || 0;
										} else if (
											sunDayPartIndex !== -1 &&
											typeof dataArr[index][sunDayPartIndex] === 'object'
										) {
											dataArr[index][sunDayPartIndex].noOfSpots = deal?.noOfSpots || 0;
										}
									}
								}
							}
						});
					}
					setTableData(dataArr);
				}
			}

			setLoading(false);
		};
		getTableData();
	}, [setTableData, updateAqh]);

	const handleChange = (name, value) => {
		if (name === 'startDate') {
			// use startOfDay to avoid time zone issues
			// set the date to the start of the day in the start date
			const localStartDate = startOfDay(value);
			setDateState({
				...dateState,
				startDate: { value: localStartDate, isChanged: true },
			});
		} else if (name === 'endDate') {
			// use startOfDay to avoid time zone issues
			// set the date to the start of the day in the end date
			const localEndDate = startOfDay(value);
			setDateState({
				...dateState,
				endDate: { value: localEndDate, isChanged: true },
			});
		} else if (name === 'noOfWeeks') {
			// validate only integer numbers from 0 to 9, with regex.
			const regex = /^[0-9\b]+$/;
			if ((value === '' || regex.test(value)) && value <= 1000) {
				// limited because show an error with dates after 1000 weeks
				setDateState({
					...dateState,
					noOfWeeks: { value: value, isChanged: true },
				});
			} else if ((value === '' || regex.test(value)) && value > 1000) {
				// if user type more than 1000 weeks by default set 1000 weeks
				setDateState({ ...dateState, noOfWeeks: { value: 1000, isChanged: true } });
			}
		}
	};

	const allDatesInput = useMemo(() => {
		let dates = {
			endDate: endDate.value,
			startDate: startDate.value,
			noOfWeeks: noOfWeeks.value,
		};
		if (startDate.value && noOfWeeks.value > 0) {
			const wks = addWeeks(startDate.value, noOfWeeks.value);
			const endDate = previousFriday(wks);
			dates = { ...dates, endDate };
		} else if (endDate.value && noOfWeeks.value > 0) {
			const wks = subWeeks(endDate.value, noOfWeeks.value);
			const startDate = nextMonday(wks);
			dates = { ...dates, startDate };
		} else if (startDate.value && endDate.value) {
			const noOfWeeks = differenceInCalendarWeeks(endDate.value, startDate.value, {
				weekStartsOn: 1,
			});
			dates = { ...dates, noOfWeeks: noOfWeeks + 1 };
		}
		return dates;
	}, [startDate, noOfWeeks, endDate]);

	const checkDisabled = (name) => {
		if (name === 'startDate') {
			return noOfWeeks.isChanged && endDate.isChanged ? true : false;
		} else if (name === 'endDate') {
			return noOfWeeks.isChanged && startDate.isChanged ? true : false;
		} else if (name === 'noOfWeeks') {
			return startDate.isChanged && endDate.isChanged ? true : false;
		}
	};

	const handleResetInputs = () => setDateState(initialDateState);

	const removeSelectedStation = (stationName) => {
		let copyTableData = [...tableData];
		copyTableData = copyTableData.filter(
			(stationArr) => stationArr[0] !== stationName
		);
		setTableData(copyTableData);
	};

	const getWeeklySeconds = (noOfSpots, dayPartString) => {
		const seconds = noOfSpots * 2;
		return seconds * getNumberOfDays(dayPartString);
	};

	const getLiveTableData = useMemo(() => {
		let getData = {};
		tableData.length > 0 &&
			tableData.forEach((col) =>
				col.forEach((val, rowIdx) => {
					if (typeof val === 'object' && val?.noOfSpots > 0) {
						handleCptRatioChange((prev) =>
							prev[1] > cptConstant[rowHeadersName[rowIdx]][1]
								? prev
								: cptConstant[rowHeadersName[rowIdx]]
						);
						const dayPartString = rowHeadersName[rowIdx].slice(0, 3);
						const aqh =
							typeof val?.orignalValue === 'string' ? 0 : val?.orignalValue || 0;
						const gross =
							(aqh / 128340600) *
							100 *
							2200 *
							getWeeklySeconds(val?.noOfSpots, dayPartString) *
							allDatesInput.noOfWeeks *
							0.85;
						getData[`${col[0]}@${val?.noOfSpots}/${rowIdx}`] = {
							orignalValue: aqh || 0,
							noOfSpots: val.noOfSpots,
							dayPartName: rowHeadersName[rowIdx],
							impressions: Number(
								aqh * val.noOfSpots * 2 * getNumberOfDays(dayPartString)
							),
							gross: Number(gross).toFixed(2),
							4.1: Number(gross / 4.1).toFixed(2),
							695: Number(gross / 695).toFixed(2),
							4.2: Number(gross / 4.2).toFixed(2),
							705: Number(gross / 705).toFixed(2),
							4.3: Number(gross / 4.3).toFixed(2),
							715: Number(gross / 715).toFixed(2),
							4.4: Number(gross / 4.4).toFixed(2),
							725: Number(gross / 725).toFixed(2),
							4.5: Number(gross / 4.5).toFixed(2),
							735: Number(gross / 735).toFixed(2),
							4.6: Number(gross / 4.6).toFixed(2),
							745: Number(gross / 745).toFixed(2),
						};
					} // if ends
				})
			);
		return getData;
	}, [tableData, allDatesInput.noOfWeeks]);

	const grossTotal = useMemo(() => {
		let total = [];
		Object.keys(getLiveTableData).filter((key) =>
			total.push(Number(getLiveTableData[key].gross))
		);
		total = total.reduce((a, b) => a + b, 0);
		return Number(total).toFixed(2);
	}, [getLiveTableData]);

	const cpt = useMemo(() => {
		return allInputs[0].cogs > 0
			? Number(allInputs[0].revenue / allInputs[0].cogs).toFixed(2)
			: 0;
	}, [allInputs[0]]);

	const sumOfRatio = useMemo(() => {
		let filterInputs = allInputs.filter((input) => input.name !== 'Vacations');
		let sumOfRevenues = filterInputs.reduce(
			(n, { revenue }) => n + Number(revenue),
			0
		);
		let sumOfCogs = filterInputs.reduce((n, { cogs }) => n + Number(cogs), 0);
		return sumOfCogs > 0 ? Number(sumOfRevenues / sumOfCogs).toFixed(2) : 0;
	}, [allInputs]);

	const remainingTotal = useMemo(() => {
		let sumOfRevenues = allInputs.reduce(
			(n, { revenue }) => n + Number(revenue),
			0
		);
		return Number(grossTotal - sumOfRevenues).toFixed(2);
	}, [allInputs]);

	React.useEffect(() => {
		if (user) {
			const getData = async () => {
				const docRef = doc(db, 'users', user.uid);
				const document = await getDoc(docRef);

				setUserState((prev) => ({
					...prev,
					employee_id: document.data()?.employeeId || '',
					employee_name: `${document.data()?.firstName} ${
						document.data()?.lastName
					}`,
				}));
			};
			getData();
		}
	}, [user]);

	React.useEffect(() => {
		let getData = {};
		if (Object.keys(getLiveTableData).length > 0) {
			Object.entries(getLiveTableData).forEach(([key, value]) => {
				let stationName = String(key).split('@')[0];
				getData[`${stationName}@${value.dayPartName}`] = value;
			});
			setNoOfEditRows(Object.keys(getData).length);
		}
		setDataToSave(getData);
	}, [getLiveTableData]);

	React.useEffect(() => {
		let mainObj = {
			...dataToSave,
			...userState,
			startDate: allDatesInput.startDate
				? format(allDatesInput.startDate, 'MM/dd/yy')
				: '',
			endDate: allDatesInput.endDate
				? format(allDatesInput.endDate, 'MM/dd/yy')
				: '',
			noOfWeeks: allDatesInput.noOfWeeks,
			dealCalculations: allInputs,
			remainingTotal,
			cpt,
			grossTotal,
			sumOfRatio,
			getLiveTableData,
			simulcast,
			selectedRowsAqh: newSelectedRows,
			grossWeekly,
			vacationCredits: vacationCredits ? vacationCredits : currentVacationCredits,
			rewards: rewards ? rewards : currentRewards,
			proposalId: data?.type === 'proposal' ? data?.id : '',
			bridgeDeal: data?.bridgeDeal
				? data?.bridgeDeal
				: bridgeObj
				? bridgeObj
				: null,
			dealTerms: data?.dealTerms ? data?.dealTerms : null,
		};
		// if there are selected rows, update the main object
		if (newSelectedRows.length > 0) {
			setMainObj(mainObj);
		}
	}, [
		dataToSave,
		userState,
		allDatesInput,
		allInputs,
		remainingTotal,
		cpt,
		grossTotal,
		sumOfRatio,
		getLiveTableData,
		startDate,
		endDate,
		noOfWeeks,
		simulcast,
		newSelectedRows,
		grossWeekly,
		vacationCredits,
		rewards,
		data,
		currentVacationCredits,
		currentRewards,
	]);

	const handleClosePrint = () => {
		setOpenPrint(false);
	};

	// Check if the question of whether a bridge deak exists was answered
	const verifyOpenBridgeDealModal = () => {
		const confirmation =
			hasBridgeDeal && (hasBridgeDeal === 'Yes' || hasBridgeDeal === 'No')
				? false
				: true;
		if (confirmation && confirmation === true) {
			setIsBridgeDealModal(confirmation);
		}
	};

	const handleBlurWeeks = () => {
		// if hasBridgeDeal is 'Yes' or 'No' open modal
		verifyOpenBridgeDealModal();
	};

	const handleKeyDownWeeks = (event) => {
		if (event.key === 'Enter') {
			// if hasBridgeDeal is 'Yes' or 'No' open modals
			verifyOpenBridgeDealModal();
		}
	};

	const MessagesDetails = () => {
		if (messageId) {
			return (
				<Grid container spacing={2}>
					<Grid container item xs={2.5} direction="column">
						<Button
							variant="contained"
							color="success"
							disabled={loadingApprove}
							startIcon={loadingApprove && <CircularProgress size={18} />}
							onClick={aproveDeal}
						>
							{loadingApprove ? 'Loading' : 'Approve'}
						</Button>
						<Button
							variant="contained"
							color="error"
							sx={{ marginTop: '20px' }}
							onClick={denyDeal}
						>
							Deny
						</Button>
					</Grid>
					<Grid container item xs={9}>
						<FormControl fullWidth sx={{ m: 0.5 }}>
							<TextField
								disabled
								id="outlined-multiline-flexible"
								label="Message"
								InputLabelProps={{
									style: { color: '#000000', fontSize: '22px' },
								}}
								sx={{
									'& .MuiInputBase-input.Mui-disabled': {
										WebkitTextFillColor: '#6D6D6D',
									},
									'& fieldset': { border: 'none' },
								}}
								multiline
								maxRows={3}
								value={message}
							/>
						</FormControl>
					</Grid>
				</Grid>
			);
		} else if (data?.denyReason) {
			return (
				<Grid container spacing={2}>
					<Grid container item xs={9}>
						<FormControl fullWidth sx={{ m: 0.5 }}>
							<TextField
								disabled
								id="outlined-multiline-flexible"
								label="Deny Reason"
								InputLabelProps={{
									style: { color: '#000000', fontSize: '22px' },
								}}
								sx={{
									'& .MuiInputBase-input.Mui-disabled': {
										WebkitTextFillColor: '#6D6D6D',
									},
									'& fieldset': { border: 'none' },
								}}
								multiline
								maxRows={3}
								value={message}
							/>
						</FormControl>
					</Grid>
				</Grid>
			);
		}
	};

	const sendApproveEmail = async (dealName) => {
		if (user && user.email) {
			try {
				await sendReviewEmailMessage(
					`your deal ${dealName ? dealName : ''} was approved`,
					toEmail
				);
				enqueueSnackbar('deal approved!', { variant: 'success' });
				await updateDocument('approved', messageId, user, messageProposalId, true);
			} catch (error) {
				console.log('Error:'`${error}`);
			}
		}
	};

	const updateProposalData = async () => {
		try {
			setProposalState('approved');
			setIsManager(true);
			let mainObj = {
				proposalState: proposalState,
			};

			if (proposalId) {
				const documentRef = doc(db, 'savedProposals', proposalId);
				await updateDoc(documentRef, mainObj);
			} else if (messageProposalId) {
				const documentRef = doc(db, 'savedProposals', messageProposalId);
				await updateDoc(documentRef, mainObj);
			}
		} catch (error) {
			console.log('Error:'`${error}`);
		}
	};

	const aproveDeal = async () => {
		if (user && user.email) {
			setLoadingApprove(true);
			try {
				const docRef = doc(db, 'reviewMessages', messageId);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					const email = docSnap.data()?.employeeEmail || null;
					if (email) {
						const q = query(collection(db, 'users'), where('email', '==', email));
						const querySnapshot = await getDocs(q);
						const document = querySnapshot.docs[0];
						if (document.exists()) {
							const userId = document.id;
							setUserId(userId);
						}
					}
				}
				await updateProposalData();
				// send text message to rep
				const messageRep = `Your deal was approved.\nDeal Name: ${
					docSnap?.data()?.dealName
				}`;
				await sendTextMessage(messageRep, docSnap.data()?.employeeEmail);
				// send email to rep
				await sendApproveEmail(docSnap?.data()?.dealName);
				setLoadingApprove(false);
			} catch (error) {
				setLoadingApprove(false);
				console.log('Error:'`${error}`);
			}
		}
		setLoadingApprove(false);
	};

	const denyDealAction = async () => {
		if (user && user.email) {
			setProposalState('denied');
			await updateDocument('denied', messageId, user, messageProposalId, true);
		}
	};

	const denyDeal = async () => {
		if (user && user.email) {
			setOpenDenyModal(true);
		}
	};

	const verifyCreditsStep = async () => {
		if (
			allInputs[0].name === 'Vacations' &&
			allInputs[0].cogs !== '' &&
			allInputs[0].cogs !== '0' &&
			allInputs[0].cogs !== 0
		) {
			setOpenCreditsModal(true);
		} else if (
			allInputs[0].cogs === '' &&
			(allInputs[1].cogs !== '' ||
				allInputs[2].cogs !== '' ||
				allInputs[3].cogs !== '')
		) {
			setOpenRewardsModal(true);
		} else {
			const dates = {
				startDate: allDatesInput.startDate,
				noOfWeeks: allDatesInput.noOfWeeks,
				endDate: allDatesInput.endDate,
			};
			let mainObj = {
				filters: {
					filters: filtersAtom,
					filterDataList: filterDataListAtom,
				},
			};
			try {
				const generalObject = () => {
					return {
						mainObj,
						vacationCredits,
						rewards,
						optionData,
						isAqhOption,
						isEditAqhOption,
						setOptionId,
						aqhSavedOptions,
						docId: proposalId,
						optionId,
						createNewOne: isNewOne,
						proposalObj,
						setProposalId,
						setAqhSavedOptions,
					};
				};
				await updateOrCreateOptionData(generalObject());
				setOpenVacationTripsModal(false);
				setIsNewOne(false);
			} catch (error) {
				console.log(error);
			}

			enqueueSnackbar('this proposal does not have vacation credits', {
				variant: 'warning',
			});

			if (isPublish) {
				if (proposalId) {
					const options = await getProposalOptions(proposalId);
					// validate if has vacations credits
					const result = await validateOptionErrors(options);
					const vacationsResult = await validateVacationCredits(options);
					// if one saved option in the proposal has vacations and errors, show airports modal
					if (vacationsResult.includes(true) && result.includes(true)) {
						setOpenAirportsModal(true);
					} else if (!vacationsResult.includes(true) && result.includes(true)) {
						// If dealTerms is true, show Rollover modal
						if (dealTerms !== null) {
							setOpenRolloverModal(true);
						} else {
							// if one saved option in the proposal has errors, show manager override modal
							setOpenOverrideModal(true);
						}
					} else if (vacationsResult.includes(true) && !result.includes(true)) {
						// if one saved option in the proposal has vacations, show airports modal
						setOpenAirportsModal(true);
					} else {
						// If dealTerms is true, show Rollover modal
						if (dealTerms !== null) {
							setOpenRolloverModal(true);
						} else {
							// open publish quote modal
							setOpenPublishModal(true);
						}
					}
				} else {
					// If dealTerms is true, show Rollover modal
					if (dealTerms !== null) {
						setOpenRolloverModal(true);
					} else {
						// open publish quote modal
						setOpenPublishModal(true);
					}
					// setOpenPublishModal(true);
				}
			}
		}
	};

	useEffect(() => {
		openModal();
	}, [openVacationTripsModal]);

	const handleAddMoreStations = () => {
		navigate('/dashboard/AQH');
	};
	// Textfield props for datepicker
	const textDatePickerProps = {
		disabled: false, // false allows you to open the date picker from the texfield
		size: 'small',
		sx: { minWidth: '130px', maxWidth: '140px' },
	};

	return (
		<MainBox>
			<Grid container spacing={2}>
				{' '}
				{!messageId ? <TopGrid item xs={12} sm={12} md={4} lg={5}></TopGrid> : ''}
				<Grid
					container
					sx={{
						justifyContent: 'space-between',
						alignItems: 'center',
						paddingLeft: '14px',
						paddingRight: '10px',
					}}
				>
					<Grid item xs={6}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: '0px',
									flexWrap: 'wrap',
								}}
							>
								<MessagesDetails />
								<Grid container spacing={1}>
									<Grid item md={4.5} lg={2.7}>
										<DatePicker
											open={openStartDate}
											onOpen={() => setOpenStartDate(true)}
											onClose={() => setOpenStartDate(false)}
											components={{ OpenPickerIcon: CalendarMonthIcon }}
											label="Start Date"
											value={allDatesInput.startDate}
											onChange={(newValue) => handleChange('startDate', newValue)}
											disabled={checkDisabled('startDate')}
											shouldDisableDate={(day) => !isMonday(day)}
											InputProps={{
												value: allDatesInput.startDate
													? format(allDatesInput.startDate, 'MM/dd/yy')
													: '',
												inputProps: { readOnly: true },
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													{...textDatePickerProps}
													onClick={() => setOpenStartDate(true)}
												/>
											)}
										/>
									</Grid>
									<Grid item md={3.3} lg={2}>
										<TextField
											name="noOfWeeks"
											size="small"
											variant="outlined"
											label="No of Weeks"
											onBlur={handleBlurWeeks}
											onKeyDown={handleKeyDownWeeks}
											sx={{ minWidth: '100px', maxWidth: '130px' }}
											value={allDatesInput.noOfWeeks}
											onChange={(e) => handleChange('noOfWeeks', e.target.value)}
											disabled={checkDisabled('noOfWeeks')}
											type="text"
											autoComplete="off"
										/>
									</Grid>
									<Grid item md={2} lg={2}>
										<Box display="inline-flex" alignItems="center">
											<DatePicker
												open={openEndDate}
												onOpen={() => setOpenEndDate(true)}
												onClose={() => setOpenEndDate(false)}
												components={{ OpenPickerIcon: CalendarMonthIcon }}
												label="End Date"
												value={allDatesInput.endDate}
												onChange={(newValue) => handleChange('endDate', newValue)}
												disablePast={true}
												disabled={checkDisabled('endDate')}
												shouldDisableDate={(day) => !isFriday(day)}
												InputProps={{
													value: allDatesInput.endDate
														? format(allDatesInput.endDate, 'MM/dd/yy')
														: '',
													inputProps: { readOnly: true },
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														{...textDatePickerProps}
														onClick={() => setOpenEndDate(true)}
													/>
												)}
											/>
											<Tooltip title="Reset">
												<IconButton
													size="small"
													color="info"
													sx={{ marginLeft: '10px' }}
													onClick={handleResetInputs}
													disabled={wasApproved}
												>
													<RestartAltIcon />
												</IconButton>
											</Tooltip>
										</Box>
									</Grid>
								</Grid>
							</Box>
							<Box sx={{ width: '100%' }}>
								<Collapse in={openAlert}>
									<Alert sx={{ mb: 2 }} severity="info">
										Remember, first add Start Date and Number of Weeks!
									</Alert>
								</Collapse>
							</Box>
						</LocalizationProvider>
					</Grid>
					<Grid
						item
						xs={3}
						sx={{ display: 'flex', justifyContent: 'flex-end', gap: '2rem' }}
					>
						<BridgeSchedule
							proposalId={proposalId}
							setProposalId={setProposalId}
							setBridgeObj={setBridgeObj}
							bridgeObj={bridgeObj}
							disabled={disableBridgeDeal}
							aqhSavedOptions={aqhSavedOptions ? aqhSavedOptions : []}
							setOpenBridgeDealModal={setOpenBridgeDealModal}
							openBridgeDealModal={openBridgeDealModal}
							setOpenDeleteBridgeModal={setOpenDeleteBridgeModal}
							openDeleteBridgeModal={openDeleteBridgeModal}
							startDateNewOne={allDatesInput.startDate}
						/>
					</Grid>
					<Grid
						item
						xs={2}
						sx={{ display: 'flex', justifyContent: 'flex-end', gap: '2rem' }}
					>
						<ActionButtons>
							<Button
								variant="contained"
								color="info"
								size="small"
								onClick={() => {
									setOpenPrint(true);
									setOpenPdf(true);
								}}
								disabled={noOfEditRows === 0}
							>
								Print
							</Button>
							<PublishQuoteModal
								open={openPublishModal}
								setOpen={setOpenPublishModal}
								getLiveTableData={getLiveTableData}
								proposalId={data?.type === 'proposal' ? data?.id : proposalId}
								openSaveProposal={setOpen}
								setIsPublish={setIsPublish}
								setProposalId={setProposalId}
								messageProposalId={messageProposalId}
								disabledByDates={disabledByDates}
								aqhSavedOptions={aqhSavedOptions ? aqhSavedOptions : []}
								handleCptRatioChange={handleCptRatioChange}
								userId={user.uid}
								proposalState={data?.proposalState ? data.proposalState : ''}
								setOpenOverride={setOpenOverrideModal}
								userState={userState}
								bridgeDealData={bridgeObj}
								setPublishProcess={setPublishProcess}
								publishProcess={publishProcess}
								setOpenAirportsModal={setOpenAirportsModal}
								dealTerms={dealTerms}
								setOpenRolloverModal={setOpenRolloverModal}
							/>
							<SaveProposal
								open={open}
								setOpen={setOpen}
								rowHeadersName={rowHeadersName}
								tableData={tableData}
								getLiveTableData={getLiveTableData}
								allInputs={allInputs}
								selectedRowsAqh={newSelectedRows}
								userId={!messageId ? user.uid : userId}
								dates={{
									startDate: allDatesInput.startDate,
									noOfWeeks: allDatesInput.noOfWeeks,
									endDate: allDatesInput.endDate,
								}}
								setOpenVacationTripsModal={setOpenVacationTripsModal}
								isPublish={isPublish}
								messageProposalId={messageProposalId}
								disabledByDates={disabledByDates}
								setProposalId={setProposalId}
								setGrossWeekly={setGrossWeekly}
								cptRatio={cptRatio}
								setOptionData={setOptionData}
								user={user}
								setIsNewOne={setIsNewOne}
								setProposalObj={setProposalObj}
								openPublishQuoteModal={setOpenPublishModal}
								setIsPublish={setIsPublish}
								lowerDeal={lowerDeal}
								maxValues={maxValues}
								openOverrideModal={setOpenOverrideModal}
								bridgeObj={bridgeObj ? bridgeObj : null}
								dealTerms={dealTerms}
								setOpenAirportsModal={setOpenAirportsModal}
								rolloverCredits={rolloverCredits}
								rolloverData={rolloverData}
								setOpenRolloverModal={setOpenRolloverModal}
								hasBridgeDeal={hasBridgeDeal}
								tagProposal={tagProposal}
							/>
							<DeleteModal
								openDeleteModal={openDeleteModal}
								setOpenDeleteModal={setOpenDeleteModal}
								rowHeadersName={rowHeadersName}
								tableData={tableData}
								aqhDataSaved={aqhData}
							/>
						</ActionButtons>
					</Grid>
				</Grid>
			</Grid>
			<Grid marginBottom={1}>
				<Button variant="contained" onClick={handleAddMoreStations}>
					<AddOutlinedIcon size="small" />
					<div>Add More Stations</div>
				</Button>
			</Grid>
			<Grid container spacing={4}>
				<Grid
					item
					xs={12}
					md={12}
					lg={7}
					xl={7}
					sx={{ width: '60% !important', flex: 1 }}
				>
					<DayPartTable
						loading={loading}
						tableData={tableData}
						selectedRowsAqh={newSelectedRows}
						setTableData={setTableData}
						removeSelectedStation={removeSelectedStation}
						wasApproved={wasApproved}
						dates={{
							startDate: allDatesInput.startDate,
							noOfWeeks: allDatesInput.noOfWeeks,
						}}
						setIsBridgeDealModal={setIsBridgeDealModal}
						hasBridgeDeal={hasBridgeDeal}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={12}
					lg={5}
					xl={5}
					sx={{
						width: '40% !important',
						// display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'center',
						paddingRight: { sm: '1rem', md: '1rem', lg: '1rem' },
						paddingTop: '32px !important',
					}}
				>
					<LiveTableContainer
						tableData={tableData}
						setTableData={setTableData}
						getLiveTableData={getLiveTableData}
						aqhSavedOptions={aqhSavedOptions ? aqhSavedOptions : []}
						cptRatio={cptRatio}
						allInputs={allInputs}
						setAllInputs={setAllInputs}
						selectedRowsAqh={newSelectedRows || []}
						dates={{
							startDate: allDatesInput.startDate,
							noOfWeeks: allDatesInput.noOfWeeks,
							endDate: allDatesInput.endDate,
						}}
						setDateState={setDateState}
						lowerDeal={lowerDeal}
						handleLowDeal={setLowerDeal}
						maxValues={maxValues}
						handleMaxValues={setMaxValues}
						messageId={messageId}
						setGrossWeekly={setGrossWeekly}
						wasApproved={wasApproved}
						proposalId={data?.type === 'proposal' ? data?.id : proposalId}
						setAqhSavedOptions={setAqhSavedOptions}
						setIsAqhOption={setIsAqhOption}
						setIsEditAqhOption={setIsEditAqhOption}
						setOptionId={setOptionId}
						setOpenVacationTripsModal={setOpenVacationTripsModal}
						setOptionData={setOptionData}
						setIsPublish={setIsPublish}
						bridgeGross={bridgeObj ? bridgeObj.grossTotal : 0}
						dealTerms={dealTerms}
						setRolloverCredits={setRolloverCredits}
						bridgeObj={bridgeObj ? bridgeObj : null}
						setBridgeObj={setBridgeObj}
						optionIndex={optionIndex}
						setOptionIndex={setOptionIndex}
						setOpenBridgeDealModal={setOpenBridgeDealModal} // Bridge Deal modal
						openBridgeDealModal={openBridgeDealModal}
						setOpenDeleteBridgeModal={setOpenDeleteBridgeModal}
						openDeleteBridgeModal={openDeleteBridgeModal}
						disabledOptions={data.disabledOptions || false}
						limitsEdits={limitEdits}
						tagProposal={tagProposal}
						setTagProposal={setTagProposal}
						setVacationCredits={setVacationCredits}
						setRewards={setRewards}
					/>
				</Grid>
			</Grid>
			<VacationsModal
				openModal={openCreditsModal}
				onClose={() => setOpenCreditsModal(false)}
				docId={proposalId}
				optionId={optionId}
				setOptionId={setOptionId}
				openPublishQuoteModal={setOpenPublishModal}
				setVacationCredits={setVacationCredits}
				allInputs={allInputs}
				openOverrideModal={setOpenOverrideModal}
				proposalState={data?.proposalState ? data.proposalState : ''}
				isAqhOption={isAqhOption}
				isEditAqhOption={isEditAqhOption}
				optionData={optionData}
				aqhSavedOptions={aqhSavedOptions ? aqhSavedOptions : []}
				rewards={rewards}
				isNewOne={isNewOne}
				setIsNewOne={setIsNewOne}
				proposalObj={proposalObj}
				setProposalId={setProposalId}
				setAqhSavedOptions={setAqhSavedOptions}
				setOpenVacationTripsModal={setOpenVacationTripsModal}
				setOpenRewardsModal={setOpenRewardsModal}
				isPublish={isPublish}
				setOpenAirportsModal={setOpenAirportsModal}
				dealTerms={dealTerms}
				setOpenRolloverModal={setOpenRolloverModal}
				setCurrentVacationCredits={setCurrentVacationCredits}
			/>
			<RewardsModal
				allInputs={allInputs}
				docId={proposalId}
				optionId={optionId}
				setOptionId={setOptionId}
				setOpenRewardsModal={setOpenRewardsModal}
				openRewardsModal={openRewardsModal}
				setRewards={setRewards}
				openPublishQuoteModal={setOpenPublishModal}
				tableData={tableData}
				selectedRowsAqh={newSelectedRows || []}
				dates={{
					startDate: allDatesInput.startDate,
					noOfWeeks: allDatesInput.noOfWeeks,
					endDate: allDatesInput.endDate,
				}}
				isAqhOption={isAqhOption}
				isEditAqhOption={isEditAqhOption}
				optionData={optionData}
				aqhSavedOptions={aqhSavedOptions ? aqhSavedOptions : []}
				vacationCredits={vacationCredits}
				setAqhSavedOptions={setAqhSavedOptions}
				isNewOne={isNewOne}
				setIsNewOne={setIsNewOne}
				proposalObj={proposalObj}
				setProposalId={setProposalId}
				setOpenVacationTripsModal={setOpenVacationTripsModal}
				isPublish={isPublish}
				proposalState={data?.proposalState ? data.proposalState : ''}
				openOverrideModal={setOpenOverrideModal}
				setPublishProcess={setPublishProcess}
				setOpenAirportsModal={setOpenAirportsModal}
				dealTerms={dealTerms}
				setOpenRolloverModal={setOpenRolloverModal}
				setCurrentRewards={setCurrentRewards}
			/>
			<ManagerOverrideModal
				openOverride={openOverrideModal}
				setOpenOverride={setOpenOverrideModal}
				proposalId={proposalId}
				userId={user.uid}
				userState={userState}
				setPublishProcess={setPublishProcess}
			/>
			<DenyDealModal
				open={openDenyModal}
				setOpen={setOpenDenyModal}
				messageId={messageId}
				email={toEmail}
				action={denyDealAction}
				userId={user.uid}
			/>
			<AirportsModal
				proposalId={proposalId}
				openPublishQuoteModal={setOpenPublishModal}
				setOpenAirportsModal={setOpenAirportsModal}
				openAirportsModal={openAirportsModal}
				openOverrideModal={setOpenOverrideModal}
				setPublishProcess={setPublishProcess}
				dealTerms={dealTerms}
				setOpenRolloverModal={setOpenRolloverModal}
			/>
			<RolloverCreditsModal
				proposalId={proposalId}
				rolloverCredits={rolloverCredits}
				setRolloverCredits={setRolloverCredits}
				setOpenRolloverModal={setOpenRolloverModal}
				openRolloverModal={openRolloverModal}
				setRolloverData={setRolloverData}
				openPublishQuoteModal={setOpenPublishModal}
				openOverrideModal={setOpenOverrideModal}
				setPublishProcess={setPublishProcess}
			/>
			<IsBridgeComponent
				setIsBridgeDealModal={setIsBridgeDealModal}
				isBridgeDealModal={isBridgeDealModal}
				setHasBridgeDeal={setHasBridgeDeal}
				hasBridgeDeal={hasBridgeDeal}
				proposalId={data?.type === 'proposal' ? data?.id : proposalId}
				setProposalId={setProposalId}
				setOpenBridgeDealModal={setOpenBridgeDealModal}
				openBridgeDealModal={openBridgeDealModal}
			/>
			<Dialog
				open={openPrint}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setOpenPrint(false)}
				aria-describedby="alert-dialog-slide-description"
				sx={{
					'& .MuiDialog-paper': {
						minWidth: openPdf ? '95vw' : '340px',
						minHeight: openPdf && '97vh',
						overflowY: 'hidden',
						backgroundColor: 'white',
					},
				}}
			>
				<DialogTitle>Print</DialogTitle>
				<IconButton
					color="primary"
					size="small"
					sx={{
						width: 'fit-content',
						position: 'absolute',
						right: '3%',
						top: '2%',
					}}
					onClick={handleClosePrint}
				>
					<CloseOutlined />
				</IconButton>
				<GeneratePDF
					mainObj={mainObj}
					user={user}
					show={openPdf}
					print={'true'}
					showPdfPreview={true}
				/>
			</Dialog>
		</MainBox>
	);
};

export default AQHCalculator;
