/* eslint-disable no-unused-vars */
import { useState } from 'react';
import useAuthContext from '../hooks/useAuthContext';
import { db, auth } from '../firebase/config';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, doc, addDoc, setDoc } from 'firebase/firestore';
import { result } from 'lodash';

export const useRegister = () => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const { dispatch } = useAuthContext();

	const signup = async (email, password) => {
		setError(null);
		try {
			const res = await createUserWithEmailAndPassword(auth, email, password);
			// await addUser(res)
			return result.user;
			// dispatch({ type: 'LOGIN', payload: res.user })
		} catch (error) {
			setError(error);
			setLoading(false);
			setTimeout(() => {
				setError(null);
			}, 6000);
		}
	};

	return { error, signup };
};
