/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { combineSales } from '../../StationList/utils/utils';
import { db } from '../../../firebase/config';

const PreviousDeal = ({ dealTerms }) => {
	const [previousDeal, setPreviousDeal] = useState([]);
	const salesRef = collection(db, 'sales');

	useEffect(() => {
		const getPreviousDeal = async () => {
			const optionsData = query(salesRef, where('Deal #', '==', dealTerms));

			const unsub = onSnapshot(optionsData, (snapshot) => {
				const result = snapshot.docs.map((doc) => {
					return { ...doc.data(), id: doc.id };
				});
				// get unique deal with previous CPT and previous ratio in combineSales function
				const previous = combineSales(result);
				setPreviousDeal([previous]);
			});

			return unsub;
		};

		getPreviousDeal();
	}, []);

	const getPreviuosCPT = (obj) => {
		if (obj['Previous CPT'] !== '' || obj['Previous CPT'] !== '0') {
			return obj['Previous CPT'];
		} else {
			return '$ 0';
		}
	};

	const getPreviuosRatio = (obj) => {
		if (obj['Previous Ratio'] !== '' || obj['Previous Ratio'] !== '0.00') {
			return obj['Previous Ratio'];
		} else {
			return '0';
		}
	};

	return (
		<Box>
			{previousDeal && previousDeal.length > 0
				? previousDeal.map((obj, idx) => (
						<Box key={idx}>
							<Stack direction="row" spacing={1}>
								<Typography
									sx={{
										fontSize: 13,
										width: 110,
										fontWeight: 'bold',
										textAlign: 'right',
									}}
								>
									Deal #:
								</Typography>
								<Typography sx={{ fontSize: 13, width: 80 }}>
									{' '}
									{obj['Deal #'] ? obj['Deal #'] : 0}
								</Typography>
							</Stack>
							<Stack direction="row" spacing={1}>
								<Typography
									sx={{
										fontSize: 13,
										width: 110,
										fontWeight: 'bold',
										textAlign: 'right',
									}}
								>
									Previous CPT:{' '}
								</Typography>
								<Typography sx={{ fontSize: 13, width: 80 }}>
									{' '}
									{getPreviuosCPT(obj) ? getPreviuosCPT(obj) : '$ 0'}
								</Typography>
								<Typography
									sx={{
										fontSize: 13,
										width: 110,
										fontWeight: 'bold',
										textAlign: 'right',
									}}
								>
									Previous Ratio:{' '}
								</Typography>
								<Typography sx={{ fontSize: 13, width: 80 }}>
									{' '}
									{getPreviuosRatio(obj) ? getPreviuosRatio(obj) : '0'}
								</Typography>
							</Stack>
							<br />
						</Box>
				  ))
				: ''}
		</Box>
	);
};

export default PreviousDeal;
