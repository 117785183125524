/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import EmployeeData from './components/EmployeeData';
import GeneralData from './components/GeneralData';
import { styled } from '@mui/material/styles';
import { Tabs, Tab, Box } from '@mui/material';
import TabPanel from '../Documents/components/TabPanel';
import EditVacationCredits from './components/EditVacationCredits';
import UpdateRatings from './components/UpdateRatings';
import useAuthContext from '../../hooks/useAuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { isAdminVerify } from '../../utils/index';

const MainAdminBox = styled(Box)(() => ({
	'& .MuiFormControl-root': {
		margin: 0,
	},
	'& .MuiAutocomplete-root': {
		width: '220px',
	},
}));

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function Admin() {
	const { user, authIsReady } = useAuthContext();
	const [value, setValue] = useState(0);
	const [isAdmin, setIsAdmin] = useState(false);

	const handleChange = (e, newValue) => {
		setValue(newValue);
	};

	// check if user is admin
	useEffect(() => {
		const isAutorized = async () => {
			const ref = doc(db, 'users', user?.uid);
			const userDocument = await getDoc(ref);
			if (userDocument.data() && userDocument.data().role) {
				// verify if user is admin
				const isAdmin = isAdminVerify(userDocument.data().role);
				if (isAdmin) {
					setIsAdmin(true);
				} else {
					setIsAdmin(false);
				}
			}
		};
		isAutorized();
	}, [user]);

	// define tabs and components
	const tabs = [
		{ label: 'Employee Data', component: <EmployeeData />, isAdminOnly: true },
		{ label: 'General Data', component: <GeneralData />, show: true },
		{
			label: 'Edit Vacation Credits',
			component: <EditVacationCredits />,
			isAdminOnly: true,
		},
		{ label: 'Update Ratings', component: <UpdateRatings />, isAdminOnly: true },
	];

	// filter tabs based on user role
	const filteredTabs = tabs.filter(
		(tab) => !tab.isAdminOnly || (tab.isAdminOnly && isAdmin)
	);

	return (
		<MainAdminBox sx={{ width: '100%' }}>
			<Box
				sx={{
					height: '60px',
					display: 'flex',
					alignItems: 'flex-end',
					gap: '1.5rem',
				}}
			>
				<Box sx={{ borderBottom: 1, borderColor: 'divider', width: 'fit-content' }}>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
					>
						{filteredTabs.map((tab, index) => (
							<Tab key={index} label={tab.label} {...a11yProps(index)} />
						))}
					</Tabs>
				</Box>
			</Box>
			<TabPanel value={value} index={value} sx={{ px: 1 }}>
				{filteredTabs[value].component}
			</TabPanel>
		</MainAdminBox>
	);
}
