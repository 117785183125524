import { Box, CircularProgress, TextField, Typography } from "@mui/material"
import React from "react"
import { useCollection } from "../../../hooks/useCollection"
import { stopPropagation } from "../constants"

const Proposal = ({ data, i }) => {
  const { documents: aqhData } = useCollection("aqhData")
  const LightenDarkenColor = (col, amt) => {
    var num = parseInt(col, 16)
    var r = (num >> 16) + amt
    var b = ((num >> 8) & 0x00ff) + amt
    var g = (num & 0x0000ff) + amt
    var newColor = g | (b << 8) | (r << 16)
    return newColor.toString(16)
  }
  return (
    <Box sx={{ px: 3, py: 1 }}>
      <Typography
        variant="h5"
        className="grid-item__title"
        p={1}
        textAlign="center"
        fontWeight="bold"
      >
        Proposals {i + 1}
      </Typography>
      <TextField
        variant="outlined"
        placeholder="Search..."
        size="small"
        fullWidth
        color="info"
        inputProps={{
          style: {
            fontSize: "14px",
          },
        }}
        onMouseDown={stopPropagation}
        onTouchStart={stopPropagation}
      />
      <Box
        sx={{
          my: 2,
          height:
            document.getElementsByClassName("proposal")[i]?.clientHeight - 120,
          overflowY: aqhData ? "auto" : "hidden",
          "&::-webkit-scrollbar": {
            width: 7,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: (theme) => theme.palette.primary.main,
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: (theme) =>
              `#${LightenDarkenColor(theme.palette.primary.main.slice(1), 19)}`,
          },
        }}
      >
        {aqhData ? (
          aqhData.map((c) => (
            <Typography
              key={c.name + Math.floor(Math.random() * 9999)}
              variant="subtitle2"
              pt={1}
            >
              {c.name}
            </Typography>
          ))
        ) : (
          <Box textAlign="center" pt={3}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Proposal
