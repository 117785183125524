export const firebasekeysTitles = {
	stations: 'Stations',
	markets: 'Markets',
	owners: 'Owners',
	inactiveMarkets: 'Inactive Markets',
	activeMarkets: 'Active Markets',
	totalAqh: 'Total Aqh',
	phone: 'Phone',
	gm: 'GM',
	gmName: 'GM Name',
	gmEmail: 'GM Email',
	gsm: 'GSM',
	gsmName: 'GSM Name',
	gsmEmail: 'GSM Email',
	aqh: 'Aqh',
	clusterAqh: 'Cluster AQH',
	status: 'Status',
};

export const ownerProps = {
	stations: null,
	markets: null,
	inactiveMarkets: 99,
	activeMarkets: 99,
	totalAqh: null,
	phone: '',
};

export const stationProps = {
	gm: '',
	gmName: '',
	gmEmail: '',
	gsm: '',
	gsmName: '',
	gsmEmail: '',
	aqh: '-',
	clusterAqh: '-',
	status: 'never',
};

export const homeMarketProps = {
	stations: null,
	owners: null,
	totalAqh: null,
};

export const NDMANameProps = {
	stations: null,
	owners: null,
	totalAqh: null,
};

export const trendLineProps = {
	MF1_2p: 0,
	MF2_3p: 0,
	MF3_4p: 0,
	MF3_7p: 0,
	MF4_5p: 0,
	MF5_6a: 0,
	MF5_6p: 0,
	MF5a_8p: 0,
	MF5a_9p: 0,
	MF5a_10p: 0,
	MF5a_11p: 0,
	MF5a_12m: 0,
	MF6_7a: 0,
	MF6_7p: 0,
	MF6_10a: 0,
	MF6a_7p: 0,
	MF7_8a: 0,
	MF7_8p: 0,
	MF8_9a: 0,
	MF9_10a: 0,
	MF10_11a: 0,
	MF10a_3p: 0,
	MF11a_12n: 0,
	MF12n_1p: 0,
	MSu6a_12m: 0,
	MSu12m_6a: 0,
	SS6a_12m: 0,
	SS6a_12n: 0,
	Sat3_7p: 0,
	Sat6_10a: 0,
	Sat10a_3p: 0,
	Sun3_7p: 0,
	Sun6_10a: 0,
	Sun10a_3p: 0,
};

export const pastDealTitles = {
	previousDealNumber: 'Previous Deal #',
	previousDeal: 'Previous $',
	previousCPT: 'Previous CPT',
	previousRatio: 'Previous Ratio',
	previousNotes: 'Previous Notes',
};

export const dealProps = {
	previousDealNumber: '',
	previousDeal: '',
	previousCPT: '',
	previousRatio: '',
	previousNotes: '',
};

export const tableRowHeadersName = [
	'MF 6a-10a',
	'MF 10a-3p',
	'MF 3p-7p',
	'MF 6a-7p',
	'MF 5a-8p',
	'MF 5a-9p',
	'MF 5a-10p',
	'MF 5a-11p',
	'MF 5a-12m',
	'MF 5a-6a',
	'MF 6a-7a',
	'MF 7a-8a',
	'MF 8a-9a',
	'MF 9a-10a',
	'MF 10a-11a',
	'MF 11a-12n',
	'MF 12n-1p',
	'MF 1p-2p',
	'MF 2p-3p',
	'MF 3p-4p',
	'MF 4p-5p',
	'MF 5p-6p',
	'MF 6p-7p',
	'MF 7p-8p',
	'MSu 6a-12m',
	'MSu 12m-6a',
	'SS 6a-12m',
	'SS 6a-12n',
	'Sat 6a-10a',
	'Sat 10a-3p',
	'Sat 3p-7p',
	'Sun 6a-10a',
	'Sun 10a-3p',
	'Sun 3p-7p',
];

export const getNumberOfDays = (dayPartString) => {
	if (dayPartString === 'MF ') {
		return 5;
	} else if (dayPartString === 'MSu') {
		return 7;
	} else if (dayPartString === 'SS ') {
		return 2;
	} else if (dayPartString === 'Sat' || dayPartString === 'Sun') {
		return 1;
	} else {
		return 0;
	}
};

export const currentDealTitles = {
	dealNumber: 'Deal #',
	current$: 'Current New $',
	currentCPT: 'Current CPT',
	currentRatio: 'Current Ratio',
	currentCOG: 'Current COG',
	currentCredits: 'Current Credits',
	currentRenewal: 'Current Renewal',
	currentDueDate: 'Current Due Date',
	bonus: 'Bonus',
	commission: 'Commission',
	commissionDate: 'Commission Date',
	city: 'City',
	company: 'Company',
	saleDate: 'Sale Date',
	renewalNewDate: 'Renewal New Date',
	endDate: 'Contract End Date',
};

export const currentDealProps = {
	dealNumber: '',
	current$: '',
	currentCPT: '',
	currentRatio: '',
	currentCOG: '',
	currentCredits: '',
	currentRenewal: '',
	currentDueDate: '',
	bonus: '',
	commission: '',
	commissionDate: '',
	city: '',
	company: '',
	saleDate: '',
	renewalNewDate: '',
	endDate: '',
};
