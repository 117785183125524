import { nextMonday } from 'date-fns';
import { getNumberOfDays } from '../../SalesProspector/utils/constants';

export const rowHeadersName = [
	'Station',
	'Format',
	'MF 6a-10a',
	'MF 10a-3p',
	'MF 3p-7p',
	'MF 6a-7p',
	'MF 5a-8p',
	'MF 5a-9p',
	'MF 5a-10p',
	'MF 5a-11p',
	'MF 5a-12m',
	'MF 5a-6a',
	'MF 6a-7a',
	'MF 7a-8a',
	'MF 8a-9a',
	'MF 9a-10a',
	'MF 10a-11a',
	'MF 11a-12n',
	'MF 12n-1p',
	'MF 1p-2p',
	'MF 2p-3p',
	'MF 3p-4p',
	'MF 4p-5p',
	'MF 5p-6p',
	'MF 6p-7p',
	'MF 7p-8p',
	'MSu 6a-12m',
	'MSu 12m-6a',
	'SS 6a-12m',
	'SS 6a-12n',
	'Sat 6a-10a',
	'Sat 10a-3p',
	'Sat 3p-7p',
	'Sun 6a-10a',
	'Sun 10a-3p',
	'Sun 3p-7p',
];

export const cptConstant = {
	'MF 6a-10a': [1, 685, 4],
	'MF 10a-3p': [1, 685, 4],
	'MF 3p-7p': [1, 685, 4],
	'MF 6a-7p': [2, 685, 4],
	'MF 5a-8p': [1, 685, 4],
	'MF 5a-9p': [1, 685, 4],
	'MF 5a-10p': [1, 685, 4],
	'MF 5a-11p': [1, 685, 4],
	'MF 5a-12m': [1, 685, 4],
	'MF 5a-6a': [2, 685, 4],
	'MF 6a-7a': [2, 685, 4],
	'MF 7a-8a': [2, 685, 4],
	'MF 8a-9a': [2, 685, 4],
	'MF 9a-10a': [2, 685, 4],
	'MF 10a-11a': [2, 685, 4],
	'MF 11a-12n': [2, 685, 4],
	'MF 12n-1p': [2, 685, 4],
	'MF 1p-2p': [2, 685, 4],
	'MF 2p-3p': [2, 685, 4],
	'MF 3p-4p': [2, 685, 4],
	'MF 4p-5p': [2, 685, 4],
	'MF 5p-6p': [1, 685, 4],
	'MF 6p-7p': [1, 685, 4],
	'MF 7p-8p': [2, 685, 4],
	'MSu 6a-12m': [1, 685, 4],
	'MSu 12m-6a': [1, 685, 4],
	'SS 6a-12m': [1, 685, 4],
	'SS 6a-12n': [1, 685, 4],
	'Sat 6a-10a': [1, 685, 4],
	'Sat 10a-3p': [1, 685, 4],
	'Sat 3p-7p': [1, 685, 4],
	'Sun 6a-10a': [1, 685, 4],
	'Sun 10a-3p': [1, 685, 4],
	'Sun 3p-7p': [1, 685, 4],
};

export const getWeeklySeconds = (noOfSpots, dayPartString) => {
	const seconds = noOfSpots * 2;
	return seconds * getNumberOfDays(dayPartString);
};

export const createTableDataObject = (
	tableDataValues,
	handleCptRatioChange,
	noOfWeeks
) => {
	let getData = {};
	tableDataValues.length > 0 &&
		tableDataValues.forEach((col) =>
			col.forEach((val, rowIdx) => {
				if (typeof val === 'object' && val?.noOfSpots > 0) {
					handleCptRatioChange((prev) =>
						prev[1] > cptConstant[rowHeadersName[rowIdx]][1]
							? prev
							: cptConstant[rowHeadersName[rowIdx]]
					);
					const dayPartString = rowHeadersName[rowIdx].slice(0, 3);
					const aqh =
						typeof val?.orignalValue === 'string' ? 0 : val?.orignalValue || 0;
					const gross =
						(aqh / 128340600) *
						100 *
						2200 *
						getWeeklySeconds(val?.noOfSpots, dayPartString) *
						noOfWeeks *
						0.85;
					getData[`${col[0]}@${val?.noOfSpots}/${rowIdx}`] = {
						orignalValue: aqh || 0,
						noOfSpots: val.noOfSpots,
						dayPartName: rowHeadersName[rowIdx],
						impressions: Number(
							aqh * val.noOfSpots * 2 * getNumberOfDays(dayPartString)
						),
						gross: Number(gross).toFixed(2),
						4.1: Number(gross / 4.1).toFixed(2),
						695: Number(gross / 695).toFixed(2),
						4.2: Number(gross / 4.2).toFixed(2),
						705: Number(gross / 705).toFixed(2),
						4.3: Number(gross / 4.3).toFixed(2),
						715: Number(gross / 715).toFixed(2),
						4.4: Number(gross / 4.4).toFixed(2),
						725: Number(gross / 725).toFixed(2),
						4.5: Number(gross / 4.5).toFixed(2),
						735: Number(gross / 735).toFixed(2),
						4.6: Number(gross / 4.6).toFixed(2),
						745: Number(gross / 745).toFixed(2),
					};
				} // if ends
			})
		);
	return getData;
};

export const initialDateState = {
	startDate: { value: null, isChanged: false },
	endDate: { value: null, isChanged: false },
	noOfWeeks: { value: 0, isChanged: false },
};

export const headerNames = [
	{ keyName: 'dayPartName', title: 'day-parts' },
	{ keyName: 'orignalValue', title: 'AQH' },
	{ keyName: 'noOfSpots', title: 'minutes' },
	{ keyName: 'impressions', title: 'Gross Impressions' },
	{ keyName: 'gross', title: 'gross' },
];

export const initialFieldsPublish = {
	contract_name: '',
	to_fname: '',
	to_lname: '',
	to_email: '',
	emailError: '',
	cc_emails: '',
	header: '',
	footer: '',
	title: '',
	subject: '',
};

export const initialFieldsOverride = {
	deal_name: '',
	comments: '',
};

export const initialDenyFields = {
	deny_reason: '',
};

export const firebasekeysTitles = {
	gm: 'GM',
	gmName: 'GM Name',
	gmEmail: 'GM Email',
	gsm: 'GSM',
	gsmName: 'GSM Name',
	gsmEmail: 'GSM Email',
};

export const stationTitles = {
	gm: '',
	gmName: '',
	gmEmail: '',
	gsm: '',
	gsmName: '',
	gsmEmail: '',
};

export const pastDealTitles = {
	previousDealNumber: 'Previous Deal #',
	previousDeal: 'Previous $',
	previousCPT: 'Previous CPT',
	previousRatio: 'Previous Ratio',
	previousNotes: 'Previous Notes',
	bonus: 'Bonus',
	commission: 'Commission',
	commissionDate: 'Commission Date',
	city: 'City',
	company: 'Company',
	saleDate: 'Sale Date',
	renewalNewDate: 'Renewal New Date',
};

export const dealProps = {
	previousDeal: '',
	previousCPT: '',
	previousRatio: '',
	previousNotes: '',
};

export const restoreDates = {
	startDate: { value: nextMonday(new Date()), isChanged: true },
	endDate: { value: null, isChanged: false },
	noOfWeeks: { value: 0, isChanged: false },
};

export const visaRewards = [
	{
		id: 'quantityVisa',
		align: 'center',
		label: 'Quantity',
	},
	{
		id: 'visa',
		align: 'center',
		label: 'Visa Reward Cards',
	},
	{
		id: 'subtotalVisa',
		align: 'center',
		label: '',
	},
	{
		id: 'dateVisa',
		align: 'center',
		label: 'Due Date',
	},
];

export const amazonRewards = [
	{
		id: 'quantityAmazon',
		align: 'center',
		label: 'Quantity',
	},
	{
		id: 'amazon',
		align: 'center',
		label: 'Amazon Gift Cards',
	},
	{
		id: 'subtotalAmazon',
		align: 'center',
		label: '',
	},
	{
		id: 'dateAmazon',
		align: 'center',
		label: 'Due Date',
	},
];

export const billpayRewards = [
	{
		id: 'quantityBillpay',
		align: 'center',
		label: 'Quantity',
	},
	{
		id: 'billpay',
		align: 'center',
		label: 'Billpay',
	},
	{
		id: 'subtotalBillpay',
		align: 'center',
		label: '',
	},
	{
		id: 'dateBillpay',
		align: 'center',
		label: 'Due Date',
	},
];

export const airports = [
	{
		id: 'airportName',
		align: 'center',
		label: 'Airport',
	},
];

// This array contains the formats that must be taken into account to calculate the values
const formatsNewCalc = [
	'Primary Format',
	'News/Talk',
	'Talk',
	'Business News',
	'Farm',
	'News',
	'Spanish News/Talk',
	'Weather/Traffic/Info',
	'Spanish Talk',
	'Spanish News',
];

// This array contains the stations that must be taken into account to calculate the values
const stationsNewCalc = ['WNIR-FM'];

// This function calculates the value of AQH based on the current and past value
// Now the format is taken as part of the calculation in Specific Station Formats
// The Formats are found in the array called 'formatsNewCalc'
export const newAQH = (curr, past, format, station) => {
	// If the current value is less than the past or they are equal
	if (curr < past || curr === past) {
		// If the format is in the array of formatsNewCalc then divide by 2
		// Otherwise return the current value
		return formatsNewCalc.includes(format) && !stationsNewCalc.includes(station)
			? curr / 2
			: curr;
	} else if (curr > past) {
		// If the current value is greater than the past calculate the average
		const average = (curr + past) / 2;
		// If the format is in the array of formatsNewCalc and the current value is greater than the average
		return curr > average &&
			formatsNewCalc.includes(format) &&
			!stationsNewCalc.includes(station)
			? average / 2
			: average;
	}
};

export const columnsArray = ['quantity', 'value', 'subtotal', 'date'];
export const columnsArrayAmazon = ['quantity', 'value', 'subtotal', 'date'];
export const columnsArrayBillpay = ['quantity', 'value', 'subtotal', 'date'];
export const columnsArrayAirports = ['airportName'];
// email regex
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// email list in array
export const emailList = (emails) => {
	return emails.split(',').map((email) => email.trim());
};

// default rows values
export const defaultRowsVacations = [
	'Daytona Beach, FL',
	'Gatlinburg, TN',
	'Jackson Hole, WY',
	'Key West, FL',
	'Las Vegas, NV',
	'Memphis, TN',
	'Miami Beach, FL',
	'Myrtle Beach, SC',
	'Nashville, TN',
	'New Orleans, LA',
	'New York, NY',
	'Orlando Attractions, FL',
	'Rapid City, SD',
	'San Diego, CA',
];

// Row values for the Rewards Modal
// It changes to the 15th of each month
export const handleObjectWithDay = (obj) => {
	const date = obj.date ? new Date(obj.date) : new Date();
	const newObj = {
		quantity: obj.quantity,
		value: obj.value,
		date: new Date(date.setDate(15)),
	};
	return newObj;
};

export const handleDefaultDate = () => {
	const today = new Date();
	today.setDate(15); // Takes the 15th of each month
	return today;
};
