import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { addDoc, collection } from 'firebase/firestore';
import { CircularProgress, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { db } from '../../../firebase/config';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
	selectedDocumentsIds,
	open,
	setOpen,
	userId,
}) {
	const [name, setName] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	const handleSave = async () => {
		try {
			const data = {
				documentsIds: selectedDocumentsIds,
				userId: userId,
				name: name,
			};
			const docRef = collection(db, 'customStaGroups');
			await addDoc(docRef, data);
			setOpen(false);
			setName('');
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	return (
		<Box>
			<Button
				className="save"
				size="small"
				variant="contained"
				sx={{ backgroundColor: '#9E9E9E', position: 'absolute', right: '1.2rem' }}
				onClick={() => setOpen(true)}
				disabled={selectedDocumentsIds?.length === 0}
			>
				Save as...
			</Button>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setOpen(false)}
				aria-describedby="alert-dialog-slide-description"
				sx={{
					'& .MuiDialog-paper': {
						minWidth: '340px',
					},
				}}
			>
				<DialogTitle>Save AQH Data</DialogTitle>
				<DialogContent>
					<Box
						sx={{
							my: 2,
							'& >*': {
								my: 2,
							},
						}}
					>
						<TextField
							label={`Name A sample`}
							variant="outlined"
							size="small"
							name={'name'}
							value={name}
							onChange={(e) => setName(e.target.value)}
							fullWidth
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={() => setOpen(false)}>
						Close
					</Button>
					<Button
						onClick={handleSave}
						variant="contained"
						startIcon={loading && <CircularProgress size={18} />}
						disabled={loading || !name}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
