import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import {
	numberWithCommas,
	handleEquitableDayparts,
} from '../../../utils/index';
import {
	getDayPart,
	getTotalReward,
	getValuesTable,
	valuePerDay,
} from '../../Pdf/utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../../../firebase/config';
import pako from 'pako';
const _ = require('lodash');

export const maxMinsPerDay = (dayPart) => {
	// calculate the max minutes per day
	// first get the number of hoursPerDayPart in the day part
	// and multiplate that value by the minutes variable

	const minutes = 2;
	let hoursPerDayPart = 0;
	let maxMinutesPerDayPart = 0;

	if (
		dayPart === 'MF 6a-7a' ||
		dayPart === 'MF 5a-6a' ||
		dayPart === 'MF 7a-8a' ||
		dayPart === 'MF 8a-9a' ||
		dayPart === 'MF 9a-10a' ||
		dayPart === 'MF 10a-11a' ||
		dayPart === 'MF 11a-12n' ||
		dayPart === 'MF 12n-1p' ||
		dayPart === 'MF 1p-2p' ||
		dayPart === 'MF 2p-3p' ||
		dayPart === 'MF 3p-4p' ||
		dayPart === 'MF 4p-5p' ||
		dayPart === 'MF 5p-6p' ||
		dayPart === 'MF 6p-7p' ||
		dayPart === 'MF 7p-8p'
	) {
		hoursPerDayPart = 1;
	} else if (
		dayPart === 'MF 6a-10a' ||
		dayPart === 'MF 3p-7p' ||
		dayPart === 'Sat 6a-10a' ||
		dayPart === 'Sat 3p-7p' ||
		dayPart === 'Sun 6a-10a' ||
		dayPart === 'Sun 3p-7p'
	) {
		hoursPerDayPart = 4;
	} else if (
		dayPart === 'MF 10a-3p' ||
		dayPart === 'Sat 10a-3p' ||
		dayPart === 'Sun 10a-3p'
	) {
		hoursPerDayPart = 5;
	} else if (dayPart === 'MF 6a-7p') {
		hoursPerDayPart = 13;
	} else if (dayPart === 'MF 5a-8p') {
		hoursPerDayPart = 15;
	} else if (dayPart === 'MF 5a-9p') {
		hoursPerDayPart = 16;
	} else if (dayPart === 'MF 5a-10p') {
		hoursPerDayPart = 17;
	} else if (
		dayPart === 'MF 5a-11p' ||
		dayPart === 'MSu 6a-12m' ||
		dayPart === 'SS 6a-12m'
	) {
		hoursPerDayPart = 18;
	} else if (dayPart === 'MF 5a-12m') {
		hoursPerDayPart = 19;
	} else if (dayPart === 'MSu 12m-6a' || dayPart === 'SS 6a-12n') {
		hoursPerDayPart = 6;
	}

	maxMinutesPerDayPart = hoursPerDayPart * minutes;

	return maxMinutesPerDayPart;
};

export const dateTransform = (timestamp) => {
	if (timestamp.seconds) {
		const date = new Date(timestamp.seconds * 1000);
		let mm = date.getMonth() + 1;
		let dd = date.getDate();
		let yyyy = date.getFullYear();
		const newDate = mm + '/' + dd + '/' + yyyy;
		return newDate;
	} else {
		const date = new Date(timestamp);
		let mm = date.getMonth() + 1;
		let dd = date.getDate();
		let yyyy = date.getFullYear();
		const newDate = mm + '/' + dd + '/' + yyyy;
		return newDate;
	}
};

export const dateTimeStamp = (timestamp) => {
	if (timestamp?.seconds) {
		const date = new Date(timestamp.seconds * 1000);
		return date;
	} else {
		const date = new Date(timestamp);
		return date;
	}
};

export function createTableHeader(data) {
	const arr = data.map((e) => {
		return `<td style="border:1px solid black; padding: 5px; width: 95px; font-size: 14px;">${e}</td>`;
	});

	return `${arr.join('')}`;
}

export const createHtmlOptionTable = async (
	headers,
	arr,
	option,
	index,
	proposalId,
	showButton,
	date,
	showDivider,
	isBridgeDeal,
	bridgeDeal,
	optionsLength
) => {
	const tableHeaders = headers.map((header) => {
		return createTableHeader([header]);
	});

	const valueCells = (arr) => {
		const newObject = getValuesTable(arr);
		let days = Object.keys(newObject).map((key, i) =>
			newObject[key].map((v, i) => v.dayPartName.split(' ')[0])
		);
		// flat the array
		const arrayDays = days.flatMap((v) => v);

		// check if the array has weekend days
		if (arrayDays.includes('MSu')) {
			return true;
		} else if (arrayDays.includes('SS')) {
			return true;
		} else if (arrayDays.includes('Sat')) {
			return true;
		} else if (arrayDays.includes('Sun')) {
			return true;
		} else {
			return false;
		}
	};

	const getValuePerWeekDay = (v) => {
		return getDayPart(v.dayPartName) === 'MF' ||
			getDayPart(v.dayPartName) === 'MSu'
			? valuePerDay(v.noOfSpots)
			: '';
	};

	const getOptionBillDataAndVacations = (
		rewards,
		allInputs,
		vacationCredits
	) => {
		if (
			allInputs[0].cogs ||
			(rewards &&
				(rewards?.amazon?.length > 0 ||
					rewards?.billpay?.length > 0 ||
					rewards.visa?.length > 0 ||
					allInputs[4].cogs))
		) {
			let text = `SFM to provide: <br>`;
			if (allInputs[0].cogs !== 0) {
				text = text + getVacationCredits(allInputs, vacationCredits);
			}
			if (rewards?.amazon?.length > 0 && !isObjectEmpty(rewards?.amazon[0])) {
				let total = getTotalReward(rewards?.amazon);
				text = text + `<strong>$${total} Amazon Reward Cards:</strong><br>`;
				rewards?.amazon.forEach((amazon) => {
					if (!isObjectEmpty(amazon)) {
						text =
							text +
							`&nbsp;&nbsp;* ${amazon.quantity} - $${amazon.value} cards ${
								amazon?.date ? dateTransform(amazon.date) : ''
							}<br>`;
					}
				});
			}
			if (rewards?.visa?.length > 0 && !isObjectEmpty(rewards?.visa[0])) {
				let total = getTotalReward(rewards?.visa);
				text = text + `<strong>$${total} Visa Reward Cards:</strong><br>`;
				rewards?.visa.forEach((visa) => {
					if (!isObjectEmpty(visa)) {
						text =
							text +
							`&nbsp;&nbsp;* ${visa.quantity} - $${visa.value} cards ${
								visa.date ? dateTransform(visa.date) : ''
							}<br>`;
					}
				});
			}
			if (rewards?.billpay?.length > 0 && !isObjectEmpty(rewards?.billpay[0])) {
				let total = getTotalReward(rewards?.billpay);
				text = text + `<strong>$${total} Bill pay:</strong><br>`;
				rewards?.billpay.forEach((billPay) => {
					if (!isObjectEmpty(billPay)) {
						text =
							text +
							`&nbsp;&nbsp;* ${billPay.quantity} - $${billPay.value} in billpay ${
								billPay.date ? dateTransform(billPay.date) : ''
							}<br>`;
					}
				});
			}
			if (allInputs[4].cogs) {
				text = text + `<strong>$${allInputs[4].cogs} Others</strong><br>`;
			}
			return `
      <p>
        ${text}
      </p>`;
		} else {
			return '';
		}
	};

	const getVacationCredits = (allInputs, vacationCredits) => {
		if (
			allInputs &&
			allInputs[0].name === 'Vacations' &&
			allInputs[0].cogs !== '' &&
			allInputs[0].cogs !== '0' &&
			allInputs[0].cogs !== 0
		) {
			let specialtyVacations = [];
			let vacationWithAirfare = [];
			let vacationWithoutAirfare = [];
			// get values if exist values in the object specialtyVacations
			for (const [key, value] of Object.entries(
				vacationCredits['Specialty Vacations']
			)) {
				if (value !== 'N/A') {
					specialtyVacations.push(`${key}: ${value}`);
				}
			}
			// get values if exist values in the object vacationWithAirfare
			for (const [key, value] of Object.entries(
				vacationCredits['Vacations with airfare']
			)) {
				if (value !== 'N/A') {
					vacationWithAirfare.push(`${key}: ${value}`);
				}
			}
			// get values if exist values in the object vacationWithoutAirfare
			for (const [key, value] of Object.entries(
				vacationCredits['Vacations without airfare']
			)) {
				if (value !== 'N/A') {
					vacationWithoutAirfare.push(`${key}: ${value}`);
				}
			}
			// create vacations credits for emails and proposals
			if (
				specialtyVacations.length > 0 ||
				vacationWithAirfare.length > 0 ||
				vacationWithoutAirfare.length > 0
			) {
				let vacationCredits = `<strong>${allInputs[0].cogs} Vacation Credits</strong>`;
				let text = ``;
				if (specialtyVacations.length > 0) {
					specialtyVacations.forEach((specialty) => {
						text = text + `&nbsp;&nbsp;* ${specialty}<br>`;
					});
				}
				if (vacationWithAirfare.length > 0) {
					vacationWithAirfare.forEach((withAirfare) => {
						text = text + `&nbsp;&nbsp;* ${withAirfare}<br>`;
					});
				}
				if (vacationWithoutAirfare.length > 0) {
					vacationWithoutAirfare.forEach((withoutAirfare) => {
						text = text + `&nbsp;&nbsp;* ${withoutAirfare}<br>`;
					});
				}
				return `${vacationCredits}<br>${text}`;
			}
		} else {
			return '';
		}
	};

	const getOptionOwnerData = async (
		selectedRows,
		index,
		weeks,
		rewards,
		allInputs,
		gross,
		isBridgeDeal,
		bridgeDeal,
		showDivider,
		vacationCredits,
		alternativeName
	) => {
		if (isBridgeDeal) {
			return `
				<p>
					<strong> Bridge Deal: ${date} - ${bridgeDeal?.noOfWeeks} weeks</strong>
					</strong>
				</p>`;
		} else if (selectedRows.length > 0 && selectedRows[0].Ownername) {
			return `
				${
					isBridgeDeal === false
						? `
				<p>
					<strong>
						<u style="font-size: 18px;">
							Option ${index + 1}
						</u>
					</strong>
				</p>
				<br></br>
				`
						: ''
				}
			${getOptionBillDataAndVacations(rewards, allInputs, vacationCredits)}
			<br>
			<p>${selectedRows[0].Ownername} provide: ${numberWithCommas(
				gross
			)} gross weekly impressions by airing the Commercial Schedules below:
			</p>
			<br>
			`;
		} else {
			return `
				<p>
					<strong>
						<u style="font-size: 18px;">
							Option ${index + 1}
						</u>
					</strong>
				</p>`;
		}
	};

	const createButton = (showButton) => {
		if (showButton) {
			return `
			<a href=${window.location.origin + '/' + proposalId + '/' + option.id}
				style="
				background-color: #1c87c9;
				border: none;
				color: white;
				padding: 10px 45px;
				text-align: center;
				text-decoration: none;
				display: inline-block;
				font-size: 18px;
				margin: 2px 2px;
				cursor: pointer;"
				>
				Accept
			</a>`;
		} else {
			return '';
		}
	};

	const thStyle =
		'style="border:1px solid black; padding: 5px; width: 95px; font-size: 14px;"';

	const rows = Object.keys(getValuesTable(arr)).map((key, i) => {
		return getValuesTable(arr)
			[key].map(
				(v, i) =>
					`<tr>
						<td ${thStyle}>${v.station || ''}</td>
						<td ${thStyle}>${handleEquitableDayparts(v.dayPartName.split(' ')[1])}</td>
						<td ${thStyle}>${getValuePerWeekDay(v)}</td>
						<td ${thStyle}>${getValuePerWeekDay(v)}</td>
						<td ${thStyle}>${getValuePerWeekDay(v)}</td>
						<td ${thStyle}>${getValuePerWeekDay(v)}</td>
						<td ${thStyle}>${getValuePerWeekDay(v)}</td>
  					${
								valueCells(arr)
									? `<td ${thStyle}>${
											getDayPart(v.dayPartName) === 'SS' ||
											getDayPart(v.dayPartName) === 'Sat' ||
											getDayPart(v.dayPartName) === 'MSu'
												? valuePerDay(v.noOfSpots)
												: ''
									  }</td>`
									: ''
							}
					${
						valueCells(arr)
							? `<td ${thStyle}>${
									getDayPart(v.dayPartName) === 'SS' ||
									getDayPart(v.dayPartName) === 'Sun' ||
									getDayPart(v.dayPartName) === 'MSu'
										? valuePerDay(v.noOfSpots)
										: ''
							  }</td>`
							: ''
					}
  					</tr>`
			)
			.join('');
	});

	return `
	<br>
	<div style="text-align: center;">
	${createButton(showButton)}
	</div>
	<br>
	<br>
		${await getOptionOwnerData(
			option?.selectedRowsAqh,
			index,
			option?.dates.noOfWeeks,
			option?.rewards,
			option?.allInputs,
			option?.grossWeeklyImpressions,
			isBridgeDeal,
			bridgeDeal,
			showDivider,
			option?.vacationCredits,
			option?.alternativeName
		)} 
	<table style="border-collapse: collapse; margin: 0 auto;">
	<thead>
		<tr>
		${tableHeaders.join('')}
		</tr>
	</thead>
	<tbody>
		${rows.join('')}
	</tbody>
	</table>
	<p>
	each :60 scheduled with one :60 or two :30s with a minimum of 30 minutes of separation
	</p>
	<p>
	* equitable distribution required
	</p>
	<p>start date: ${
		dateTransform(
			option?.dates?.startDate
				? option.dates.startDate
				: bridgeDeal?.startDate
				? bridgeDeal.startDate
				: ''
		) || ''
	}&emsp;
  	end date: ${
				dateTransform(
					option?.dates?.endDate
						? option.dates.endDate
						: bridgeDeal?.endDate
						? bridgeDeal.endDate
						: ''
				) || ''
			}
	</p>
	<br>
		<div style="text-align: center;">
		${createButton(showButton)}
		</div>
	<br>
	${
		showDivider === true &&
		optionsLength > 1 && // if the option has more than one option
		((isBridgeDeal === true && bridgeDeal !== null) ||
			(isBridgeDeal === false && bridgeDeal === null))
			? '<hr style="border: 1px solid black;">'
			: ''
	}
`;
};

export const hasWeekendDays = (data) => {
	let days = Object.keys(data).map(
		(key, i) => data[key].dayPartName.split(' ')[0]
	);
	// flat the array
	const arrayDays = days.flatMap((v) => v);
	// check if the array has weekend days
	if (arrayDays.includes('MSu')) {
		return true;
	} else if (arrayDays.includes('SS')) {
		return true;
	} else if (arrayDays.includes('Sat')) {
		return true;
	} else if (arrayDays.includes('Sun')) {
		return true;
	} else {
		return false;
	}
};

export const getWeeksDays = (data) => {
	if (hasWeekendDays(data)) {
		return [
			'Station',
			'DayPart',
			'Mon',
			'Tues',
			'Wed',
			'Thurs',
			'Fri',
			'Sat',
			'Sun',
		];
	} else {
		return ['Station', 'DayPart', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri'];
	}
};

export const createOptionObjectData = (
	id,
	uid,
	tableData,
	dates,
	grossTotal,
	grossWeeklyImpressions,
	remainingTotal,
	cpt,
	sumOfRatio,
	selectedRowsAqh,
	allInputs,
	lowerDeal,
	maxValues,
	compressed,
	dealTerms,
	createdAt,
	alternativeName,
	optionIndex
) => {
	// verify if max value is true in the array return true
	const isMaxValueCogs = allInputs.some(
		(obj) => obj.cogs > Math.floor(obj.revenue / obj.min)
	);
	// create object to save to firebase
	const object = Object.assign(
		{},
		{
			userId: uid,
			dayParts: compressed && compressed === true ? tableData : { ...tableData },
			dates: dates,
			gross: grossTotal,
			grossWeeklyImpressions: grossWeeklyImpressions,
			discrepancy: remainingTotal,
			cpt: cpt,
			ratio: sumOfRatio,
			selectedRowsAqh: selectedRowsAqh,
			allInputs: allInputs,
			hasErrors: lowerDeal || maxValues || isMaxValueCogs ? true : false, // if the option has errors
			createdAt: createdAt ? createdAt : new Date(), // if the was created before, use the same date,
			optionIndex: optionIndex || 1,
			alternativeName: alternativeName ? alternativeName : '', // if the option has alternative name, save it
			// create unique uuid for each option
			// needed to handle edit or delete option
			id: id ? id : uuidv4(),
			compressed: compressed || false,
		}
	);
	return object;
};

const updateProposalOptionCredits = async (
	docId,
	mainObj,
	aqhSavedOptions,
	optionId,
	vacationCredits,
	rewards,
	optionData,
	setAqhSavedOptions
) => {
	// save credtis data to proposal
	const updatedOptions = aqhSavedOptions.map((option) => {
		if (option.id === optionId) {
			if (vacationCredits && rewards) {
				return {
					...optionData,
					vacationCredits: vacationCredits,
					rewards: rewards,
				};
			} else if (vacationCredits) {
				return { ...optionData, vacationCredits: vacationCredits };
			} else if (rewards) {
				return { ...optionData, rewards: rewards };
			} else {
				return { ...optionData };
			}
		} else {
			return option;
		}
	});

	const docRef = doc(db, 'savedProposals', docId);
	await updateDoc(docRef, {
		...mainObj,
		aqhSavedOptions: updatedOptions,
	});
	// sorted options
	const sortedAqhOptions = sortedOptions(updatedOptions);
	setAqhSavedOptions(sortedAqhOptions);
};

const createAqhOption = async (vacationCredits, rewards, optionData) => {
	const aqhSavedOptionsRef = collection(db, 'aqhSavedOptions');
	if (vacationCredits && rewards) {
		await addDoc(aqhSavedOptionsRef, {
			...optionData,
			vacationCredits: vacationCredits,
			rewards: rewards,
		});
	} else if (vacationCredits) {
		await addDoc(aqhSavedOptionsRef, {
			...optionData,
			vacationCredits: vacationCredits,
		});
	} else if (rewards) {
		await addDoc(aqhSavedOptionsRef, {
			...optionData,
			rewards: rewards,
		});
	} else {
		await addDoc(aqhSavedOptionsRef, optionData);
	}
};

export const updateOrCreateOptionData = async ({
	mainObj,
	vacationCredits,
	rewards,
	optionData,
	isAqhOption,
	isEditAqhOption,
	setOptionId,
	aqhSavedOptions,
	docId,
	optionId,
	createNewOne,
	proposalObj,
	setProposalId,
	setAqhSavedOptions,
}) => {
	if (isAqhOption) {
		// create new aqh option
		createAqhOption(vacationCredits, rewards, optionData);
	} else if (isEditAqhOption) {
		// update aqh option
		const aqhSavedOptionsRef = doc(db, 'aqhSavedOptions', optionId);
		if (vacationCredits && rewards) {
			await updateDoc(aqhSavedOptionsRef, {
				...optionData,
				vacationCredits: vacationCredits,
				rewards: rewards,
			});
		} else if (vacationCredits) {
			await updateDoc(aqhSavedOptionsRef, {
				...optionData,
				vacationCredits: vacationCredits,
			});
		} else if (rewards) {
			await updateDoc(aqhSavedOptionsRef, {
				...optionData,
				rewards: rewards,
			});
		} else {
			await updateDoc(aqhSavedOptionsRef, optionData);
		}
		setOptionId('');
	} else if (docId && optionId) {
		// update proposal option
		await updateProposalOptionCredits(
			docId,
			mainObj,
			aqhSavedOptions,
			optionId,
			vacationCredits,
			rewards,
			optionData,
			setAqhSavedOptions
		);
		setOptionId('');
	} else if (createNewOne && proposalObj) {
		// when create a new proposal with default option
		// get options from proposal
		const options = proposalObj.aqhSavedOptions;
		// if not exist optionId, update rewards and vacation credits to the default option
		if (vacationCredits && rewards) {
			options[0] = {
				...optionData,
				vacationCredits: vacationCredits,
				rewards: rewards,
			};
		} else if (vacationCredits && !rewards) {
			options[0] = { ...optionData, vacationCredits: vacationCredits };
		} else if (rewards && !vacationCredits) {
			options[0] = { ...optionData, rewards: rewards };
		} else {
			options[0] = optionData;
		}
		// update default option with rewards and vacation credits
		const docRef = doc(db, 'savedProposals', docId);
		await updateDoc(docRef, {
			aqhSavedOptions: options,
		});
		// update options
		setAqhSavedOptions(options);
		setProposalId(docId);
	} else {
		// craete new option on existing proposal
		const docRef = doc(db, 'savedProposals', docId);
		const querySnapShot = await getDoc(docRef);
		const result = querySnapShot.data();

		const options = result.aqhSavedOptions;
		if (vacationCredits && rewards) {
			options.push({
				...optionData,
				vacationCredits: vacationCredits,
				rewards: rewards,
			});
		} else if (vacationCredits) {
			options.push({ ...optionData, vacationCredits: vacationCredits });
		} else if (rewards) {
			options.push({ ...optionData, rewards: rewards });
		} else {
			options.push(optionData);
		}

		await updateDoc(docRef, {
			aqhSavedOptions: options,
		});
		// sorted options
		const sortedAqhOptions = sortedOptions(options);
		setAqhSavedOptions(sortedAqhOptions);
		setProposalId(docId);
	}
};

export const validateOptionErrors = async (aqhSavedOptions) => {
	const result = await Promise.all(
		aqhSavedOptions.map(async (option) => {
			if (option?.hasErrors && option?.hasErrors === true) {
				return true;
			} else {
				return false;
			}
		})
	);

	return result;
};

// validate if a options has vacation credits
export const validateVacationCredits = async (aqhSavedOptions) => {
	const result = await Promise.all(
		aqhSavedOptions.map(async (option) => {
			if (
				option?.allInputs &&
				option.allInputs[0].name === 'Vacations' &&
				option.allInputs[0].cogs !== '' &&
				option.allInputs[0].cogs !== '0' &&
				option.allInputs[0].cogs !== 0
			) {
				return true;
			} else {
				return false;
			}
		})
	);

	return result;
};

// validate if exists airports in the proposal
export const validateAirports = async (proposalId) => {
	// reference to the proposal
	const proposalRef = doc(db, 'savedProposals', proposalId);
	// get proposal data
	const docSnap = await getDoc(proposalRef);
	// validate if airports exists
	if (docSnap.exists() && docSnap.data()?.airports) {
		return true;
	} else {
		return false;
	}
};

// validate if exists rollover credits in the proposal
export const validateRolloverCredits = async (proposalId) => {
	// reference to the proposal
	const proposalRef = doc(db, 'savedProposals', proposalId);
	// get proposal data
	const docSnap = await getDoc(proposalRef);
	// validate if rollover credits exists
	if (docSnap.exists() && docSnap.data()?.rolloverCredits) {
		return true;
	} else {
		return false;
	}
};

export const getProposalOptions = async (proposalId) => {
	const proposalRef = doc(db, 'savedProposals', proposalId);
	const docSnap = await getDoc(proposalRef);
	if (docSnap.exists() && docSnap.data()?.aqhSavedOptions) {
		// sort array by createdAt
		const sortedArray = sortedOptions(docSnap.data()?.aqhSavedOptions);
		return sortedArray;
	}
};

export const getProposalBridgeDeal = async (proposalId) => {
	const proposalRef = doc(db, 'savedProposals', proposalId);
	const docSnap = await getDoc(proposalRef);
	if (docSnap.exists() && docSnap.data()?.bridgeDeal) {
		return docSnap.data()?.bridgeDeal;
	}
};

export const getHasBridgeDeal = async (proposalId) => {
	const proposalRef = doc(db, 'savedProposals', proposalId);
	const docSnap = await getDoc(proposalRef);
	if (docSnap.exists() && docSnap.data()?.hasBridgeDeal) {
		return docSnap.data()?.hasBridgeDeal;
	}
};

export const reusableEmailButton = (title, url) => {
	return `
    <a href=${url}
    style="
      background-color: #1c87c9;
      border: none;
      color: white;
      padding: 10px 45px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 18px;
      margin: 2px 2px;
      cursor: pointer;"
    >
    ${title}
    </a>`;
};

export const isObjectEmpty = (objectName) => {
	return _.isEmpty(objectName);
};

// order by createdAt if exists
export const sortedOptions = (aqhSavedOptions) => {
	// validate if item has createdAt
	const hasCreatedAt = (item) => item && item.createdAt;
	// compare by createdAt
	const compareByCreatedAt = (a, b) => {
		const createdAtComparison = a.createdAt.seconds - b.createdAt.seconds;
		return createdAtComparison === 0
			? a.id.localeCompare(b.id)
			: createdAtComparison;
	};
	// sort array
	const sortedArray = aqhSavedOptions.sort((a, b) => {
		if (hasCreatedAt(a) && hasCreatedAt(b)) {
			return compareByCreatedAt(a, b);
		}
		// if one of the items doesn't have createdAt, sort by id
		return a.id.localeCompare(b.id);
	});

	return sortedArray;
};

// return a necessary object to create the proposal
export const necessaryDataObject = (obj) => {
	return {
		Station: obj.Station,
		Simulcast: obj.Simulcast,
		Ownername: obj.Ownername,
		NDMAName: obj.NDMAName,
		Maddr1: obj.Maddr1,
		Maddr2: obj.Maddr2,
		Mstate: obj.Mstate,
		Mcity: obj.Mcity,
		Mzip: obj.Mzip,
		dealDetails: obj.dealDetails,
		GM: obj.GM,
		GM2: obj.GM2,
		GM3: obj.GM3,
		GM5: obj.GM5,
		GM6: obj.GM6,
		GSM: obj.GSM,
		GSM2: obj.GSM2,
		GSM3: obj.GSM3,
		GSM5: obj.GSM5,
		GSM6: obj.GSM6,
	};
};

// compress tableData if exceeds 1MB for firebase
export const compressDataIfNecessary = (object) => {
	// transform object to string
	const jsonString = JSON.stringify(object);
	// get bytes of object
	const objectBytes = new TextEncoder().encode(jsonString).length;

	// Firebase has a limit to the size of a document so if it exceeds 1048576 Bytes it gives an error.
	// if bytes is greater than 500000 Bytes, compress tableData and save into object

	if (objectBytes > 500000) {
		// compress tableData
		const jsonData = JSON.stringify(object.tableData);
		const compressedData = pako.gzip(jsonData);
		// transform compressedData to base64
		const base64 = btoa(String.fromCharCode.apply(null, compressedData));
		// save compressedData into object
		object.tableData = base64;
		object.compressed = true;
	}
	// otherwise, save tableData into object
	return object;
};

// convert string to array buffer
const stringToArrayBuffer = (string) => {
	const arrayBuffer = new Uint8Array(string.length);
	for (let i = 0; i < string.length; i++) {
		arrayBuffer[i] = string.charCodeAt(i);
	}
	return arrayBuffer;
};

// decompress tableData inside AQH options
export const decompressDayParts = (aqhSavedOptions) => {
	const aqhSavedOptionsDecompressed = aqhSavedOptions.map((option) => {
		if (option && option.compressed === true) {
			// Decode from base64
			const decodedData = window.atob(option.dayParts);
			// convert string to array buffer
			const arrayBuffer = stringToArrayBuffer(decodedData);
			const decompressed = pako.inflate(arrayBuffer, { to: 'string' });
			const parsed = JSON.parse(decompressed);
			return {
				...option,
				dayParts: { ...parsed },
			};
		}
		return option;
	});
	return aqhSavedOptionsDecompressed;
};

// decompress only field dayParts
export const decompressDayPartsField = (dayParts) => {
	// Decode from base64
	const decodedData = window.atob(dayParts);
	// convert string to array buffer
	const arrayBuffer = stringToArrayBuffer(decodedData);
	const decompressed = pako.inflate(arrayBuffer, { to: 'string' });
	const parsed = JSON.parse(decompressed);
	return { ...parsed };
};

export const getNextMonday = (date) => {
	// Get the day of the week
	const dayOfWeek = date.getDay();
	// Calculate the days until the next monday
	const daysUntilMonday = dayOfWeek === 1 ? 0 : (8 - dayOfWeek) % 7;
	// Create a copy of the original date
	const nextMonday = new Date(date);
	// Add the necessary days to advance to the next monday
	nextMonday.setDate(date.getDate() + daysUntilMonday);
	return nextMonday;
};

export const getPreviousFriday = (date) => {
	// Get the day of the week
	const dayOfWeek = date.getDay();

	// Calculate the days to subtract to reach the previous Friday
	let daysToSubtract;
	if (dayOfWeek === 0) {
		// If it's Sunday (0), we need to subtract 2 days to reach the previous Friday
		daysToSubtract = 2;
	} else if (dayOfWeek <= 5) {
		// If it's Monday (1) to Friday (5), we need to subtract (dayOfWeek + 2) days
		daysToSubtract = dayOfWeek + 2;
	} else {
		// If it's Saturday (6), we need to subtract 1 day to reach the previous friday
		daysToSubtract = 1;
	}
	// Create a copy of the original date
	const previousFriday = new Date(date);
	// Subtract the necessary days to reach the previous friday
	previousFriday.setDate(date.getDate() - daysToSubtract);
	return previousFriday;
};

// verify if a proposal was approved or denied when the user is trying to send in same view
export const proposalStatusReview = async (proposalId) => {
	const proposalRef = doc(db, 'savedProposals', proposalId);
	const docSnap = await getDoc(proposalRef);
	if (docSnap.exists() && docSnap.data()?.proposalState) {
		const state = docSnap.data().proposalState;
		if (state === 'approved') {
			return 'approved';
		} else if (state === 'denied') {
			return 'denied';
		} else if (state === 'unread') {
			return 'unread';
		}
	}
};
