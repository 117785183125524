import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
// import DescriptionIcon from '@mui/icons-material/Description';
import { Link } from 'react-router-dom';
import useProfileContext from '../hooks/useProfileContext';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Badge } from '@mui/material';

export default function AccountMenu({
	open,
	anchorEl,
	handleClose,
	hanldeLogout,
	receivedNoti,
}) {
	const { profile } = useProfileContext();

	return (
		<React.Fragment>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem component={Link} to="/dashboard/profile">
					<Avatar
						sx={{ width: 38, height: 38, fontSize: '0.9rem' }}
						src={profile?.photoURL || ''}
					>
						{profile &&
							profile.firstName.charAt(0) + ' ' + profile.lastName.charAt(0)}
					</Avatar>
					Profile
				</MenuItem>
				<Divider />
				<MenuItem component={Link} to="/dashboard/notification-center">
					<ListItemIcon>
						<Badge
							color="secondary"
							badgeContent=""
							sx={{
								'& > .BaseBadge-badge': {
									minWidth: receivedNoti?.filter((v) => !v.seen).length ? 12 : 0,
									height: receivedNoti?.filter((v) => !v.seen).length ? 12 : 0,
									right: 1,
								},
							}}
						>
							<NotificationsNoneOutlinedIcon fontSize="small" />
						</Badge>
					</ListItemIcon>
					Notifications
				</MenuItem>
				<MenuItem component={Link} to="/dashboard/settings">
					<ListItemIcon>
						<Settings fontSize="small" />
					</ListItemIcon>
					Settings
				</MenuItem>
				{/* <MenuItem component={Link} to="/dashboard/employee-data">
          <ListItemIcon>
            <DescriptionIcon fontSize="small" />
          </ListItemIcon>
          Employee Data
        </MenuItem> */}
				<MenuItem onClick={hanldeLogout}>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
					Logout
				</MenuItem>
			</Menu>
		</React.Fragment>
	);
}
