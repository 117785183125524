import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import History from '../HistoryNotes/History';
import { getCertificatesById } from '../../utils/utils';

const PrivateNotes = ({ certificate, open, closeModal }) => {
	const [query, setQuery] = useState();
	useEffect(() => {
		if (certificate) {
			const contractId = certificate.contractId;
			const id = certificate.id;
			const query = getCertificatesById(id, contractId);
			setQuery(query);
		}
	}, [certificate]);
	return (
		<Dialog
			open={open}
			disableAutoFocus
			onClose={closeModal}
			PaperProps={{
				style: { overflow: 'hidden' },
			}}
			maxWidth={'md'}
			fullWidth={true}
		>
			<DialogTitle>Private Notes</DialogTitle>
			<DialogContent>
				<History
					slug={'certificates'}
					nameProperty="privateHistory"
					query={query}
				></History>
			</DialogContent>
		</Dialog>
	);
};
export default PrivateNotes;
