import React, { useState } from 'react';
import {
	Alert,
	Box,
	Grid,
	IconButton,
	ThemeProvider,
	Typography,
} from '@mui/material';
import moment from 'moment';
import { useRoles } from '../../hooks/useRoles';
import { CustomBadge, MainBox, getTheme } from '../../utils/themes';
import MUIDataTable from 'mui-datatables';
import { useTheme } from '@emotion/react';
import useTraveler from '../../hooks/useTraveler';
import useModal from '../../hooks/useModal';
import { Info, SpeakerNotes } from '@mui/icons-material';
import TravelDetails from './TravelDetails';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import PrivateNotes from './PrivateNotes';
import PublicNotes from './PublicNotes';
const TravelerInfo = ({ travelerId }) => {
	const { traveler } = useTraveler(travelerId);
	return (
		<Box justifyContent={'center'}>
			{traveler ? (
				<Typography component="p">
					{traveler.firstName} {traveler.lastName}
				</Typography>
			) : (
				<Typography>
					<Alert
						sx={{ display: 'flex', justifyContent: 'center' }}
						severity="warning"
					>
						No Traveler Name
					</Alert>
				</Typography>
			)}
		</Box>
	);
};
const ListCertificates = ({ certificates }) => {
	const theme = useTheme();
	const { isCogsManager, isBillPayManager } = useRoles();
	const { closeModal, openModal, status } = useModal();

	const {
		closeModal: closeModalPrivateNotes,
		openModal: openModalPrivateNote,
		status: modalPrivateNotes,
	} = useModal();
	const {
		closeModal: closeModalPublicNotes,
		openModal: openModalPublicNote,
		status: modalPublicNotes,
	} = useModal();
	const [travel, setTravel] = useState();
	const openModalTraveler = (item) => {
		setTravel(item);
		openModal();
	};

	const openModalPrivateNotes = (item) => {
		setTravel(item);
		openModalPrivateNote();
	};

	const openModalPublicotes = (item) => {
		setTravel(item);
		openModalPublicNote();
	};
	const columnToAdmin = [
		{
			name: 'contract',
			label: 'Contract Number',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return value.mainObj.agreementNumber;
				},
			},
		},
		{
			name: 'contract',
			label: 'Contract Name',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return value.contractName;
				},
			},
		},
	];
	const columns = [].concat(isCogsManager ? columnToAdmin : []).concat([
		{
			name: 'contract',
			label: 'Main Station',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => {
					if (
						value?.mainObj?.selectedRowsAqh &&
						Array.isArray(value?.mainObj?.selectedRowsAqh) &&
						value?.mainObj?.selectedRowsAqh.length > 0
					) {
						const mainStation = value.mainObj.selectedRowsAqh[0].Station;
						return mainStation;
					}
					return 'Not Main Station';
				},
			},
		},
		{
			name: 'NRes',
			label: 'Res#',
			options: {
				filter: true,
				sort: false,
			},
		},

		{
			name: 'startDate',
			label: 'Start Date',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return moment(value).format('DD/M/YYYY');
				},
				setCellProps: () => {
					return {
						style: {
							minWidth: '90px',
						},
					};
				},
			},
		},
		{
			name: 'endDate',
			label: 'End Date',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return moment(value).format('DD/M/YYYY');
				},
			},
		},
		{
			name: 'travelerId',
			label: 'travelerName',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return <TravelerInfo travelerId={value}></TravelerInfo>;
				},
			},
		},
		{
			name: 'nameCredit',
			label: 'Trip',
			options: {
				filter: true,
				sort: false,
			},
		},
		{
			name: 'credits',
			label: 'Credits',
			options: {
				filter: true,
				sort: false,
			},
		},
		{
			name: '',
			label: 'Details',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (certificates.length > 0) {
						let count = 0;
						const rowIndex = tableMeta.rowIndex;
						const rowData = certificates[rowIndex];
						if (rowData && rowData.history && rowData.history.length > 0) {
							const filter = rowData.history.filter(
								(item) => item.sender !== 'manager' && !item.seen
							);
							count = filter.length;
						}
						return (
							<Grid container spacing={2} justifyContent={'center'}>
								<Grid item>
									<IconButton onClick={() => openModalTraveler(rowData)}>
										<Info> </Info>
									</IconButton>
								</Grid>
								{isBillPayManager && (
									<Grid item>
										<IconButton onClick={() => openModalPrivateNotes(rowData)}>
											<PrivacyTipIcon></PrivacyTipIcon>
										</IconButton>
									</Grid>
								)}
								{isBillPayManager && (
									<Grid item>
										<CustomBadge badgeContent={count} color="secondary" max={99}>
											<IconButton onClick={() => openModalPublicotes(rowData)}>
												<SpeakerNotes></SpeakerNotes>
											</IconButton>
										</CustomBadge>
									</Grid>
								)}
							</Grid>
						);
					}
					return '';
				},
			},
		},
	]);

	const options = {
		filterType: 'dropdown',
		print: 'false',
		pagination: true,
	};
	return (
		<MainBox
			sx={{
				boxShadow: (theme) => theme.shadows[4],
				borderRadius: 2,
				paddingX: 1.2,
				marginX: -1,
			}}
		>
			<ThemeProvider theme={getTheme(theme)}>
				<MUIDataTable
					title={'Vacation Credits'}
					data={certificates || []}
					columns={columns}
					options={{
						...options,
						rowsPerPage: 25,
						rowsPerPageOptions: [25, 50, 100],
						selectToolbarPlacement: 'replace',
						selectableRowsHeader: true,
						selectableRows: 'none',
						responsive: 'standard',
					}}
				/>
			</ThemeProvider>
			<TravelDetails
				travel={travel}
				closeModal={closeModal}
				status={status}
			></TravelDetails>
			<PrivateNotes
				open={modalPrivateNotes}
				closeModal={closeModalPrivateNotes}
				certificate={travel}
			></PrivateNotes>
			<PublicNotes
				open={modalPublicNotes}
				closeModal={closeModalPublicNotes}
				certificate={travel}
			></PublicNotes>
		</MainBox>
	);
};
export default ListCertificates;
