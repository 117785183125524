/* eslint-disable no-unused-vars */
/* eslint-disable  react-hooks/exhaustive-deps*/
import { CheckCircleOutline, CloudUpload, Delete } from '@mui/icons-material';
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	ListItem,
	ListItemText,
	Paper,
	TextField,
	Typography,
	List,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MainBox, UploadFileTheme } from '../../utils/themes';
import useUploadMultipleFile from '../../hooks/useUploadMultipleFile';
import useFiles from '../../hooks/useFiles';
import { useDocument } from 'react-firebase-hooks/firestore';
import { updateDoc, getDoc, doc } from 'firebase/firestore';
import { db } from '../../../../firebase/config';
import { v4 } from 'uuid';
import HistoryTables from './HistoryTable';
import { sendEmailMessage } from '../../../../utils';
import useAuthContext from '../../../../hooks/useAuthContext';
import { generateText } from './utils/utils';

const History = ({ query, slug, nameProperty = 'history' }) => {
	const { user } = useAuthContext();
	const [history, setHistory] = useState([]);
	const [error, setError] = useState(false);
	const [sending, setSending] = useState(false);
	const { progress, uploadFiles, nameFiles, reset } =
		useUploadMultipleFile(slug);
	const { add, files, remove, update, clear } = useFiles();
	const [note, setNote] = useState('');
	const [userInfo, setUserInfo] = useState({});
	const [item] = useDocument(query);

	// Get user info
	useEffect(() => {
		if (user) {
			const getData = async () => {
				const docRef = doc(db, 'users', user.uid);
				const document = await getDoc(docRef);
				if (document.exists()) {
					setUserInfo({ ...document.data(), id: document.id });
				}
			};
			getData();
		}
	}, [user]);

	useEffect(() => {
		if (item) {
			if (item.exists()) {
				const data = item.data();
				if (data[nameProperty] && data[nameProperty].length > 0) {
					const history = data[nameProperty].sort((a, b) => {
						const dateA = new Date(a.date).getTime();
						const dateB = new Date(b.date).getTime();
						return dateB - dateA;
					});
					setHistory(history);
				}
			}
		}
	}, [item]);

	//UseEffect which is responsible for updating the status of a list of files to show that they have been successfully uploaded.
	useEffect(() => {
		if (progress) {
			const fileId = progress.metadata.id;
			const porcentComplete = progress.progress;
			if (porcentComplete === 100) {
				update(fileId, { metadata: { ...progress.metadata, status: 1 } });
			}
		}
	}, [progress, update]);

	/**
	 * useEffect created to send a note once the files have been fully uploaded.
	 * If there are no files to be uploaded, the note is sent immediately.
	 */
	useEffect(() => {
		const update = async (newNote) => {
			await updateDoc(query, { [nameProperty]: [newNote, ...history] });
			setSending(false);
			setNote('');
			clear();
			reset();
		};
		if (sending) {
			const filterDocs = files.filter((item) => item.metadata.status !== 1);

			if (filterDocs.length <= 0) {
				const newNote = {
					note: note,
					date: moment().format(),
					seen: false,
					sender: 'manager',
				};
				if (nameFiles.length > 0) {
					newNote.files = nameFiles;
				}
				update(newNote);
			}
		}
	}, [sending, files, nameFiles]);

	const verifySlug = (slug) => {
		if (slug === 'notes') {
			return 'Bill Pay';
		} else if (slug === 'certificates') {
			return 'Certificates';
		}
	};

	const sendFile = async () => {
		if (note.trim().length <= 0) {
			setError(true);
			return;
		}
		setSending(true);
		if (files.length > 0) {
			const filesUpload = [...files];
			files.forEach((item) => {
				const name = `${v4()}_${item.file.name}`;
				update(item.metadata.id, {
					metadata: { ...item.metadata, status: 2, name },
				});
			});
			uploadFiles(filesUpload);
		}
		// send email with note
		const data = item.data();
		// if is vacation credits find the client email
		// otherwise use the user email
		const clientInfo =
			data.history.find((record) => record.client)?.client || null;

		if (
			(data?.user?.email || clientInfo?.email) &&
			userInfo &&
			nameProperty === 'history'
		) {
			const title = 'New Note';
			const clientEmail = data?.user?.email || clientInfo?.email;
			const currentSlug = verifySlug(slug);
			const html = generateText(userInfo, currentSlug, note);
			// send email
			try {
				await sendEmailMessage(
					title,
					'',
					clientEmail,
					user.uid,
					null,
					null,
					null,
					null,
					html
				);
			} catch (error) {
				console.error('Error sending email:', error);
			}
		}
	};
	return (
		<>
			<Typography> Date: {moment().format('DD/MM/YYYY')}</Typography>
			<Typography> Time: {moment().format('HH:MM')}</Typography>

			<Paper elevation={2} sx={{ padding: 3, marginTop: 2 }}>
				<React.Fragment>
					<TextField
						id="outlined-multiline-static"
						label="Note"
						multiline
						error={error}
						rows={5}
						fullWidth={true}
						value={note}
						onChange={(e) => {
							setNote(e.target.value);
							setError(false);
						}}
						helperText={error ? 'Please fill in this field' : ''}
					/>
					<Box>
						<Grid container>
							<Grid item xs={10}>
								<Box>
									<Typography>Files(s):</Typography>
									<List sx={{ width: '90%' }}>
										{files.length > 0 &&
											files.map((file, index) => {
												return (
													<ListItem
														secondaryAction={
															<>
																{file.metadata.status === 3 && (
																	<IconButton
																		onClick={() => remove(file.metadata.id)}
																		edge="end"
																		aria-label="delete"
																	>
																		<Delete></Delete>
																	</IconButton>
																)}
																{file.metadata.status === 2 && (
																	<CircularProgress size={20}></CircularProgress>
																)}
																{file.metadata.status === 1 && (
																	<CheckCircleOutline></CheckCircleOutline>
																)}
															</>
														}
													>
														<ListItemText
															style={{ textOverflow: 'ellipsis' }}
															secondary={
																<span
																	style={{
																		display: 'block',
																		maxWidth: '100%',
																		whiteSpace: 'nowrap',
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																	}}
																>
																	{file.file.name}
																</span>
															}
														></ListItemText>
													</ListItem>
												);
											})}
									</List>
								</Box>
							</Grid>
							<Grid item xs={2} alignContent={'start'} justifyContent={'end'}>
								<div style={{ paddingTop: 10 }}>
									<Button
										component="label"
										disabled={sending}
										role={undefined}
										size="small"
										variant="contained"
										tabIndex={-1}
										startIcon={<CloudUpload />}
									>
										Add atachment
										<UploadFileTheme
											type="file"
											onChange={(event) => {
												if (!event?.target?.files) return;
												if (event.target.files.length <= 0) return;
												add(event.target.files[0], { status: 3 });
											}}
										></UploadFileTheme>
									</Button>
								</div>
							</Grid>
						</Grid>
					</Box>
				</React.Fragment>
			</Paper>
			<MainBox>
				<Grid item xs={2}>
					<Button onClick={sendFile} disabled={sending} variant="contained">
						Send
					</Button>
				</Grid>
				<HistoryTables
					history={history}
					collection={query}
					slug={slug}
				></HistoryTables>
			</MainBox>
		</>
	);
};
export default History;
