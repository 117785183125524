import * as React from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';

export default function LoadingSign() {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			minHeight="100vh"
			bgcolor="#FFFFFF"
		>
			<Stack spacing={2} direction="row" alignItems="center">
				<CircularProgress size={18} />
				<Typography alignContent={'center'}>
					Please wait, while we review the status of your document
				</Typography>
			</Stack>
		</Box>
	);
}
