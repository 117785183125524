import React from 'react';

import { MainBox } from './utils/themes';
import ListItems from './components/BillPay/ListItems';
import useContracts from './hooks/useContracts';
import Search from './components/Search';

const BillPay = () => {
	const { contractsId, setFilter, contracts } = useContracts();
	return (
		<MainBox>
			<Search setFilter={setFilter}></Search>
			<ListItems contracts={contracts} contractsId={contractsId}></ListItems>
		</MainBox>
	);
};

export default BillPay;
