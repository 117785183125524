import React from 'react';
import { Box, Typography, Stack, Divider } from '@mui/material';
import { dateTransform } from '../utils/utils';

export default function DeliverableData({ obj }) {
	// show the data of the rewards
	const RewardItem = ({ rewardData, title }) => {
		return (
			<>
				<Divider sx={{ mt: 1, mb: 1, fontSize: 14, fontWeight: 'bold' }}>
					{title}
				</Divider>
				{title !== 'Other' ? (
					rewardData?.map((reward, idx) => (
						<Box key={idx}>
							<Stack direction="row" spacing={2}>
								<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
									Quantity:
								</Typography>
								<Typography sx={{ fontSize: 14, width: '30px' }}>
									{reward?.quantity || 0}
								</Typography>
								<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
									Value:
								</Typography>
								<Typography sx={{ fontSize: 14, width: '50px' }}>
									$ {reward?.value || 0}
								</Typography>
								<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Date:</Typography>
								<Typography sx={{ fontSize: 14, width: '15%' }}>
									{reward.date ? dateTransform(reward.date) : ''}
								</Typography>
							</Stack>
						</Box>
					))
				) : (
					<Box>
						<Stack direction="row" spacing={2}>
							<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
								Revenue:
							</Typography>
							<Typography sx={{ fontSize: 14, width: '30px' }}>
								{rewardData?.revenue || 0}
							</Typography>
							<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Value:</Typography>
							<Typography sx={{ fontSize: 14, width: '50px' }}>
								$ {rewardData?.cogs || 0}
							</Typography>
						</Stack>
					</Box>
				)}
			</>
		);
	};
	// get the data of the vacation
	const VacationCredits = ({ allInputs, vacationCredits }) => {
		if (
			allInputs &&
			allInputs[0].name === 'Vacations' &&
			allInputs[0].cogs !== '' &&
			allInputs[0].cogs !== '0' &&
			allInputs[0].cogs !== 0
		) {
			// render the data
			const renderVacationBox = (title, data) => {
				if (data.length > 0) {
					return (
						<Box>
							<Stack direction="row" spacing={2}>
								<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
									{title}:
								</Typography>
								<Typography sx={{ fontSize: 14 }}>{data.join(', ')}</Typography>
							</Stack>
						</Box>
					);
				}
				return null;
			};
			// extract the data of Vacations credits
			const buildVacationData = (creditType) => {
				const vacationData = vacationCredits[creditType];
				const result = [];

				for (const [key, value] of Object.entries(vacationData)) {
					if (value !== 'N/A') {
						result.push(`${key}`);
					}
				}

				return result;
			};
			// build the data in buildVacationData
			const specialtyVacations = buildVacationData('Specialty Vacations');
			const vacationWithAirfare = buildVacationData('Vacations with airfare');
			const vacationWithoutAirfare = buildVacationData(
				'Vacations without airfare'
			);

			return (
				<>
					{renderVacationBox('Specialty Vacations', specialtyVacations)}
					{renderVacationBox('Vacations with airfare', vacationWithAirfare)}
					{renderVacationBox('Vacations without airfare', vacationWithoutAirfare)}
				</>
			);
		}

		return null; // Opcional: devolver algo en caso de no cumplir la condición
	};
	// return the data
	return (
		<>
			<Divider sx={{ mt: 1, mb: 1 }} />
			<Typography sx={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>
				Deliverable Data
			</Typography>
			{obj?.allInputs[0]?.cogs !== 0 &&
				obj?.allInputs[0]?.cogs !== '0' &&
				obj?.allInputs[0]?.cogs !== '' &&
				obj?.vacationCredits && (
					<>
						<Divider sx={{ mt: 1, mb: 1, fontSize: 14, fontWeight: 'bold' }}>
							Vacations
						</Divider>
						<VacationCredits
							allInputs={obj?.allInputs}
							vacationCredits={obj?.vacationCredits}
						/>
					</>
				)}
			{obj?.rewards?.visa?.length > 0 && (
				<RewardItem rewardData={obj?.rewards?.visa} title="Visa" />
			)}
			{obj?.rewards?.amazon?.length > 0 && (
				<RewardItem rewardData={obj?.rewards?.amazon} title="Amazon" />
			)}
			{obj?.rewards?.billpay?.length > 0 && (
				<RewardItem rewardData={obj?.rewards?.billpay} title="Bill pay" />
			)}
			{obj?.allInputs[4]?.cogs !== 0 &&
				obj?.allInputs[4]?.cogs !== '0' &&
				obj?.allInputs[4]?.cogs !== '' && (
					<RewardItem rewardData={obj?.allInputs[4]} title="Other" />
				)}
		</>
	);
}
