/* eslint-disable react-hooks/exhaustive-deps */
import {
	Table,
	TableCell,
	TableBody,
	TableHead,
	TableRow,
	Box,
	Paper,
	Typography,
	TextField,
	Tooltip,
	Fade,
	colors,
	IconButton,
	TableContainer,
	Stack,
	Button,
	ClickAwayListener,
	DialogActions,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
	ArrowBack as ArrowBackIcon,
	ArrowForward as ArrowForwardIcon,
	Close as CloseIcon,
	Verified as VerifiedIcon,
	Cancel as CancelIcon,
} from '@mui/icons-material';
import DealDetailsModal from '../../../components/DealDetailsModal';
import {
	getNumberOfDays,
	tableRowHeadersName,
} from '../../SalesProspector/utils/constants';
import { maxMinsPerDay } from '../utils/utils';
import { numberWithCommas } from '../../../utils/index';

const MuiBox = styled(Paper)(({ theme }) => ({
	background: '#FCFBFB',
	border: '1px solid rgb(0 0 0 / 22%)',
	boxShadow: 'rgb(99 99 99 / 20%) 0px 2px 8px 0px',
	borderRadius: '6px',
	padding: '2px',
	height: 'auto',
	overflowX: 'auto',
	'& > .MuiBox-root > p.mainHeading': {
		marginTop: '8px',
		marginBottom: '15px',
		color: colors.blue[800],
		paddingLeft: '5px',
		fontWeight: 500,
		fontSize: '1.4rem',
		textShadow:
			'0px 0px 1px rgb(255 255 255 / 10%), 1px -1px 0px rgb(0 0 0 / 50%), 2px 0px 1px rgb(21 101 192 / 44%)',
		letterSpacing: '0.05rem',
	},
	'& .noData > p': {
		padding: '10px',
		background: '#42A5F5',
		color: 'white',
		textAlign: 'center',
		borderRadius: 1,
	},
	table: {
		width: 'auto',
		borderCollapse: 'separate',
		borderSpacing: '5px',
	},
	'& .MuiTableCell-root': {
		fontSize: '12px',
		borderBottom: '0px',
		'&.MuiTableCell-head': {
			padding: '0px',
		},
		'&.MuiTableCell-body': {
			padding: '2px 4px',
		},
	},
	thead: {
		'& tr.station': {
			'& th': {
				background: '#42A5F5',
				color: '#FFF',
			},
		},
		'& tr.format': {
			'& th': {
				background: '#1565C0',
				color: '#FFF',
			},
		},
		'& tr > th': {
			'& p': {
				fontSize: '12px',
				padding: '3px 5px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
			'&.customHeaderStation': {
				'& > button': {
					position: 'absolute',
					right: '0px',
					top: '0px',
					visibility: 'hidden',
				},
				'&:hover > button': {
					visibility: 'visible',
				},
			},
		},
	},
	'& tr': {
		'& > td:not(:first-of-type), th:not(:first-of-type)': {
			minWidth: '130px',
			width: '140px',
		},
		'& > td:first-of-type, th:first-of-type': {
			color: '#fff',
			minWidth: '80px',
			width: '70px',
		},
		'&:nth-of-type(even) > td:first-of-type': {
			backgroundColor: '#42A5F5',
		},
		'&:nth-of-type(odd) > td:first-of-type': {
			backgroundColor: '#007DFF',
		},
		'& > td.rest': {
			height: '27px',
			'& > p': {
				fontSize: '12px',
			},
			'&.selected': {
				border: '1px solid #008000de',
			},
		},
	},
}));
const aqhCellColor = (aqh, index, edited, colordaypart) => {
	if (aqh > 0 || colordaypart === 'mainStation') {
		return edited ? colors.green[200] : index % 2 === 0 ? '#BBDEFB' : '#E3F2FD';
	} else {
		return edited
			? colors.green[200]
			: index % 2 === 0
			? colors.grey[400]
			: colors.grey[500];
	}
};

const MuiDataTableCell = styled(TableCell, {
	shouldForwardProp: (prop) => prop !== 'edited' || prop === 'index',
})(({ theme, edited, aqh, index, colordaypart }) => ({
	backgroundColor: aqhCellColor(aqh, index, edited, colordaypart),
}));

const StickyTableCell = styled(TableCell)(({ theme }) => ({
	cursor: 'default !important',
	'&.MuiTableCell-head': {
		left: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar + 2,
	},
	'&.MuiTableCell-body': {
		left: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar + 1,
	},
}));

const CustomToolTip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	zIndex: theme.zIndex.tooltip + 1,
	[`& .MuiTooltip-tooltip`]: {
		color: '#fff',
		backgroundColor: theme.palette.info.main,
		padding: '5px 10px',
	},
	[`& .MuiTooltip-arrow`]: {
		color: theme.palette.info.main,
		fontSize: 12,
	},
}));

const CustomTableCell = ({
	arr,
	rowIdx,
	colIdx,
	hanldeBlur,
	selected,
	tableData,
	dayPart,
	wasApproved,
	startDateExists,
	noOfWeeksExists,
	setMissingDataModal,
	callbackRestoreValue,
	missingDataModal,
	setSumOfValuesDaypart,
	setIsBridgeDealModal,
	hasBridgeDeal,
}) => {
	const [showInput, setShowInput] = useState(false);
	const [value, setValue] = useState(arr[rowIdx + 2].noOfSpots);
	const [valueEntered, setValueEntered] = useState(false);
	const [open, setOpen] = useState(false);
	const [tooltipTitle, setTooltipTitle] = useState('');

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const numberOfImpressions = (minutes, aqh) => {
		const dayPartString = tableRowHeadersName[rowIdx].slice(0, 3);
		return minutes * aqh * 2 * getNumberOfDays(dayPartString);
	};
	// if the value is 0 then set the valueEntered to false
	const onBlur = () => {
		hanldeBlur(arr, rowIdx, value);
		setShowInput(false);
		let copyEntered = value === 0 ? false : !valueEntered ? true : valueEntered;
		setValueEntered(copyEntered);
	};

	const handleChange = (e) => {
		const { value: val } = e.target;
		if (val > maxMinsPerDay(dayPart)) {
			setTooltipTitle(`${maxMinsPerDay(dayPart)}`);
			setOpen(true);
		} else if (val > 0 && val <= maxMinsPerDay(dayPart)) {
			setOpen(false);
			setValue(
				!Number.isNaN(Number(val)) && val >= 0 && val <= maxMinsPerDay(dayPart)
					? Number(val)
					: value
			);
		} else {
			setOpen(false);
			setValue(0);
		}
	};
	// if enter key is pressed then call onBlur function
	const onEnterKeyPress = (ev) => {
		if (ev.key === 'Enter') {
			onBlur();
		}
	};
	// change the value of the all daypart
	const changeAlldayPart = (rowIdx) => {
		tableData.map((arr) => {
			let copyArr = arr;
			copyArr[rowIdx] = { ...arr[rowIdx], noOfSpots: value };
			return copyArr;
		});
		onBlur();
	};

	// reset the value of all rows and reset the table state
	const restoreValue = (rowIdx) => {
		setValueEntered(false);
		setShowInput(false);
		callbackRestoreValue(rowIdx);
	};

	// validate if the value is entered and the daypart
	const validateInput = (valueEntered, rowIdx) => {
		if (valueEntered && arr[rowIdx + 2]?.show === true) {
			return true;
		} else {
			return false;
		}
	};

	// This function calculates the sum of values for a specific daypart 'MF 6a-7p'
	const sumValuesOfDaypart = () => {
		let sum = 0;
		// Iterate over each array in tableData
		tableData.forEach((array) => {
			// Check if the subArray has more than 5 elements and if the sixth element has the 'orignalValue' property
			if (array.length > 5 && array[5].hasOwnProperty('orignalValue')) {
				// Convert the 'orignalValue' to an integer
				const value = parseInt(array[5].orignalValue, 10);
				// Check if the value is a valid number and not NaN
				if (!isNaN(value)) {
					sum += value;
				}
			}
		});
		return sum;
	};
	// This function is called when the component is mounted
	useEffect(() => {
		if (tableData.length > 0) {
			// save the sum of values for the daypart 'MF 6a-7p'
			const sum = sumValuesOfDaypart();
			// set value of sumOfValuesDaypart
			setSumOfValuesDaypart(sum);
		}
	}, []);

	return (
		<MuiDataTableCell
			className={selected ? 'rest selected' : 'rest'}
			align="center"
			edited={arr[rowIdx + 2].noOfSpots > 0}
			aqh={arr[rowIdx + 2].orignalValue}
			index={rowIdx}
			colordaypart={arr[rowIdx + 2].colorDayPart}
		>
			{!showInput ? (
				valueEntered || arr[rowIdx + 2].noOfSpots > 0 ? (
					<div>
						<Stack
							direction="row"
							alignItems={'center'}
							justifyContent={arr[rowIdx + 2]?.show === true ? 'flex-end' : 'center'}
						>
							{/* Restore the vales in the daypart */}
							{arr[rowIdx + 2]?.show === true ||
							validateInput(valueEntered, rowIdx) ? (
								<CustomToolTip
									arrow
									placement="right"
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 400 }}
									title="Remove to all stations"
								>
									<span>
										<IconButton
											disabled={wasApproved || !startDateExists || !noOfWeeksExists}
											size="small"
											aria-label="arrow-forward"
											color="error"
											onClick={() => {
												restoreValue(rowIdx + 2);
											}}
										>
											<CancelIcon fontSize="inherit" />
										</IconButton>
									</span>
								</CustomToolTip>
							) : (
								''
							)}
							{/* Show input for daypart */}
							<Button
								disabled={wasApproved || !startDateExists || !noOfWeeksExists}
								onClick={(_) => setShowInput(true)}
							>
								<CustomToolTip
									arrow
									placement="right"
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 400 }}
									title={
										<Box sx={{ width: 'max-content' }}>
											<Typography fontSize={13}>
												mins per day: <b>{arr[rowIdx + 2].noOfSpots}</b>
											</Typography>
											<Typography fontSize={13}>
												AQH: <b>{arr[rowIdx + 2].orignalValue}</b>
											</Typography>
											<Typography fontSize={13}>
												No. of impressions:{' '}
												<b>
													{numberOfImpressions(
														arr[rowIdx + 2].noOfSpots,
														arr[rowIdx + 2].orignalValue
													)}
												</b>
											</Typography>
										</Box>
									}
								>
									<Typography
										sx={{ textDecoration: 'none', color: 'black' }}
										fontSize={12}
									>
										{arr[rowIdx + 2].noOfSpots}
									</Typography>
								</CustomToolTip>
							</Button>
							{/* Add the same value for all stations */}
							{arr[rowIdx + 2]?.show === true ||
							validateInput(valueEntered, rowIdx) ? (
								<CustomToolTip
									arrow
									placement="right"
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 400 }}
									title="Add to all stations"
								>
									<span>
										<IconButton
											disabled={wasApproved || !startDateExists || !noOfWeeksExists}
											size="small"
											aria-label="arrow-forward"
											color="primary"
											onClick={() => {
												changeAlldayPart(rowIdx + 2);
											}}
										>
											<ArrowForwardIcon fontSize="inherit" />
										</IconButton>
									</span>
								</CustomToolTip>
							) : (
								''
							)}
						</Stack>
					</div>
				) : (
					<Button
						// if proposal was approved is true then disable the button
						disabled={wasApproved}
						// if startDateExists or noOfWeeksExists is false then show modal
						onClick={(_) => {
							if (!startDateExists || !noOfWeeksExists) {
								setMissingDataModal(true);
							} else {
								setShowInput(true);
							}
						}}
					>
						<Typography sx={{ textDecoration: 'none', color: 'black' }} fontSize={12}>
							{arr[rowIdx + 2].orignalValue}
						</Typography>
					</Button>
				)
			) : (
				<Box>
					<ClickAwayListener onClickAway={handleTooltipClose}>
						<Tooltip
							arrow
							TransitionComponent={Fade}
							TransitionProps={{ timeout: 400 }}
							PopperProps={{
								disablePortal: true,
								sx: {
									'& .MuiTooltip-tooltip': {
										backgroundColor: 'red',
										color: '#fff',
									},
									'& .MuiTooltip-arrow': {
										color: 'red',
										fontSize: 12,
									},
								},
							}}
							onClose={handleTooltipClose}
							open={open}
							disableFocusListener
							disableHoverListener
							disableTouchListener
							title={
								<Box sx={{ width: 'max-content' }}>
									<Typography fontSize={13}>
										max minutes for daypart per day: <b>{tooltipTitle}</b>
									</Typography>
									<Typography fontSize={13}>
										max minutes per hour: <b>{2}</b>
									</Typography>
								</Box>
							}
						>
							<TextField
								size="small"
								variant="outlined"
								color="info"
								value={value}
								onBlur={onBlur}
								onChange={handleChange}
								onKeyPress={onEnterKeyPress}
								autoFocus={showInput}
								inputProps={{
									style: {
										padding: '2px 4px',
										fontSize: '12px',
										width: '30%',
										textAlign: 'center',
									},
								}}
								InputProps={{
									style: {
										paddingLeft: '5px',
									},
									startAdornment: <Typography fontSize="12px">mins per day:</Typography>,
								}}
							/>
						</Tooltip>
					</ClickAwayListener>
				</Box>
			)}
		</MuiDataTableCell>
	);
};

const CustomHeaderStationCell = ({
	arr,
	colIdx,
	removeSelectedStation,
	selectedRowsAqh,
	wasApproved,
	startDateExists,
	noOfWeeksExists,
	setIsBridgeDealModal,
	hasBridgeDeal,
}) => {
	const [openDealModal, setOpenDealModal] = useState(false);
	const [isDeal, setIsDeal] = useState(false);
	const initialQueryDetails = { title: '', value: '', name: '' };
	const [queryDetails, setQueryDetails] = useState(initialQueryDetails);
	const initialSelectAllDetails = {
		checked: false,
		value: '',
		checkedBy: '',
		total: 0,
	};
	const [selectAllDetails, setSelectAllDetails] = useState(
		initialSelectAllDetails
	);

	const showDealDetails = (station) => {
		const stationInfo = selectedRowsAqh?.find((s) => s['Station'] === station);
		setOpenDealModal(true);
		setIsDeal(true);
		setQueryDetails({
			name: 'Deal',
			title: 'Deal',
			statusDeal: 'Deal',
			detailsObj: stationInfo,
		});
	};

	const activeDeal = (station) => {
		const str = 'endDateSchedule';
		const stationInfo = selectedRowsAqh?.find((s) => s['Station'] === station);
		if (
			stationInfo &&
			stationInfo.dealDetails[str] !== undefined &&
			stationInfo.dealDetails[str] !== 'N/A' &&
			stationInfo.dealDetails[str] !== 'n/a'
		) {
			const dealDate = new Date(stationInfo.dealDetails[str]);
			const today = new Date();
			// Set dates time to midnight
			dealDate.setHours(0, 0, 0, 0);
			today.setHours(0, 0, 0, 0);
			// Check if deal date is greater than or equal to today
			if (dealDate >= today) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};
	return (
		<>
			<TableCell key={arr[0] + colIdx} align="center">
				<Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
					<IconButton
						disabled={wasApproved}
						size="small"
						onClick={() => showDealDetails(arr[0])}
					>
						{activeDeal(arr[0]) === true ? (
							<VerifiedIcon color="success" size="small" />
						) : (
							<CancelIcon color="error" size="small" />
						)}
					</IconButton>
					<Typography>{arr[0]}</Typography>
					<IconButton
						disabled={wasApproved || !startDateExists || !noOfWeeksExists}
						color="error"
						size="small"
						onClick={() => removeSelectedStation(arr[0])}
					>
						<CloseIcon />
					</IconButton>
				</Stack>
			</TableCell>
			<DealDetailsModal
				openDealModal={openDealModal}
				setOpenDealModal={setOpenDealModal}
				queryDealDetails={queryDetails}
				isDeal={isDeal}
				onClose={() => {
					setOpenDealModal(false);
					isDeal && setIsDeal(false);
					setQueryDetails(initialQueryDetails);
					setSelectAllDetails(initialSelectAllDetails);
				}}
				selectAllDetails={selectAllDetails}
				salesList={selectedRowsAqh}
			/>
		</>
	);
};

const scrollRightTable = () => {
	document.getElementById('container').scrollLeft += 10000;
};
const scrollLeftTable = () => {
	document.getElementById('container').scrollLeft -= 10000;
};

const AQHDayPartTable = ({
	tableData,
	setTableData,
	removeSelectedStation,
	loading,
	selectedRowsAqh,
	wasApproved,
	dates,
	setIsBridgeDealModal,
	hasBridgeDeal,
}) => {
	const [missingDataModal, setMissingDataModal] = useState(false);
	// validate if start date and no of weeks exists
	let startDateExists = dates.startDate !== null ? true : false;
	let noOfWeeksExists = dates?.noOfWeeks > 0 ? true : false;
	const [sumOfValuesDaypart, setSumOfValuesDaypart] = useState(0);

	const newTableData = tableData.map((arr, idx) => {
		if (idx === 0) {
			return arr.map((obj, idx) => {
				if (idx === 0) return obj;
				else if (idx === 1) return obj;
				else return { ...obj, show: true };
			});
		} else {
			return arr;
		}
	});

	const hanldeBlur = (arr, rowIdx, value) => {
		let tableArrays = newTableData.map((array) => {
			let copyArr = array;
			if (array[0] === arr[0] && array[1] === arr[1]) {
				copyArr[rowIdx + 2] = { ...array[rowIdx + 2], noOfSpots: value };
				return copyArr;
			} else return array;
		});
		setTableData(tableArrays);
	};
	// close modal
	const handleCloseModal = (event, reason) => {
		if (reason && reason === 'backdropClick') return;
		setMissingDataModal(false);
	};
	//This function is used to reset the values of noOfSpots to 0 in the row indicated in the rowIdx parameter.
	const restoreValue = (rowIdx) => {
		const newTable = newTableData.map((item) => {
			const copyArray = item;
			copyArray[rowIdx] = { ...item[rowIdx], noOfSpots: 0 };
			return copyArray;
		});
		setTableData(newTable);
	};

	return (
		<>
			<MuiBox>
				{!tableData.length ? (
					<Box className="noData" p={1}>
						<Typography>Data is Loading...</Typography>
					</Box>
				) : (
					<Box>
						<TableContainer sx={{ maxHeight: 495 }} id="container">
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow className="station">
										<StickyTableCell align="center" className="sticky">
											<Typography>Station</Typography>
										</StickyTableCell>
										{tableData.map((arr, colIdx) => (
											<CustomHeaderStationCell
												key={colIdx}
												colIdx={colIdx}
												arr={arr}
												removeSelectedStation={removeSelectedStation}
												selectedRowsAqh={selectedRowsAqh}
												wasApproved={wasApproved}
												startDateExists={startDateExists}
												noOfWeeksExists={noOfWeeksExists}
											/>
										))}
									</TableRow>
									<TableRow className="format">
										<StickyTableCell
											style={{ top: 40 }}
											align="center"
											className="sticky"
										>
											<Typography>Format</Typography>
										</StickyTableCell>
										{tableData.map((arr, colIdx) => (
											<TableCell key={arr[0] + colIdx} align="center" style={{ top: 40 }}>
												<Typography>{arr[1]}</Typography>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{tableRowHeadersName.map((name, rowIdx) => (
										<TableRow key={name + rowIdx}>
											{/* if Daypart is "MF 6a-7p" show in tooltip value in sumOfValuesDaypart */}
											{name === 'MF 6a-7p' ? (
												<CustomToolTip
													arrow
													placement="right"
													TransitionComponent={Fade}
													TransitionProps={{ timeout: 400 }}
													title={
														<Box sx={{ width: 'max-content' }}>
															<Typography fontSize={13}>
																Total AQH: <b>{numberWithCommas(sumOfValuesDaypart)}</b>
															</Typography>
														</Box>
													}
												>
													<StickyTableCell align="center">{name}</StickyTableCell>
												</CustomToolTip>
											) : (
												<StickyTableCell align="center">{name}</StickyTableCell>
											)}
											{newTableData.map((arr, colIdx) => {
												return (
													<CustomTableCell
														key={rowIdx + colIdx}
														arr={arr}
														rowIdx={rowIdx}
														colIdx={colIdx}
														setTableData={setTableData}
														tableData={newTableData}
														hanldeBlur={hanldeBlur}
														dayPart={name}
														wasApproved={wasApproved}
														startDateExists={startDateExists}
														noOfWeeksExists={noOfWeeksExists}
														setMissingDataModal={setMissingDataModal}
														missingDataModal={missingDataModal}
														setSumOfValuesDaypart={setSumOfValuesDaypart}
														callbackRestoreValue={restoreValue}
														setIsBridgeDealModal={setIsBridgeDealModal}
														hasBridgeDeal={hasBridgeDeal}
													/>
												);
											})}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<Stack direction="row" justifyContent="space-between">
							<IconButton
								aria-label="arrow-back"
								color="primary"
								onClick={() => scrollLeftTable()}
							>
								<ArrowBackIcon />
							</IconButton>
							<IconButton
								aria-label="arrow-forward"
								color="primary"
								onClick={() => scrollRightTable()}
							>
								<ArrowForwardIcon />
							</IconButton>
						</Stack>
					</Box>
				)}
			</MuiBox>
			<Dialog open={missingDataModal} onClose={handleCloseModal}>
				<DialogTitle>Remember</DialogTitle>
				<IconButton
					color="primary"
					size="small"
					sx={{
						width: 'fit-content',
						position: 'absolute',
						right: '3%',
						top: '5%',
					}}
					onClick={handleCloseModal}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<Typography textAlign={'center'}>
						Before continuing you must add the start date, the number of weeks and the
						end date
					</Typography>
				</DialogContent>
				<DialogActions>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						sx={{ width: '100%', pb: 2 }}
					>
						<Button
							onClick={handleCloseModal}
							color="primary"
							variant="contained"
							autoFocus
						>
							ok, got it
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AQHDayPartTable;
