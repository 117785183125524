import React from 'react';
import { Box } from '@mui/material';
import { MainBox } from '../../utils/themes';
import ListRewardCards from './ListRewardCards';
import useRewardCards from '../../hooks/useRewardCards';
import Search from './SearchRewardCards';
import ExportCSV from './ExportRewardCards';

const RewardCards = () => {
	// Get the order cards records
	const { orderCardsRecords, setFilter } = useRewardCards();

	return (
		<MainBox>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				my={2}
			>
				<Search setFilter={setFilter} />
				<ExportCSV data={orderCardsRecords} />
			</Box>
			<ListRewardCards orderCardsRecords={orderCardsRecords} />
		</MainBox>
	);
};
export default RewardCards;
