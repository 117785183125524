import { Button, Box } from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport';
// import { useLocation } from 'react-router-dom'
import BugReportModal from '../Pages/BugReport/BugReportModal';
import { useState } from 'react';

const reportBugButton = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [openModal, setOpenModal] = useState(false);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	// const location = useLocation()
	const reportBug = () => {
		setOpenModal(true);
	};
	return (
		<Box>
			<Button variant="contained" sx={{ mt: 2 }} onClick={reportBug}>
				<BugReportIcon /> Report Bug
			</Button>
			<BugReportModal
				openModal={openModal}
				onClose={() => {
					setOpenModal(false);
				}}
			/>
		</Box>
	);
};

export default reportBugButton;
