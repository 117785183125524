/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase/config';
import useAuthContext from '../../hooks/useAuthContext';
import {
	CircularProgress,
	DialogActions,
	IconButton,
	Typography,
	Button,
	TextField,
	Grid,
	colors,
	Snackbar,
} from '@mui/material';
import { Box } from '@mui/system';
import { CloseOutlined } from '@mui/icons-material';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { pages, browserDetect, generateBugMessage } from './utils';
import axios from 'axios';
import { baseURL, sendEmailMessage } from '../../utils';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ openModal, onClose }) {
	const [scrollHere, setScroll] = React.useState(0);
	const [loading, setLoading] = useState(false);
	const [date, setDate] = useState(new Date());
	const { user } = useAuthContext();
	const [userData, setUserData] = useState('');
	const [userName, setUserName] = useState('');
	const [bug, setBug] = useState('');
	const location = useLocation();
	const userBrowser = browserDetect(navigator.userAgent);
	const userPage = pages(location.pathname);
	const [error, setError] = useState('');
	const [state, setState] = React.useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	});
	const { vertical, horizontal, open } = state;

	const getUserName = async () => {
		if (auth?.currentUser?.uid) {
			const docRef = doc(db, 'users', auth.currentUser.uid);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				const user = docSnap.data();
				setUserName(user.firstName + ' ' + user.lastName);
			}
		}
	};
	getUserName();

	// get user info
	useEffect(() => {
		const getUser = async () => {
			if (!user.uid) return;
			const userRef = doc(db, 'users', user.uid);
			const userDoc = await getDoc(userRef);
			if (userDoc.exists()) {
				const user = userDoc.data();
				setUserData({ ...user, uid: userDoc.id });
			}
		};
		if (user !== null) {
			getUser();
		}
	}, [user]);

	const handleChangeBug = (event) => {
		setBug(event.target.value);
	};

	const reportBug = async () => {
		if (!bug || bug === '') {
			setError('Please describe the bug');
		} else {
			setLoading(true);
			try {
				const dataBug = {
					name: userName,
					browser: userBrowser,
					page: userPage,
					dateAndHour: format(date, 'MM/dd/yyyy HH:mm:ss'),
					bug,
				};
				// generate bug report
				const response = await axios.post(baseURL + '/reportBug', { ...dataBug });
				// get issue id
				const issueId = response.data.identifier;
				const description = generateBugMessage({ ...dataBug, issueId });
				// send email
				if (userData) {
					await sendEmailMessage(
						'Bug Report',
						'',
						userData.email,
						userData.uid,
						null,
						null,
						null,
						null,
						description
					);
					// notify admin
					await sendEmailMessage(
						'Bug Report',
						'',
						process.env.REACT_APP_BUG_ADMIN_EMAIL,
						userData.uid,
						null,
						null,
						null,
						null,
						description
					);
				}
				setLoading(false);
				onClose();
				setState({ open: true, vertical: 'top', horizontal: 'right' });
				setError('');
				setBug('');
			} catch (error) {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		if (openModal) {
			const y = window.pageYOffset;
			setScroll(y);
		}
	}, [openModal]);

	const handleClose = () => {
		onClose();
		setTimeout(() => {
			window.scrollTo({ top: scrollHere, behavior: 'auto' });
		}, 1);
	};

	const handleCloseSnackBar = () => {
		setState({ ...state, open: false });
	};

	return (
		<div>
			<Dialog
				open={openModal}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
				sx={{
					'& .MuiDialog-paper': {
						minWidth: '800px',
					},
				}}
			>
				<DialogTitle sx={{ pb: 0 }}>Bug Report</DialogTitle>

				<IconButton
					color="primary"
					size="small"
					sx={{
						width: 'fit-content',
						position: 'absolute',
						right: '3%',
						top: '3.5%',
					}}
					onClick={handleClose}
				>
					<CloseOutlined />
				</IconButton>
				<DialogContent>
					<Box
						sx={{
							my: 1,
							'& >*:not(.loading)': {
								display: 'flex',
								gap: '10px',
							},
						}}
					>
						<Grid container spacing={2}>
							<Grid item container xs={12} spacing={2}>
								<Grid item xs={6} />
								<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Typography variant="h7" fontWeight="600">
										Date:{' '}
									</Typography>
									<Typography variant="h7"> {format(date, 'MMMM dd yyyy')}</Typography>
								</Grid>
							</Grid>
							<Grid item container xs={12} spacing={2}>
								<Grid item xs={6} display="flex" gap="10px">
									<TextField
										label="Name"
										variant="outlined"
										size="medium"
										name="name"
										value={userName}
										disabled
										fullWidth
									/>
								</Grid>
							</Grid>
							<Grid item container xs={12} spacing={2}>
								<Grid item xs={6}>
									<TextField
										label="Browser"
										variant="outlined"
										size="medium"
										name="browser"
										value={userBrowser}
										disabled
										fullWidth
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										label="Page/Module"
										variant="outlined"
										size="medium"
										name="page"
										value={userPage}
										disabled
										fullWidth
									/>
								</Grid>
							</Grid>
							<Grid item container xs={12} spacing={2}>
								<Grid item xs={12} display="flex" gap="10px">
									<TextField
										id="outlined-multiline-static"
										label="Describe the bug"
										multiline
										rows={4}
										fullWidth
										value={bug}
										onChange={handleChangeBug}
										placeholder="Describe the bug"
									/>
								</Grid>
							</Grid>
						</Grid>
					</Box>
					<Typography color="error" variant="h7">
						{error}
					</Typography>
				</DialogContent>
				<DialogActions sx={{ my: 1, pr: 3 }}>
					<Button
						color="primary"
						variant="contained"
						onClick={reportBug}
						disabled={loading}
						sx={{ minWidth: '100px' }}
						startIcon={loading && <CircularProgress size={18} />}
					>
						{' '}
						Report
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				anchorOrigin={{ vertical, horizontal }}
				open={open}
				onClose={handleCloseSnackBar}
				autoHideDuration={3000}
				key={vertical + horizontal}
			>
				<Alert sx={{ backgroundColor: colors.green[500] }}>Bug Reported</Alert>
			</Snackbar>
		</div>
	);
}
