import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
	Dialog,
	DialogActions,
	DialogTitle,
	Fade,
	DialogContent,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Fade direction="up" ref={ref} {...props} />;
});

export default function NotFoundModal({ notFoundModalOpen, onCloseAction }) {
	const handleClose = () => {
		onCloseAction();
	};

	return (
		<div>
			<Dialog
				open={notFoundModalOpen}
				TransitionComponent={Transition}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
				sx={{
					'& .MuiDialog-paper': {
						minWidth: '300px',
					},
				}}
			>
				<DialogTitle sx={{ userSelect: 'none' }}>Not Found</DialogTitle>

				<DialogContent>
					<Typography>The selected proposal was not found</Typography>
				</DialogContent>

				<DialogActions sx={{ textAlign: 'center' }}>
					<Button variant="outlined" color="error" onClick={handleClose}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
