/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CloseOutlined } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
    openDealModal,
    onClose,
    detailsObj,
}) {

    const [scrollHere, setScroll] = React.useState(0)
    const [data, setData] = React.useState({})

    useEffect(() => {
        const getData = () => {
            setData(detailsObj)
        }
        getData()
    }, [])

    useEffect(() => {
        if (openDealModal) {
            const y = window.pageYOffset;
            setScroll(y)
        }
    }, [openDealModal])

    const handleCloseDeal = () => {
        onClose()
        setTimeout(() => {
            window.scrollTo({ top: scrollHere, behavior: 'auto' })
        }, 1)

    }
    return (
      <Dialog
        open={openDealModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeal}
        aria-describedby="alert-dialog-slide-description"
        sx={{
            "& .MuiDialog-paper": {
                minWidth: "600px",
            }
        }}
        >
        <DialogTitle sx={{ pb: 0 }}>Deal Message Details</DialogTitle>

        <IconButton color="primary" size='small'
            sx={{
                width: "fit-content",
                position: "absolute",
                right: "3%",
                top: "3.5%",
            }}
            onClick={handleCloseDeal}
            >
            <CloseOutlined />
            </IconButton>
            <DialogContent>
                <Box sx={{
                    my: 1,
                    "& >*:not(.loading)": {
                        display: "flex",
                        gap: "10px",
                        margin: "6px 0",
                        border: `1px solid rgb(0 0 0 / 10%)`,
                        background: "rgb(0 0 0 / 10%)",
                        borderRadius: "4px",
                        padding: "5px",
                        boxShadow: "rgb(0 0 0 / 40%) -1px 1px 1.8px",
                    }
                }}>
                  {
                    <>
                    {Object.keys(data).map((key, idx) => (
                        <Box key={key}>
                            <Typography fontSize={16} fontWeight="bold">{key}:</Typography>
                            <Typography fontSize={16}>{data[key]}</Typography>
                        </Box>
                    ))}
                    </> 
                    }
                </Box>
            </DialogContent>
        </Dialog>
    );
}
