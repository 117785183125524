import { ThemeProvider, useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { getTheme, optionsTable } from '../../utils/themes';
import { SpeakerNotes } from '@mui/icons-material';
import useModal from '../../hooks/useModal';
import moment from 'moment';
import NotesDetail from './NotesDetail';
import { useEffect, useState } from 'react';
import { updateDoc } from 'firebase/firestore';

const HistoryTables = ({ history, collection, slug }) => {
	const { closeModal, openModal, status } = useModal();
	const [register, setRegister] = useState();
	const theme = useTheme();
	useEffect(() => {
		const updateSeen = async (history) => {
			await updateDoc(collection, { history });
		};
		if (history.length > 0 && collection) {
			const hasUnseenFields = history.filter(
				(register) => !register.seen && register.sender !== 'manager'
			);
			if (hasUnseenFields.length > 0) {
				const updateHistory = history.map((item) => {
					if (item.sender !== 'manager') {
						return {
							...item,
							seen: true,
						};
					}
					return item;
				});
				updateSeen(updateHistory);
			}
		}
	}, [history, collection]);
	const columns = [
		{
			name: 'date',
			label: 'Date',
			options: {
				customBodyRender: (value) => {
					return moment(value).format('DD/MM/YYYY');
				},
			},
		},
		{
			name: 'note',
			label: 'Note',
		},

		{
			name: '',
			label: 'View',
			options: {
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const rowData = history[rowIndex];
					return (
						<SpeakerNotes
							sx={{ cursor: 'pointer' }}
							onClick={() => openModalDetailRegister(rowData)}
						></SpeakerNotes>
					);
				},
			},
		},
	];

	const openModalDetailRegister = (register) => {
		setRegister(register);
		openModal();
	};
	return (
		<Box>
			<ThemeProvider theme={getTheme(theme)}>
				<MUIDataTable
					columns={columns}
					title={'History'}
					options={{ ...optionsTable }}
					data={history || []}
				></MUIDataTable>
			</ThemeProvider>
			<NotesDetail
				open={status}
				closeModal={closeModal}
				register={register}
				slug={slug}
			></NotesDetail>
		</Box>
	);
};
export default HistoryTables;
