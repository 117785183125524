
import React, { useState } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import EChart from "../Dashboard/components/EChart.js";
import { optionEchart1, optionEchart2, optionEchart4, style } from "./constants.js";
import { Box, Button, Typography } from '@mui/material'
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Data from "../Dashboard/components/dataComp.js";
import Proposal from "../Dashboard/components/proposal.js";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

/**
 * This layout demonstrates how to sync multiple responsive layouts to localstorage.
 */
export default function AdminPanel() {
  const [state, setState] = useState({
    layouts: JSON.parse(JSON.stringify(originalLayouts))
  })

  const resetLayout = () => {
    setState({ layouts: {} });
  }

  const onLayoutChange = (layout, layouts) => {
    saveToLS("layouts", layouts);
    setState({ layouts });
  }

  return (
    <div>
      <Button size="small" sx={{ ml: 1 }} onClick={() => resetLayout()}>Reset Layout</Button>
      <ResponsiveReactGridLayout
        className="layout"
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={120}
        layouts={state.layouts}
        onLayoutChange={(layout, layouts) =>
          onLayoutChange(layout, layouts)
        }
        draggableHandle=".grid-item__title"
        isResizable={true}
        resizeHandles={["se"]}
      >
        {/* row 1 */}
        <Box key="1s" className="proposal" data-grid={{ w: 4, h: 8, x: 0, y: 0, minW: 4, minH: 8, }} style={style}>
          <Typography
            variant="h5"
            className="grid-item__title"
            p={1}
            textAlign="center"
            fontWeight="bold"
          >
            Stack column chart
          </Typography>
          <EChart
            option={optionEchart1}
          />
        </Box>
        <Box key="2s" className="dataComponent" data-grid={{ w: 4, h: 4, x: 4, y: 0, minW: 4, minH: 4, }} style={style}>
          <Typography
            variant="h5"
            className="grid-item__title"
            p={1}
            textAlign="center"
            fontWeight="bold"
          >
            Pie chart
          </Typography>
          <EChart
            option={optionEchart2}
          />

        </Box>
        <Box key="3s" className="dataComponent" data-grid={{ w: 4, h: 4, x: 8, y: 0, minW: 4, minH: 4, }} style={style}>
          <Data data={"b"} i={1} />
        </Box>
        <Box key="4s" className="proposal" data-grid={{ w: 4, h: 4, x: 4, y: 2, minW: 4, minH: 4, }} style={style}>
          <Proposal data={"b"} i={1} />
        </Box>
        <Box key="5s" data-grid={{ w: 8, h: 8, x: 0, y: 3, minW: 8, minH: 8, }} style={style}>
          <Typography
            variant="h5"
            className="grid-item__title"
            p={1}
            textAlign="center"
            fontWeight="bold"
          >
            Share dataset
          </Typography>
          <EChart
            option={optionEchart4}
          />
        </Box>
      </ResponsiveReactGridLayout>
    </div>
  );
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value
      })
    );
  }
}