
import React from 'react'
import { styled, } from '@mui/material/styles';
import { AppBar, Toolbar, Box } from '@mui/material'
import Logo from "../assets/images/sunfunmediaLogo.png"
import headerLogoText from "../assets/images/header-logo-text.png"

const StyledIToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: "center",
  alignItems: 'center',
  padding: "10px 30px !important",
  minHeight: "48px !important",
  position: "relative",
  // borderBottom: "solid 5px #231f20",
  // borderBottomColor: "rgba(35,31,32,.2)",

  "& .logoContainer": {
    display: "flex",
    alignItems: "center",
    height: "100%",

    "& > img.logo": {
      width: "50px",
      marginRight: "20px",
      [theme.breakpoints.down('sm')]: {
        width: "40px"
      },
    },
    '& > img.logoText': {
      width: '200px',
      opacity: 0.9,
      [theme.breakpoints.down('sm')]: {
        width: "150px",
      },
    }
  }

}));


const Navbar = () => {

  return (
    <AppBar
      position="sticky"
      sx={{
        marginBottom: "20px",
        // borderBottom: "1px solid #231f20",
        // borderBottomColor: "rgba(35,31,32,.2)",
        boxShadow: "0px 14px 17px -23px #111",
      }}
    >
      <StyledIToolbar>
        <Box className="logoContainer">
          <img src={Logo} className="logo" />
          <img src={headerLogoText} className="logoText" />
        </Box>
      </StyledIToolbar>
    </AppBar>
  )
}

export default Navbar
