import React from 'react';
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Paper,
	Stack,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import { MainBox } from '../../utils/themes';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import TravelInfo from './TravelInfo';
import TravelerInfo from './TravelerInfo';
import useTraveler from '../../hooks/useTraveler';

/**
 * Create a customized panel as a container for the tab components, this is done in accordance with the documentation.
 
 */
////////////////Componente////////////////////
function CustomTabPanel(props) {
	const { children, value, index } = props;

	return (
		<div role="tabpanel" hidden={value !== index}>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

////////////////Componente////////////////////
const TravelDetails = ({ travel, closeModal, status }) => {
	const [nRes, setRes] = useState(0);
	const [travelerId, setTravelerId] = useState();
	const { traveler } = useTraveler(travelerId);
	const [value, setValue] = useState(1);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	useEffect(() => {
		if (travel) {
			if (travel.travelerId) {
				setTravelerId(travel.travelerId);
			} else {
				setTravelerId(null);
			}
			setRes(travel.NRes);
		}
	}, [travel]);
	return (
		<Box>
			<Dialog open={status} maxWidth={'md'} fullWidth={true} onClose={closeModal}>
				<DialogTitle>Travel Details</DialogTitle>
				<DialogContent>
					<MainBox>
						<Stack direction={'row'}>
							<Typography>Reservation: </Typography>
							<Typography sx={{ fontWeight: 'bold' }}>{nRes}</Typography>
						</Stack>
						<MainBox>
							<Box sx={{ width: '100%' }}>
								<Box sx={{ borderColor: 'divider', width: '100%' }}>
									<Tabs value={value} onChange={handleChange} centered>
										<Tab label="Travel Info" {...a11yProps(0)} />
										<Tab label="Traveler Info" {...a11yProps(1)} />
									</Tabs>
								</Box>
							</Box>
						</MainBox>
					</MainBox>

					<Paper elevation={2} sx={{ padding: 3, marginTop: 2 }}>
						<React.Fragment>
							<CustomTabPanel value={value} index={0}>
								<TravelInfo travel={travel}></TravelInfo>
							</CustomTabPanel>
							<CustomTabPanel value={value} index={1}>
								<TravelerInfo traveler={traveler}></TravelerInfo>
							</CustomTabPanel>
						</React.Fragment>
					</Paper>
				</DialogContent>
			</Dialog>
		</Box>
	);
};
export default TravelDetails;
