import { useState } from 'react';
import useAuthContext from '../hooks/useAuthContext';
import { auth } from '../firebase/config';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import useProfileContext from './useProfileContext';
import { useResetFilterPageData } from '../Pages/StationList/utils/constants';

export const useLogout = (enqueueSnackbar) => {
	const { dispatch } = useAuthContext();
	const { dispatch: profileDispatch } = useProfileContext();
	const navigate = useNavigate();
	const [error, setError] = useState(null);
	const resetData = useResetFilterPageData();

	const logout = async () => {
		try {
			await signOut(auth);
			resetData();
			dispatch({ type: 'LOGOUT' });
			profileDispatch({ type: 'clearProfile' });
			enqueueSnackbar('Successfully Logout', { variant: 'info' });
			localStorage.removeItem('filters');
			localStorage.removeItem('selectRows');
			localStorage.removeItem('aqhCalculator');
			navigate('/');
		} catch (error) {
			setError(error);
			setTimeout(() => {
				setError(null);
			}, 6000);
		}
	};
	return { logout, error };
};
