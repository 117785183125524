import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';

const NotFound = () => {
	return (
		<>
			<Navbar />
			<div style={{ textAlign: 'center', paddingTop: '30px' }}>
				<h1>The page your are looking for is not found...</h1>
				<Link to="/">
					<p>HOME</p>
				</Link>
			</div>
		</>
	);
};

export default NotFound;
