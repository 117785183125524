import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { useTheme, ThemeProvider, Typography, Checkbox } from '@mui/material';
import { MainBox, getTheme } from '../../utils/themes';
import useGetOrderCards from '../../hooks/useGetOrderCards';
import { optionsTableRewards } from './utils';
import moment from 'moment';
import { createUniqueId, handleCheckboxChange } from './utils';

const CurrentRewardCards = () => {
	const currentMonth = moment().month();
	const currentYear = moment().year();
	const orderCards = useGetOrderCards(currentMonth);
	const theme = useTheme();
	const [checkedState, setCheckedState] = useState({});

	const columns = [
		{
			name: 'dealNumber',
			label: 'Deal #',
		},
		{
			name: 'date',
			label: 'Date',
		},
		{
			name: 'contractName',
			label: 'Contract Name',
		},
		{
			name: 'balance',
			label: 'Balance',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => {
					return value ? `$ ${value}` : '$ 0';
				},
			},
		},
		{
			name: 'quantity',
			label: 'Number Of Cards',
		},
		{
			name: 'value',
			label: 'Denomination',
		},
		{
			name: 'manually',
			label: 'Manually',
			options: {
				customBodyRender: (value, tableMeta) => {
					// Get the record and create a unique ID
					const record = orderCards[tableMeta.rowIndex];
					const uniqueId = createUniqueId(record, tableMeta.rowIndex);

					return (
						<Checkbox
							checked={checkedState[uniqueId] || value === true}
							onChange={(e) =>
								handleCheckboxChange(
									record,
									e.target.checked,
									tableMeta.rowIndex,
									currentMonth,
									currentYear,
									setCheckedState
								)
							}
						/>
					);
				},
			},
		},
	];

	return (
		<>
			<Typography variant="h6">Orders for {moment().format('MMMM')}</Typography>
			<MainBox
				sx={{
					boxShadow: (theme) => theme.shadows[4],
					borderRadius: 2,
					paddingX: 1.2,
					marginX: -1,
				}}
			>
				<ThemeProvider theme={getTheme(theme)}>
					<MUIDataTable
						data={orderCards || []}
						columns={columns}
						options={{
							...optionsTableRewards,
							selectToolbarPlacement: 'replace',
							responsive: 'standard',
							selectableRows: 'none',
						}}
					></MUIDataTable>
				</ThemeProvider>
			</MainBox>
		</>
	);
};
export default CurrentRewardCards;
