/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	Button,
	TextField,
	Grid,
	Box,
	CircularProgress,
	Typography,
	FormControlLabel,
	Checkbox,
	Tooltip,
	Autocomplete,
	Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import useAuthContext from '../../hooks/useAuthContext';
import {
	collection,
	doc,
	updateDoc,
	where,
	query,
	getDocs,
	getDoc,
} from 'firebase/firestore';
import { db, storage } from '../../firebase/config';
import useProfileContext from '../../hooks/useProfileContext';
import ProgressWithValue from '../../components/ProgressWithValue';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { Camera } from '@mui/icons-material';
import { handleUserTypeValue } from '../Admin/utils/constants';
import { initialEmployeeState, initialState } from './utils/constants';

const GridContainer = styled(Grid)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	margin: '0px auto',
}));

const ProfileBox = styled(Box)(({ theme }) => ({
	border: '1px solid rgb(128 128 128 / 33%)',
	background: '#F8F8F8',
	borderRadius: '8px',
	boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
	padding: '0 2rem',
	'&>*': {
		marginTop: '1.2rem !important',
		marginBottom: '1.2rem !important',
	},
	'& > .avatar': {
		textTransform: 'uppercase',
		border: '3px solid rgb(176 13 36 / 4%)',
		fontSize: '2rem',
		width: 120,
		height: 120,
		marginLeft: 'auto',
		marginRight: 'auto',
		position: 'relative',
		borderRadius: '50%',
		overflow: 'hidden',
		transition: 'all 0.3s ease',
		background: 'rgb(0 0 0 / 12%)',
		zIndex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			width: 100,
			height: 100,
		},
		'& > img': {
			position: 'absolute',
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			objectFit: 'cover',
		},

		'& > .upload': {
			zIndex: -1,
			position: 'absolute',
			borderRadius: '50%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'rgb(0 0 0 / 30%)',
			overflow: 'hidden',
			width: '99%',
			height: '99%',
			transition: 'all 0.4s ease',
			opacity: 0,
		},
		'&:hover': {
			'& > .upload': {
				zIndex: 2,
				opacity: 1,
			},
		},
	},
}));

const Profile = () => {
	const { state: locState } = useLocation();
	const navigate = useNavigate();
	const { authIsReady, user } = useAuthContext();
	const { profile } = useProfileContext();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const [shouldUpdate, setShouldUpdate] = useState(true);
	const [role, setRole] = useState('');
	const uploadInputRef = useRef(null);
	const employeeRef = collection(db, 'employees');
	const [state, setState] = useState(initialState);
	const [employeeState, setEmployeeState] = useState(initialEmployeeState);

	//get employee and user data
	useEffect(() => {
		// get role from user
		const getUserData = async () => {
			try {
				const docRef = doc(db, 'users', user.uid);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					const userRole = handleUserTypeValue(docSnap.data().role);
					setRole(userRole);
				}
			} catch (error) {
				console.log(error);
			}
		};
		// get employee data
		const getEmployeeData = async () => {
			try {
				const q = query(employeeRef, where('userId', '==', user.uid));
				const querySnapshot = await getDocs(q);
				querySnapshot.forEach((doc) => {
					setEmployeeState({ ...doc.data(), id: doc.id });
				});
			} catch (error) {
				console.log(error);
			}
		};

		if (user.uid) {
			Promise.all([getUserData(), getEmployeeData()]);
		}
	}, [profile]);

	// check if user has updated profile
	useEffect(() => {
		if (authIsReady && locState?.isEmpty && shouldUpdate) {
			enqueueSnackbar('Please update your profile!', { variant: 'warning' });
			setShouldUpdate(false);
		}
		if (profile) {
			setState({ ...state, ...profile, shouldNickName: !!profile?.nickName });
		}
	}, [locState, authIsReady, profile, shouldUpdate]);

	const handleChange = (e) => {
		const { name, value, checked, type } = e.target;
		setState({ ...state, [name]: type === 'checkbox' ? checked : value });
	};

	const handleEmployeeChange = (e) => {
		const { name, value } = e.target;
		setEmployeeState({ ...employeeState, [name]: value });
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			let { shouldNickName, ...rest } = state;
			const docRef = doc(db, 'users', user?.uid);
			if (shouldNickName && !state.nickName)
				throw Error('Nick name selected but empty field');
			await updateDoc(docRef, {
				...rest,
				nickName: shouldNickName ? state.nickName : '',
			});
			// update employee data
			await updateDoc(doc(db, 'employees', employeeState.id), employeeState);
			enqueueSnackbar('Profile updated!', { variant: 'success' });
			setLoading(false);
		} catch (error) {
			enqueueSnackbar(error.message, { variant: 'error' });
			console.log(error);
			setLoading(false);
		}
	};

	const handleImage = (e) => {
		setIsUploading(true);
		const fileObj = e.target.files[0];
		if (!fileObj.type.includes('image'))
			return enqueueSnackbar('Plz select images only', { variant: 'error' });
		uploadFile(fileObj);
	};

	const uploadFile = (file) => {
		if (!file) return;
		const storageRef = ref(storage, `profile_pics/${user.uid}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			'state_changed',
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgress(progress);
			},
			(error) => {
				setIsUploading(false);
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setIsUploading(false);
					enqueueSnackbar('Pic Uploaded', { variant: 'success' });
					setState({ ...state, photoURL: downloadURL });
				});
			}
		);
	};

	return (
		<Box
			sx={{
				margin: '4%',
			}}
		>
			<GridContainer container spacing={2}>
				<Grid item xs={12} sm={7} md={7} lg={5}>
					<ProfileBox>
						<Box className="avatar">
							{state.photoURL ? (
								<img
									src={state.photoURL || ''}
									style={{
										filter:
											(progress > 0 && progress < 100) || isUploading
												? 'brightness(0.5)'
												: 'brightness(1)',
									}}
								/>
							) : (
								<Typography fontSize={'1.5rem'} color="white">
									{state.firstName.charAt(0) + ' ' + state.lastName.charAt(0)}
								</Typography>
							)}
							{!(progress > 0 && progress < 100) ? (
								<Box className="upload">
									<Button
										color="primary"
										variant="contained"
										size="small"
										sx={{
											fontSize: '12px',
											textTransform: 'lowercase',
											display: 'flex',
											alignItems: 'center',
										}}
										onClick={() =>
											uploadInputRef.current && uploadInputRef.current.click()
										}
									>
										<Camera fontSize="14px" sx={{ mr: 0.5 }} /> upload
										<input
											ref={uploadInputRef}
											type="file"
											accept="image/*"
											style={{ display: 'none' }}
											onChange={handleImage}
										/>
									</Button>
								</Box>
							) : (
								<ProgressWithValue value={progress} />
							)}
						</Box>
						<Grid item container xs={12} spacing={2}>
							<Grid item xs={6}>
								<TextField
									label="First Name"
									variant="outlined"
									size="medium"
									name="firstName"
									onChange={handleChange}
									value={state.firstName}
									fullWidth
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="Last Name"
									variant="outlined"
									size="medium"
									name="lastName"
									onChange={handleChange}
									value={state.lastName}
									fullWidth
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Box
								sx={{
									display: 'flex',
									gap: '10px',
									flexDirection: 'row',
									alignItems: 'center',
									minHeight: '56px',
								}}
							>
								<FormControlLabel
									control={
										<Checkbox
											name="shouldNickName"
											onChange={handleChange}
											checked={state.shouldNickName}
										/>
									}
									label="Nick Name"
								/>
								{state.shouldNickName && (
									<TextField
										label="Nick Name"
										variant="outlined"
										size="medium"
										name="nickName"
										onChange={handleChange}
										value={state.nickName}
										fullWidth
										sx={{ flex: 1 }}
									/>
								)}
							</Box>
						</Grid>
						<Grid item container xs={12} spacing={2}>
							<Grid item xs={6}>
								<TextField
									label="Position"
									variant="outlined"
									size="medium"
									name="position"
									onChange={handleEmployeeChange}
									value={employeeState?.position || ''}
									fullWidth
								/>
							</Grid>
							<Grid item xs={6}>
								<Tooltip title="Example: 18667705560" placement="top" arrow>
									<TextField
										label="Phone Number"
										variant="outlined"
										size="medium"
										name="employeePhone"
										onChange={handleEmployeeChange}
										value={employeeState?.employeePhone || ''}
										fullWidth
									/>
								</Tooltip>
							</Grid>
						</Grid>
						<Grid item container xs={12} spacing={2}>
							<Grid item xs={12}>
								<Autocomplete
									multiple
									id="role"
									name="role"
									options={[]} // only show the role of the user
									getOptionLabel={(option) => option.label}
									value={role ? role : []}
									disabled
									style={{ width: '100%' }}
									renderInput={(params) => (
										<TextField {...params} label="Role" variant="outlined" width="100%" />
									)}
									renderTags={(value, getTagProps) =>
										value.map((option, index) => (
											<Chip key={index} label={option.label} {...getTagProps({ index })} />
										))
									}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Button
									variant="contained"
									sx={{ minWidth: '90px', fontSize: '15px' }}
									onClick={handleSubmit}
									disabled={loading}
								>
									{loading && (
										<CircularProgress
											style={{ width: '20px', height: '20px', marginRight: '10px' }}
										/>
									)}
									Save
								</Button>
							</Box>
						</Grid>
					</ProfileBox>
				</Grid>
			</GridContainer>
		</Box>
	);
};

export default Profile;
