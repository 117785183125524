export const initialEmployeeState = {
	position: '',
	employeePhone: '',
};

export const initialState = {
	firstName: '',
	lastName: '',
	photoURL: '',
	nickName: '',
	shouldNickName: false,
};
