import React, { useEffect, useState } from 'react';
import { MainBox, getTheme } from '../../utils/themes';
import { Box, Stack, ThemeProvider, Typography } from '@mui/material';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { useTheme } from '@emotion/react';
const TravelInfo = ({ travel }) => {
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const theme = useTheme();
	useEffect(() => {
		if (travel) {
			if (travel.startDate)
				setStartDate(moment(travel.startDate).format('DD/MM/YYYY'));
			else setStartDate('');

			if (travel.endDate) setEndDate(moment(travel.endDate).format('DD/MM/YYYY'));
			else setStartDate('');
		}
	}, [travel]);

	const columns = [
		{
			name: 'NRes',
			label: 'Res #',
		},
		{
			name: 'nameCredit',
			label: 'Trip',
		},
		{
			name: 'credits',
			label: 'Credits',
		},
	];
	const options = {
		filterType: 'dropdown',
		print: 'false',
		pagination: true,
		selectableRows: 'none',
	};
	return (
		<MainBox>
			<Stack direction={'row'} justifyContent={'space-around'}>
				<Box>
					<Stack direction={'row'}>
						<Typography sx={{ fontWeight: 'bold' }}>Start Date</Typography>

						<Typography>{startDate}</Typography>
					</Stack>
				</Box>
				<Box>
					<Stack direction={'row'}>
						<Typography sx={{ fontWeight: 'bold' }}>End Date</Typography>

						<Typography>{endDate}</Typography>
					</Stack>
				</Box>
			</Stack>
			<Stack>
				<Box
					style={{
						padding: 8,
						border: '1px solid',
						borderColor: 'gray',
						marginTop: 5,
						borderRadius: 10,
					}}
				>
					<Box>
						<ThemeProvider theme={getTheme(theme)}>
							<MUIDataTable
								title={<strong>Main Travel</strong>}
								data={[travel] || []}
								columns={columns}
								options={{
									...options,
									rowsPerPage: 25,
									rowsPerPageOptions: [25, 50, 100],
									selectToolbarPlacement: 'replace',
									selectableRowsHeader: true,
									responsive: 'standard',
								}}
							></MUIDataTable>
						</ThemeProvider>
					</Box>
				</Box>

				{travel.secondaryCertificates &&
					travel.secondaryCertificates.length > 0 && (
						<Box
							style={{
								padding: 8,
								border: '1px solid',
								borderColor: 'gray',
								marginTop: 5,
								borderRadius: 10,
							}}
						>
							<Box>
								<ThemeProvider theme={getTheme(theme)}>
									<MUIDataTable
										title={<strong>Secondary Travel</strong>}
										data={travel.secondaryCertificates || []}
										columns={columns}
										options={{
											...options,
											rowsPerPage: 25,
											rowsPerPageOptions: [25, 50, 100],
											selectToolbarPlacement: 'replace',
											selectableRowsHeader: true,
											responsive: 'standard',
										}}
									></MUIDataTable>
								</ThemeProvider>
							</Box>
						</Box>
					)}
			</Stack>
		</MainBox>
	);
};
export default TravelInfo;
