import { Container } from '@mui/material'
import React from 'react'
import Navbar from '../components/Navbar'


const AuthLayout = ({ children }) => {
    return (
        <div>
            <Navbar />
            <Container>
                {children}
            </Container>
        </div>
    )
}

export default AuthLayout