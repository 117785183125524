export const pages = (lastPage) => {
	switch (lastPage) {
		case '/dashboard':
			return 'Dashboard';
		case '/dashboard/prospector':
			return 'Prospector';
		case '/dashboard/AQH':
			return 'AQH';
		case '/dashboard/cogs':
			return 'COGS';
		case '/dashboard/toolkit':
			return 'Toolkit';
		case '/dashboard/inventory':
			return 'Inventory';
		case '/dashboard/documents':
			return 'Documents';
		case '/dashboard/admindata':
			return 'Admin Data';
		case '/dashboard/messages':
			return 'Messages';
		case '/dashboard/settings':
			return 'Settings';
		case '/dashboard/admin':
			return 'Employee Data/General Data/Edit Vacation Credits';
		case '/dashboard/aqhcalculator':
			return 'AQH Calculator';
		case '/dashboard/profile':
			return 'Profile';
		case '/dashboard/employee-data':
			return 'Employee Data';
		case '/dashboard/notification-center':
			return 'Notification Center/Messages';
		default:
			return 'Report Bug';
	}
};

export const browserDetect = (browserInfo) => {
	let browserName;

	if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
		browserName = 'Opera';
	} else if (browserInfo.includes('Edg')) {
		browserName = 'Edge';
	} else if (browserInfo.includes('Chrome')) {
		browserName = 'Chrome';
	} else if (browserInfo.includes('Safari')) {
		browserName = 'Safari';
	} else if (browserInfo.includes('Firefox')) {
		browserName = 'Firefox';
	} else {
		browserName = 'Unknown';
	}
	return browserName;
};

// generate html for email
export const generateBugMessage = (data) => {
	const html = `<p><strong>Issue ID:</strong> ${data.issueId}</p>
  <p><strong>Name:</strong> ${data.name}</p>
  <p><strong>Browser:</strong> ${data.browser}</p>
  <p><strong>Page:</strong> ${data.page}</p>
  <p><strong>Date:</strong> ${data.dateAndHour}</p>
  <p><strong>Bug:</strong> ${data.bug}</p>`;
	return html;
};
