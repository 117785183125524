import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {
	IconButton,
	Button,
	TableRow,
	TableCell,
	Box,
	Paper,
	TableContainer,
	Table,
	TableBody,
	Stack,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { columnsArrayAirports } from '../utils/constants';
import { db } from '../../../firebase/config';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { getProposalOptions, validateOptionErrors } from '../utils/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function RewardsModal({
	proposalId,
	openPublishQuoteModal,
	setOpenAirportsModal,
	openAirportsModal,
	openOverrideModal,
	setPublishProcess,
	dealTerms,
	setOpenRolloverModal,
}) {
	const { enqueueSnackbar } = useSnackbar();
	const [airportRows, setAirportRows] = useState([{}]);
	const [proposalOptions, setProposalOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const proposalRef = collection(db, 'savedProposals');

	// Add a new row from each table
	const handleAirportAddRow = () => {
		const item = {};
		setAirportRows([...airportRows, item]);
	};

	// Remove a row from each table
	const handleRemoveSpecificRowAirport = (idx) => {
		if (idx !== 0) {
			const tempRows = [...airportRows];
			tempRows.splice(idx, 1);
			setAirportRows(tempRows);
		}
	};

	// update state for each table
	const updateStateAirports = (e, name, idx, column) => {
		let prope = e.target.attributes.column.value;
		let index = e.target.attributes.index.value;
		let fieldValue = e.target.value;

		const tempRows = [...airportRows];
		const tempObj = airportRows[index];
		tempObj[prope] = fieldValue;

		// return object to airportRows clone
		tempRows[index] = tempObj;
		setAirportRows(tempRows);
	};

	// close modal
	const handleCloseModal = (event, reason) => {
		// if click outside the modal or press esc key, don't close the modal
		if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
			return;
		}
		setOpenAirportsModal(false);
		setAirportRows([{}]);
	};
	// get proposal options
	useEffect(() => {
		const getOptions = async () => {
			const options = await getProposalOptions(proposalId);
			if (options) {
				setProposalOptions(options);
			}
		};
		if (proposalId) {
			getOptions();
		}
	}, [proposalId]);

	const saveAirports = async () => {
		//convert array in list with commas
		const airportsRep = airportRows.map((row) => row.airportName).join(', ');
		setLoading(true);
		try {
			// validate if vacations credits and proposalId exists
			if (proposalId) {
				await updateDoc(doc(proposalRef, proposalId), {
					airports: airportRows.length > 0 ? airportsRep : null,
				});
				enqueueSnackbar('Airports saved', { variant: 'success' });
				if (proposalOptions.length > 0) {
					// validate if there are errors in the options
					const errors = await validateOptionErrors(proposalOptions);
					// If deal terms is not empty, show rollover modal otherwise if there are errors, show manager override modal
					if (dealTerms !== null) {
						setLoading(false);
						setOpenAirportsModal(false);
						setOpenRolloverModal(true);
					} else {
						if (errors.includes(true)) {
							setLoading(false);
							setOpenAirportsModal(false);
							openOverrideModal(true);
						} else {
							setLoading(false);
							setOpenAirportsModal(false);
							openPublishQuoteModal(true);
						}
					}
				}
			} else {
				setLoading(false);
				setPublishProcess(false);
				enqueueSnackbar('Airports not saved', { variant: 'error' });
				setOpenAirportsModal(false);
			}
		} catch (error) {
			console.log(error.message);
			setLoading(false);
			setOpenAirportsModal(false);
			setPublishProcess(false);
			enqueueSnackbar('Airports not saved', { variant: 'error' });
		}
	};

	return (
		<Dialog
			open={openAirportsModal}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseModal}
			aria-describedby="alert-dialog-slide-description"
			sx={{
				'& .MuiDialog-paper': {
					minWidth: '600px',
					minHeight: '200px',
				},
			}}
		>
			<DialogTitle sx={{ pb: 0 }}>Add Airports</DialogTitle>
			<DialogContent sx={{ alignItems: 'center' }}>
				{
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						sx={{ width: '100%' }}
					>
						<Paper sx={{ width: '100%', mb: 2 }}>
							<TableContainer sx={{ maxWidth: 600, maxHeight: 200 }}>
								<>
									<Table stickyHeader aria-label="sticky table">
										<TableBody>
											{airportRows.map((item, idx) => (
												<TableRow key={idx}>
													{columnsArrayAirports.map((column, index) => (
														<TableCell
															key={index}
															sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}
														>
															<Stack direction="row" spacing={1}>
																<input
																	style={{
																		padding: 10,
																		textAlign: 'center',
																		width: 300,
																		fontSize: 14,
																	}}
																	column={column}
																	value={airportRows[idx][column] || ''}
																	index={idx}
																	onChange={(e) => updateStateAirports(e)}
																/>
															</Stack>
														</TableCell>
													))}

													<TableCell>
														<IconButton
															color="error"
															onClick={() => handleRemoveSpecificRowAirport(idx)}
														>
															<RemoveCircleIcon />
														</IconButton>
														<IconButton color="success" onClick={handleAirportAddRow}>
															<AddCircleIcon />
														</IconButton>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</>
							</TableContainer>
						</Paper>
						<Button
							size="medium"
							variant="contained"
							color="success"
							onClick={saveAirports}
							disabled={airportRows.length === 0 || loading}
						>
							{loading ? 'Loading...' : 'Submit'}
						</Button>
					</Box>
				}
			</DialogContent>
		</Dialog>
	);
}
