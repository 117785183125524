/* eslint-disable no-unused-vars */
/* eslint-disable  react-hooks/exhaustive-deps*/
import { CheckCircleOutline, CloudUpload, Delete } from '@mui/icons-material';
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	ListItem,
	ListItemText,
	Stack,
	TextField,
	Typography,
	List,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MainBox, UploadFileTheme } from '../../Cogs/utils/themes';
import useUploadMultipleFile from '../../Cogs/hooks/useUploadMultipleFile';
import useFiles from '../../Cogs/hooks/useFiles';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { v4 } from 'uuid';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

const ReplyMessage = ({
	replyMessageModal,
	setReplyMessageModal,
	currentMessage,
	setCurrentMessage,
}) => {
	const [error, setError] = useState(false);
	const [sending, setSending] = useState(false);
	const { progress, uploadFiles, nameFiles, reset } =
		useUploadMultipleFile('messages');
	const { add, files, remove, update, clear } = useFiles();
	const [message, setMessage] = useState('');
	const [subject, setSubject] = useState('');

	//UseEffect which is responsible for updating the status of a list of files to show that they have been successfully uploaded.
	useEffect(() => {
		if (progress) {
			const fileId = progress.metadata.id;
			const porcentComplete = progress.progress;
			if (porcentComplete === 100) {
				update(fileId, { metadata: { ...progress.metadata, status: 1 } });
			}
		}
	}, [progress, update]);

	const closeModal = (event, reason) => {
		// if click outside the modal or press esc key, don't close the modal
		if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
			setCurrentMessage(null);
			setReplyMessageModal(false);
		}
	};
	/**
	 * useEffect created to send a message once the files have been fully uploaded.
	 * If there are no files to be uploaded, the message is sent immediately.
	 */
	useEffect(() => {
		const addMessage = async (messageData) => {
			await addDoc(collection(db, 'reviewMessages'), messageData);
			setSending(false);
			setMessage('');
			clear();
			reset();
		};
		if (sending) {
			const filterDocs = files.filter((item) => item.metadata.status !== 1);
			const senderName = 'Ashley';
			if (filterDocs.length <= 0) {
				const messageData = {
					message: message,
					subject: subject,
					seen: false,
					messageDate: moment().format(),
					receiver_id: currentMessage?.receiver_id,
					sender_id: currentMessage?.sender_id,
					fromName: senderName,
					travelerEmail: currentMessage?.travelerEmail,
					travelerId: currentMessage?.travelerId,
					to:
						currentMessage?.fromName === senderName
							? currentMessage?.to
							: currentMessage?.fromName,
					platform: 'cogs',
					module: 'traveler',
				};
				if (nameFiles.length > 0) {
					messageData.docs = nameFiles;
				}
				addMessage(messageData);
			}
		}
	}, [sending, files, nameFiles]);

	const replyMessage = async () => {
		if (message.trim().length <= 0 || subject.trim().length <= 0) {
			setError(true);
			return;
		}
		setSending(true);
		if (files.length > 0) {
			const filesUpload = [...files];
			files.forEach((item) => {
				const name = `${v4()}_${item.file.name}`;
				update(item.metadata.id, {
					metadata: { ...item.metadata, status: 2, name },
				});
			});
			uploadFiles(filesUpload);
		}
	};
	return (
		<Dialog
			open={replyMessageModal}
			disableAutoFocus
			onClose={closeModal}
			PaperProps={{
				style: { overflow: 'hidden' },
			}}
			// maxWidth={'md'}
			fullWidth={true}
		>
			<DialogTitle style={{ textAlign: 'center', paddingBottom: 20 }}>
				Reply Message
			</DialogTitle>
			<DialogContent>
				<>
					<Typography> Date: {moment().format('DD/MM/YYYY')}</Typography>
					<Typography sx={{ paddingBottom: 2 }}>
						{' '}
						Time: {moment().format('HH:MM')}
					</Typography>
					<Stack direction="column" spacing={2}>
						<Typography> Subject</Typography>
						<TextField
							id="subject"
							label="Subject"
							error={error}
							rows={5}
							fullWidth={true}
							value={subject}
							onChange={(e) => {
								setSubject(e.target.value);
								setError(false);
							}}
							helperText={error ? 'Please fill in this field' : ''}
						/>
					</Stack>
					<Stack sx={{ paddingTop: 2 }} direction="column" spacing={2}>
						<Typography> Message</Typography>
						<TextField
							id="message"
							label="Message"
							multiline
							error={error}
							rows={5}
							fullWidth={true}
							value={message}
							onChange={(e) => {
								setMessage(e.target.value);
								setError(false);
							}}
							helperText={error ? 'Please fill in this field' : ''}
						/>
					</Stack>

					<Box sx={{ paddingTop: 2 }}>
						<Grid container>
							<Grid item xs={9}>
								<Box>
									<Typography>Files(s):</Typography>
									<List sx={{ width: '90%' }}>
										{files.length > 0 &&
											files.map((file, index) => {
												return (
													<ListItem
														key={index}
														secondaryAction={
															<>
																{file.metadata.status === 3 && (
																	<IconButton
																		onClick={() => remove(file.metadata.id)}
																		edge="end"
																		aria-label="delete"
																	>
																		<Delete></Delete>
																	</IconButton>
																)}
																{file.metadata.status === 2 && (
																	<CircularProgress size={20}></CircularProgress>
																)}
																{file.metadata.status === 1 && (
																	<CheckCircleOutline></CheckCircleOutline>
																)}
															</>
														}
													>
														<ListItemText
															style={{ textOverflow: 'ellipsis' }}
															secondary={
																<span
																	style={{
																		display: 'block',
																		maxWidth: '100%',
																		whiteSpace: 'nowrap',
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																	}}
																>
																	{file.file.name}
																</span>
															}
														></ListItemText>
													</ListItem>
												);
											})}
									</List>
								</Box>
							</Grid>
							<Grid item xs={2} alignContent={'start'} justifyContent={'end'}>
								<div style={{ paddingTop: 10 }}>
									<Button
										component="label"
										disabled={sending}
										role={undefined}
										size="small"
										variant="contained"
										tabIndex={-1}
										startIcon={<CloudUpload fontSize="small" />}
									>
										Add atachment
										<UploadFileTheme
											type="file"
											onChange={(event) => {
												if (!event?.target?.files) return;
												if (event.target.files.length <= 0) return;
												add(event.target.files[0], { status: 3 });
											}}
										></UploadFileTheme>
									</Button>
								</div>
							</Grid>
						</Grid>
					</Box>

					<MainBox>
						<Grid item xs={2}>
							<Button onClick={replyMessage} disabled={sending} variant="contained">
								Send
							</Button>
						</Grid>
					</MainBox>
				</>
			</DialogContent>
		</Dialog>
	);
};
export default ReplyMessage;
