import React from 'react';
import Logo from '../../../assets/images/sunfunmediaLogo.png';
import { Stack, Typography } from '@mui/material';

export default function ClientConfirmationPage() {
	return (
		<div>
			<Stack spacing={2} direction="column" alignItems="center">
				<img src={Logo} alt="Logo" />

				<Typography>Your agreement has been received</Typography>
				<Typography>
					You'll receive a copy of the fully executed contract in your email.
				</Typography>
				<Typography>Click here to download your copy of the agreement.</Typography>
			</Stack>
		</div>
	);
}
