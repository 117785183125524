/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { db } from '../../../firebase/config';
import {
	collection,
	onSnapshot,
	query,
	where,
	limit,
	getDocs,
} from 'firebase/firestore';
import {
	Checkbox,
	CircularProgress,
	DialogActions,
	FormControlLabel,
	IconButton,
	Typography,
	InputLabel,
	Select,
	MenuItem,
	FormControl,
} from '@mui/material';
import { Box } from '@mui/system';
import {
	getUniqueArr,
	numberWithCommas,
	splitArrayIntoChunks,
	trendLineTitles,
	colorGraphLine,
	stationRef,
} from '../../../utils';
import { AddLink as AddLinkIcon, CloseOutlined } from '@mui/icons-material';
import { useEffect } from 'react';
import { firebaseKeys } from '../../StationList/utils/constants';
import { useState } from 'react';
import {
	aqhTotalByAddress,
	formatSeason,
	formatSeasonName,
} from '../utils/utils';
import EChart from '../../Dashboard/components/EChart';
import { style } from '../../Dashboard/constants.js';
import {
	firebasekeysTitles,
	ownerProps,
	stationProps,
	homeMarketProps,
	NDMANameProps,
	trendLineProps,
} from '../utils/constants';
import {
	transformSeason,
	seasonsGraphValuesX,
} from '../../StationList/utils/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const isEmptyObject = (o) => {
	return (
		typeof o === 'object' &&
		Object.keys(o).some(function (x) {
			return (
				o[x] === '' || o[x] === null || (Array.isArray(o[x]) && o[x].length === 0)
			); // or just "return o[x];" for falsy values
		})
	);
};

const trendLineOptions = (values, allSeasons) => {
	// handle season names
	const seasonNames = seasonsGraphValuesX(allSeasons);
	const seasonNamesGraph = allSeasons.map((season) => {
		return formatSeasonName(season);
	});

	// get the values for the graph
	const seriesData = seasonNamesGraph.map((season) => {
		return values[`${season}GraphValue`] || 0;
	});

	return {
		tooltip: {
			trigger: 'axis',
		},
		xAxis: {
			type: 'category',
			boundaryGap: false,
			data: seasonNames,
			height: '400px',
		},
		yAxis: {
			type: 'value',
		},
		series: [
			{
				data: seriesData,
				type: 'line',
				lineStyle: {
					width: 5,
				},
				smooth: true,
				animation: false,
			},
		],
		color: colorGraphLine,
	};
};

export default function AlertDialogSlide({
	openModal,
	onClose,
	queryDetails,
	isStation,
	isOwner,
	handleSelectAll,
	selectAllDetails,
	salesList,
	stationData,
	setStationData,
}) {
	let { name, graphData, title, value, url, detailsObj } = queryDetails;
	const initialProperties = {
		Ownername: ownerProps,
		Station: stationProps,
		HomeMarket: homeMarketProps,
		NDMAName: NDMANameProps,
		TrendLine: trendLineProps,
	};
	const [properties, setProperties] = useState(initialProperties);
	const [scrollHere, setScroll] = React.useState(0);
	const [graphValues, setGraphValues] = React.useState({});
	const [seasonData, setSeasonData] = useState({});
	const seasonsRef = collection(db, 'seasons');
	const [currentRef, setCurrentRef] = useState(null);
	const [allSeasons, setAllSeasons] = useState([]);

	// get a current and past seasons collection
	useEffect(() => {
		const getSeasons = async () => {
			try {
				const q = query(seasonsRef, limit(1));
				const seasonsDocs = await getDocs(q);
				let seasonsResults = [];
				seasonsDocs.forEach((doc) => {
					seasonsResults.push({ ...doc.data(), seasonId: doc.id });
				});
				if (seasonsResults.length === 0) {
					return;
				}
				let currentSeason = seasonsResults[0]?.currentSeason;
				let allSeasons = seasonsResults[0]?.allSeasons;
				setCurrentRef(currentSeason);
				setAllSeasons(allSeasons);
			} catch (error) {
				console.log(error);
			}
		};
		getSeasons();
	}, []);

	React.useEffect(() => {
		const getData = () => {
			if (currentRef) {
				const collRef = collection(db, stationRef);
				const refSpr = collection(db, currentRef);
				const q =
					name && title && value
						? query(collRef, where(name, '==', value))
						: query(collRef);
				const unsub = onSnapshot(q, (snapshot) => {
					let stations = [];
					let owners = [];
					let markets = [];
					let totalAqh = [];
					snapshot.docs.forEach((doc) => {
						!isOwner && owners.push(doc.data().Ownername);
						stations.push(doc.data().Station);
						isOwner && markets.push(doc.data().HomeMarket);
					});
					owners = [...new Set(owners)];
					stations = [...new Set(stations)];
					markets = [...new Set(markets)];

					let chunkArray = splitArrayIntoChunks(10, stations);
					chunkArray.forEach((batch, idx) => {
						const q = query(refSpr, where('Station', 'in', batch));
						const unsubSnap = onSnapshot(q, (snapshotSpr) => {
							let results = [];
							snapshotSpr.docs.forEach((docSpr) => {
								results.push({
									station: docSpr.data().Station,
									aqh: docSpr.data()['MF 6a-7p'],
								});
							});
							totalAqh = [...totalAqh, ...results];

							if (chunkArray.length === idx + 1) {
								let uniqueTotal = getUniqueArr(totalAqh, ['station']).map((s) => s.aqh);
								let resultObj = {
									stations: stations.length,
									totalAqh:
										uniqueTotal.length > 0
											? numberWithCommas(uniqueTotal.reduce((a, b) => a + b, 0))
											: 0,
									...(!isOwner && {
										owners: owners.length,
									}),
									...(isOwner && {
										markets: markets.length,
										phone: detailsObj.Phone,
									}),
								};
								let obj = { ...properties[name] };
								Object.keys(properties[name]).forEach((key) => {
									if (resultObj.hasOwnProperty(key)) {
										Object.assign(obj, { [key]: resultObj[key] });
									}
								});
								let copyProperties = { ...properties, [name]: obj };

								setProperties(copyProperties);
							}
						});
						return unsubSnap;
					});
				});
				return unsub;
			}
		};

		openModal && name && !isStation && getData();
	}, [queryDetails, isStation, isOwner, currentRef]);

	const getClusterAqh = (address) => {
		let filterSalesListByAddress = salesList.filter((s) => s.Maddr1 === address);
		let getGroupedAqh = aqhTotalByAddress(filterSalesListByAddress);
		return getGroupedAqh[address] || 0;
	};

	const getDayPartsData = (book) => {
		let MF1_2p = book?.['MF 1p-2p'] || 0;
		let MF2_3p = book?.['MF 2p-3p'] || 0;
		let MF3_4p = book?.['MF 3p-4p'] || 0;
		let MF3_7p = book?.['MF 3p-7p'] || 0;
		let MF4_5p = book?.['MF 4p-5p'] || 0;
		let MF5_6a = book?.['MF 5a-6a'] || 0;
		let MF5_6p = book?.['MF 5p-6p'] || 0;
		let MF5a_8p = book?.['MF 5a-8p'] || 0;
		let MF5a_9p = book?.['MF 5a-9p'] || 0;
		let MF5a_10p = book?.['MF 5a-10p'] || 0;
		let MF5a_11p = book?.['MF 5a-11p'] || 0;
		let MF5a_12m = book?.['MF 5a-12m'] || 0;
		let MF6_7a = book?.['MF 6a-7a'] || 0;
		let MF6_7p = book?.['MF 6p-7p'] || 0;
		let MF6_10a = book?.['MF 6a-10a'] || 0;
		let MF6a_7p = book?.['MF 6a-7p'] || 0;
		let MF7_8a = book?.['MF 7a-8a'] || 0;
		let MF7_8p = book?.['MF 7p-8p'] || 0;
		let MF8_9a = book?.['MF 8a-9a'] || 0;
		let MF9_10a = book?.['MF 9a-10a'] || 0;
		let MF10_11a = book?.['MF 10a-11a'] || 0;
		let MF10a_3p = book?.['MF 10a-3p'] || 0;
		let MF11a_12n = book?.['MF 11a-12n'] || 0;
		let MF12n_1p = book?.['MF 12n-1p'] || 0;
		let MSu6a_12m = book?.['MSu 6a-12m'] || 0;
		let MSu12m_6a = book?.['MSu 12m-6a'] || 0;
		let SS6a_12m = book?.['SS 6a-12m'] || 0;
		let SS6a_12n = book?.['SS 6a-12n'] || 0;
		let Sat3_7p = book?.['Sat 3p-7p'] || 0;
		let Sat6_10a = book?.['Sat 6a-10a'] || 0;
		let Sat10a_3p = book?.['Sat 10a-3p'] || 0;
		let Sun3_7p = book?.['Sun 3p-7p'] || 0;
		let Sun6_10a = book?.['Sun 6a-10a'] || 0;
		let Sun10a_3p = book?.['Sun 10a-3p'] || 0;

		let trendLineObj = {
			...properties[name],
			MF1_2p,
			MF2_3p,
			MF3_4p,
			MF3_7p,
			MF4_5p,
			MF5_6a,
			MF5_6p,
			MF5a_8p,
			MF5a_9p,
			MF5a_10p,
			MF5a_11p,
			MF5a_12m,
			MF6_7a,
			MF6_7p,
			MF6_10a,
			MF6a_7p,
			MF7_8a,
			MF7_8p,
			MF8_9a,
			MF9_10a,
			MF10_11a,
			MF10a_3p,
			MF11a_12n,
			MF12n_1p,
			MSu6a_12m,
			MSu12m_6a,
			SS6a_12m,
			SS6a_12n,
			Sat3_7p,
			Sat6_10a,
			Sat10a_3p,
			Sun3_7p,
			Sun6_10a,
			Sun10a_3p,
		};

		let TrendLineProperties = { ...properties, [name]: { ...trendLineObj } };

		return TrendLineProperties;
	};

	useEffect(() => {
		const getData = async () => {
			try {
				// get the details object
				const {
					GM,
					GM2,
					GM3,
					GM5,
					GM6,
					GSM,
					GSM2,
					GSM3,
					GSM5,
					GSM6,
					Aqh,
					dayParts,
					...seasonsData
				} = detailsObj;

				// Dynamically calculate chart values based on allSeasons
				const graphProperties = allSeasons.reduce((acc, season, index) => {
					// Convert season name to the format that matches detailsObj
					let formattedSeason = formatSeasonName(season);

					// If it's the last season, assign `dayParts`
					if (index === allSeasons.length - 1) {
						acc[`${formattedSeason}GraphValue`] = dayParts?.['MF 6a-7p'] || 0;
					} else {
						// for other seasons, assign data from seasonsData
						acc[`${formattedSeason}GraphValue`] =
							seasonsData[formattedSeason]?.['MF 6a-7p'] || 0;
					}
					return acc;
				}, {});

				let resultObj = {
					...properties[name],
					gm: GM5 || '-',
					gmName: GM || GM2 || GM3 ? `${GM} ${GM2} ${GM3}` : '-',
					gmEmail: GM6 || '-',
					gsm: GSM5 || '-',
					gsmName: GSM || GSM2 || GSM3 ? `${GSM} ${GSM2} ${GSM3}` : '-',
					gsmEmail: GSM6 || '-',
					aqh: Aqh || 0,
				};

				let copyProperties = {
					...properties,
					[name]: { ...resultObj, clusterAqh: getClusterAqh(detailsObj.Maddr1) },
				};

				let TrendLineProperties = getDayPartsData(dayParts);

				// Dynamically set data for each season
				allSeasons.forEach((season, index) => {
					let formattedSeason = formatSeasonName(season);

					// If it's the last season, assign `dayParts`
					if (index === allSeasons.length - 1) {
						setData(formattedSeason, dayParts);
					} else {
						// for other seasons, assign data from seasonsData
						setData(formattedSeason, seasonsData[formattedSeason]);
					}
				});

				setGraphValues(graphData ? graphProperties : {});
				setProperties(graphData ? TrendLineProperties : copyProperties);
			} catch (error) {
				console.error(error);
			}
		};

		openModal && isStation && getData();
	}, [isStation, openModal]);

	// function to set data for each season
	const setData = (season, data) => {
		// update the state with the data
		setSeasonData((prevState) => ({
			...prevState,
			[season]: data, // The name of the season is the key
		}));
	};

	useEffect(() => {
		if (openModal) {
			const y = window.pageYOffset;
			setScroll(y);
		}
	}, [openModal]);

	const handleChange = (event) => {
		if (event.target.checked) {
			let propertyName = firebaseKeys[String(title).toLowerCase()];
			handleSelectAll(
				event.target.checked,
				value,
				properties[name].stations,
				propertyName
			);
		} else {
			handleSelectAll(event.target.checked, '', 0, '');
		}
	};

	const handleClose = () => {
		setProperties(initialProperties);
		onClose();
		setTimeout(() => {
			window.scrollTo({ top: scrollHere, behavior: 'auto' });
		}, 1);
	};

	const setTrendLineData = (data) => {
		const trendLineProperties = getDayPartsData(data);
		setProperties(trendLineProperties);
	};

	// handle menu select change
	const handleSelectOnChange = (event) => {
		const selectedSeason = event.target.value;
		const lastSeason = allSeasons[allSeasons.length - 1];

		if (selectedSeason === formatSeasonName(lastSeason)) {
			setTrendLineData(detailsObj.dayParts);
			setStationData(selectedSeason);
			return;
		}
		// search for the season in the detailsObj
		const seasonData = detailsObj[selectedSeason] || {};

		setTrendLineData(seasonData);
		setStationData(selectedSeason);
	};

	// handle chart click in graph
	const onChartClick = (params) => {
		const index = params.dataIndex;
		const selectedSeason = allSeasons[index]; // season corresponding to the index
		const formattedSeason = formatSeasonName(selectedSeason);

		// If the season is not available in the detailsObj then return
		if (!detailsObj[formattedSeason]) {
			console.error('No data available for the selected season:', formattedSeason);
			return;
		}

		// Get the data for the selected season
		const selectedData =
			index === allSeasons.length - 1
				? detailsObj.dayParts
				: detailsObj[formattedSeason];

		setStationData(formattedSeason);
		setTrendLineData(selectedData);
	};

	return (
		<Dialog
			open={openModal}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby="alert-dialog-slide-description"
			sx={{
				'& .MuiDialog-paper': {
					minWidth: graphData ? '800px' : '400px',
				},
			}}
		>
			<DialogTitle sx={{ pb: 0 }}>{title} Details</DialogTitle>
			{isStation && !graphData && (
				<IconButton
					color="primary"
					size="small"
					sx={{
						width: 'fit-content',
						position: 'absolute',
						right: '12%',
						top: '3.5%',
					}}
					onClick={() => {
						url && window.open(`https://${url}`, '_blank', 'noopener,noreferrer');
					}}
					disabled={!url}
				>
					<AddLinkIcon />
				</IconButton>
			)}
			<IconButton
				color="primary"
				size="small"
				sx={{
					width: 'fit-content',
					position: 'absolute',
					right: '3%',
					top: '3.5%',
				}}
				onClick={handleClose}
			>
				<CloseOutlined />
			</IconButton>
			<DialogContent>
				<Box
					sx={{
						my: 1,
						'& >*:not(.loading)': {
							display: 'flex',
							gap: '10px',
							margin: '6px 0',
							border: `1px solid rgb(0 0 0 / 10%)`,
							background: 'rgb(0 0 0 / 10%)',
							borderRadius: '4px',
							padding: '5px',
							boxShadow: 'rgb(0 0 0 / 40%) -1px 1px 1.8px',
						},
					}}
				>
					{graphData ? (
						<>
							<Box style={style}>
								<EChart
									style={{ height: '300px', width: '100%' }}
									option={trendLineOptions(graphValues, allSeasons)}
									event={onChartClick}
								/>
							</Box>
							<FormControl style={{ width: '50%' }}>
								<InputLabel id="demo-simple-select-label">Seasons</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={stationData}
									label="Stations"
									onChange={handleSelectOnChange}
								>
									{allSeasons &&
										allSeasons.map((season, idx) => (
											<MenuItem key={idx} value={transformSeason(season)}>
												{formatSeason(season)}
											</MenuItem>
										))}
								</Select>
							</FormControl>
							{Object.keys(properties[name]).map((key, idx) => (
								<Box key={key} style={{ width: '50%' }}>
									<Typography fontSize={16} fontWeight="bold">
										{trendLineTitles[key]}:
									</Typography>
									<Typography fontSize={16}>{properties[name][key]}</Typography>
								</Box>
							))}
						</>
					) : name && !isEmptyObject(properties[name]) ? (
						<>
							<Box>
								<Typography fontSize={16} fontWeight="bold">
									Name:
								</Typography>
								<Typography fontSize={16}>{value}</Typography>
							</Box>

							{Object.keys(properties[name]).map((key, idx) => (
								<Box key={key}>
									<Typography fontSize={16} fontWeight="bold">
										{firebasekeysTitles[key]}:
									</Typography>
									<Typography fontSize={16}>{properties[name][key]}</Typography>
								</Box>
							))}
						</>
					) : (
						<Box
							className="loading"
							sx={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100px',
							}}
						>
							<CircularProgress sx={{ fontSize: 18 }} color="primary" />
						</Box>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				{!isStation && !isOwner && (
					<FormControlLabel
						control={
							<Checkbox
								size="small"
								checked={selectAllDetails.checked}
								onChange={handleChange}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
						label="Select All"
						sx={{
							'& >span.MuiTypography-root': {
								fontSize: '14px',
								fontWeight: 'bold',
							},
						}}
					/>
				)}
			</DialogActions>
		</Dialog>
	);
}
