import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { db } from '../../../firebase/config';
import { doc, updateDoc } from 'firebase/firestore';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

const IsBridgeComponent = ({
	openStaGroup,
	setOpenStaGroup,
	customStaGroupDataId,
	setCustomStaGroupDataId,
	selectStationIds,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = React.useState(false);
	// handle update custom station group
	const handleUpdate = () => {
		setLoading(true);
		try {
			if (
				customStaGroupDataId &&
				customStaGroupDataId !== '' &&
				selectStationIds.length > 0
			) {
				// get the group id
				const groupId = customStaGroupDataId.id;
				// update the selected station ids
				const customRef = doc(db, 'customStaGroups', groupId);
				updateDoc(customRef, {
					documentsIds: selectStationIds,
				});
			}
		} catch (error) {
			console.log(error);
			setOpenStaGroup(false);
			enqueueSnackbar('Error updating custom station group!', {
				variant: 'error',
			});
		} finally {
			setLoading(false);
			setOpenStaGroup(false);
			enqueueSnackbar('Custom station group updated successfully!', {
				variant: 'success',
			});
		}
	};

	const handleCancel = async () => {
		setOpenStaGroup(false);
	};

	const handleCloseModal = (event, reason) => {
		// if click outside the modal or press esc key, don't close the modal
		if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
			return;
		}
		setOpenStaGroup(false);
	};

	return (
		<Dialog open={openStaGroup} onClose={handleCloseModal}>
			<DialogTitle aria-describedby="alert-dialog-slide-description">
				Confirmation
			</DialogTitle>
			<DialogContent>
				Are you sure you want to overwrite this custom station group?
			</DialogContent>
			<DialogActions>
				<Button
					startIcon={
						loading && <CircularProgress style={{ color: 'white' }} size={18} />
					}
					variant="contained"
					color="success"
					onClick={handleUpdate}
				>
					Overwrite
				</Button>
				<Button variant="contained" color="error" onClick={handleCancel}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default IsBridgeComponent;
