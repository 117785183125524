import { useDocument } from 'react-firebase-hooks/firestore';
import useAuthContext from '../../../hooks/useAuthContext';
import { getRoles, getUserById } from '../utils/utils';
import { useEffect, useState } from 'react';

/**
 * Hoot to valid user Roles
 * @returns roles: list of user roles
 * @returns user: current user
 * @returns isBillPayManger: valid role  billPayManager
 * @returns cogsManager: valid role  cogsManager
 */
export const useRoles = () => {
	const { user } = useAuthContext();
	const [userInfo] = useDocument(getUserById(user.uid));
	const [roles, setRoles] = useState([]);
	useEffect(() => {
		if (userInfo) {
			const data = userInfo.data();
			const roles = getRoles(data);
			setRoles(roles);
		}
	}, [userInfo]);
	return {
		roles,
		user,
		isBillPayManager: roles.includes('billPayManager'),
		isCogsManager: roles.includes('cogsManager'),
	};
};
