import { useContext } from 'react'
import { ProfileContext } from '../context/ProfileContext'

const useProfileContext = () => {
    const context = useContext(ProfileContext)

    if (!context) {
        throw Error('useProfileContext must be used inside an ProfileContextProvider')
    }

    return context
}

export default useProfileContext;