import { ThemeProvider, useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { getTheme } from '../../utils/themes';
import { SpeakerNotes } from '@mui/icons-material';
import useModal from '../../hooks/useModal';
import moment from 'moment';
import { optionsOrderHistory } from './utils';
import NotesDetail from '../HistoryNotes/NotesDetail';
import { useState } from 'react';

const HistoryTables = ({ history, collection, slug }) => {
	const { closeModal, openModal, status } = useModal();
	const [register, setRegister] = useState();
	const theme = useTheme();

	const columns = [
		{
			name: 'date',
			label: 'Date',
			options: {
				customBodyRender: (value) => {
					return moment(value).format('DD/MM/YYYY');
				},
			},
		},
		{
			name: 'note',
			label: 'Note',
		},

		{
			name: '',
			label: 'View',
			options: {
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const rowData = history[rowIndex];
					return (
						<SpeakerNotes
							sx={{ cursor: 'pointer' }}
							onClick={() => openModalDetailRegister(rowData)}
						></SpeakerNotes>
					);
				},
			},
		},
	];

	const openModalDetailRegister = (register) => {
		setRegister(register);
		openModal();
	};
	return (
		<Box>
			<ThemeProvider theme={getTheme(theme)}>
				<MUIDataTable
					columns={columns}
					title={'History'}
					options={{ ...optionsOrderHistory }}
					data={history || []}
				></MUIDataTable>
			</ThemeProvider>
			<NotesDetail
				open={status}
				closeModal={closeModal}
				register={register}
				slug="order"
			></NotesDetail>
		</Box>
	);
};
export default HistoryTables;
