import { collection, query } from 'firebase/firestore';

import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../../firebase/config';
// import { useEffect, useState } from 'react';

const useClients = () => {
	const queryClients = query(collection(db, 'clients'));
	const [clients] = useCollection(queryClients, {
		snapshotListenOptions: { includeMetadataChanges: true },
	});

	const clientsData = clients?.docs.map((item) => {
		return { ...item.data(), id: item.id };
	});

	return clientsData;
};

export default useClients;
