/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
	Box,
	Button,
	Toolbar,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	CssBaseline,
	Divider,
	IconButton,
	useMediaQuery,
	Badge,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import DocumentIcon from '@mui/icons-material/Grading';
// import SettingsIcon from '@mui/icons-material/Settings'
// import DashboardIcon from '@mui/icons-material/Dashboard'
import ForumIcon from '@mui/icons-material/Forum';
// import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
// import Inventory2Icon from '@mui/icons-material/Inventory2'
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CalculateIcon from '@mui/icons-material/Calculate';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// import AssignmentDataIcon from '@mui/icons-material/AssignmentInd';
import { useLocation, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useLogout } from '../hooks/useLogout';
import Logo from '../assets/images/sunfunmediaLogo.png';
import headerLogoText from '../assets/images/header-logo-text.png';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import AccountMenu from '../components/ProfileMenu';
import useProfileContext from '../hooks/useProfileContext';
import useAuthContext from '../hooks/useAuthContext';
import {
	collection,
	doc,
	getDoc,
	where,
	query,
	onSnapshot,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { isAdminVerify } from '../utils/index';

const openedMixin = (theme, drawerWidth) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	backgroundColor: theme.palette.primary.main,

	'& button svg': {
		color: 'white',
	},
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth',
})(({ theme, open, drawerWidth }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const MuiToolbar = styled(Toolbar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	'& > button': {
		marginRight: '12px',
		marginLeft: '-5px',
		...(open && { display: 'none' }),

		'& svg': {
			color: 'white',
		},
	},

	'& .logoContainer': {
		display: 'flex',
		alignItems: 'center',
		height: '100%',

		'& > img.logo': {
			width: '50px',
			marginRight: '20px',
			[theme.breakpoints.down('sm')]: {
				width: '45px',
				height: '45px',
				display: 'none',
			},
		},
		'& > img.logoText': {
			width: '200px',
			opacity: 0.9,
			[theme.breakpoints.down('md')]: {
				width: '160px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '130px',
				display: open ? 'none' : 'block',
			},
		},
	},
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth',
})(({ theme, open, drawerWidth }) => ({
	boxShadow: `0px 2px 4px -3px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)`,
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme, drawerWidth),
		'& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),

	'& .logoContainer': {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		flex: 1,
		justifyContent: 'center',

		'& > img.logo': {
			width: '55px',
			[theme.breakpoints.down('sm')]: {
				width: '45px',
				height: '45px',
			},
		},
	},

	'& .drawerContent': {
		position: 'relative',
		height: '100%',
		marginBottom: '5px',
	},
}));
const SideBarListsBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,

	'&>ul:first-of-type': {
		paddingRight: open ? theme.spacing(0.8) : 0,
		paddingLeft: open ? theme.spacing(0.8) : 0,
		position: 'initial',
		flex: 1,
	},
	'&>ul:last-of-type': {
		paddingRight: open ? theme.spacing(0.8) : 0,
		paddingLeft: open ? theme.spacing(0.8) : 0,
		position: 'initial',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		flex: 1,
	},
}));

const SideBarListItem = styled(ListItem, {
	shouldForwardProp: (prop) =>
		prop !== 'open' && prop !== 'isSelected' && prop !== 'bottom',
})(({ theme, open, isSelected, bottom }) => ({
	// border: "1px solid black",
	borderRadius: 4,
	margin: bottom ? '4px 7px' : open ? '7px' : '10px 7px',
	padding: open ? '10px' : '7px 10px',
	cursor: 'pointer',
	width: 'auto',
	transition: theme.transitions.create(['all'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	'&:hover': {
		opacity: 0.8,
		textDecoration: 'none',
	},
	'& svg': {
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem',
		},
	},
	'& > .MuiListItemIcon-root': {
		paddingLeft: open ? '5px' : '2px',
	},
	'& > .MuiListItemText-root': {
		color: 'rgb(0 0 0 / 83%)',
		'& .MuiTypography-body1': {
			fontSize: '15px',
		},
	},
	...(isSelected && {
		width: 'auto',
		borderRadius: 4,
		// backgroundColor: "#00acc1",
		backgroundColor: theme.palette.primary.main,
		'& .MuiListItemIcon-root': {
			color: '#fff',
		},
		'& .MuiListItemText-root': {
			color: '#fff',
		},
	}),
}));

export default function MiniDrawer({ receivedNoti, children }) {
	const theme = useTheme();
	const { user } = useAuthContext();
	const matches = useMediaQuery('(max-width:600px)');
	const { profile } = useProfileContext();
	const { state } = useLocation();
	const { enqueueSnackbar } = useSnackbar();
	// const { authIsReady } = useAuth()
	const { logout, error } = useLogout(enqueueSnackbar);
	const location = useLocation();

	const drawerWidth = useMemo(() => (matches ? 200 : 240), [matches]);
	const [open, setOpen] = useState(false);
	const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const hanldeLogout = () => {
		logout();
	};

	const MessagesBadge = (props) => {
		const badge = props.badge;
		const icon = props.icon;

		if (badge) {
			// Todo handle number of unread messages.
			return (
				<Badge
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					color="secondary"
					badgeContent={unreadMessagesCount}
					max={99}
				>
					<ListItemIcon>{props.badge}</ListItemIcon>
				</Badge>
			);
		} else {
			return <ListItemIcon>{icon}</ListItemIcon>;
		}
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const openMenu = Boolean(anchorEl);
	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		error && enqueueSnackbar(error.message, { variant: 'error' });
	}, [error]);

	const initalSidebarItemsDashboard = [
		// {
		//   id: 1,
		//   text: 'Dashboard',
		//   icon: <DashboardIcon fontSize='medium' />,
		//   path: '/dashboard',
		// },
		// {
		//   id: 2,
		//   text: 'Prospector',
		//   icon: <AttachMoneyIcon fontSize='medium' />,
		//   path: '/dashboard/prospector',
		// },
		{
			id: 3,
			text: 'AQH',
			icon: <CalculateIcon fontSize="medium" />,
			path: '/dashboard/AQH',
		},
		{
		  id: 4,
		  text: 'COGS',
		  icon: <ManageAccountsIcon fontSize='medium' />,
		  path: '/dashboard/cogs',
		},
		// {
		//   id: 5,
		//   text: 'Toolkit',
		//   icon: <HomeRepairServiceIcon fontSize='medium' />,
		//   path: '/dashboard/toolkit',
		// },
		// {
		//   id: 6,
		//   text: 'Inventory',
		//   icon: <Inventory2Icon fontSize='medium' />,
		//   path: '/dashboard/inventory',
		// },
		{
			id: 7,
			text: 'Documents',
			icon: <DocumentIcon fontSize="medium" />,
			path: '/dashboard/documents',
		},
		// {
		//   id: 8,
		//   text: 'Admin Data',
		//   icon: <AssignmentDataIcon fontSize='medium' />,
		//   path: '/dashboard/admindata',
		// },
		{
			id: 9,
			text: 'Messages',
			icon: null,
			path: '/dashboard/messages',
			badge: <ForumIcon fontSize="medium" />,
		},
	];

	const setUnreadMessages = (queryData) => {
		// watch realtime "reviewMessages" collection updates to update number of unread messages
		// and update messages tab content
		const unsub = onSnapshot(queryData, (snapshot) => {
			let results = [];
			snapshot.docs.forEach((doc) => {
				results.push(doc.data());
			});
			setUnreadMessagesCount(results.length);
		});
		return unsub;
	};

	const [sidebarItemsDashboard, setSidebarItemsDashboard] = useState(
		initalSidebarItemsDashboard
	);

	useEffect(() => {
		// get user Role to show admin messages tab on side menu.
		const getUserRole = async () => {
			try {
				const userRef = doc(db, 'users', user.uid);
				const userDoc = await getDoc(userRef);
				const userRole = userDoc?.data()?.['role'];
				const colRef = collection(db, 'reviewMessages');

				// set unread messages count based on user role.
				if (userRole && isAdminVerify(userRole)) {
					const queryData = query(colRef, where('state', '==', 'unread'));
					setUnreadMessages(queryData);
				} else {
					const queryData = query(
						colRef,
						where('state', 'in', [
							'approved',
							'denied',
							'managerSigned',
							'sentClient',
							'signedClient',
						]),
						where('employeeEmail', '==', user.email),
						where('repState', '==', 'unread')
					);
					setUnreadMessages(queryData);
				}
			} catch {
				console.log('error getting user role');
			}
		};

		getUserRole();
	}, [user.email, user.uid]);

	return (
		<Box sx={{ display: 'flex', background: 'white' }}>
			<CssBaseline />
			<AppBar position="fixed" open={open} drawerWidth={drawerWidth}>
				<MuiToolbar open={open}>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
					>
						<MenuIcon />
					</IconButton>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							flex: 1,
						}}
					>
						<Box className="logoContainer" open>
							{!open && <img src={Logo} className="logo" alt="logo" />}
							<img src={headerLogoText} className="logoText" alt="logo-text" />
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Box sx={{ px: 2 }}>
								<Tooltip title="Account settings">
									<IconButton
										onClick={handleMenuClick}
										size="small"
										sx={{ ml: 2 }}
										aria-controls={openMenu ? 'account-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={openMenu ? 'true' : undefined}
									>
										<Badge
											badgeContent={receivedNoti?.filter((v) => !v.seen).length}
											color="secondary"
										>
											<Avatar
												sx={{
													width: 38,
													height: 38,
													fontSize: '0.9rem',
													cursor: 'pointer',
												}}
												src={profile?.photoURL || ''}
											>
												{profile &&
													profile.firstName.charAt(0) + ' ' + profile.lastName.charAt(0)}
											</Avatar>
										</Badge>
									</IconButton>
								</Tooltip>
							</Box>
							<Button
								variant="contained"
								size={matches ? 'small' : 'medium'}
								onClick={hanldeLogout}
								sx={{ color: '#fff', border: '1px solid #fff' }}
							>
								Logout
							</Button>
						</Box>
					</Box>
				</MuiToolbar>
			</AppBar>
			<Drawer variant="permanent" open={open} drawerWidth={drawerWidth}>
				<DrawerHeader>
					<Box className="logoContainer" open>
						{open && <img src={Logo} className="logo" alt="logo" />}
					</Box>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<SideBarListsBox open={open}>
					<List>
						{sidebarItemsDashboard.map((item) => (
							<SideBarListItem
								key={item.id}
								component={Link}
								to={item.path ? item.path : location.pathname}
								isSelected={item.path === location.pathname}
								open={open}
							>
								<MessagesBadge badge={item.badge} icon={item.icon}></MessagesBadge>
								<ListItemText primary={item.text} />
								{/* <ListItemIcon>{item.rightIcon}</ListItemIcon> */}
							</SideBarListItem>
						))}
					</List>
					<List>
						{/* <SideBarListItem
              className='settings'
              component={Link}
              isSelected={location.pathname === "/dashboard/settings"}
              to={"/dashboard/settings"}
              open={open}
              bottom
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={'Settings'} />
            </SideBarListItem> */}
						<SideBarListItem
							className="settings"
							component={Link}
							isSelected={location.pathname === '/dashboard/admin'}
							to={'/dashboard/admin'}
							open={open}
							bottom
						>
							<ListItemIcon>
								<AdminPanelSettingsIcon />
							</ListItemIcon>
							<ListItemText primary={'Admin'} />
						</SideBarListItem>
						<SideBarListItem
							className="logout"
							onClick={hanldeLogout}
							open={open}
							bottom
						>
							<ListItemIcon>
								<LogoutIcon />
							</ListItemIcon>
							<ListItemText primary={'Logout'} />
						</SideBarListItem>
					</List>
				</SideBarListsBox>
			</Drawer>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: 3,
					maxHeight: window.location.href.includes('#messages') && '99vh',
					overflow: window.location.href.includes('#messages') && 'hidden',
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}
			>
				<Toolbar />
				{children}
			</Box>
			<AccountMenu
				handleClick={handleMenuClick}
				receivedNoti={receivedNoti}
				open={openMenu}
				anchorEl={anchorEl}
				handleClose={handleMenuClose}
				hanldeLogout={hanldeLogout}
			/>
		</Box>
	);
}
