/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import JSZip from 'jszip';
import { baseURL } from '../../src/utils/index.js';
import axios from 'axios';
import {
	collection,
	doc,
	getDocs,
	onSnapshot,
	query,
	updateDoc,
	where,
	orderBy,
} from 'firebase/firestore';
import { db } from '../firebase/config.js';
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
	openContractSignedView,
	onClose,
	documentId,
	service,
	setEsignDocumentId,
	setService,
}) {
	const [scrollHere, setScroll] = React.useState(0);
	const [loading, setLoading] = React.useState(false);
	const [isFetched, setIsFetched] = React.useState(false);

	useEffect(() => {
		// Function to open the signed document
		const openSignedDocument = (signedURL) => {
			if (signedURL) {
				window.open(signedURL, '_blank');
				setLoading(false);
				onClose();
				setEsignDocumentId(null);
				setService(null);
			}
		};

		// Function to fetch the signed document from Anvil
		const fetchAnvilDocument = async () => {
			const blob = await fetch(baseURL + '/fetchSignedDocs', {
				method: 'POST',
				body: JSON.stringify({
					documentGroupEid: documentId,
				}),
			});

			const response = await blob.json();
			if (response.statusCode === 200) {
				const zip = new JSZip();
				const unzipped = await zip.loadAsync(response.data.data);
				let blob = new Blob([unzipped.files['blob.pdf']._data.compressedContent], {
					type: 'application/pdf',
				});
				let pdfurl = window.URL.createObjectURL(blob);
				openSignedDocument(pdfurl);
			}
		};

		// Function to fetch the signed document from Firebase
		const fetchFirebaseDocument = async (field) => {
			const contractsRef = collection(db, 'contracts');
			const q = query(contractsRef, where(field, '==', documentId));
			const querySnapshot = await getDocs(q);

			if (!querySnapshot.empty) {
				querySnapshot.forEach((doc) => {
					const contractData = doc.data();
					openSignedDocument(contractData?.signedContractURL);
				});
			}
		};

		const getData = async () => {
			try {
				setLoading(true);
				if (service === 'anvil') {
					await fetchAnvilDocument();
				} else if (service === 'xodo') {
					await fetchFirebaseDocument('documentHash');
				} else if (service === 'docuSeal') {
					await fetchFirebaseDocument('submissionId');
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};

		if (documentId !== null && !isFetched) {
			getData();
			setIsFetched(true);
		}
	}, [documentId, service, isFetched]);

	useEffect(() => {
		if (openContractSignedView) {
			const y = window.pageYOffset;
			setScroll(y);
		}
	}, [openContractSignedView]);

	const handleCloseDeal = () => {
		onClose();
		setTimeout(() => {
			window.scrollTo({ top: scrollHere, behavior: 'auto' });
		}, 1);
	};

	return (
		<Dialog
			open={openContractSignedView}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseDeal}
			aria-describedby="alert-dialog-slide-description"
			sx={{
				'& .MuiDialog-paper': {
					overflowY: 'hidden',
					backgroundColor: 'white',
				},
			}}
		>
			<DialogTitle sx={{ pb: 2 }}>Loading...</DialogTitle>
		</Dialog>
	);
}
