/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Button, Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import useProfileContext from '../../hooks/useProfileContext';
import { Edit as EditIcon } from '@mui/icons-material';
import EmployeeForm from './components/EmployeeForm';
import useAuthContext from '../../hooks/useAuthContext';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { styled } from '@mui/material/styles';

const MuiPaper = styled(Paper)(({ theme }) => ({
	background: '#eeeeee',
	borderRadius: '8px',
	minHeight: '75vh',
	position: 'relative',
	'& .infoContainer > .MuiGrid-item, & .MuiGrid-item.employee-id': {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	'& .infoContainer > .MuiGrid-item > .MuiBox-root, .MuiGrid-item.employee-id > .MuiBox-root':
		{
			padding: theme.spacing(2),
			background: '#fff',
			borderRadius: '4px',
			float: 'right',
			display: 'flex',
			flexDirection: 'column',
			minWidth: '200px',
			width: '100%',
			gap: '10px',
			'& > .MuiTypography-root.title': {
				fontWeight: 'bold',
				fontSize: '1rem',
			},
			'& > .MuiTypography-root.value': {
				fontSize: '0.9rem',
				wordBreak: 'break-all',
			},
		},
	'& .MuiTypography-root.info': {
		display: 'inline-block',
		padding: theme.spacing(2),
		background: '#fff',
		borderRadius: '4px',
		float: 'right',
	},
}));

const EmployeeData = () => {
	const { user, authIsReady } = useAuthContext();
	const { profile } = useProfileContext();
	const [isEdit, setIsEdit] = useState(false);
	const [employeeInfo, setEmployeeInfo] = useState({});

	const initialState = {
		firstName: '',
		lastName: '',
		photoURL: '',
	};
	const [state, setState] = useState(initialState);

	useEffect(() => {
		if (profile) {
			setState({ ...state, ...profile });
		}
	}, [profile]);

	useEffect(() => {
		if (authIsReady) {
			const collRef = collection(db, 'employees');
			const q = query(collRef, where('userId', '==', user.uid));

			const unsub = onSnapshot(q, (snapshot) => {
				if (!snapshot.empty) {
					let results = [];
					snapshot.docs.forEach((doc) => {
						results.push({ ...doc.data(), id: doc.id });
					});
					setEmployeeInfo({ ...results[0], employeeId: profile?.employeeId || '' });
				} else {
					setEmployeeInfo({ employeeId: profile?.employeeId || '' });
				}
			});
			return () => unsub();
		}
	}, [user]);

	return (
		<Box mt={3}>
			{isEdit ? (
				<EmployeeForm
					setIsEdit={setIsEdit}
					employeeInfo={employeeInfo}
					user={user}
				/>
			) : (
				<MuiPaper sx={{ mx: 'auto', width: { md: '80%' }, p: 3 }}>
					<Grid container spacing={2}>
						<Grid
							item
							xs={12}
							sm={8}
							lg={6}
							sx={{ display: 'flex', gap: '2rem', height: '125px' }}
						>
							<Avatar
								sx={{
									width: 124,
									height: 124,
									borderRadius: '4px',
									fontSize: '2.2rem',
								}}
								src={state.photoURL || ''}
							>
								{state.firstName.charAt(0) + ' ' + state.lastName.charAt(0)}
							</Avatar>
							<Box sx={{ marginRight: '10px' }}>
								<Typography
									sx={{
										display: 'inline-block',
										p: 2,
										bgcolor: '#fff',
										borderRadius: '4px',
										minWidth: '15rem',
										mb: 2,
									}}
								>
									{state.firstName || 'First Name (not available)'}
								</Typography>
								<Typography
									sx={{
										display: 'inline-block',
										p: 2,
										bgcolor: '#fff',
										borderRadius: '4px',
										minWidth: '15rem',
									}}
								>
									{state.lastName || 'Last Name (not available)'}
								</Typography>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={4}
							lg={6}
							className="employee-id"
							style={{ justifyContent: 'flex-end', alignItems: 'flex-start' }}
						>
							<Box sx={{ width: 'auto !important' }}>
								<Typography className="title">Employee Id</Typography>
								<Typography className="value">
									{employeeInfo?.employeeId || '-'}
								</Typography>
							</Box>
						</Grid>
					</Grid>
					{Object.keys(employeeInfo).length > 0 && (
						<Grid
							container
							spacing={2}
							mt={5}
							display="flex"
							justifyContent="flex-start"
							className="infoContainer"
						>
							<Grid item xs={4}>
								<Box>
									<Typography className="title">Employee Email</Typography>
									<Typography className="value">{user.email || '-'}</Typography>
								</Box>
							</Grid>
							<Grid item xs={4}>
								<Box>
									<Typography className="title">Employee Phone</Typography>
									<Typography className="value">
										{employeeInfo.employeePhone || '-'}
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={4}>
								<Box>
									<Typography className="title">Department</Typography>
									<Typography className="value">
										{employeeInfo.department || '-'}
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={4}>
								<Box>
									<Typography className="title">Position</Typography>
									<Typography className="value">
										{employeeInfo.position || '-'}
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={4}>
								<Box>
									<Typography className="title">Supervisor</Typography>
									<Typography className="value">
										{employeeInfo.supervisor || '-'}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					)}
					<Button
						variant="outlined"
						color="primary"
						sx={{
							minWidth: '8rem',
							position: 'absolute',
							left: '1.8rem',
							bottom: '1.8rem',
						}}
						startIcon={<EditIcon />}
						onClick={() => setIsEdit(true)}
					>
						Edit
					</Button>
					<Button
						variant="contained"
						color="primary"
						sx={{
							minWidth: '8rem',
							position: 'absolute',
							right: '1.8rem',
							bottom: '1.8rem',
						}}
					>
						Save
					</Button>
				</MuiPaper>
			)}
		</Box>
	);
};

export default EmployeeData;
