import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { CircularProgress, TextField, Stack } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import useAuthContext from '../../../hooks/useAuthContext';
import { useSnackbar } from 'notistack';
import { initialFieldsOverride } from '../utils/constants';
import {
	addDoc,
	collection,
	getDocs,
	query,
	serverTimestamp,
	where,
} from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { sendEmailMessage, sendTextMessage } from '../../../utils';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
	openOverride,
	setOpenOverride,
	proposalId,
	userId,
	userState,
	setPublishProcess,
}) {
	const { user } = useAuthContext();
	const { enqueueSnackbar } = useSnackbar();
	const [fields, setFields] = React.useState(initialFieldsOverride);
	const [adminUsersEmails, setAdminUsersEmails] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const hostEnv = window.location.hostname === 'localhost' ? 'dev' : 'prod';

	React.useEffect(() => {
		const getData = async () => {
			// Query for documents where role is an array and contains 'admin'
			const arrayQuery = query(
				collection(db, 'users'),
				where('role', 'array-contains', { label: 'Manager', value: 'admin' })
			);
			// Query for documents where role is a string equal to 'admin'
			const stringQuery = query(
				collection(db, 'users'),
				where('role', '==', 'admin')
			);
			// Run both queries in parallel
			const [arraySnapshot, stringSnapshot] = await Promise.all([
				getDocs(arrayQuery),
				getDocs(stringQuery),
			]);
			const adminUsers = [];
			// Add users from the array query
			arraySnapshot.forEach((doc) => {
				adminUsers.push(doc.data().email);
			});
			// Add users from the string query
			stringSnapshot.forEach((doc) => {
				adminUsers.push(doc.data().email);
			});
			// Remove duplicates if any
			const uniqueAdminUsers = Array.from(new Set(adminUsers));
			setAdminUsersEmails(uniqueAdminUsers);
		};
		getData();
	}, []);
	// if error exist in calculator, it will be sent to manager for override
	const sendAdminsEmails = async (emailInfo) => {
		try {
			// send email to rep
			await sendEmailMessage(
				emailInfo.title,
				'',
				user.email,
				userId,
				null,
				null,
				null,
				null,
				'An approval request was sent to the manager'
			);
			if (hostEnv === 'prod') {
				adminUsersEmails.forEach(async (email) => {
					await sendEmailMessage(
						emailInfo.title,
						'',
						email,
						userId,
						null,
						null,
						null,
						null,
						emailInfo.html
					);
				});
			} else {
				await sendEmailMessage(
					emailInfo.title,
					'',
					'fortega703@gmail.com',
					userId,
					null,
					null,
					null,
					null,
					emailInfo.html
				);
			}
		} catch (error) {
			enqueueSnackbar('Could not send email to manager', { variant: 'error' });
		}
	};

	const handleOverride = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			// send review message
			const messageInfo = {
				proposalId: proposalId,
				dealName: fields.deal_name,
				fromName: userState.employee_name,
				employeeEmail: user.email,
				employeeName: userState.employee_name,
				requestReview: 'yes',
				comments: fields.comments,
				state: 'unread',
				submittedDate: serverTimestamp(),
			};

			const docRef = collection(db, 'reviewMessages');
			await addDoc(docRef, messageInfo);

			const emailMessage = {
				title: `Deal: ${fields.deal_name}`,
				html: `<strong>You got a new message from: </strong> ${userState.employee_name} <br></br> <strong>user email:</strong> ${user.email} <br></br> <strong>request review</strong> <br></br> <strong>comments:</strong> ${fields.comments}`,
			};
			const dataMessage = {
				message: `An approval request was sent to the manager\nDeal: ${fields.deal_name}`,
				email: user.email,
			};
			// notify rep by email and text
			await sendTextMessage(dataMessage.message, dataMessage.email);
			// send email to manager and rep
			await sendAdminsEmails(emailMessage);
			enqueueSnackbar('Email sent to manager!', { variant: 'success' });
			setPublishProcess(false);
			setLoading(false);
			setFields(initialFieldsOverride);
			setOpenOverride(false);
		} catch (error) {
			setPublishProcess(false);
			setLoading(false);
			enqueueSnackbar(error.message, { variant: 'error' });
		}
	};

	const handleFieldChange = (e) => {
		setFields((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	return (
		<div>
			<Dialog
				open={openOverride}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => {
					setPublishProcess(false);
					setOpenOverride(false);
				}}
				aria-describedby="alert-dialog-slide-description"
				sx={{
					'& .MuiDialog-paper': {
						minWidth: '500px',
						minHeight: '330px',
					},
				}}
			>
				<DialogTitle>Manager Override</DialogTitle>
				<form onSubmit={handleOverride} autoComplete="off">
					<DialogContent>
						<Stack direction="column" spacing={2}>
							<TextField
								label="Deal Name"
								variant="outlined"
								size="small"
								name="deal_name"
								value={fields.deal_name}
								onChange={handleFieldChange}
								fullWidth
								required
							/>
							<TextField
								label="Comments"
								variant="outlined"
								name="comments"
								value={fields.comments}
								onChange={handleFieldChange}
								multiline
								rows={4}
							/>
						</Stack>
					</DialogContent>
					<DialogActions sx={{ mr: 2 }}>
						<Button
							variant="outlined"
							type="button"
							onClick={() => {
								setLoading(false);
								setPublishProcess(false);
								setFields(initialFieldsOverride);
								setOpenOverride(false);
							}}
						>
							Close
						</Button>
						<Button
							variant="contained"
							startIcon={loading && <CircularProgress size={18} />}
							disabled={loading}
							type={'submit'}
							endIcon={<SendIcon />}
						>
							SEND
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</div>
	);
}
