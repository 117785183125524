/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableHead, TextField } from '@mui/material';
import { useEffect } from 'react';
import { createCreditsTableRowData } from '../../StationList/utils/constants.js';

const vacationObjectKeys = [
	'Amsterdam, NL',
	'London, UK',
	'Paris, FR',
	'Rome, IT',
	'Aruba',
	'Cancun, MX',
	'Freeport, BS',
	'Montego Bay, JM',
	'Nassau, BS',
	'Negril, JM',
	'Waikiki Beach, HI',
];

const columns = [
	{ id: 'name', label: 'Vacations with airfare', minWidth: 400 },
	{ id: 'credits', label: 'Credits', minWidth: 100 },
];

const VacationWithAirFareTable = ({ object, tableRows, setTableRows }) => {
	const handleNameChange = (e) => {
		setTableRows((prev) => {
			return prev.map((row) => {
				if (row.name === e.target.name) {
					return { ...row, credits: customTextField(e.target.name, e.target.value) };
				}
				return row;
			});
		});
	};

	const customTextField = (key, value) => {
		return (
			<TextField
				inputProps={{
					style: { textAlign: 'center', fontSize: 13, fontWeight: 500 },
				}}
				variant="outlined"
				size="small"
				name={key}
				value={value}
				onChange={handleNameChange}
			></TextField>
		);
	};

	useEffect(() => {
		const rows = [];
		vacationObjectKeys.forEach((trip) => {
			rows.push(
				createCreditsTableRowData(
					trip,
					customTextField(trip, object[trip]),
					'normal'
				)
			);
		});
		// add manually subtitle rows in table by index
		rows.splice(
			0,
			0,
			createCreditsTableRowData(
				'Includes airfare for 2 from New York City, valid September 1– March 1, minimum 120 days advance notice required, excluding holidays and annual events',
				'',
				'gray'
			)
		);
		rows.splice(
			5,
			0,
			createCreditsTableRowData(
				'Includes airfare for 2 from Miami, valid September 1– March 1, minimum 120 days advance notice required, excluding holidays and annual events',
				'',
				'gray'
			)
		);
		rows.splice(
			12,
			0,
			createCreditsTableRowData(
				'Includes airfare for 2 from Los Angeles, valid September 1 – March 1, minimum 120 days advance notice required, excluding holidays and annual events',
				'',
				'gray'
			)
		);
		setTableRows(rows);
	}, []);

	return (
		<Table sx={{ marginTop: 7 }} stickyHeader aria-label="sticky table">
			<TableHead>
				<TableRow>
					{columns.map((column) => (
						<TableCell
							sx={{
								backgroundColor: '#6C6767',
								color: 'white',
								padding: '5px 0px 5px 10px',
								borderLeft: '1px solid black',
								'&:last-child': {
									borderRight: '1px solid black',
									paddingLeft: '40px',
								},
							}}
							key={column.id}
							align={column.align}
							style={{ minWidth: column.minWidth }}
						>
							{column.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{tableRows.map((row) => {
					return (
						<TableRow
							hover
							role="checkbox"
							tabIndex={-1}
							key={row.name}
							sx={{ backgroundColor: row.type === 'normal' ? 'white' : '#D2D2D2' }}
						>
							{columns.map((column) => {
								const value = row[column.id];
								return (
									<TableCell
										sx={{
											fontSize: 12,
											borderBottom: '1px solid black',
											borderLeft: '1px solid black',
											'&:last-child': {
												borderRight: '1px solid black',
											},
										}}
										key={column.id}
										align={column.align}
									>
										{column.format && typeof value === 'number'
											? column.format(value)
											: value}
									</TableCell>
								);
							})}
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
};

export default VacationWithAirFareTable;
