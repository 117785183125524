/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import axios from 'axios';
import { baseURL } from '../../../utils';
import { combineSales } from '../../StationList/utils/utils';

const MostRecentDeal = ({
	sumOfRatio,
	grossTotal,
	cpt,
	handleLowDeal,
	dealTerms,
	setRolloverCredits,
}) => {
	const [recentDeal, setRecentDeal] = useState([]);
	const salesRef = collection(db, 'sales');

	useEffect(() => {
		const getMostRecentDeal = async () => {
			const optionsData = query(salesRef, where('Deal #', '==', dealTerms));

			const unsub = onSnapshot(optionsData, (snapshot) => {
				const result = snapshot.docs.map((doc) => {
					return { ...doc.data(), id: doc.id };
				});
				// get unique deal with current CPT and current ratio in combineSales function
				const current = combineSales(result);
				setRecentDeal([current]);
			});

			return unsub;
		};

		getMostRecentDeal();
	}, []);

	// Get Rollover Credits from firebase function
	useEffect(() => {
		const getRolloverCredits = async () => {
			const data = {
				deal: dealTerms,
			};
			const rolloverCredits = await axios.post(baseURL + '/getRolloverCredits', {
				...data,
			});
			setRolloverCredits(rolloverCredits.data.data);
		};

		if (dealTerms) {
			getRolloverCredits();
		}
	}, []);

	// transform string to number
	const stringToNumber = (str) => {
		if (str === undefined || '') return 0;
		const foundNumber = str.replace(/\D/g, '');
		const result = Number((foundNumber / 100).toFixed(2));
		return Number(result);
	};

	const getCurrentCPT = (obj) => {
		if (obj['Current CPT'] !== '' || obj['Current CPT'] !== '0') {
			return obj['Current CPT'];
		} else {
			return 0;
		}
	};

	const getCurrentRatio = (obj) => {
		if (obj['Current Ratio'] !== '' || obj['Current Ratio'] !== '0.00') {
			return obj['Current Ratio'];
		} else {
			return 0;
		}
	};

	const getCurrentRenewal = (obj) => {
		if (obj['Current Renewal $'] !== '' || obj['Current Renewal $'] !== '0') {
			return obj['Current Renewal $'];
		} else {
			return 0;
		}
	};

	// Check Lower Deal
	useEffect(() => {
		if (recentDeal) {
			recentDeal.map((obj) => {
				if (
					(grossTotal > 0 &&
						Number(grossTotal) < stringToNumber(getCurrentRenewal(obj))) ||
					(sumOfRatio > 0 &&
						Number(sumOfRatio) < stringToNumber(getCurrentRatio(obj))) ||
					(cpt > 0 && Number(cpt) < stringToNumber(getCurrentCPT(obj)))
				) {
					handleLowDeal(true);
				} else {
					handleLowDeal(false);
				}
			});
		} else {
			// if no recent deal, set low deal to false
			handleLowDeal(false);
		}
	}, [grossTotal, sumOfRatio, cpt]);

	return (
		<Box>
			{recentDeal && recentDeal.length > 0
				? recentDeal.map((obj, idx) => (
						<Box key={idx}>
							<Stack direction="row" spacing={1}>
								<Typography
									sx={{ fontSize: 13, width: 110, fontWeight: 'bold', textAlign: 'end' }}
								>
									Deal #:
								</Typography>
								<Typography sx={{ fontSize: 13, width: 80 }}>
									{' '}
									{obj['Deal #'] ? obj['Deal #'] : 0}
								</Typography>
							</Stack>
							<Stack direction="row" spacing={1}>
								<Typography
									sx={{
										fontSize: 13,
										width: 110,
										fontWeight: 'bold',
										textAlign: 'right',
									}}
								>
									Current CPT:{' '}
								</Typography>
								<Typography sx={{ fontSize: 13, width: 80 }}>
									{' '}
									{getCurrentCPT(obj) ? getCurrentCPT(obj) : '$ 0'}
								</Typography>
								<Typography
									sx={{
										fontSize: 13,
										width: 100,
										fontWeight: 'bold',
										textAlign: 'right',
									}}
								>
									Current Ratio:{' '}
								</Typography>
								<Typography sx={{ fontSize: 13, width: 80 }}>
									{' '}
									{getCurrentRatio(obj) ? getCurrentRatio(obj) : 0}
								</Typography>
							</Stack>
							<Stack direction="row" spacing={1}>
								<Typography
									sx={{
										fontSize: 13,
										width: 110,
										fontWeight: 'bold',
										textAlign: 'right',
									}}
								>
									Current Renewal:{' '}
								</Typography>
								<Typography sx={{ fontSize: 13, width: 80 }}>
									{' '}
									{getCurrentRenewal(obj) ? getCurrentRenewal(obj) : 0}
								</Typography>
								<Typography
									sx={{ fontSize: 13, width: 110, fontWeight: 'bold' }}
								></Typography>
							</Stack>
							<br />
							{grossTotal > 0 ? (
								Number(grossTotal) > stringToNumber(getCurrentRenewal(obj)) ? (
									''
								) : (
									<Typography color="error" sx={{ fontSize: 13, fontWeight: 'bold' }}>
										The current Gross is Lower than most recent deal
									</Typography>
								)
							) : (
								''
							)}

							{sumOfRatio > 0 ? (
								Number(sumOfRatio) > stringToNumber(getCurrentRatio(obj)) ? (
									''
								) : (
									<Typography color="error" sx={{ fontSize: 13, fontWeight: 'bold' }}>
										The current Ratio is Lower than most recent deal
									</Typography>
								)
							) : (
								''
							)}

							{cpt > 0 ? (
								Number(cpt) > stringToNumber(getCurrentCPT(obj)) ? (
									''
								) : (
									<Typography color="error" sx={{ fontSize: 13, fontWeight: 'bold' }}>
										The current CPT is Lower than most recent deal
									</Typography>
								)
							) : (
								''
							)}
						</Box>
				  ))
				: ''}
		</Box>
	);
};

export default MostRecentDeal;
