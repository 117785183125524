/* eslint-disable no-unused-vars */
/* eslint-disable  react-hooks/exhaustive-deps*/

import {
	Box,
	Grid,
	Stack,
	Typography,
	Link,
	Dialog,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { ref } from 'firebase/storage';
import { storage } from '../../../firebase/config';
import DownloadIcon from '@mui/icons-material/Download';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider, useTheme, styled } from '@mui/material/styles';
import { getTheme } from '../utils';

const MainBox = styled(Box)(({ theme }) => ({
	margin: '20px auto',
	'.MuiPaper-root': {
		background: 'transparent',
		boxShadow: 'none',
		border: 0,
	},
}));

const DownloadUrl = ({ name }) => {
	const [query, setQuery] = useState();
	const [url] = useDownloadURL(query);

	useEffect(() => {
		if (name) {
			const notesStorageRef = ref(storage, `messages/${name}`);
			setQuery(notesStorageRef);
		}
	}, [name]);
	return (
		<>
			{url ? (
				<Link href={url || ''} target="_blank" rel="noreferrer">
					<DownloadIcon />
				</Link>
			) : (
				''
			)}
		</>
	);
};

const ViewMessage = ({
	viewMessageModal,
	setViewMessageModal,
	currentMessage,
	setCurrentMessage,
}) => {
	const theme = useTheme();
	const closeModal = (event, reason) => {
		// if click outside the modal or press esc key, don't close the modal
		if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
			setCurrentMessage(null);
			setViewMessageModal(false);
		}
	};
	// memoize the data
	const messageData = React.useMemo(() => {
		if (currentMessage?.docs) {
			return currentMessage.docs.map((doc) => {
				return {
					name: doc,
				};
			});
		}
		return [];
	}, [currentMessage?.docs]);

	const columns = [
		{
			name: 'name',
			label: 'File',
			options: {
				customBodyRender: (value) => {
					if (!value) return 'N/A'; // Check if value is defined
					const index = value.indexOf('_');
					if (index === -1) return value; // Handle cases where '_' is not found
					const name = value.substring(index + 1);
					return name;
				},
			},
		},
		{
			label: 'Download',
			name: 'name',
			options: {
				setCellProps: () => {
					return { style: { textAlign: 'center' } };
				},
				customBodyRender: (value) => {
					return <DownloadUrl name={value} />;
				},
			},
		},
	];

	return (
		<Dialog
			open={viewMessageModal}
			onClose={closeModal}
			disableAutoFocus
			fullWidth={true}
		>
			<DialogTitle style={{ textAlign: 'center', paddingBottom: 20 }}>
				Message
			</DialogTitle>
			<DialogContent>
				<Box>
					<Stack direction="column" spacing={2}>
						<Stack direction="row" spacing={5}>
							<Typography style={{ fontWeight: 'bold' }}>Date:</Typography>
							<Typography>
								{moment(currentMessage?.messsageDate).format('DD/MM/YYYY')}
							</Typography>
						</Stack>
						<Stack direction="row" spacing={3}>
							<Typography style={{ fontWeight: 'bold' }}>Subject:</Typography>
							<Typography>{currentMessage?.subject}</Typography>
						</Stack>
						<Stack direction="row" spacing={2}>
							<Typography style={{ fontWeight: 'bold' }}>Message:</Typography>
							<Typography>{currentMessage?.message}</Typography>
						</Stack>
						{currentMessage?.docs && currentMessage.docs.length > 0 && (
							<Grid container>
								<Grid item xs={12}>
									<MainBox
										sx={{
											boxShadow: (theme) => theme.shadows[4],
											borderRadius: 2,
											paddingX: 1.2,
											marginX: -1,
										}}
									>
										<ThemeProvider theme={getTheme(theme)}>
											<MUIDataTable
												columns={columns}
												title={'Attachments'}
												data={messageData || []}
												options={{
													download: false,
													print: false,
													viewColumns: false,
													pagination: false,
													search: false,
													filter: false,
													selectableRows: 'none',
												}}
											/>
										</ThemeProvider>
									</MainBox>
								</Grid>
							</Grid>
						)}
					</Stack>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default ViewMessage;
