import React from 'react';
import { CSVLink } from 'react-csv';
import Button from '@mui/material/Button';
import moment from 'moment';

const ExportCSV = ({ data }) => {
	// Get all keys from the data
	const allKeys = Array.from(new Set(data.flatMap(Object.keys)));

	// Generate dinamic headers
	const headers = allKeys.map((key) => ({
		label: key.charAt(0).toUpperCase() + key.slice(1),
		key: key,
	}));

	// Process data to handle arrays
	const processedData = data.map((row) => {
		const newRow = {};
		allKeys.forEach((key) => {
			if (Array.isArray(row[key])) {
				newRow[key] = row[key]
					.map((item) => item.note) // Extract only the 'note' field
					.filter(Boolean) // Remove undefined or null notes
					.join(', ');
			} else if (typeof row[key] === 'object' && row[key] !== null) {
				// Convert object to string
				newRow[key] = JSON.stringify(row[key]);
			} else {
				newRow[key] = row[key];
			}
		});
		return newRow;
	});

	const filename = `orderCards_${moment().format('DD/MM/YYYY')}.csv`;

	return (
		<CSVLink
			data={processedData}
			headers={headers}
			filename={filename}
			style={{ textDecoration: 'none' }}
		>
			<Button variant="contained" color="primary">
				Export CSV
			</Button>
		</CSVLink>
	);
};

export default ExportCSV;
