import {
	Table,
	TableCell,
	TableBody,
	TableHead,
	TableRow,
	Box,
	Paper,
	Typography,
	Tooltip,
	Fade,
	Stack,
} from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { numberWithCommas } from '../../../utils';
import { headerNames } from '../utils/constants';
import {
	Verified as VerifiedIcon,
	Cancel as CancelIcon,
} from '@mui/icons-material';
import StationDetailsCalculator from './StationDetailsCalculator';

const MuiBox = styled(Paper)(({ theme }) => ({
	boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
	overflow: 'auto',
	paddingBottom: '5px',
	maxHeight: '200px',
	width: '100%',
	table: {
		borderCollapse: 'collapse',
		borderSpacing: '0px',
		width: '100%',
	},
	'& .MuiTableCell-root': {
		borderBottom: '0px',
		'&.MuiTableCell-head': {
			padding: '0px',
		},
		'&.MuiTableCell-body': {
			padding: '2px 2px',
		},
		'&.MuiTableCell-footer': {
			padding: '5px 5px',
		},
	},
	thead: {
		'& tr > th': {
			minWidth: '20%',
			backgroundColor: '#1565C0',
			'& > p': {
				color: '#FFF',
				padding: '3px 5px',
				fontWeight: 'bold',
				fontSize: '14px',
				textTransform: 'capitalize',
			},
			':last-of-type > p': {
				textAlign: 'right',
				paddingRight: '5px',
			},
		},
	},

	'& tbody tr': {
		'&:first-of-type > td': {
			paddingTop: '6px !important',
			width: '200px',
			[theme.breakpoints.up('lg')]: {
				width: '200px',
			},
			[theme.breakpoints.down('lg')]: {
				width: '150px',
			},
		},
		'& > td:first-of-type': {
			minWidth: '20%',
			backgroundColor: '#fff',
		},
		'& > td.data': {
			minWidth: '20%',
			maxWidth: '100%',

			'& p': {
				color: '#000',
				textAlign: 'center',
				fontSize: '13px',
			},
			':last-of-type > p': {
				textAlign: 'right',
				paddingRight: '5px',
			},
		},
	},

	'tfoot > tr': {
		'& > td': {
			'& > p': {
				fontWeight: 'bold',
				textAlign: 'center',
				fontSize: '14px',
			},
			'& > p.footer-heading': {
				color: 'rgb(0, 0, 0, 80%)',
			},
			'& p.footer-result': {
				color: 'rgb(0, 0, 0, 60%)',
			},
		},
	},
}));

const StickyTableCell = styled(TableCell)(({ theme }) => ({
	cursor: 'default !important',
	'&.MuiTableCell-head': {
		left: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar + 2,
	},
	'&.MuiTableCell-body': {
		left: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar + 1,
	},
	'&.MuiTableCell-footer': {
		left: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar + 1,
		backgroundColor: '#fff',
	},
}));

const CustomToolTip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	zIndex: theme.zIndex.tooltip + 1,
	[`& .MuiTooltip-tooltip`]: {
		color: '#fff',
		backgroundColor: theme.palette.info.main,
		padding: '5px 10px',
	},
	[`& .MuiTooltip-arrow`]: {
		color: theme.palette.info.main,
		fontSize: 12,
	},
}));

const AQHLiveData = ({ getLiveTableData, selectedRowsAqh }) => {
	const [openStationDeal, setOpenStationDeal] = useState(false);
	const [stationDeal, setStationDeal] = useState(false);
	const initialQueryDetails = { title: '', value: '', name: '' };
	const [queryDetails, setQueryDetails] = useState(initialQueryDetails);
	const initialSelectAllDetails = {
		checked: false,
		value: '',
		checkedBy: '',
		total: 0,
	};
	const [selectAllDetails, setSelectAllDetails] = useState(
		initialSelectAllDetails
	);

	const activeDeal = (station) => {
		const str = 'endDateSchedule';
		const stationInfo = selectedRowsAqh?.find((s) => s['Station'] === station);
		if (
			stationInfo &&
			stationInfo.dealDetails[str] !== undefined &&
			stationInfo.dealDetails[str] !== 'N/A' &&
			stationInfo.dealDetails[str] !== 'n/a'
		) {
			const dealDate = new Date(stationInfo.dealDetails[str]);
			const today = new Date();
			// Set dates time to midnight
			dealDate.setHours(0, 0, 0, 0);
			today.setHours(0, 0, 0, 0);
			// Check if deal date is greater than or equal to today

			return dealDate >= today;
		}

		return false;
	};

	const showStationDetails = (station) => {
		const stationInfo = selectedRowsAqh?.find((s) => s['Station'] === station);
		setOpenStationDeal(true); //
		setStationDeal(true);
		setQueryDetails({
			name: 'Calculator',
			title: 'Station',
			stationInfoDeal: 'StationInfoDeal',
			detailsObj: stationInfo,
		});
	};

	return (
		<MuiBox elevation={4} sx={{ width: '100%' }}>
			<Table aria-label="simple table" stickyHeader sx={{ width: '10%' }}>
				<TableHead>
					<TableRow>
						<StickyTableCell align="center">
							<Typography fontWeight="bold" fontSize="13px">
								Station
							</Typography>
						</StickyTableCell>
						{headerNames.map(({ title }, idx) => {
							return (
								<TableCell key={title + idx} align="center" className={'header data'}>
									<Typography>{title}</Typography>
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>

				<TableBody>
					{!!Object.keys(getLiveTableData).length ? (
						Object.keys(getLiveTableData).map((key) => (
							<TableRow key={key}>
								<StickyTableCell align="center">
									<CustomToolTip
										arrow
										placement="right"
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 400 }}
										onClick={() => {
											showStationDetails(key.split('@')[0]);
										}}
										title={
											<Box sx={{ width: 'max-content' }}>
												<Typography fontSize={13}>
													DayPart : <b>{getLiveTableData[key].dayPartName}</b>
												</Typography>
												<Typography fontSize={13}>
													AQH : <b>{getLiveTableData[key].orignalValue}</b>
												</Typography>
												<Typography fontSize={13}>
													Minutes : <b>{getLiveTableData[key].noOfSpots}</b>
												</Typography>
												<Typography fontSize={13}>
													Gross Impressions : <b>{getLiveTableData[key].impressions}</b>
												</Typography>
												<Typography fontSize={13}>
													Gross : <b>{getLiveTableData[key].gross}</b>
												</Typography>
											</Box>
										}
									>
										<Stack alignItems="center" direction="row" spacing={1}>
											<>
												{activeDeal(key.split('@')[0]) === true ? (
													<VerifiedIcon fontSize="12px" color="success" />
												) : (
													<CancelIcon fontSize="12px" color="error" />
												)}
											</>
											<Typography fontWeight="bold" fontSize="13px">
												{key.split('@')[0]}
											</Typography>
										</Stack>
									</CustomToolTip>
								</StickyTableCell>

								{headerNames.map(({ keyName }, colIdx) => {
									return (
										<TableCell key={keyName + colIdx} className={'body data'}>
											<Typography>
												{keyName === 'gross'
													? '$' + numberWithCommas(getLiveTableData[key][keyName])
													: keyName === 'impressions'
													? numberWithCommas(getLiveTableData[key][keyName])
													: getLiveTableData[key][keyName]}
											</Typography>
										</TableCell>
									);
								})}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={headerNames.length + 1}>
								<Typography textAlign="center" fontWeight="bold" className="noData">
									No Live Data
								</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<StationDetailsCalculator
				openStationDeal={openStationDeal}
				setOpenDealModal={setOpenStationDeal}
				queryDealDetails={queryDetails}
				stationDeal={stationDeal}
				onClose={() => {
					setOpenStationDeal(false);
					stationDeal && setStationDeal(false);
					setQueryDetails(initialQueryDetails);
					setSelectAllDetails(initialSelectAllDetails);
				}}
				selectAllDetails={selectAllDetails}
				salesList={selectedRowsAqh}
			/>
		</MuiBox>
	);
};

export default AQHLiveData;
