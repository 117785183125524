import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Button, DialogActions, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { CloseOutlined } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SimpleModal({ open, setOpen, details, }) {
    const [scrollHere, setScroll] = React.useState(0)
    React.useEffect(() => {
        if (open) {
            const y = window.pageYOffset;
            setScroll(y)
        }
    }, [open])
    const handleClose = () => {
        setOpen(false)
        setTimeout(() => {
            window.scrollTo({ top: scrollHere, behavior: 'auto' })
        }, 1)
    }
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: "400px",
                }
            }}
        >
            <DialogTitle sx={{ pb: 0 }}>{details?.title}</DialogTitle>
            <IconButton color="primary" size='small'
                sx={{
                    width: "fit-content",
                    position: "absolute",
                    right: "3%",
                    top: "6%",
                }}
                onClick={handleClose}
            >
                <CloseOutlined />
            </IconButton>
            <DialogContent>
                <Box sx={{
                    my: 2,
                    "& >*": {
                        display: "flex",
                        gap: "10px",
                        margin: "5px 0px",
                        border: `1px solid rgb(0 0 0 / 10%)`,
                        background: "rgb(0 0 0 / 10%)",
                        borderRadius: "4px",
                        padding: "5px",
                    }
                }}>
                </Box>
            </DialogContent>

            <DialogActions>
                {/* <Button variant="outlined" onClick={handleClose}>Close</Button> */}
                {/* <Button
                        onClick={handleSave}
                        variant="contained"
                        startIcon={loading && <CircularProgress size={18} />}
                        disabled={loading || !name}
                    >
                        Save
                    </Button> */}
            </DialogActions>
        </Dialog>
    );
}
