import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {
	Button,
	Box,
	Paper,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { db } from '../../../firebase/config';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { getProposalOptions, validateOptionErrors } from '../utils/utils';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Alert from '@mui/material/Alert';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function RewardsModal({
	proposalId,
	rolloverCredits,
	setRolloverCredits,
	setOpenRolloverModal,
	openRolloverModal,
	setRolloverData,
	openPublishQuoteModal,
	openOverrideModal,
	setPublishProcess,
}) {
	const { enqueueSnackbar } = useSnackbar();
	const [proposalOptions, setProposalOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [openDate, setOpenDate] = useState(false);
	const [rolloverDate, setRolloverDate] = useState(null);
	const [rolloverValue, setRolloverValue] = useState(null);
	const proposalRef = collection(db, 'savedProposals');

	const handleChangeDate = (name, value) => {
		setRolloverDate(value);
	};

	const handleRolloverValue = (e) => {
		setRolloverValue(parseInt(e.target.value));
	};

	// close modal
	const handleCloseModal = (event, reason) => {
		// if click outside the modal or press esc key, don't close the modal
		if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
			return;
		}
		setOpenRolloverModal(false);
		setRolloverDate(null);
		setRolloverValue(null);
	};
	// get proposal options
	useEffect(() => {
		const getOptions = async () => {
			const options = await getProposalOptions(proposalId);
			if (options) {
				setProposalOptions(options);
			}
		};
		if (proposalId) {
			getOptions();
		}
	}, [proposalId]);

	const saveRollovers = async () => {
		setLoading(true);
		// validate if rollover credits exists
		const rolloverData = {
			credits:
				!rolloverValue && rolloverCredits !== null
					? rolloverCredits
					: rolloverValue,
			date: rolloverDate,
		};
		setRolloverData(rolloverData);
		try {
			// validate if vacations credits and proposalId exists
			if (proposalId) {
				// save rollover credits
				await updateDoc(doc(proposalRef, proposalId), {
					rolloverCredits: rolloverData,
				});
				enqueueSnackbar('Rollover credits saved', { variant: 'success' });
				if (proposalOptions.length > 0) {
					// validate if there are errors in the options
					const errors = await validateOptionErrors(proposalOptions);
					if (errors.includes(true)) {
						setLoading(false);
						setOpenRolloverModal(false);
						openOverrideModal(true);
					} else {
						setLoading(false);
						setOpenRolloverModal(false);
						openPublishQuoteModal(true);
					}
				}
			} else {
				setLoading(false);
				setPublishProcess(false);
				enqueueSnackbar('Rollover credits not saved', { variant: 'error' });
				setOpenRolloverModal(false);
			}
		} catch (error) {
			console.log(error.message);
			setLoading(false);
			setOpenRolloverModal(false);
			setPublishProcess(false);
			enqueueSnackbar('Rollover credits not saved', { variant: 'error' });
		}
	};

	return (
		<Dialog
			open={openRolloverModal}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseModal}
			aria-describedby="alert-dialog-slide-description"
			sx={{
				'& .MuiDialog-paper': {
					minWidth: '600px',
					minHeight: '200px',
				},
			}}
		>
			<DialogTitle sx={{ pb: 0 }}>Add Rollover Credits</DialogTitle>
			<DialogContent sx={{ alignItems: 'center' }}>
				{
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						sx={{ width: '100%' }}
					>
						<Paper sx={{ width: '100%', mb: 4, p: 3 }}>
							<Stack direction="row" spacing={1}>
								<Stack direction="column" spacing={1}>
									<Typography fontWeight={'bold'}>Credits</Typography>
									<div>
										<input
											style={{
												padding: 11,
												textAlign: 'center',
												width: 300,
												fontSize: 14,
												borderRadius: 5,
												border: '1px solid #ccc',
											}}
											type="number"
											defaultValue={rolloverCredits || ''}
											onChange={(e) => handleRolloverValue(e)}
										/>
									</div>
								</Stack>
								<Stack direction="column" spacing={1}>
									<Typography fontWeight={'bold'}>Date</Typography>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											open={openDate}
											onOpen={() => setOpenDate(true)}
											onClose={() => setOpenDate(false)}
											components={{ OpenPickerIcon: CalendarMonthIcon }}
											value={rolloverDate}
											onChange={(newValue) => handleChangeDate('rolloverDate', newValue)}
											renderInput={(params) => (
												<TextField
													disabled={true}
													{...params}
													size="small"
													sx={{ width: '180px' }}
													onClick={() => setOpenDate(true)}
												/>
											)}
										/>
									</LocalizationProvider>
								</Stack>
							</Stack>
							<Alert sx={{ mt: 2 }} severity="info">
								If there are rollover credit values, they are autopopulate
							</Alert>
						</Paper>
						<Button
							size="medium"
							variant="contained"
							color="success"
							onClick={saveRollovers}
							disabled={!rolloverDate}
						>
							{loading ? 'Loading...' : 'Submit'}
						</Button>
					</Box>
				}
			</DialogContent>
		</Dialog>
	);
}
