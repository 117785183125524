import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Link,
	Paper,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { storage } from '../../../../firebase/config';
import { ref } from 'firebase/storage';
import DownloadIcon from '@mui/icons-material/Download';
import { ThemeProvider, useTheme } from '@emotion/react';
import { getTheme } from '../../utils/themes';
import MUIDataTable from 'mui-datatables';
/**
 * Component used to download a file. Since a React hook can only be used within a functional component,
 * this component enables the use of a hook to obtain the file URL and download it efficiently.
 * @param {*} name: Name of file to download
 * @returns Componente
 */
const DownloadUrl = ({ name, slug }) => {
	const [query, setQuery] = useState();
	const [url] = useDownloadURL(query);
	useEffect(() => {
		if (name) {
			const notesStorageRef = ref(storage, `${slug}/${name}`);
			setQuery(notesStorageRef);
		}
	}, [name, slug]);
	return (
		<>
			{url ? (
				<Link href={url || ''} target="_blank" rel="noreferrer">
					<DownloadIcon></DownloadIcon>
				</Link>
			) : (
				''
			)}
		</>
	);
};
const NotesDetail = ({ open, closeModal, register, slug }) => {
	const theme = useTheme();
	const [files, setFiles] = useState([]);
	useEffect(() => {
		if (register && register.files) {
			const files = register.files.map((item) => {
				return {
					name: item,
				};
			});
			setFiles(files);
		}
		return () => {
			setFiles([]);
		};
	}, [register]);
	const columns = [
		{
			name: 'name',
			label: 'File',
			options: {
				customBodyRender: (value) => {
					const index = value.indexOf('_');
					const name = value.substring(index + 1);
					return name;
				},
			},
		},
		{
			label: 'Download',
			name: 'name',
			options: {
				customBodyRender: (value) => {
					return <DownloadUrl name={value} slug={slug}></DownloadUrl>;
				},
			},
		},
	];
	return (
		<Box>
			<Dialog
				open={open}
				disableAutoFocus
				onClose={closeModal}
				maxWidth={'sm'}
				fullWidth={true}
				PaperProps={{
					style: { pointerEvents: open ? 'auto' : 'none' },
				}}
			>
				<DialogTitle>Notes Detail</DialogTitle>
				<DialogContent>
					<Paper elevation={2} sx={{ paddingY: 2, paddingX: 1 }}>
						<Typography variant="h6">Description</Typography>
						{register && (
							<Typography sx={{ paddingX: 1 }}>{register.note}</Typography>
						)}
					</Paper>
					{files.length > 0 && (
						<ThemeProvider theme={getTheme(theme)}>
							<MUIDataTable
								columns={columns}
								data={files || []}
								title="File(s) :"
							></MUIDataTable>
						</ThemeProvider>
					)}
				</DialogContent>
			</Dialog>
		</Box>
	);
};
export default NotesDetail;
