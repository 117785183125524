/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import {
	Box,
	Typography,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	CircularProgress,
	Tooltip,
} from '@mui/material';
import { ThemeProvider, useTheme, styled } from '@mui/material/styles';
import { db } from '../../../firebase/config';
import {
	deleteDoc,
	doc,
	query,
	onSnapshot,
	where,
	collection,
} from 'firebase/firestore';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import { decompressDayParts } from '../../AQHCalculator/utils/utils';
import MUIDataTable from 'mui-datatables';
import { getTheme, defaultOptionsTable } from '../utils';
import CloneProposal from './CloneProposal';

const MainBox = styled(Box)(({ theme }) => ({
	margin: '20px auto',
	'.MuiPaper-root': {
		background: 'transparent',
		boxShadow: 'none',
		border: 0,
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Proposals = ({ uid }) => {
	const [openDlt, setOpenDlt] = useState(false);
	const [proposals, setProposals] = useState([]);
	const [loading, setLoading] = React.useState(false);
	const [proposalId, setProposalId] = useState('');
	const [cloneProposalData, setCloneProposalData] = useState('');
	const [openCloneProposal, setOpenCloneProposal] = useState(false);
	const proposalsRef = collection(db, 'savedProposals');
	const navigate = useNavigate();
	const theme = useTheme();

	useEffect(() => {
		const getProposals = async () => {
			const queryData = query(proposalsRef, where('userId', '==', uid));

			const unsub = onSnapshot(queryData, (snapshot) => {
				const result = snapshot.docs.map((doc) => {
					return { ...doc.data(), id: doc.id };
				});
				//get proposals and uncompress dayParts if needed compressed is true
				const uncompressedDayparts = result.map((proposal) => {
					// if proposal has compressed data then decompress it
					const aqhSavedOptions = proposal.aqhSavedOptions;
					const aqhSavedOptionsDecompressed = decompressDayParts(aqhSavedOptions);
					return {
						...proposal,
						aqhSavedOptions: aqhSavedOptionsDecompressed,
					};
				});
				setProposals(uncompressedDayparts);
			});

			return unsub;
		};
		getProposals();
	}, []);

	const handleDelete = async () => {
		setLoading(true);
		try {
			await deleteDoc(doc(db, 'savedProposals', proposalId));
			handleClose();
			setLoading(false);
		} catch (error) {
			handleClose();
			setLoading(false);
			console.log(error);
		}
	};

	const handleOpenDelete = (id) => {
		setOpenDlt(true);
		setProposalId(id);
	};

	const handleClose = () => {
		setOpenDlt(false);
		setProposalId('');
	};

	const sendToCalculator = (proposal) => {
		// get proposal id and get proposal from db and send it to calculator
		const proposalId = proposal;
		// get proposal from proposals array
		const proposalToSend = proposals.find(
			(proposal) => proposal.id === proposalId
		);
		// navigate to calculator and send proposal
		// if proposal has not been submmited then dont limit edits
		if (
			proposalToSend?.proposalState &&
			proposalToSend.proposalState === 'approved'
		) {
			navigate('/dashboard/aqhcalculator', {
				state: {
					data: proposalToSend,
					disabledOptions: true,
					limitEdits: proposalToSend.aqhSavedOptions.length,
				},
			});
		} else {
			navigate('/dashboard/aqhcalculator', {
				state: {
					data: proposalToSend,
				},
			});
		}
	};
	// get date from name in table
	const getDateFromName = (name) => {
		// regex to get date from name
		const regex = /(\d{1,2}\/\d{1,2}\/\d{4})/;
		const matchResult = name.match(regex);

		if (matchResult && matchResult.length > 0) {
			return matchResult[0];
		}

		return '';
	};
	// get revision number from name in table
	const getRevisionNumberFromName = (name) => {
		// divide name in array
		const elements = name.split('-');
		const revisionNumber = elements.pop();
		return revisionNumber;
	};

	const sendToCloneProposal = (proposalId) => {
		// get proposal from proposals array
		const proposalToSend = proposals.find(
			(proposal) => proposal.id === proposalId
		);
		setOpenCloneProposal(true);
		setCloneProposalData(proposalToSend);
	};

	const options = {
		...defaultOptionsTable,
		// sort by date
		sortOrder: {
			name: 'revisionNumber',
			direction: 'desc',
		},
	};

	const columns = [
		{
			name: 'name',
			label: 'Proposal Name',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => {
					return (
						<Typography
							onClick={() => {
								sendToCalculator(tableMeta.rowData[3]);
							}}
						>
							{value}
						</Typography>
					);
				},
			},
		},
		{
			name: 'tagProposal',
			label: 'Proposal Tag',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => {
					return (
						<Typography>
							{value && value.trim().length > 0 ? value : 'None'}
						</Typography>
					);
				},
			},
		},
		// get date from name
		{
			name: 'date',
			label: 'Date',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => {
					return (
						<Typography
							width={'70px'}
							onClick={() => sendToCalculator(tableMeta.rowData[3])}
						>
							{getDateFromName(tableMeta?.rowData?.[0])}
						</Typography>
					);
				},
				sortCompare: (order) => (rowA, rowB) => {
					const dateA = new Date(getDateFromName(rowA?.rowData?.[0])).getTime();
					const dateB = new Date(getDateFromName(rowB?.rowData?.[0])).getTime();

					return order === 'asc' ? dateA - dateB : dateB - dateA;
				},
			},
		},
		{
			name: 'id',
			label: 'Id',
			options: {
				filter: false,
				sort: false,
				display: false,
			},
		},
		{
			name: 'revisionNumber',
			label: 'Revision Number',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => {
					return (
						<Typography
							textAlign={'center'}
							width={'100px'}
							onClick={() => sendToCalculator(tableMeta.rowData[3])}
						>
							{getRevisionNumberFromName(tableMeta?.rowData?.[0])}
						</Typography>
					);
				},
				sortCompare: (order) => (rowA, rowB) => {
					const revisionNumberA = getRevisionNumberFromName(rowA?.rowData?.[0]);
					const revisionNumberB = getRevisionNumberFromName(rowB?.rowData?.[0]);

					return order === 'asc'
						? revisionNumberA - revisionNumberB
						: revisionNumberB - revisionNumberA;
				},
			},
		},

		{
			name: 'actions',
			label: 'Actions',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<Box width={'150px'}>
							<Tooltip title="Look" placement="top" arrow>
								<IconButton onClick={() => sendToCalculator(tableMeta.rowData[3])}>
									<DescriptionIcon color="success" />
								</IconButton>
							</Tooltip>
							<Tooltip title="Delete" placement="top" arrow>
								<IconButton onClick={() => handleOpenDelete(tableMeta.rowData[3])}>
									<DeleteIcon color="error" />
								</IconButton>
							</Tooltip>
							<Tooltip title="Clone" placement="top" arrow>
								<IconButton onClick={() => sendToCloneProposal(tableMeta.rowData[3])}>
									<FileCopyIcon color="info" />
								</IconButton>
							</Tooltip>
						</Box>
					);
				},
			},
		},
	];

	return (
		<>
			<MainBox
				sx={{
					boxShadow: (theme) => theme.shadows[4],
					borderRadius: 2,
					paddingX: 1.2,
					marginX: -1,
				}}
			>
				<ThemeProvider theme={getTheme(theme)}>
					<MUIDataTable
						title={'Proposals'}
						data={proposals || []}
						columns={columns}
						options={{
							...options,
							rowsPerPage: 25,
							rowsPerPageOptions: [25, 50, 100],
							selectToolbarPlacement: 'replace',
							responsive: 'standard',
							selectableRows: 'none',
						}}
					/>
				</ThemeProvider>
			</MainBox>
			<Dialog
				open={openDlt}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle>Delete Proposal</DialogTitle>
				<DialogContent>
					<Typography>Are you sure you want to delete this Proposal?</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleClose}>
						No
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={handleDelete}
						disabled={loading}
						startIcon={loading && <CircularProgress size={18} />}
					>
						YES
					</Button>
				</DialogActions>
			</Dialog>
			<CloneProposal
				openCloneProposal={openCloneProposal}
				setOpenCloneProposal={setOpenCloneProposal}
				cloneProposalData={cloneProposalData}
				setCloneProposalData={setCloneProposalData}
			/>
		</>
	);
};

export default Proposals;
