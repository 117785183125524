import React from 'react';

import { useTheme, ThemeProvider } from '@mui/material';
import { MainBox, getTheme, optionsTable } from '../utils/themes';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import useContracts from '../hooks/useContracts';
import Search from './Search';
const ListContracts = () => {
	const { contracts, setFilter } = useContracts();
	const columns = [
		{
			name: 'nContract',
			label: 'Contract #',
			search: true,
		},

		{
			name: 'startDate',
			label: 'Start Date',
			options: {
				filter: false,
				rost: false,
				customBodyRender: (value) => {
					const miliseconds = value.seconds * 1000;
					return moment(miliseconds).format('MM/DD/YYYY');
				},
			},
		},
		{
			name: 'endDate',
			label: 'endDate',
			options: {
				filter: false,
				rost: false,
				customBodyRender: (value) => {
					const miliseconds = value.seconds * 1000;
					return moment(miliseconds).format('MM/DD/YYYY');
				},
			},
		},
		{
			name: 'dealCalculation',
			label: 'Vacation Credits',
			options: {
				filter: false,
				rost: false,
				customBodyRender: (value) => {
					const credits = value.find((item) => item.name === 'Vacations');
					try {
						const cogsInteger = credits ? parseInt(credits.cogs) : 0;
						return cogsInteger || 0;
					} catch (error) {
						return 0;
					}
				},
			},
		},
		{
			name: 'dealCalculation',
			label: 'Visa Reward Cards',
			options: {
				filter: false,
				rost: false,
				customBodyRender: (value) => {
					const visa = value.find((item) => item.name === 'visa');
					try {
						const cogsInteger = visa ? parseInt(visa.cogs) : 0;
						return cogsInteger || 0;
					} catch (error) {
						return 0;
					}
				},
			},
		},
		{
			name: 'dealCalculation',
			label: 'Amazon Cards',
			options: {
				filter: false,
				rost: false,
				customBodyRender: (value) => {
					const amazon = value.find((item) => item.name === 'amazon');
					try {
						const cogsInteger = amazon ? parseInt(amazon.cogs) : 0;
						return cogsInteger || 0;
					} catch (error) {
						return 0;
					}
				},
			},
		},
		{
			name: 'dealCalculation',
			label: 'Bill Pay',
			options: {
				filter: false,
				rost: false,
				customBodyRender: (value) => {
					const billPayer = value.find((item) => item.name === 'bill payer');
					try {
						const cogsInteger = billPayer ? parseInt(billPayer.cogs) : 0;
						return cogsInteger || 0;
					} catch (error) {
						return 0;
					}
				},
			},
		},
	];
	const theme = useTheme();
	return (
		<MainBox
			sx={{
				boxShadow: (theme) => theme.shadows[4],
				borderRadius: 2,
				paddingX: 1.2,
				marginX: -1,
			}}
		>
			<Search setFilter={setFilter}></Search>
			<ThemeProvider theme={getTheme(theme)}>
				<MUIDataTable
					title={'Contracts'}
					data={contracts || []}
					columns={columns}
					options={{ ...optionsTable }}
				></MUIDataTable>
			</ThemeProvider>
		</MainBox>
	);
};
export default ListContracts;
