import { collectionGroup } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../../firebase/config';
import { useEffect, useState } from 'react';

/**
 * Hook used to return a list of active contract certificates.
 * @returns certificates; id of certificates
 */

const useCertificates = (contractsId) => {
	const [query, setQuery] = useState();
	const [certificates, setCertificates] = useState([]);
	const [value] = useCollection(query);
	useEffect(() => {
		if (value) {
			const data = value.docs
				.map((item) => {
					return {
						...item.data(),
						id: item.id,
						contractId: item.ref.parent.parent.id,
					};
				})
				.filter((item) => contractsId.includes(item.contractId));
			if (data && data.length > 0) {
				const parenthCertificates = data.filter((item) => !item.parenthCertificate);
				const mainCertificate = parenthCertificates.map((item) => {
					const secondaryCertificates = data.filter(
						(cert) => item.id === cert.parenthCertificate
					);
					return {
						...item,
						secondaryCertificates: secondaryCertificates,
					};
				});
				setCertificates(mainCertificate);
			} else {
				setCertificates([]);
			}
		}
	}, [contractsId, value]);

	useEffect(() => {
		if (contractsId && contractsId.length > 0) {
			setQuery(collectionGroup(db, 'certificates'));
		}
	}, [contractsId]);
	return { certificates };
};

export default useCertificates;
