import { createTheme } from '@mui/material/styles';

export const getLightTheme = (color) => {
    return createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: color,
                // main: "#00B3D1",
                // text: "#000000",
            },
            secondary: {
                main: "#f50057"
            }
        },
        components: {
            // Name of the component
            MuiButton: {
                styleOverrides: {
                    // Name of the slot
                    containedPrimary: {
                        color: "#fff",
                    },
                    contained: {
                        color: "#fff",
                        fontWeight: "normal",
                        textTransform: "capitalize"
                    },
                    root: {
                        // Some CSS
                    },
                },
            },
        },
    })
};