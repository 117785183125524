import React from 'react';
import { CSVLink } from 'react-csv';
import { Button } from '@mui/material';

const ExportClients = ({ data }) => {
	if (!data || !data.length) return null;
	const headers = Object.keys(data[0] || {}).map((key) => ({
		label: key
			.replace(/([A-Z])/g, ' $1')
			.replace(/^./, (str) => str.toUpperCase()),
		key: key,
	}));

	return (
		<CSVLink data={data} headers={headers} filename="Clients.csv">
			<Button variant="contained" color="primary">
				Export CSV
			</Button>
		</CSVLink>
	);
};

export default ExportClients;
