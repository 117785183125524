/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {
	CircularProgress,
	TextField,
	Box,
	IconButton,
	Grid,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import useAuthContext from '../../../hooks/useAuthContext';
import { useSnackbar } from 'notistack';
import {
	updateDoc,
	doc,
	serverTimestamp,
	collection,
	addDoc,
	getDoc,
} from 'firebase/firestore';
import { db } from '../../../firebase/config';
import {
	createTableDataObject,
	initialFieldsPublish,
	emailRegex,
	emailList,
} from '../utils/constants';
import {
	createHtmlOptionTable,
	dateTransform,
	getWeeksDays,
	validateOptionErrors,
	validateVacationCredits,
	validateAirports,
	validateRolloverCredits,
	decompressDayParts,
	proposalStatusReview,
} from '../utils/utils';
import { sendEmailMessage, sendTextMessage } from '../../../utils';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
	getLiveTableData,
	open,
	setOpen,
	openSaveProposal,
	setIsPublish,
	proposalId,
	setProposalId,
	messageProposalId,
	disabledByDates,
	aqhSavedOptions,
	handleCptRatioChange,
	userId,
	proposalState,
	setOpenOverride,
	userState,
	bridgeDealData,
	setPublishProcess,
	publishProcess,
	setOpenAirportsModal,
	dealTerms,
	setOpenRolloverModal,
}) {
	const { user } = useAuthContext();
	const { enqueueSnackbar } = useSnackbar();
	const [fields, setFields] = React.useState(initialFieldsPublish);
	const [noOfEditRows, setNoOfEditRows] = React.useState(0);
	const [loading, setLoading] = React.useState(false);
	const [loadingReview, setLoadingReview] = React.useState(false);
	const [openPdf, setOpenPdf] = React.useState(false);
	const [scroll, setScroll] = React.useState('paper');
	const [error, setError] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const [errorCc, setErrorCc] = React.useState(false);
	const [errorMessageCc, setErrorMessageCc] = React.useState('');
	// function to create tables for the email
	const createTablesEmail = async (optionsTableInfo, date) => {
		const tables = [];
		// generate the first table if the option has bridge deal
		if (bridgeDealData && bridgeDealData.dayParts && bridgeDealData.noOfWeeks) {
			const objectEntries = createTableDataObject(
				Object.values(bridgeDealData.dayParts),
				handleCptRatioChange,
				bridgeDealData?.noOfWeeks
			);
			const showDivider = false;
			const headerDays = getWeeksDays(objectEntries);
			const bridgeDealTable = await createHtmlOptionTable(
				headerDays,
				objectEntries,
				null,
				0,
				proposalId,
				false,
				dateTransform(date),
				showDivider,
				true,
				bridgeDealData
			);

			tables.push(bridgeDealTable);
		}

		// count number of options to send in createHtmlOptionTable
		const optionsLength = optionsTableInfo.length;
		// use uncompress data in the functions in utils to create html tables
		const tablesOptions = await Promise.all(
			optionsTableInfo.map(async (option, index) => {
				// generate the first table
				const objectEntries = createTableDataObject(
					Object.values(option.dayParts),
					handleCptRatioChange,
					option?.dates?.noOfWeeks
				);
				const headerDays = getWeeksDays(objectEntries);
				const showButton = true;

				// this is a divider for options when there is no bridge deal
				const showDivider = true;
				const secondTable = await createHtmlOptionTable(
					headerDays,
					objectEntries,
					option,
					index,
					proposalId,
					showButton,
					dateTransform(date),
					showDivider,
					false,
					null,
					optionsLength
				);

				return secondTable;
			})
		);
		const options = tables.concat(...tablesOptions);
		return options;
	};
	// validate email when rep tries to send proposal
	const validateEmails = (email) => {
		return emailRegex.test(email);
	};

	// validate string to emails separated by commas and spaces and validate each email
	const validateCcEmails = (emails) => {
		// generate an array of emails
		const ccEmails = emailList(emails);
		// validate each email
		const result = ccEmails.map((email) => emailRegex.test(email));
		// if one email is invalid, set error to true
		return result.includes(false);
	};

	const handleSave = async (e) => {
		e.preventDefault();
		setLoading(true);
		setPublishProcess(true);
		try {
			// validate emails and cc_emails after submit
			if (
				error ||
				!validateEmails(fields.to_email) ||
				(fields.cc_emails && (errorCc || validateCcEmails(fields.cc_emails)))
			) {
				setLoading(false);
				setPublishProcess(false);
				enqueueSnackbar('Invalid email', { variant: 'error' });
			} else {
				if (aqhSavedOptions && aqhSavedOptions.length > 0) {
					// uncompress dayParts if needed
					const aqhSavedOptionsUncompressed = decompressDayParts(aqhSavedOptions);
					const date = new Date();
					// generate tables for the email
					const options = await createTablesEmail(aqhSavedOptionsUncompressed, date);
					// update proposal with the email info
					await updateDoc(doc(db, 'savedProposals', proposalId), {
						submmitedEmailDate: date,
						uid: user.uid,
						from_name: userState.employee_name,
						employee_id: userState.employee_id,
						employee_email: user.email,
						employee_name: userState.employee_name,
						receiver_fname: fields.to_fname,
						receiver_lname: fields.to_lname,
						to_email: fields.to_email,
						contract_name: fields.contract_name,
						receiver_title: fields.title,
					});
					// verify if the cc_emails field is empty, if it is, set it to 'copy'
					const cc = fields.cc_emails || 'copy';
					// send email with proposal and options
					await sendEmailMessage(
						`Proposals`,
						' ',
						fields.to_email,
						userId,
						cc, // cc_emails
						null,
						null,
						null,
						null,
						options,
						fields.header,
						fields.footer,
						fields.subject,
						user.email
					);
					const payloadMessage = {
						proposalId,
						dealName: fields.contract_name,
						fromName: userState.employee_name,
						employeeEmail: user.email,
						employeeName: userState.employee_name,
						clientEmail: fields.to_email,
						clientName: `${fields.to_fname} ${fields.to_lname}`,
						repState: 'unread',
						state: 'sentClient',
						typeMessage: 'Sent to client',
						submittedDate: serverTimestamp(),
					};
					// reference to the messages collection
					const messagesRef = collection(db, 'reviewMessages');
					// send message to the rep
					await addDoc(messagesRef, payloadMessage);
					// data for the notification
					const payloadNoti = {
						title: `Proposal sent to client for review`,
						message: `${fields.contract_name} is sent to <b>${fields.to_email}</b>`,
						sender_id: user.uid,
						receiver_id: user.uid,
						seen: false,
						createdAt: serverTimestamp(),
					};
					// notification reference
					const notiRef = collection(db, 'notifications');
					// send notification to the rep
					await addDoc(notiRef, payloadNoti);
					// send text message to the rep
					const messageRep = `Proposal sent to client for review.\nContract Name: ${fields?.contract_name}\nClient Name: ${fields?.to_fname} ${fields?.to_lname}`;
					await sendTextMessage(messageRep, user.email);
					enqueueSnackbar('Email sent!', { variant: 'success' });
					localStorage.removeItem('filters');
					localStorage.removeItem('selectRows');
					localStorage.removeItem('aqhCalculator');
				}
				setOpen(false);
				setLoading(false);
				setPublishProcess(false);
			}
		} catch (error) {
			console.log(error);
			setLoading(false);
			setPublishProcess(false);
			enqueueSnackbar(error.message, { variant: 'error' });
			setOpen(false);
		}
	};

	React.useEffect(() => {
		let getData = {};
		if (Object.keys(getLiveTableData).length > 0) {
			Object.entries(getLiveTableData).forEach(([key, value]) => {
				let stationName = String(key).split('@')[0];
				getData[`${stationName}@${value.dayPartName}`] = value;
			});
			setNoOfEditRows(Object.keys(getData).length);
		}
	}, [getLiveTableData]);

	const handleFieldChange = (e) => {
		setFields((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};
	// handleBlur for to_email
	const handleBlur = (e) => {
		const { name, value } = e.target; // get name and value from the input
		if (name === 'to_email') {
			// validate email
			const isValidEmail = emailRegex.test(value);
			// if email is invalid, set error to true
			setError(!isValidEmail);
			if (!isValidEmail) {
				setErrorMessage('Please enter a valid email address.');
			} else {
				setErrorMessage('');
			}
		}
	};

	// handleBlur for cc_emails with multiple emails
	const handleBlurCcEmails = (e) => {
		const { name, value } = e.target; // get name and value from the input
		if (name === 'cc_emails') {
			// generate an array of emails
			const ccEmails = emailList(value);
			// validate each email
			const result = ccEmails.map((email) => emailRegex.test(email));
			// if one email is invalid, set error to true
			setErrorCc(result.includes(false));
			if (result.includes(false)) {
				setErrorMessageCc('Please make sure all email addresses are valid.');
			} else {
				setErrorMessageCc('');
			}
		}
	};

	const openNextModal = async () => {
		// prevent user to open save proposal modal again if proposalId exist
		if (proposalId) {
			setProposalId(proposalId);
			const proposalStatus = await proposalStatusReview(proposalId); // get proposal status
			// determinate if proposal has a status
			const actualProposalState =
				proposalStatus && proposalStatus !== 'unread' // if proposal has a status, use query status
					? proposalStatus // if proposal has no status, use proposalState
					: proposalState; // state from data in father component
			if (actualProposalState === 'approved') {
				// if manager accepted the proposal, user can publish quote
				setOpen(true);
			} else if (actualProposalState === 'denied') {
				// if manager denied the proposal show manager override modal again on publish quote button
				setOpenOverride(true);
			} else if (aqhSavedOptions && aqhSavedOptions.length > 0) {
				// determinate if saved options in the proposal has errors
				const result = await validateOptionErrors(aqhSavedOptions);
				const vacationsResult = await validateVacationCredits(aqhSavedOptions);
				// determitate if in the proposal has airports
				const airportsResult = await validateAirports(proposalId);
				// determitate if in the proposal has rollover credits
				const rolloverResult = await validateRolloverCredits(proposalId);
				// if one saved option in the proposal has vacations and errors, show airports modal
				if (
					vacationsResult.includes(true) &&
					result.includes(true) &&
					!airportsResult
				) {
					setOpenAirportsModal(true);
				} else if (!vacationsResult.includes(true) && result.includes(true)) {
					// If dealTerms is true, show Rollover modal
					if (dealTerms !== null) {
						// if one saved proposal has rollover credits, show publish quote modal
						if (rolloverResult === true) {
							setOpen(true);
						} else {
							setOpenRolloverModal(true);
						}
					} else {
						// if one saved option in the proposal has errors, show manager override modal
						setOpenOverride(true);
					}
				} else if (vacationsResult.includes(true) && !result.includes(true)) {
					// if one saved option in the proposal has vacations, show airports modal
					if (airportsResult === true) {
						setOpen(true);
					} else {
						setOpenAirportsModal(true);
					}
				} else if (
					vacationsResult.includes(true) &&
					result.includes(true) &&
					airportsResult
				) {
					setOpenOverride(true);
				} else {
					// If dealTerms is true, show Rollover modal
					if (dealTerms !== null) {
						// if one saved proposal has rollover credits, show publish quote modal
						if (rolloverResult === true) {
							setOpen(true);
						} else {
							setOpenRolloverModal(true);
						}
					} else {
						// open publish quote modal
						setOpen(true);
					}
				}
			}
		} else {
			// save proposal
			openSaveProposal(true);
		}
	};
	useEffect(() => {
		const createFormatName = async () => {
			// It is validated  if a contract name already exists, the proposal is created and the modal has been started.
			if (open && proposalId && !fields.contract_name) {
				const companyName = aqhSavedOptions[0].selectedRowsAqh[0].Ownername;
				const dma = aqhSavedOptions[0].selectedRowsAqh[0].NDMAName;
				const date = dateTransform(new Date());
				const userDoc = await getDoc(doc(db, 'users', userId));
				const proposal = await getDoc(doc(db, 'savedProposals', proposalId));
				const initials =
					userDoc.data()?.firstName?.charAt(0) + userDoc.data()?.lastName?.charAt(0);
				const revisionNumber = proposal.data().name.split('-').pop().trim();
				const formatName = `${companyName} - ${dma} - ${date} - ${initials} - ${revisionNumber}`;
				setFields({ ...fields, contract_name: formatName });
			}
		};
		createFormatName();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, proposalId]);
	// handle close publish quote modal
	const handleCloseModal = (event, reason) => {
		if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown'))
			return;
		setOpen(false);
	};
	// send email to rep with the preview of the email for the client
	const reviewEmail = async () => {
		setLoadingReview(true);
		try {
			if (aqhSavedOptions && aqhSavedOptions.length > 0) {
				// uncompress dayParts if needed
				const aqhSavedOptionsUncompressed = decompressDayParts(aqhSavedOptions);
				const date = new Date();
				// generate tables for the email
				const options = await createTablesEmail(aqhSavedOptionsUncompressed, date);
				// send email with proposal and options
				await sendEmailMessage(
					`Review Email`,
					'This is a preview of the email you will send to the client',
					user.email,
					userId,
					null,
					null,
					null,
					null,
					null,
					options,
					fields.header,
					fields.footer,
					fields.subject,
					user.email
				);
			}
			enqueueSnackbar('Sent review email', { variant: 'success' });
			setLoadingReview(false);
		} catch (error) {
			console.log(error);
			setLoadingReview(false);
			enqueueSnackbar(error.message, { variant: 'error' });
		}
	};

	return (
		<div>
			<Button
				className="publish"
				size="small"
				variant="contained"
				onClick={() => {
					setIsPublish(true);
					openNextModal();
					setPublishProcess(true);
				}}
				sx={{ backgroundColor: '#42A5F5' }}
				disabled={
					disabledByDates ||
					noOfEditRows === 0 ||
					messageProposalId !== null ||
					loading ||
					publishProcess
				}
				startIcon={(loading || publishProcess) && <CircularProgress size={18} />}
			>
				Publish Quote
			</Button>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				scroll={scroll}
				onClose={handleCloseModal}
				aria-describedby="alert-dialog-slide-description"
				sx={{
					'& .MuiDialog-paper': {
						minWidth: openPdf ? '95vw' : '340px',
						minHeight: openPdf && '97vh',
						backgroundColor: openPdf && 'initial',
					},
				}}
			>
				{openPdf ? (
					<Box>
						<Box display={'flex'}>
							<IconButton
								color="primary"
								sx={{
									ml: 'auto',
									bgcolor: '#fff',
									cursor: 'pointer',
									position: 'relative',
									'&:hover': {
										bgcolor: '#fffa',
									},
								}}
								onClick={() => {
									setLoading(false);
									setFields(initialFieldsPublish);
									setOpenPdf(false);
									setOpen(false);
								}}
							>
								<CloseOutlined />
							</IconButton>
						</Box>
					</Box>
				) : (
					<Box>
						<DialogTitle>Publish Quote</DialogTitle>
						<form onSubmit={handleSave} autoComplete="off">
							<DialogContent>
								<Box
									sx={{
										my: 2,
										'& >*': {
											my: 2,
										},
									}}
								>
									<TextField
										label={`Contract Name`}
										variant="outlined"
										size="small"
										name={'contract_name'}
										value={fields.contract_name}
										onChange={handleFieldChange}
										fullWidth
										required
										disabled={loading}
									/>
									<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
										<TextField
											label={`Receiver First Name`}
											variant="outlined"
											size="small"
											name={'to_fname'}
											value={fields.to_fname}
											onChange={handleFieldChange}
											disabled={loading}
											sx={{ width: '48%' }}
											required
										/>
										<TextField
											label={`Receiver Last Name`}
											variant="outlined"
											size="small"
											name={'to_lname'}
											value={fields.to_lname}
											onChange={handleFieldChange}
											disabled={loading}
											sx={{ width: '48%' }}
											required
										/>
									</Box>
									<TextField
										label={`Receiver Email`}
										variant="outlined"
										size="small"
										name={'to_email'}
										value={fields.to_email}
										onChange={handleFieldChange}
										disabled={loading}
										fullWidth
										onBlur={handleBlur}
										error={error}
										helperText={error ? errorMessage : ''}
										required
									/>
									<TextField
										label={`CC Emails`}
										variant="outlined"
										size="small"
										name={'cc_emails'}
										value={fields.cc_emails}
										onChange={handleFieldChange}
										disabled={loading}
										onBlur={handleBlurCcEmails}
										error={errorCc}
										helperText={errorCc ? errorMessageCc : ''}
										fullWidth
									/>
									<TextField
										label={`Receiver Title`}
										variant="outlined"
										size="small"
										name={'title'}
										value={fields.title}
										onChange={handleFieldChange}
										disabled={loading}
										fullWidth
										required
									/>
									<TextField
										label={`Custom Email Subject`}
										variant="outlined"
										size="small"
										name={'subject'}
										value={fields.subject}
										onChange={handleFieldChange}
										disabled={loading}
										fullWidth
									/>
									<TextField
										label={`Custom Email Header`}
										variant="outlined"
										multiline
										rows={4}
										size="small"
										name={'header'}
										value={fields.header}
										onChange={handleFieldChange}
										disabled={loading}
										fullWidth
									/>
									<TextField
										label={`Custom Email Footer`}
										variant="outlined"
										multiline
										rows={4}
										size="small"
										name={'footer'}
										value={fields.footer}
										onChange={handleFieldChange}
										disabled={loading}
										fullWidth
									/>
								</Box>
							</DialogContent>
							<DialogActions sx={{ pr: 3 }}>
								<Grid container justifyContent="space-between">
									<Grid item sx={{ pl: 2 }}>
										<Button
											variant="contained"
											color="success"
											sx={{ mr: 1 }}
											type="button"
											disabled={loadingReview}
											startIcon={loadingReview && <CircularProgress size={18} />}
											onClick={() => {
												reviewEmail();
											}}
										>
											Review Email
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant="outlined"
											sx={{ mr: 1 }}
											type="button"
											disabled={loading}
											onClick={() => {
												setLoading(false);
												setFields(initialFieldsPublish);
												setOpen(false);
												setPublishProcess(false);
											}}
										>
											Close
										</Button>
										<Button
											variant="contained"
											startIcon={loading && <CircularProgress size={18} />}
											disabled={loading}
											type={'submit'}
										>
											{loading ? 'Loading...' : 'Publish'}
										</Button>
									</Grid>
								</Grid>
							</DialogActions>
						</form>
					</Box>
				)}
			</Dialog>
		</div>
	);
}
