/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	doc,
	getDoc,
	collection,
	getDocs,
	updateDoc,
} from 'firebase/firestore';
import { styled } from '@mui/material/styles';
import {
	Container,
	Typography,
	CircularProgress,
	Stack,
	Button,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/system';
import { db } from '../../firebase/config';
import { useSnackbar } from 'notistack';
import {
	createHtmlOptionTable,
	dateTransform,
	getWeeksDays,
} from '../AQHCalculator/utils/utils';
import { createTableDataObject } from '../AQHCalculator/utils/constants';
import Loading from '../../components/Loading';
import { getValuesTable } from '../Pdf/utils/utils';
import { addDashSimulcast } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { sendEmailMessage } from '../../utils';
import { decompressDayParts } from '../AQHCalculator/utils/utils';
import axios from 'axios';
import { baseURL } from '../../utils';
import Logo from '../../assets/images/sunfunmediaLogo.png';

const MainContainer = styled(Container)(() => ({
	margin: 'auto',
	textAlign: 'center',
	padding: '2rem 0',
	'&>form>div': {
		margin: '1rem 0!important',
	},
}));

const OptionConfirmation = () => {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const params = useParams();
	const { proposalId, optionId } = params;
	const [loading, setLoading] = useState(null);
	const [userState, setUserState] = useState({});
	const [option, setOption] = useState({});
	const [cptRatio, handleCptRatioChange] = useState([1, 685, 4]);
	const [htmlData, setHtmlData] = useState('');
	const [dataToSave, setDataToSave] = React.useState({});
	const [liveTableData, setLiveTableData] = useState([]);
	const [simulcast, setSimulcast] = useState([]);
	const [vacationCredits, setVacationCredits] = useState([]);
	const [rewards, setRewards] = useState([]);
	const [agreementNumber, setAgreementNumber] = useState(null);
	const [mainObj, setMainObj] = useState(null);
	const [proposal, setProposal] = useState(null);
	const [proposalData, setProposalData] = useState(null);
	const [remainingTotal, setReamainingTotal] = useState(0);
	const [wasAccepted, setWasAccepted] = useState(false);
	const [contractSignUrl, setContractSignUrl] = useState(null);
	const [readyToSend, setReadyToSend] = useState(false);
	const [contractCreated, setContractCreated] = useState(false);
	const [contractUploaded, setContractUploaded] = useState(false);
	const [optionAccepted, setOptionAccepted] = useState(false);
	const [generateContract, setGenerateContract] = useState(false);

	const getUserData = async (userId) => {
		const userData = await getDoc(doc(db, 'users', userId));
		if (userData.exists()) {
			setUserState({
				...userData.data(),
				uid: userId,
				employee_id: userData.data()?.employeeId,
				employee_name: userData.data()?.firstName + ' ' + userData.data()?.lastName,
			});
		}
	};

	const verifyOptionAccepted = (proposalOptions) => {
		const alreadyAccepted = proposalOptions.some(
			(option) => option.accepted === true
		);
		if (alreadyAccepted) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		const getData = async () => {
			const ref = doc(db, 'savedProposals', proposalId);
			const proposalDocument = await getDoc(ref);
			if (
				proposalDocument.exists() &&
				proposalDocument.data() &&
				proposalDocument.data()?.aqhSavedOptions &&
				proposalDocument.data()?.aqhSavedOptions.length > 0
			) {
				// uncompress dayParts if needed
				const aqhSavedOptionsUncompressed = decompressDayParts(
					proposalDocument.data()?.aqhSavedOptions
				);
				try {
					const optionIndex = aqhSavedOptionsUncompressed.findIndex(
						(obj) => obj.id === optionId
					);
					const optionData = aqhSavedOptionsUncompressed[optionIndex];

					const objectEntries = createTableDataObject(
						Object.values(optionData?.dayParts),
						handleCptRatioChange,
						optionData.dates.noOfWeeks
					);
					const headerDays = getWeeksDays(objectEntries);
					const showHtmlButton = false;
					// in this case we dont want to show the divider
					const showDivider = false;
					const emailDate =
						proposalDocument.data()?.submmitedEmailDate || new Date();
					//send data to html template
					let table = await createHtmlOptionTable(
						headerDays,
						objectEntries,
						optionData,
						optionIndex,
						proposalId,
						showHtmlButton,
						dateTransform(emailDate),
						showDivider,
						false,
						null
					);
					if (
						proposalDocument.data()?.bridgeDeal &&
						proposalDocument.data()?.dayParts &&
						proposalDocument.data()?.noOfWeeks
					) {
						const bridgeDealEntries = createTableDataObject(
							Object.values(proposalDocument.data()?.bridgeDeal?.dayParts),
							handleCptRatioChange,
							proposalDocument.data()?.bridgeDeal?.noOfWeeks
						);
						const bridgeDealTable = await createHtmlOptionTable(
							headerDays,
							bridgeDealEntries,
							null,
							optionIndex,
							proposalId,
							false,
							dateTransform(emailDate),
							false,
							true,
							proposalDocument.data()?.bridgeDeal
						);
						table = table + bridgeDealTable;
					}

					const newObject = getValuesTable(objectEntries);

					setProposal(proposalDocument.data());
					setHtmlData(table);
					setOption(optionData);
					setLiveTableData(objectEntries);
					setDataToSave(newObject);
					separateSimulcast(optionData);
					setVacationCredits(optionData?.vacationCredits || []);
					setRewards(optionData?.rewards || []);
					getReamaindTotal(objectEntries, optionData.allInputs);
					await getUserData(proposalDocument.data()?.userId);

					const dataToSend = {
						uid: proposalDocument.data()?.uid,
						from_name: proposalDocument.data()?.from_name,
						employee_id: proposalDocument.data()?.employee_id,
						employee_email: proposalDocument.data()?.employee_email,
						employee_name: proposalDocument.data()?.employee_name,
						receiver_fname: proposalDocument.data()?.receiver_fname,
						receiver_lname: proposalDocument.data()?.receiver_lname,
						to_email: proposalDocument.data()?.to_email,
					};

					setProposalData(dataToSend);
					setReadyToSend(
						optionData?.accepted && optionData.accepted === true ? false : true
					);
				} catch (error) {
					console.log(error.message);
				}
			}
		};
		getData();
	}, [proposalId, optionId]);

	const getReamaindTotal = (liveTableData, allInputs) => {
		let total = [];
		Object.keys(liveTableData).filter((key) =>
			total.push(Number(liveTableData[key].gross))
		);
		total = total.reduce((a, b) => a + b, 0);
		const grossTotal = Number(total).toFixed(2);

		let sumOfRevenues = allInputs.reduce(
			(n, { revenue }) => n + Number(revenue),
			0
		);
		const reaminging = Number(grossTotal - sumOfRevenues).toFixed(2);

		setReamainingTotal(reaminging);
	};
	useEffect(() => {
		const getAgreementNumber = async () => {
			const colRef = collection(db, 'agreementNumber');
			const docs = await getDocs(colRef);
			const result = [];
			docs.forEach((doc) => {
				result.push(doc.data());
			});
			setAgreementNumber(result[0]?.current);
		};
		getAgreementNumber();
	}, []);

	const separateSimulcast = (data) => {
		const simulcasts = data.selectedRowsAqh.map((row) =>
			addDashSimulcast(row?.Simulcast)
		);
		let simulcastArr = [];
		simulcasts.map((simulcast) => {
			if (!simulcast) {
				return [];
			}
			if (simulcast.length > 7) {
				simulcastArr.push(...simulcast.split('/'));
			} else {
				simulcastArr.push(simulcast);
			}
		});
		const simulcastObj = simulcastArr.map((s) => s).join(',');
		setSimulcast(simulcastObj);
	};

	const updateProposalOption = async () => {
		// verify if the option was accepted
		const alreadyAccepted = verifyOptionAccepted(proposal?.aqhSavedOptions);
		// if the option was already accepted, do not update the proposal
		if (alreadyAccepted) {
			return false;
		}

		const proposalOptionIndex = proposal?.aqhSavedOptions.findIndex(
			(obj) => obj.id === option.id
		);

		const updatedOptions = proposal?.aqhSavedOptions.map((option, index) => {
			if (index === proposalOptionIndex) {
				return { ...option, accepted: true };
			} else {
				return option;
			}
		});

		const docRef = doc(db, 'savedProposals', proposalId);
		await updateDoc(docRef, {
			aqhSavedOptions: updatedOptions,
		});
		return true;
	};

	const handleSend = async () => {
		setLoading(true);
		setGenerateContract(true);
		try {
			let mainObj = {
				contract_name: proposal.contract_name,
				...dataToSave,
				...userState,
				startDate: option.dates.startDate,
				endDate: option.dates.endDate,
				noOfWeeks: option.dates.noOfWeeks,
				dealCalculations: option.allInputs,
				remainingTotal,
				cpt: option.cpt,
				grossTotal: option.gross,
				sumOfRatio: option.ratio,
				getLiveTableData: liveTableData,
				simulcast,
				selectedRowsAqh: option.selectedRowsAqh,
				grossWeekly: option.grossWeeklyImpressions,
				vacationCredits: vacationCredits ? vacationCredits : '',
				proposalId,
				rewards: rewards ? rewards : '',
				agreementNumber: agreementNumber || '',
				accepted: true,
				bridgeDeal: proposal?.bridgeDeal ? proposal?.bridgeDeal : null,
				airports: proposal?.airports || null,
				rolloverCredits: proposal?.rolloverCredits || null,
				receiver_title: proposal?.receiver_title,
			};
			setMainObj(mainObj);
			if (
				readyToSend &&
				proposalData !== null &&
				agreementNumber !== null &&
				userState &&
				proposal &&
				option &&
				!option?.accepted
			) {
				// update proposal option
				const isUpdateOption = await updateProposalOption();
				// if any option was accepted, do not update the proposal
				if (!isUpdateOption) {
					setLoading(false);
					return;
				}
				// send rep option accepted to email
				await sendEmailMessage(
					'Proposal Option Accepted',
					`${proposal?.receiver_fname} ${proposal?.receiver_lname} accepted this option`,
					proposal?.employee_email,
					proposal?.uid,
					null,
					null,
					null,
					null,
					htmlData
				);
				// send to endpoint to create contract
				const data = {
					mainObj: { ...mainObj },
					user: { ...userState },
					proposalData: { ...proposalData },
				};
				console.log('update proposal option');
				// send to endpoint to create contract
				const response = await axios.post(baseURL + '/generatePDF', data);
				// // handle response
				if (response.status === 200) {
					setOption(response.data.option);
					setWasAccepted(true);
					setContractCreated(true);
					setContractUploaded(true);
					setContractSignUrl(response.data.contractSignURL);
					setLoading(false);
				} else {
					enqueueSnackbar('Something went wrong', { variant: 'error' });
					setLoading(false);
				}
			} else if (option?.accepted) {
				setWasAccepted(true);
			}
		} catch (err) {
			enqueueSnackbar(err.message, { variant: 'error' });
			console.log(err);
			setLoading(false);
		}
	};

	// verify if the option was accepted
	useEffect(() => {
		const verifyAccepted = () => {
			const alreadyAccepted = verifyOptionAccepted(proposal?.aqhSavedOptions);
			if (alreadyAccepted || option?.accepted === true) {
				setWasAccepted(true);
			}
		};
		if (option && proposal) {
			verifyAccepted();
		}
	}, [option]);

	// send to sign when proposal is accepted
	useEffect(() => {
		if (
			wasAccepted &&
			contractSignUrl &&
			contractSignUrl !== null &&
			contractCreated &&
			contractCreated === true &&
			contractUploaded &&
			contractUploaded === true
		) {
			// if option was accepted and contract was created and uploaded
			// redirect to sign url
			window.location.assign(contractSignUrl);
			setContractCreated(false);
			setContractUploaded(false);
		} else if (
			// if the option was accepted and the contract was not created and loaded
			// show the option accepted message
			wasAccepted &&
			!contractSignUrl &&
			!contractCreated &&
			!contractUploaded &&
			!generateContract
		) {
			setOptionAccepted(true);
			// if contract was created send to looks good page
			const handleUrl = `${window.location.origin}/${proposalId}/${proposal.to_email}/${proposal.emailId}`;
			if (proposal.emailId) {
				window.location.href = handleUrl;
			}
		}
	}, [wasAccepted, contractSignUrl, contractCreated, contractUploaded]);

	// If the data of the accepted option is obtained, the process continues.
	// otherwise it shows the loading
	if (!htmlData) return <Loading />;

	if (optionAccepted) {
		return (
			<Box>
				<Typography
					variant="body1"
					sx={{ fontWeight: 'bold' }}
					mt={3}
					textAlign={'center'}
				>
					Proposal accepted, wait while being redirected.
				</Typography>
			</Box>
		);
	}

	return (
		<Box sx={{ display: 'flex', height: '100%', position: 'relative' }}>
			<MainContainer>
				{generateContract && generateContract === true ? (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						minHeight="100vh"
					>
						<Stack spacing={2} direction="column" alignItems="center">
							<Stack spacing={4} direction="row" alignItems="center">
								<Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
									Please wait, while we generate your contract and you are redirected to
									the e-sign process.
								</Typography>
							</Stack>
							<Stack spacing={2} direction="row" alignItems="center">
								<CheckCircleIcon color="success" size={18} /> Your selected option was
								processed
							</Stack>
							{!contractCreated ? (
								<Stack spacing={2} direction="row" alignItems="center">
									<CircularProgress size={18} /> Your contract is being created
								</Stack>
							) : (
								<Stack spacing={2} direction="row" alignItems="center">
									<CheckCircleIcon color="success" size={18} /> Your contract was created
								</Stack>
							)}
							{!contractUploaded ? (
								<Stack spacing={2} direction="row" alignItems="center">
									<CircularProgress size={18} /> Your contract is being uploaded
								</Stack>
							) : (
								<Stack spacing={2} direction="row" alignItems="center">
									<CheckCircleIcon color="success" size={18} /> Your contract was
									uploaded
								</Stack>
							)}
							{!contractSignUrl ? (
								<Stack spacing={2} direction="row" alignItems="center">
									<CircularProgress size={18} /> Your contract is being generated
								</Stack>
							) : (
								<Stack spacing={2} direction="row" alignItems="center">
									<CheckCircleIcon color="success" size={18} /> Your contract was sent
								</Stack>
							)}
						</Stack>
					</Box>
				) : (
					<Box>
						<Box mb={2}>
							<img src={Logo} alt="Logo" />
						</Box>
						<Typography variant="h5" gutterBottom>
							Click button to confirm
						</Typography>

						<Button
							onClick={handleSend}
							variant="contained"
							startIcon={loading && <CircularProgress size={18} />}
							disabled={loading}
							type={'submit'}
							sx={{ px: 2 }}
						>
							Confirm
						</Button>

						<div dangerouslySetInnerHTML={{ __html: htmlData }}></div>

						<Typography variant="h5" gutterBottom>
							Click button to confirm
						</Typography>

						<Button
							onClick={handleSend}
							variant="contained"
							startIcon={loading && <CircularProgress size={18} />}
							disabled={loading}
							type={'submit'}
							sx={{ px: 2 }}
						>
							Confirm
						</Button>
					</Box>
				)}
			</MainContainer>
		</Box>
	);
};

export default OptionConfirmation;
