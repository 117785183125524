import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogTitle,
	Fade,
	DialogContent,
	IconButton,
} from '@mui/material';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Fade direction="up" ref={ref} {...props} />;
});

export default function DeleteModal({
	openDeleteOption,
	setDeleteOption,
	optionId,
	proposalId,
	aqhSavedOptions,
	setAqhSavedOptions,
	setEditOptionId,
	setProposalOptionIndex,
}) {
	const handleClose = () => setDeleteOption(false);
	const [loading, setLoading] = useState(false);

	const handleDelete = async () => {
		setLoading(true);
		try {
			if (optionId && proposalId) {
				const proposalRef = doc(db, 'savedProposals', proposalId);

				const updatedAqhSavedOptions = aqhSavedOptions.filter(
					(obj) => obj.id !== optionId
				);

				await updateDoc(proposalRef, {
					aqhSavedOptions: updatedAqhSavedOptions ? updatedAqhSavedOptions : [],
				});
				setAqhSavedOptions(updatedAqhSavedOptions);
			} else {
				await deleteDoc(doc(db, 'aqhSavedOptions', optionId));
			}
		} catch (error) {
			console.log(error);
		}
		setEditOptionId('');
		setProposalOptionIndex(-1);
		setLoading(false);
		handleClose();
	};

	const paperProps = {
		sx: {
			boxShadow:
				'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		},
	};
	const backDropProps = { sx: { backgroundColor: 'rgb(0 0 0 / 10%)' } };
	return (
		<div>
			<Dialog
				open={!!openDeleteOption}
				TransitionComponent={Transition}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
				sx={{
					'& .MuiDialog-paper': {
						minWidth: '300px',
					},
				}}
				PaperProps={paperProps}
				BackdropProps={backDropProps}
			>
				<DialogTitle sx={{ userSelect: 'none' }}>Delete Option</DialogTitle>
				<IconButton
					color="primary"
					size="small"
					sx={{
						width: 'fit-content',
						position: 'absolute',
						right: '3%',
						top: '3.5%',
					}}
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<Typography>Are you sure you want to delete this option?</Typography>
				</DialogContent>

				<DialogActions>
					<Button variant="outlined" color="error" onClick={handleClose}>
						No
					</Button>
					<Button
						onClick={handleDelete}
						variant="contained"
						color="error"
						startIcon={loading && <CircularProgress color="error" size={18} />}
						disabled={loading}
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
