import React, { useEffect, useState } from 'react';
import useNotes from '../../hooks/useNotes';
import {
	CustomBadge,
	MainBox,
	getTheme,
	optionsTable,
} from '../../utils/themes';
import { ThemeProvider, useTheme } from '@emotion/react';
import MUIDataTable from 'mui-datatables';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import moment from 'moment';
import { Grid } from '@mui/material';
import HistoryNotes from './HistoryNotes';
import useModal from '../../hooks/useModal';
import { Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ItemsDetails from './ItemsDetails';
import { useRoles } from '../../hooks/useRoles';

const ListItems = ({ contractsId, contracts }) => {
	const [data, setData] = useState([]);
	const { notes } = useNotes(contractsId);
	const theme = useTheme();
	const { closeModal, openModal, status } = useModal();
	const {
		closeModal: closeItemsDetails,
		openModal: openItemsDetails,
		status: modalItemsDetails,
	} = useModal();
	const [item, setItem] = useState();
	const { isBillPayManager } = useRoles();

	useEffect(() => {
		if (notes && notes.length > 0) {
			setData(notes);
		} else {
			setData([]);
		}
	}, [notes]);

	const columns = [
		{
			name: 'contractId',
			label: 'Main Station',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => {
					if (contracts && Array.isArray(contracts)) {
						const ctr = contracts.find((item) => item.id === value);

						if (
							ctr &&
							ctr?.contractsInfo?.mainObj?.selectedRowsAqh &&
							Array.isArray(ctr?.contractsInfo?.mainObj?.selectedRowsAqh) &&
							ctr?.contractsInfo?.mainObj?.selectedRowsAqh.length > 0
						) {
							const mainStation = ctr.contractsInfo.mainObj.selectedRowsAqh[0].Station;
							return mainStation;
						}
					}
					return 'Not Main Station';
				},
			},
		},
		{
			label: 'Item',
			name: 'item',
		},
		{
			label: 'Order Date',
			name: 'date',
			options: {
				customBodyRender: (value) => {
					return moment(value).format('MM/DD/YYYY');
				},
			},
		},
		{
			label: 'Cost',
			name: 'cost',
		},
		{
			label: 'Notes',
			name: 'description',
			options: {
				customBodyRender: (value) => {
					// return <Box width={800}>{value}</Box>;
					return <div style={{ textAlign: 'start' }}>{value}</div>;
				},
			},
		},
		{
			label: 'Receipt & Notes',
			name: ' ',
			options: {
				customBodyRender: (_value, tableMeta) => {
					let count = 0;

					const rowIndex = tableMeta.rowIndex;
					const value = data[rowIndex];
					if (value.history && value.history.length > 0) {
						const filter = value.history.filter(
							(item) => item.sender !== 'manager' && !item.seen
						);
						count = filter.length;
					}
					return (
						<div>
							<Grid container spacing={2} justifyContent={'center'}>
								<Grid item>
									<InfoIcon
										sx={{ cursor: 'pointer' }}
										onClick={() => {
											setItem(value);
											openItemsDetails();
										}}
									></InfoIcon>
								</Grid>
								{isBillPayManager && (
									<Grid item>
										<Box>
											<CustomBadge badgeContent={count} color="secondary" max={99}>
												<SpeakerNotesIcon
													sx={{ cursor: 'pointer' }}
													onClick={() => {
														setItem(value);
														openModal();
													}}
												></SpeakerNotesIcon>
											</CustomBadge>
										</Box>
									</Grid>
								)}
							</Grid>
						</div>
					);
				},
			},
		},
		{
			label: 'Order By',
			name: 'user',
			options: {
				customBodyRender: (value) => {
					return value.email;
				},
			},
		},
	];
	return (
		<MainBox
			sx={{
				boxShadow: (theme) => theme.shadows[4],
				borderRadius: 2,
				paddingX: 1.2,
				marginX: -1,
			}}
		>
			<ThemeProvider theme={getTheme(theme)}>
				<MUIDataTable
					title={'Bill Pay Log'}
					data={data || []}
					columns={columns}
					options={{ ...optionsTable }}
				></MUIDataTable>
			</ThemeProvider>
			<HistoryNotes
				itemData={item}
				closeModal={closeModal}
				isBillPayManager={isBillPayManager}
				open={status}
			></HistoryNotes>
			{item && (
				<ItemsDetails
					itemId={item.id}
					isBillPayManager={isBillPayManager}
					closeModal={closeItemsDetails}
					statusModal={modalItemsDetails}
					contractId={item.contractId}
				></ItemsDetails>
			)}
		</MainBox>
	);
};
export default ListItems;
