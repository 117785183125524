/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { IconButton, Typography, Divider, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { CloseOutlined } from '@mui/icons-material';
import {
	pastDealTitles,
	dealProps,
	firebasekeysTitles,
	stationTitles,
} from '../utils/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
	openStationDeal,
	onClose,
	queryDealDetails,
	stationDeal,
}) {
	let { name, stationInfoDeal, title, detailsObj } = queryDealDetails;

	const initialProperties = {
		Calculator: dealProps,
	};
	const stationProperties = {
		Station: stationTitles,
	};
	const [properties, setProperties] = useState(initialProperties);
	const [stationProps, setStationProps] = useState(stationProperties);
	const [fetchDetails, setFetchDetails] = React.useState({});
	const [scrollHere, setScroll] = React.useState(0);

	useEffect(() => {
		const getData = () => {
			let { GM, GM2, GM3, GM5, GM6, GSM, GSM2, GSM3, GSM5, GSM6, dealDetails } =
				detailsObj;
			let gm = GM5 || '-';
			let gmName = GM || GM2 || GM3 ? `${GM} ${GM2} ${GM3}` : '-';
			let gmEmail = GM6 || '-';
			let gsm = GSM5 || '-';
			let gsmName = GSM || GSM2 || GSM3 ? `${GSM} ${GSM2} ${GSM3}` : '-';
			let gsmEmail = GSM6 || '-';
			let previousDealNumber = dealDetails?.['Pevious    Deal #'] || '';
			let previousDeal = dealDetails?.['Previous $'] || '$ 0';
			let previousCPT = dealDetails?.['Previous CPT'] || '$ 0';
			let previousRatio = dealDetails?.['Previous Ratio'] || '';
			let previousNotes = dealDetails?.['Notes'] || '';
			let bonus = dealDetails?.['Bonus'] || '';
			let commission = dealDetails?.['Commission'] || '';
			let commissionDate = dealDetails?.['COMMISSION Date'] || '';
			let city = dealDetails?.['City'] || '';
			let company = dealDetails?.['Company'] || '';
			let saleDate = dealDetails?.['SALE         Date'] || '';
			let renewalNewDate = dealDetails?.['Renewal    due date'] || '';

			let stationObj = {
				...stationProps['Station'],
				gm,
				gmName,
				gmEmail,
				gsm,
				gsmName,
				gsmEmail,
			};
			let propsObj = {
				...properties[name],
				previousDealNumber,
				previousDeal,
				previousCPT,
				previousRatio,
				previousNotes,
				bonus,
				commission,
				commissionDate,
				city,
				company,
				saleDate,
				renewalNewDate,
			};

			let stationInfo = { ...stationProps, Station: { ...stationObj } };
			let dealInfo = { ...properties, [name]: { ...propsObj } };

			setStationProps(stationInfoDeal ? stationInfo : {});
			setProperties(stationInfoDeal ? dealInfo : {});
		};
		openStationDeal && stationDeal && getData();
	}, [stationDeal, openStationDeal]);

	useEffect(() => {
		if (openStationDeal) {
			const y = window.pageYOffset;
			setScroll(y);
		}
	}, [openStationDeal]);

	const handleCloseDeal = () => {
		setFetchDetails({});
		setProperties(initialProperties);
		setStationProps(stationProperties);
		onClose();
		setTimeout(() => {
			window.scrollTo({ top: scrollHere, behavior: 'auto' });
		}, 1);
	};
	return (
		<Dialog
			open={openStationDeal}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseDeal}
			aria-describedby="alert-dialog-slide-description"
			sx={{
				'& .MuiDialog-paper': {
					minWidth: '800px',
				},
			}}
		>
			<DialogTitle sx={{ pb: 0 }}>{title} Details</DialogTitle>

			<IconButton
				color="primary"
				size="small"
				sx={{
					width: 'fit-content',
					position: 'absolute',
					right: '3%',
					top: '3.5%',
				}}
				onClick={handleCloseDeal}
			>
				<CloseOutlined />
			</IconButton>
			<DialogContent>
				{stationInfoDeal ? (
					<Stack direction="row" spacing={2}>
						<Box
							sx={{
								width: '50%',
								my: 1,
								'& >*:not(.loading)': {
									display: 'flex',
									gap: '10px',
									margin: '6px 0',
									border: `1px solid rgb(0 0 0 / 10%)`,
									background: 'rgb(0 0 0 / 10%)',
									borderRadius: '4px',
									padding: '5px',
									boxShadow: 'rgb(0 0 0 / 40%) -1px 1px 1.8px',
								},
							}}
						>
							{Object.keys(stationProps['Station']).map((key, idx) => (
								<Box key={key}>
									<Typography fontSize={16} fontWeight="bold">
										{firebasekeysTitles[key]}:
									</Typography>
									<Typography fontSize={16}>{stationProps['Station'][key]}</Typography>
								</Box>
							))}
						</Box>
						<Box
							sx={{
								width: '50%',
								my: 1,
								pt: 1,
								'& >*:not(.loading)': {
									display: 'flex',
									gap: '10px',
									margin: '6px 0',
									border: `1px solid rgb(0 0 0 / 10%)`,
									background: 'rgb(0 0 0 / 10%)',
									borderRadius: '4px',
									padding: '5px',
									boxShadow: 'rgb(0 0 0 / 40%) -1px 1px 1.8px',
								},
							}}
						>
							<Divider>
								<Typography fontSize={16} fontWeight="bold">
									Past Deal
								</Typography>
							</Divider>
							{Object.keys(properties[name]).map((key, idx) => (
								<Box key={key}>
									<Typography fontSize={16} fontWeight="bold">
										{pastDealTitles[key]}:
									</Typography>
									<Typography fontSize={16}>{properties[name][key]}</Typography>
								</Box>
							))}
						</Box>
					</Stack>
				) : (
					''
				)}
			</DialogContent>
		</Dialog>
	);
}
