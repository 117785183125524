/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import MUIDataTable from 'mui-datatables';
import { Box, Typography } from '@mui/material';
import {
	styled,
	createTheme,
	ThemeProvider,
	useTheme,
} from '@mui/material/styles';
import { Verified as VerifiedIcon } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import EChart from '../../Dashboard/components/EChart';
import DetailsModal from './DetailsModal';
import SimpleModal from './SimpleModal';

const MainBox = styled(Box)(({ theme }) => ({
	margin: '20px auto',
	'.MuiPaper-root': {
		background: 'transparent',
		boxShadow: 'none',
		border: 0,
	},
}));

const getTheme = (theme) =>
	createTheme({
		components: {
			// Name of the component ⚛️
			MuiTable: {
				styleOverrides: {
					root: {
						textTransform: 'capitalize',
						// border: "none",
						borderCollapse: 'inherit',
						borderSpacing: '0px 5px',
					},
					paper: {
						backgroundColor: 'green',
					},
				},
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					root: {
						color: '#000',
						textTransform: 'capitalize',
						minWidth: '60px',
					},
				},
			},
			MuiTableFooter: {
				styleOverrides: {
					root: {
						// display: "none",
						'& .MuiToolbar-root': {
							color: '#000',
						},
					},
				},
			},
			MuiTableHead: {
				styleOverrides: {
					root: {
						'& > tr > th': {
							border: 0,
							color: '#fff',
							height: 50,
							backgroundColor: '#1976D2 !important',
						},
						'& > tr > th:nth-of-type(2)': {
							paddingLeft: '17px',
							paddingRight: '15px',
						},
						'& > tr > th:first-of-type': {
							'& span.MuiCheckbox-root': {
								color: 'rgb(246 246 246)',
								padding: '5px 4px',
								'>svg': {
									width: '1.2rem',
									height: '1.1rem',
								},
							},
						},
						'& > tr > th:last-of-type': {
							paddingRight: '10px',
						},
					},
				},
			},
			MUIDataTableHeadCell: {
				styleOverrides: {
					root: {
						'& .MuiTableSortLabel-icon': {
							color: 'rgb(255 255 255) !important',
						},
					},
				},
			},
			MuiTableBody: {
				styleOverrides: {
					root: {
						'& > tr': {
							marginBottom: '5px',
							'&:nth-of-type(even)': {
								backgroundColor: 'rgba(0, 0, 0, 0.04)',
							},
							':nth-of-type(odd):hover': {
								backgroundColor: '#fff',
							},
							'&.Mui-selected': {
								backgroundColor: '#96BE98',
								color: '#fff',
								':hover': {
									backgroundColor: '#96BE98',
								},
								'> td': {
									color: '#fff',
								},
							},
							'& > td:first-of-type': {
								// width: "50px",
								backgroundColor: '#fff',
								'& span.MuiCheckbox-root': {
									padding: '5px 4px',
									'&.Mui-checked': {
										color: '#96BE98',
									},
									'>svg': {
										width: '1.2rem',
										height: '1.1rem',
									},
								},
							},
						},
					},
				},
			},
			MuiToolbar: {
				defaultProps: {
					style: { paddingLeft: '8px' },
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						'& h6': {
							paddingLeft: '10px',
							fontWeight: 'bold',
						},
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						padding: '1px 4px',
						borderBottom: 0,
					},
					body: {
						fontSize: '12.5px',
						'& p': {
							fontSize: '12.5px',
							cursor: 'pointer',
						},
					},
				},
			},
		},
	});

const lineChartOptions = (value) => {
	let trend = value === true ? 'positive' : 'negative';
	return {
		xAxis: {
			type: 'category',
			data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
			show: false,
		},
		yAxis: {
			type: 'value',
			show: false,
		},
		series: [
			{
				data:
					trend === 'positive'
						? [80, 100, 155, 118, 184, 100, 204]
						: [150, 100, 204, 118, 185, 200, 80],
				type: 'line',
				color: trend === 'positive' ? 'green' : 'red',
				animation: false,
			},
		],
	};
};

const DataTable = ({ salesList, selectedRows, setSelectedRows }) => {
	const theme = useTheme();

	const [rowsSelectedIndexes, setRowsSelectedIndexes] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [isStation, setIsStation] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [openSimpleModal, setOpenSimpleModal] = useState(false);
	const [simpleModalDetails, setSimpleModalDetails] = useState({
		title: '',
	});
	const initialQueryDetails = { title: '', value: '', name: '' };
	const [queryDetails, setQueryDetails] = useState(initialQueryDetails);

	const initialSelectAllDetails = {
		checked: false,
		value: '',
		checkedBy: '',
		total: 0,
	};
	const [selectAllDetails, setSelectAllDetails] = useState(
		initialSelectAllDetails
	);

	const handleSelectAll = (checked, value, total, checkedBy) => {
		setSelectAllDetails({ checked, value, total, checkedBy });
	};

	useEffect(() => {
		if (selectAllDetails.checked) {
			const { checked, value, checkedBy, total } = selectAllDetails;
			let findIndexes = [];
			salesList.forEach((item, index) => {
				if (item[checkedBy] === value) return findIndexes.push(index);
			});
			setRowsSelectedIndexes(findIndexes);
		}
	}, [selectAllDetails]);

	const columns = [
		{
			name: 'status',
			label: 'Status',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value) => (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						width="60px"
						py={0.5}
					>
						<VerifiedIcon
							color={value === true ? 'success' : 'error'}
							fontSize="medium"
						/>
					</Box>
				),
			},
		},
		{
			name: 'trendLine',
			label: 'Trend Line',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => {
					return {
						style: {
							minWidth: '90px',
						},
					};
				},
				customBodyRender: (value) => (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="flex-start"
						maxHeight="65px"
						maxWidth="80px"
						paddingTop={'8px'}
						sx={
							{
								// "& div[_echarts_instance_] > div": {
								//     height: "65px !important",
								//     width: "70px !important",
								// },
								// "& div > canvas": {
								//     height: "65px !important",
								//     width: "70px !important",
								// }
							}
						}
					>
						<EChart option={lineChartOptions(value)} />
					</Box>
				),
			},
		},
		{
			name: 'station',
			label: 'Station',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => {
					return {
						style: {
							minWidth: '80px',
						},
					};
				},
				customBodyRender: (value, tableMeta) => (
					<Typography
						onClick={(_) => {
							let findSale = salesList.find(
								(s) => s.Station === value && tableMeta.rowData[11] === s.id
							);
							setOpenModal(true); //
							setIsStation(true);
							setQueryDetails({
								name: 'Station',
								title: 'Station',
								value: value,
								url: findSale.Web,
								detailsObj: findSale,
							});
						}}
					>
						{value.split('/')[0]}
					</Typography>
				),
			},
		},
		{
			name: 'dial',
			label: 'Dial',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'format',
			label: 'Format',
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: 'owner',
			label: 'Owner',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => (
					<Typography
						onClick={(_) => {
							let findSaleListObject = salesList.find(
								(s) => s.Ownername === value && tableMeta.rowData[11] === s.id
							);
							setOpenModal(true); //
							setIsOwner(true);
							setQueryDetails({
								name: 'Ownername',
								title: 'Owner',
								value: value,
								detailsObj: findSaleListObject,
							});
						}}
					>
						{value}
					</Typography>
				),
			},
		},
		{
			name: 'aqh',
			label: 'AQH',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value) => <Typography>{value}</Typography>,
				setCellProps: () => {
					return {
						style: {
							textAlign: 'center',
						},
					};
				},
			},
		},
		{
			name: 'clusterAqh',
			label: 'Cluster AQH',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => {
					return {
						style: {
							textAlign: 'center',
						},
					};
				},
				setCellHeaderProps: () => {
					return {
						style: {
							textAlign: 'center',
							minWidth: '100px',
						},
					};
				},
			},
		},
		{
			name: 'address',
			label: 'Address',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => {
					return {
						style: {
							minWidth: '100px',
						},
					};
				},
			},
		},
		{
			name: 'dma',
			label: 'DMA',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => (
					<Box
						sx={{ cursor: 'pointer' }}
						onClick={(_) => {
							setOpenModal(true); //
							setQueryDetails({ name: 'NDMAName', title: 'DMA', value });
						}}
					>
						<Typography fontSize="12.5px">{value}</Typography>
					</Box>
				),
			},
		},
		{
			name: 'homeMarket',
			label: 'Metro',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value) => (
					<Typography
						sx={{ cursor: value === 'None' ? 'auto' : 'pointer' }}
						onClick={() => {
							if (value !== 'None') {
								setOpenModal(true);
								setQueryDetails({ name: 'HomeMarket', title: 'Metro', value });
							}
						}}
					>
						{value}
					</Typography>
				),
			},
		},
		{
			name: 'id',
			label: 'ID',
			options: {
				filter: true,
				sort: true,
				display: false,
			},
		},
	];

	const data = useMemo(() => {
		const getList =
			salesList.length > 0 &&
			salesList.map((list, idx) => {
				return {
					status: idx % 5 < 2 ? true : false,
					trendLine: idx % 6 < 3 ? true : false,
					station: list.Station,
					dial: list.Freq,
					format: list.FmtName1,
					owner: list['Ownername'],
					aqh: list.Aqh === undefined ? '...' : list.Aqh,
					clusterAqh: list.ClusterAqh === undefined ? '-' : list.ClusterAqh,
					address: list.Maddr1 + ' ,' + list.Mstate + ' ,' + list.Mzip,
					dma: list.NDMAName,
					homeMarket: list.HomeMarket || 'None',
					id: list.id,
				};
			});
		if (!!selectedRows.length) {
			let subIds = new Set(selectedRows.map(({ id }) => id));
			let result = [...salesList.keys()].filter((i) =>
				subIds.has(salesList[i].id)
			);
			setRowsSelectedIndexes(result);
		}
		return getList;
	}, [selectedRows, salesList]);

	const options = {
		filterType: 'dropdown',
		print: 'false',
		pagination: true,
		customSort: (data, colIndex, order, meta) => {
			let dataNumberSorted = data.sort((a, b) => {
				return (
					(a.data[colIndex] < b.data[colIndex] ? 1 : -1) *
					(order === 'desc' ? 1 : -1)
				);
			});
			return dataNumberSorted;
		},
	};

	return (
		<MainBox
			sx={{
				boxShadow: (theme) => theme.shadows[4],
				borderRadius: 2,
				paddingX: 1.2,
				marginX: -1,
			}}
		>
			<ThemeProvider theme={getTheme(theme)}>
				<MUIDataTable
					title={'Sales Prospector'}
					data={data || []}
					columns={columns}
					options={{
						...options,
						rowsPerPage: 25,
						rowsPerPageOptions: [25, 50, 100],
						// selectableRowsHeader: false,
						rowsSelected: rowsSelectedIndexes,
						selectToolbarPlacement: 'replace',
						selectableRowsHeader: true,
						onRowSelectionChange: (_, __, rowsSelected) => {
							const filterSelected = rowsSelected.map((rowNo) => salesList[rowNo]);
							setRowsSelectedIndexes(rowsSelected);
							setSelectedRows(filterSelected);
						},
					}}
				/>
			</ThemeProvider>
			<DetailsModal
				openModal={openModal}
				setOpenModal={setOpenModal}
				queryDetails={queryDetails}
				isStation={isStation}
				isOwner={isOwner}
				onClose={() => {
					setOpenModal(false);
					isStation && setIsStation(false);
					isOwner && setIsOwner(false);
					setQueryDetails(initialQueryDetails);
					setSelectAllDetails(initialSelectAllDetails);
				}}
				handleSelectAll={handleSelectAll}
				selectAllDetails={selectAllDetails}
				salesList={salesList}
			/>
			<SimpleModal
				open={openSimpleModal}
				setOpen={setOpenSimpleModal}
				details={simpleModalDetails}
			/>
		</MainBox>
	);
};

export default DataTable;
