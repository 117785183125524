import React, { useState, useEffect } from 'react';
import useContracts from './hooks/useContracts';
import useCertificates from './hooks/useCertificates';
import ListCertificates from './components/VacationCredits/ListCertificates';
import { MainBox } from './utils/themes';
import Search from './components/Search';
const VacationCredits = () => {
	const [certificates, setCertificates] = useState([]);

	const { contracts: contractsActive, contractsId, setFilter } = useContracts();
	const { certificates: certificatesAux } = useCertificates(contractsId);

	useEffect(() => {
		if (
			certificatesAux &&
			certificatesAux.length > 0 &&
			contractsActive.length > 0
		) {
			const certificateFormat = certificatesAux.map((cert) => {
				const contract = contractsActive.find((ctr) => ctr.id === cert.contractId);
				return {
					...cert,
					contract: contract ? contract.contractsInfo : null,
				};
			});
			setCertificates(certificateFormat);
		} else {
			setCertificates([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [certificatesAux]);

	return (
		<>
			<MainBox>
				<Search setFilter={setFilter}></Search>
				<ListCertificates certificates={certificates}></ListCertificates>
			</MainBox>
		</>
	);
};

export default VacationCredits;
