import React, { useState } from 'react';
import { Button, CircularProgress, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { sortedOptions } from '../utils/utils';

export default function ChangeOptionName({
	optionNameModal,
	setOptionNameModal,
	alternativeName,
	optionId,
	proposalId,
	aqhSavedOptions,
	setAqhSavedOptions,
	setAlternativeName,
}) {
	const [loading, setLoading] = useState(false);

	const handleCancel = (event, reason) => {
		if (reason && reason === 'backdropClick') return;
		setOptionNameModal(false);
		setAlternativeName('');
	};

	const handleSave = async () => {
		setLoading(true);

		try {
			if (optionId && proposalId) {
				const proposalRef = doc(db, 'savedProposals', proposalId);
				const updatedAqhSavedOptions = aqhSavedOptions.map((obj) => {
					if (obj.id === optionId) {
						return {
							...obj,
							alternativeName,
						};
					}
					return obj;
				});
				await updateDoc(proposalRef, {
					aqhSavedOptions: updatedAqhSavedOptions,
				});
				// sorted options
				const sortedAqhOptions = sortedOptions(updatedAqhSavedOptions);
				setAqhSavedOptions(sortedAqhOptions);
			} else {
				const optionRef = doc(db, 'aqhSavedOptions', optionId);
				await updateDoc(optionRef, {
					alternativeName,
				});
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setOptionNameModal(false);
			setAlternativeName('');
		}
	};
	return (
		<Dialog
			open={optionNameModal}
			onClose={handleCancel}
			sx={{
				'& .MuiDialog-paper': {
					minWidth: '400px',
				},
			}}
		>
			<DialogTitle>Edit name option</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Name"
					type="text"
					fullWidth
					value={alternativeName}
					onChange={(e) => setAlternativeName(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} variant="contained" color="error">
					Cancel
				</Button>
				<Button
					onClick={handleSave}
					variant="contained"
					color="success"
					startIcon={loading && <CircularProgress color="success" size={18} />}
					disabled={loading}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
