const ones = [
	'',
	'one',
	'two',
	'three',
	'four',
	'five',
	'six',
	'seven',
	'eight',
	'nine',
];
const teens = [
	'ten',
	'eleven',
	'twelve',
	'thirteen',
	'fourteen',
	'fifteen',
	'sixteen',
	'seventeen',
	'eighteen',
	'nineteen',
];
const tens = [
	'',
	'',
	'twenty',
	'thirty',
	'forty',
	'fifty',
	'sixty',
	'seventy',
	'eighty',
	'ninety',
];

// if number is between 0 and 100, return ones or tens words
function convert_tens(num) {
	if (num < 10) return ones[num];
	else if (num >= 10 && num < 20) return teens[num - 10];
	else {
		return tens[Math.floor(num / 10)] + ' ' + ones[num % 10];
	}
}

// if number is 0, return "zero"
function convert(num) {
	if (num === 0) return 'zero';
	else return convert_tens(num);
}

// get value per day based on quantity and daypart
export const valuePerDay = (value) => {
	if (value) {
		// const daypart = days.split(" ")[0]
		const result = value;
		if (result === 1) {
			//if result is 1, return "one :60"
			let single = convert(result);
			return single + ' :60';
		} else if (result > 1) {
			//if result is greater than 1, return "plural :60s"
			let plural = convert(result);
			return plural + ' :60s';
		} else if (result < 1) {
			return result + ' 60';
		}
	} else {
		return '';
	}
};

// Organize the data in a object
export const getValuesTable = (obj) => {
	if (!obj) return {};
	// transform data in array of objects
	const withNestedKeys = Object.entries(obj).map((entry) => {
		return { [entry[0].split('@')[0]]: entry[1] };
	});
	// join the objects with the same key
	const ansObj = withNestedKeys.reduce((prv, cur) => {
		Object.entries(cur).forEach(([key, v]) =>
			key in prv
				? (prv[key].push(v), (prv[key][0].station = key))
				: ((prv[key] = [v]), (prv[key][0].station = key))
		);
		return prv;
	}, {});

	return ansObj;
};

// get the day part only days
export const getDayPart = (dayPart) => {
	return dayPart.split(' ')[0];
};

// knows if the day part is weekend
export const isWeekendEmptyRows = (obj) => {
	let newObject = obj.obj;
	// extract the day part from the object
	let days = Object.keys(newObject).map((key, i) =>
		newObject[key].map((v, i) => v.dayPartName.split(' ')[0])
	);
	// flat the array
	const arrayDays = days.flatMap((v) => v);
	// check if the array has weekend days
	if (arrayDays.includes('MSu')) {
		return true;
	} else if (arrayDays.includes('SS')) {
		return true;
	} else if (arrayDays.includes('Sat')) {
		return true;
	} else if (arrayDays.includes('Sun')) {
		return true;
	} else {
		return false;
	}
};

// get total of rewards
export const getTotalReward = (array) => {
	const sumOfValues = array.reduce((previous, current) => {
		if (current.value === '' || current.quantity === undefined) {
			return Number(previous);
		} else {
			let revenueResult = Number(current.value) * Number(current.quantity);
			return Number(previous) + revenueResult;
		}
	}, 0);
	return sumOfValues;
};

export const getCompanyInfo = (stations) => {
	const companyInfo = stations
		.map((v) => {
			// get only the desired fields
			return {
				company: v.Ownername,
				address: v.Maddr1 + ' ' + v.Maddr2,
				station: v.Station,
				state: v.Mstate,
				city: v.Mcity,
				zip: v.Mzip,
			};
		})
		.reduce((acc, curr) => {
			// Function to count the frequency of each value in the current object.
			const countValues = (obj) => {
				Object.keys(obj).forEach((key) => {
					const value = obj[key];
					if (!acc[key]) {
						acc[key] = {};
					}
					if (!acc[key][value]) {
						acc[key][value] = 0;
					}
					acc[key][value]++;
				});
			};

			countValues(curr);

			return acc;
		}, {});

	// Filter for each field and select the most common value
	Object.keys(companyInfo).forEach((key) => {
		const values = companyInfo[key];
		const mostCommonValue = Object.keys(values).reduce((a, b) =>
			values[a] > values[b] ? a : b
		);
		companyInfo[key] = mostCommonValue;
	});

	// Convert the object into an array of objects with the desired structure
	return [
		{
			company: companyInfo.company || null,
			address: companyInfo.address || null,
			station: companyInfo.station || null,
			state: companyInfo.state || null,
			city: companyInfo.city || null,
			zip: companyInfo.zip || null,
		},
	];
};
