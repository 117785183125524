import { Search as Deal } from '@mui/icons-material';
import { Box, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Search = ({ setFilter }) => {
	const [query, setQuery] = useState('');

	useEffect(() => {
		if (query.trim().length > 0) {
			setFilter(query);
		} else {
			setFilter(null);
		}
	}, [query, setFilter]);

	return (
		<>
			<Box width={500} paddingTop={5}>
				<TextField
					placeholder={`Deal`}
					label="Search"
					variant="outlined"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Deal />
							</InputAdornment>
						),
					}}
					fullWidth={true}
					onChange={(e) => setQuery(e.target.value)}
				></TextField>
			</Box>
		</>
	);
};

export default Search;
