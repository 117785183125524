import { Alert, AlertTitle, Box, Button, DialogActions } from '@mui/material';
import { useEffect, useState } from 'react';
import { MainBox } from '../../utils/themes';
import {
	getNote,
	getNotesById,
	getQueryNotesAccepted,
} from '../../utils/utils';
import { addDoc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useDocument } from 'react-firebase-hooks/firestore';
/**
 * Component to manage the process of accepting the item from a note
 * @param item Item to be accepted
 * @param isBillPayManager Role
 * @param itemId ItemID form item
 * @param contractId Id of the contract to which the note belongs
 */
const AcceptedItem = ({ isBillPayManager, itemId, contractId }) => {
	const [isAccepted, setAccepted] = useState();
	const [history, setHistory] = useState();
	const { enqueueSnackbar } = useSnackbar();
	const [query, setQuery] = useState();
	const [item] = useDocument(query);
	const [data, setData] = useState();

	useEffect(() => {
		if (itemId && contractId) {
			const query = getNotesById(itemId, contractId);
			setQuery(query);
		}
	}, [contractId, itemId]);
	useEffect(() => {
		if (item && item.exists()) {
			const data = item.data();
			setData(data);
			setAccepted(data.accepted);
			setHistory(data.history);
		}
	}, [item]);
	/**
	 * Function that takes care of accepting a note, and creates a note in the subcollection of accepted notes in order to keep a history.
	 * @returns
	 */
	const acceptedNote = async () => {
		try {
			if (!isBillPayManager) return;
			const note = getNote('Item Accepted');
			const newHistory = [note, ...history];
			const noteAccepted = data;
			if (noteAccepted.history) {
				delete noteAccepted.history;
			}
			await updateDoc(query, { accepted: true, history: newHistory });
			const notesAcceptedRef = getQueryNotesAccepted(contractId);
			await addDoc(notesAcceptedRef, { ...noteAccepted });
			enqueueSnackbar('Item successfully accepted', { variant: 'success' });
		} catch (error) {
			console.log(error);
			enqueueSnackbar('Error when accepting item', { variant: 'error' });
		}
	};
	return (
		<>
			{isBillPayManager && !isAccepted && (
				<DialogActions>
					<MainBox>
						<Box>
							<Button onClick={acceptedNote} variant="contained">
								Accept
							</Button>
						</Box>
					</MainBox>
				</DialogActions>
			)}

			{isAccepted && (
				<DialogActions>
					<MainBox>
						<Alert severity="success">
							<AlertTitle>Item accepted</AlertTitle>
						</Alert>
					</MainBox>
				</DialogActions>
			)}
		</>
	);
};

export default AcceptedItem;
