import { Button, CircularProgress, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SaveIcon from "@mui/icons-material/Save";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase/config";
import { useSnackbar } from "notistack";

const EmployeeForm = ({ setIsEdit, employeeInfo, user }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const employeeinitials = {
    employeeId: "",
    employeeEmail: "",
    employeePhone: "",
    department: "",
    position: "",
    supervisor: "",
  };
  const [employeeState, setEmployeeState] = useState(employeeinitials);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeState({ ...employeeState, [name]: value });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (Object.keys(employeeInfo).length > 1 && employeeInfo?.id) {
        if (employeeInfo?.userId !== user.uid) throw Error("You'are not authorized!");
        let { id, ...rest } = employeeState
        const colRef = doc(db, "employees", employeeInfo.id);
        await updateDoc(colRef, { ...rest })
      } else {
        const colRef = collection(db, "employees");
        await addDoc(colRef, { ...employeeState, userId: user.uid });
      }
      enqueueSnackbar("Employee information updated!", { variant: "success" });
      setLoading(false);
      setIsEdit(false)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoading(false);
    }
  }

  useEffect(() => {
    (Object.keys(employeeInfo).length > 0) && setEmployeeState({ ...employeeState, ...employeeInfo })
  }, [employeeInfo])


  return (
    <Paper
      sx={{
        mx: "auto",
        bgcolor: "#eeeeee",
        p: 3,
        width: { md: "80%" }, minHeight: "75vh",
        position: "relative",
      }}
    >
      <Button
        variant="outlined"
        startIcon={<ArrowBackIosIcon />}
        onClick={() => setIsEdit(false)}
        sx={{ mb: 3 }}
      >
        Back
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Add Employee Data</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Employee Id"
            variant="outlined"
            size="small"
            name="employeeId"
            onChange={handleChange}
            value={employeeState.employeeId}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Employee Email"
            variant="outlined"
            size="small"
            name="employeeEmail"
            type="email"
            onChange={handleChange}
            value={employeeState.employeeEmail}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Employee Phone"
            variant="outlined"
            size="small"
            name="employeePhone"
            onChange={handleChange}
            value={employeeState.employeePhone}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Employee Department"
            variant="outlined"
            size="small"
            name="department"
            onChange={handleChange}
            value={employeeState.department}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Employee position"
            variant="outlined"
            size="small"
            name="position"
            onChange={handleChange}
            value={employeeState.position}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Employee Supervisor"
            variant="outlined"
            size="small"
            name="supervisor"
            onChange={handleChange}
            value={employeeState.supervisor}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} justifyContent="flex-end">
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            sx={{ minWidth: "8rem", position: "absolute", right: "1.8rem", bottom: "1.8rem" }}
            disabled={loading}
          >
            {loading ?
              <CircularProgress style={{ width: "20px", height: "20px", marginRight: "10px" }} />
              : "Save"
            }
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmployeeForm;
