import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { db } from '../../../firebase/config';
import { addDoc, getDoc, doc, updateDoc, collection } from 'firebase/firestore';
import { CircularProgress, Typography, Stack } from '@mui/material';
import { dateTransform } from '../../AQHCalculator/utils/utils';

const CloneProposal = ({
	openCloneProposal,
	setOpenCloneProposal,
	cloneProposalData,
	setCloneProposalData,
}) => {
	const [loading, setLoading] = React.useState(false);
	const revisionNumberRef = doc(db, 'revisionNumber', 'x4LoYuaH4NCIFLLD56Kb'); // reference to revision number
	const proposalRef = collection(db, 'savedProposals'); // reference to saved proposals

	const generateNewProposalName = (proposalNameParts, revisionNumber) => {
		// get company name, dma, date, initials, revision number and replace proposal name
		const companyName = proposalNameParts[0];
		const companyDMA = proposalNameParts[1];
		const date = dateTransform(new Date()); // get current date
		const initials = proposalNameParts[3];
		// create new proposal name with new revision number
		const newProposalName = `${companyName} - ${companyDMA} - ${date} - ${initials} - ${revisionNumber}`;
		return newProposalName;
	};

	const removeAcceptedField = (aqhSavedOptions) => {
		const newAqhSavedOptions = aqhSavedOptions.map((option) => {
			if (option.accepted) {
				delete option.accepted;
			}
			return option;
		});
		return newAqhSavedOptions;
	};

	// if rep responds yes, update proposal
	const handleYes = async () => {
		setLoading(true);

		try {
			// if proposal exists, update it
			if (cloneProposalData && cloneProposalData !== '') {
				const revisionDoc = await getDoc(revisionNumberRef);
				const revNumber = revisionDoc.data().current + 1;
				// get proposal name
				const proposalName = cloneProposalData.name;
				const proposalNameParts = proposalName.split(' - ');
				// get company name, dma, date, initials, revision number and replace proposal name
				const revisionNumber = revNumber;
				// create new proposal name
				const newProposalName = generateNewProposalName(
					proposalNameParts,
					revisionNumber
				);

				// review aqhSavedOptions and remove accepted field if exists
				const copyProposal = { ...cloneProposalData };
				const newAqhSavedOptions = removeAcceptedField(
					copyProposal.aqhSavedOptions
				);

				// replace proposal name and aqhSavedOptions
				const newProposalData = {
					...cloneProposalData,
					name: newProposalName,
					aqhSavedOptions: newAqhSavedOptions,
				};
				// create new proposal with new id and update id after to create
				const { id } = await addDoc(proposalRef, newProposalData);
				// update proposal id
				await updateDoc(doc(db, 'savedProposals', id), {
					id: id,
				});
				// Update revision number
				await updateDoc(revisionNumberRef, {
					current: revNumber,
				});
				setCloneProposalData('');
			}
		} catch (error) {
			console.log(error);
			setOpenCloneProposal(false);
		} finally {
			setLoading(false);
			setOpenCloneProposal(false);
		}
	};
	// if rep responds no, close modal
	const handleNo = async () => {
		setCloneProposalData('');
		setOpenCloneProposal(false);
	};

	const handleCloseModal = (event, reason) => {
		// if click outside the modal or press esc key, don't close the modal
		if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
			return;
		}
		setOpenCloneProposal(false);
	};

	return (
		<Dialog open={openCloneProposal} onClose={handleCloseModal}>
			<DialogTitle aria-describedby="alert-dialog-slide-description">
				Confirmation
			</DialogTitle>
			<DialogContent>
				<Stack direction="column" spacing={2}>
					<Typography>Are you sure you want clone this proposal ?</Typography>
					<Typography>{cloneProposalData.name}</Typography>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					startIcon={
						loading && <CircularProgress style={{ color: 'white' }} size={18} />
					}
					variant="contained"
					color="success"
					onClick={handleYes}
				>
					Yes
				</Button>
				<Button variant="contained" color="error" onClick={handleNo}>
					No
				</Button>
				{/* </div> */}
			</DialogActions>
		</Dialog>
	);
};

export default CloneProposal;
