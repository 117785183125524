/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableHead, TextField } from '@mui/material';
import { useEffect } from 'react';
import { createCreditsTableRowData } from '../../StationList/utils/constants.js';

const vacationObjectKeys = [
	'Daytona 500 Race Package',
	'Deep Sea Fishing in the Bahamas',
	'Disney Family Vacation',
	'Get Out and Golf Package',
	'Live in Concert Your Core Artist',
	'Napa Valley Winery Package',
	'On Broadway New York City',
	'Pebble Beach',
	'Santa Fe Spa Couples Getaway',
	'Shop ‘til You Drop New York City',
	'Taste of New Orleans',
];

const columns = [
	{ id: 'name', label: 'Specialty Vacations', minWidth: 400 },
	{ id: 'credits', label: 'Credits', minWidth: 85 },
];

const SpecialtyVacationTable = ({ object, tableRows, setTableRows }) => {
	const handleNameChange = (e) => {
		setTableRows((prev) => {
			return prev.map((row) => {
				if (row.name === e.target.name) {
					return { ...row, credits: customTextField(e.target.name, e.target.value) };
				}
				return row;
			});
		});
	};

	const customTextField = (key, value) => {
		return (
			<TextField
				inputProps={{
					style: { textAlign: 'center', fontSize: 13, fontWeight: 500 },
				}}
				variant="outlined"
				size="small"
				name={key}
				value={value}
				onChange={handleNameChange}
			></TextField>
		);
	};

	useEffect(() => {
		const rows = [];
		vacationObjectKeys.forEach((trip) => {
			rows.push(
				createCreditsTableRowData(
					trip,
					customTextField(trip, object[trip]),
					'normal'
				)
			);
		});
		rows.splice(
			0,
			0,
			createCreditsTableRowData(
				'Includes airfare for 2 from St Louis, determined by SFM, minimum 90 days advance notice required, excluding holidays and annual events',
				'',
				'gray'
			)
		);
		setTableRows(rows);
	}, []);

	return (
		<>
			{tableRows && tableRows.length > 0 ? (
				<Table sx={{ marginTop: 7 }} stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									sx={{
										backgroundColor: '#6C6767',
										color: 'white',
										padding: '5px 0px 5px 10px',
										borderLeft: '1px solid black',
										'&:last-child': {
											borderRight: '1px solid black',
											paddingLeft: '40px',
										},
									}}
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{tableRows.map((row) => {
							return (
								<TableRow
									hover
									role="checkbox"
									tabIndex={-1}
									key={row.name}
									sx={{ backgroundColor: row.type === 'normal' ? 'white' : '#D2D2D2' }}
								>
									{columns.map((column) => {
										const value = row[column.id];
										return (
											<TableCell
												sx={{
													fontSize: 12,
													borderBottom: '1px solid black',
													borderLeft: '1px solid black',
													'&:last-child': {
														borderRight: '1px solid black',
													},
												}}
												key={column.id}
												align={column.align}
											>
												{column.format && typeof value === 'number'
													? column.format(value)
													: value}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			) : (
				''
			)}
		</>
	);
};

export default SpecialtyVacationTable;
