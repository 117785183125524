import {
	Button,
	IconButton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getNote } from '../../utils/utils';
import { updateDoc } from 'firebase/firestore';
import { ModeEditOutline } from '@mui/icons-material';
/**
 * Component for managing the cost editing process
 * @param {IsBillPayManager} IsBillPayManager role
 * @param {item} item note to  edit cost
 * @param {query} query  reference to the edited collection
 * @returns
 */
const EditCost = ({ isBillPayManager, item, query }) => {
	const [cost, setCost] = useState();
	const [currentCost, setCurrentCost] = useState();
	const [isAccepted, setAccepted] = useState(false);
	const [isEditCost, setEditCost] = useState(false);
	useEffect(() => {
		if (item) {
			setAccepted(item.accepted);
			setCost(item.cost);
			setCurrentCost(item.cost);
		}
	}, [item]);
	/**
	 * Update the cost of an item in a note only when the user's role is billpaty manager and the note has not yet been accepted.
	 * @returns
	 */
	const updateCost = async () => {
		if (!isBillPayManager) return;
		if (cost !== currentCost) {
			const note = getNote(`Update cost to: $${cost}`);
			const newHistory = [note, ...item.history];
			await updateDoc(query, { cost: cost, history: newHistory });
		}
		setEditCost(false);
	};
	return (
		<>
			{!isEditCost ? (
				<Stack sx={{ mb: 1.5 }} direction={'row'} spacing={1} alignItems={'center'}>
					<Typography style={{ fontWeight: 'bold', fontSize: 20 }} variant="h6">
						Cost:
					</Typography>
					<Typography variant="h6">{cost}</Typography>
					{isBillPayManager && !isAccepted && (
						<IconButton onClick={() => setEditCost(true)}>
							<ModeEditOutline sx={{ fontSize: 20 }}></ModeEditOutline>
						</IconButton>
					)}
				</Stack>
			) : (
				<Stack direction={'row'} alignItems={'center'} spacing={1}>
					<Typography style={{ fontWeight: 'bold', fontSize: 20 }} variant="h6">
						Cost:
					</Typography>
					<TextField
						value={cost}
						size="medium"
						type="number"
						variant="standard"
						onChange={(e) => setCost(e.target.value)}
						style={{ fontSize: 20 }}
					></TextField>
					<Button variant="contained" onClick={updateCost}>
						Save
					</Button>
					<Button
						variant="outlined"
						color="error"
						onClick={() => {
							setEditCost(false);
							setCost(currentCost);
						}}
					>
						Cancel
					</Button>
				</Stack>
			)}
		</>
	);
};
export default EditCost;
