export const typeUsers = [
	{ value: 'admin', label: 'Manager' },
	{ value: 'coordinator', label: 'Coordinator' },
	{ value: 'traveler', label: 'Traveler' },
	{ value: 'cardCoordinator', label: 'Card Coordinator' },
	{ value: 'tripCoordinator', label: 'Trip Coordinator' },
	{ value: 'billPayCoordinator', label: 'Bill Pay Coordinator' },
	{ value: 'cogsManager', label: 'COGS Manager' },
	{ value: 'tripManager', label: 'Trip Manager' },
	{ value: 'giftCardManager', label: 'Gift Card Manager' },
	{ value: 'billPayManager', label: 'Bill Pay Manager' },
	{ value: 'client', label: 'Client' },
];

export const handleUserTypeValue = (roleUsers) => {
	if (!roleUsers || roleUsers === undefined) return [];
	if (typeof roleUsers === 'string') {
		if (roleUsers === 'admin') {
			return [{ value: 'admin', label: 'Manager' }];
		}
		if (roleUsers === 'client') {
			return [{ value: 'client', label: 'Client' }];
		} else {
			return [];
		}
	} else {
		return roleUsers;
	}
};
