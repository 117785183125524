import axios from 'axios';
import * as echarts from 'echarts';

export const numberWithCommas = (x) => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const checkIfObjectContains = (one, two) => {
	for (var i in one) {
		if (!two.hasOwnProperty(i) || one[i] !== two[i]) {
			return false;
		}
	}
	return true;
};

export const splitArrayIntoChunks = (perChunk = 5, inputArr) => {
	let results = inputArr.reduce((resultArray, item, index) => {
		const chunkIndex = Math.floor(index / perChunk);

		if (!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = []; // start a new chunk
		}

		resultArray[chunkIndex].push(item);

		return resultArray;
	}, []);

	return results;
};

export const getUniqueArr = (arr, valuesToMatch = []) => {
	return arr.filter(
		(obj, index, self) =>
			index ===
			self.findIndex(
				(t) =>
					t[valuesToMatch[0]] === obj[valuesToMatch[0]] &&
					t[valuesToMatch[1]] === obj[valuesToMatch[1]]
			)
	);
};

export function rangeArr(start, end) {
	return Array(end - start + 1)
		.fill()
		.map((_, idx) => start + idx);
}

export const getEmployeeId = (firstName, lastName, year) => {
	let randomLetter = (_) => String.fromCharCode(0 | (Math.random() * 26 + 97));
	return `${firstName.toLowerCase().slice(0, 3)}-${lastName
		.toLowerCase()
		.slice(0, 3)}-${year}-${randomLetter()}`;
};

export const sortWithGroup = (data, firstOrder, secondOrder) => {
	let advanceSort = [];
	let dataFirstOrderSorted = data.sort(
		(a, b) => parseFloat(b[firstOrder]) - parseFloat(a[firstOrder])
	);
	for (let i = 0; i < dataFirstOrderSorted.length; i++) {
		let secondPrior = dataFirstOrderSorted[0][secondOrder];
		secondPrior &&
			advanceSort.push(
				...dataFirstOrderSorted.filter((v) => v[secondOrder] === secondPrior)
			);
		dataFirstOrderSorted = dataFirstOrderSorted.filter(
			(v) => v[secondOrder] !== secondPrior
		);
		i = 0;
	}
	advanceSort.push(...dataFirstOrderSorted);
	return advanceSort;
};

export const formatDateWithSlash = (date) => {
	if (date.seconds) {
		let d = new Date(date.seconds * 1000),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [month, day, year].join('/');
	} else {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) {
			month = '0' + month;
		}
		if (day.length < 2) {
			day = '0' + day;
		}

		return [month, day, year].join('/');
	}
};

export const formatDate = (date) => {
	if (date.seconds) {
		let d = new Date(date.seconds * 1000),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [month, day, year].join('-');
	} else {
		let d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [month, day, year].join('-');
	}
};
const hostName = window.location.hostname;

export const baseURL =
	hostName === 'localhost'
		? 'http://localhost:5001/sunfuncloud/us-central1'
		: 'https://us-central1-sunfuncloud.cloudfunctions.net';

// Station ref '08242022'
export const stationRef = '08242022';

export const trendLineTitles = {
	MF1_2p: 'MF 1p-2p',
	MF2_3p: 'MF 2p-3p',
	MF3_4p: 'MF 3p-4p',
	MF3_7p: 'MF 3p-7p',
	MF4_5p: 'MF 4p-5p',
	MF5_6a: 'MF 5a-6a',
	MF5_6p: 'MF 5p-6p',
	MF5a_8p: 'MF 5a-8p',
	MF5a_9p: 'MF 5a-9p',
	MF5a_10p: 'MF 5a-10p',
	MF5a_11p: 'MF 5a-11p',
	MF5a_12m: 'MF 5a-12m',
	MF6_7a: 'MF 6a-7a',
	MF6_7p: 'MF 6p-7p',
	MF6_10a: 'MF 6a-10a',
	MF6a_7p: 'MF 6a-7p',
	MF7_8a: 'MF 7a-8a',
	MF7_8p: 'MF 7p-8p',
	MF8_9a: 'MF 8a-9a',
	MF9_10a: 'MF 9a-10a',
	MF10_11a: 'MF 10a-11a',
	MF10a_3p: 'MF 10a-3p',
	MF11a_12n: 'MF 11a-12n',
	MF12n_1p: 'MF 12n-1p',
	MSu6a_12m: 'MSu 6a-12m',
	MSu12m_6a: 'MSu 12m-6a',
	SS6a_12m: 'SS 6a-12m',
	SS6a_12n: 'SS 6a-12n',
	Sat3_7p: 'Sat 3p-7p',
	Sat6_10a: 'Sat 6a-10a',
	Sat10a_3p: 'Sat 10a-3p',
	Sun3_7p: 'Sun 3p-7p',
	Sun6_10a: 'Sun 6a-10a',
	Sun10a_3p: 'Sun 10a-3p',
};

export const SF_Client_A_F = [
	'FM',
	...rangeArr(2, 9).map((no) => 'F' + no),
	'AM',
	...rangeArr(2, 9).map((no) => 'A' + no),
];

export const colorGraphLine = new echarts.graphic.LinearGradient(0, 0, 1, 1, [
	{
		offset: 1,
		color: '#4DFFDF',
	},
	{
		offset: 0,
		color: '#418BFA',
	},
]);

export const addDashSimulcast = (simulcast) => {
	if (simulcast) {
		const simulcastLength = simulcast.length;

		if (simulcastLength >= 6 && simulcastLength < 8) {
			const newSimulcast =
				simulcast.slice(0, 4) + '-' + simulcast.slice(4, simulcastLength);
			return newSimulcast;
		} else if (simulcastLength >= 8 && simulcastLength < 14) {
			const firstStation = simulcast.slice(0, 4) + '-' + simulcast.slice(4, 6);
			const secondStation =
				simulcast.slice(6, 11) + '-' + simulcast.slice(11, simulcastLength);
			const newSimulcast = firstStation + secondStation;

			return newSimulcast;
		} else {
			return simulcast;
		}
	}
};
//If he has several simulcasts, he separates them for the search
export const separateSimulcastArr = (simulcasts) => {
	let simulcastArr = [];
	simulcasts.forEach((simulcast) => {
		if (!simulcast) {
			return [];
		}
		if (simulcast.length > 7) {
			simulcastArr.push(...simulcast.split('/'));
		} else {
			simulcastArr.push(simulcast);
		}
	});
	return simulcastArr;
};

export const sendEmailMessage = async (
	title,
	text,
	email,
	userId,
	cc = null,
	file = null,
	fileName = null,
	button = null,
	html = null,
	options = null,
	header = null,
	footer = null,
	subject = null,
	optionsMail = null
) => {
	try {
		const data = {
			title,
			text,
			email,
			userId,
			cc,
			file,
			fileName,
			button,
			html,
			options,
			header,
			footer,
			subject,
			optionsMail,
		};
		await axios.post(baseURL + '/sendGrindEmailMessage', data);
	} catch (error) {
		console.log(error);
	}
};

export const sendTextMessage = async (message, email) => {
	try {
		const data = {
			message,
			email,
		};
		await axios.post(baseURL + '/sendTextMessage', data);
	} catch (error) {
		console.log(error);
	}
};
// agreement number id from collection
export const agreementNumberId = 'a9AeOMU5EdD8Z6UPasvw';

export const handleEquitableDayparts = (daypart) => {
	// check if the daypart is equitable daypart
	const equitableDaypart = [
		'MF 5a-9p', // daypart table and bridge, starts with the day(s)
		'MF 5a-10p',
		'MF 5a-11p',
		'MF 5a-12m',
		'5a-9p', // daypart table in email, starts within the day(s)
		'5a-10p',
		'5a-11p',
		'5a-12m',
	];
	// if the daypart is equitable then add the *
	if (equitableDaypart.includes(daypart)) {
		return `${daypart} *`;
	} else {
		return daypart;
	}
};

// verify if user is admin
export const isAdminVerify = (userRole) => {
	if (typeof userRole === 'string') {
		// if userRole is a string, we check if it is 'admin'
		return userRole === 'admin';
	} else if (Array.isArray(userRole)) {
		// if userRole is an array, we check if it contains 'admin'
		return userRole.some((role) => role.value === 'admin');
	} else {
		return false;
	}
};

// verify if user is client
export const isClientVerify = (userRole) => {
	if (typeof userRole === 'string') {
		return userRole === 'client';
	} else if (Array.isArray(userRole)) {
		return userRole.some((role) => role.value === 'client');
	} else {
		return false;
	}
};
