/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CloseOutlined } from '@mui/icons-material';
import {
	pastDealTitles,
	currentDealTitles,
	dealProps,
	currentDealProps,
} from '../Pages/SalesProspector/utils/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
	openDealModal,
	onClose,
	queryDealDetails,
	isDeal,
}) {
	let { name, statusDeal, title, detailsObj } = queryDealDetails;

	const initialProperties = {
		Deal: dealProps,
		CurrentDeal: currentDealProps,
	};
	const [properties, setProperties] = useState(initialProperties);
	const [fetchDetails, setFetchDetails] = React.useState({});
	const [scrollHere, setScroll] = React.useState(0);
	const [currDeal, setCurrDeal] = React.useState({});

	useEffect(() => {
		const getData = () => {
			let { dealDetails } = detailsObj;

			let previousDealNumber = dealDetails?.['Pevious    Deal #'] || '';
			let previousDeal = dealDetails?.['Previous $'] || '$ 0';
			let previousCPT = dealDetails?.['Previous CPT'] || '0';
			let previousRatio = dealDetails?.['Previous Ratio'] || '';
			let previousNotes = dealDetails?.['Notes'] || '';
			let dealNumber = dealDetails?.['Deal #'] || '';
			let current$ = dealDetails?.['Current          New $'] || '$ 0';
			let currentCPT = dealDetails?.['Current CPT'] || '0';
			let currentRatio = dealDetails?.['Current Ratio'] || '';
			let currentCOG = dealDetails?.['Current    COG'] || '$ 0';
			let currentCredits = dealDetails?.['Current Credits'] || '$ 0';
			let currentRenewal = dealDetails?.['Current Renewal $'] || '$ 0';
			let currentDueDate = dealDetails?.['Renewal    due date'] || '';
			let bonus = dealDetails?.['Bonus'] || '';
			let commission = dealDetails?.['Commission'] || '';
			let commissionDate = dealDetails?.['COMMISSION Date'] || '';
			let city = dealDetails?.['City'] || '';
			let company = dealDetails?.['Company'] || '';
			let saleDate = dealDetails?.['SALE         Date'] || '';
			let renewalNewDate = dealDetails?.['Renewal    due date'] || '';
			let endDate = dealDetails?.endDateSchedule || '';

			let pastDealObj = {
				...properties[name],
				previousDealNumber,
				previousDeal,
				previousCPT,
				previousRatio,
				previousNotes,
			};
			let currentDealObj = {
				dealNumber,
				current$,
				currentCPT,
				currentRatio,
				currentCOG,
				currentCredits,
				currentRenewal,
				currentDueDate,
				bonus,
				commission,
				commissionDate,
				city,
				company,
				saleDate,
				renewalNewDate,
				endDate,
			};

			let dealProperties = { ...properties, [name]: { ...pastDealObj } };
			setProperties(statusDeal ? dealProperties : {});
			setCurrDeal(currentDealObj);
		};
		openDealModal && isDeal && getData();
	}, [isDeal, openDealModal]);

	useEffect(() => {
		if (openDealModal) {
			const y = window.pageYOffset;
			setScroll(y);
		}
	}, [openDealModal]);

	const handleCloseDeal = () => {
		setFetchDetails({});
		setProperties(initialProperties);
		onClose();
		setTimeout(() => {
			window.scrollTo({ top: scrollHere, behavior: 'auto' });
		}, 1);
	};
	return (
		<Dialog
			open={openDealModal}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseDeal}
			aria-describedby="alert-dialog-slide-description"
			sx={{
				'& .MuiDialog-paper': {
					minWidth: '600px',
				},
			}}
		>
			<DialogTitle sx={{ pb: 0 }}>{title} Details</DialogTitle>

			<IconButton
				color="primary"
				size="small"
				sx={{
					width: 'fit-content',
					position: 'absolute',
					right: '3%',
					top: '3.5%',
				}}
				onClick={handleCloseDeal}
			>
				<CloseOutlined />
			</IconButton>
			<DialogContent>
				<Box
					sx={{
						my: 1,
						'& >*:not(.loading)': {
							display: 'flex',
							gap: '10px',
							margin: '6px 0',
							border: `1px solid rgb(0 0 0 / 10%)`,
							background: 'rgb(0 0 0 / 10%)',
							borderRadius: '4px',
							padding: '5px',
							boxShadow: 'rgb(0 0 0 / 40%) -1px 1px 1.8px',
						},
					}}
				>
					{statusDeal ? (
						<>
							<Box>
								<Typography fontSize={16} fontWeight="bold">
									Name:
								</Typography>
								<Typography fontSize={16}>Past Deal</Typography>
							</Box>
							{Object.keys(properties[name]).map((key, idx) => (
								<Box key={key}>
									<Typography fontSize={16} fontWeight="bold">
										{pastDealTitles[key]}:
									</Typography>
									<Typography fontSize={16}>{properties[name][key]}</Typography>
								</Box>
							))}
							<hr
								style={{ height: '1px', color: 'black', backgroundColor: 'gray' }}
							></hr>
							<Box>
								<Typography fontSize={16} fontWeight="bold">
									Name:
								</Typography>
								<Typography fontSize={16}>Current Deal</Typography>
							</Box>
							{Object.keys(properties['CurrentDeal']).map((key, idx) => (
								<Box key={key}>
									<Typography fontSize={16} fontWeight="bold">
										{currentDealTitles[key]}:
									</Typography>
									<Typography fontSize={16}>{currDeal[key]}</Typography>
								</Box>
							))}
						</>
					) : (
						''
					)}
				</Box>
			</DialogContent>
		</Dialog>
	);
}
