import * as React from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';

export default function Loading() {
	return (
		<Box display="flex" justifyContent="center" alignItems="center">
			<Stack direction="row" spacing={2}>
				<CircularProgress size={30} style={{ color: '#0082A6', mr: 10 }} />
				<h4>Loading data....</h4>
			</Stack>
		</Box>
	);
}
