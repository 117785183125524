import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { CircularProgress, TextField, Stack } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { initialDenyFields } from '../utils/constants';
import { sendEmailMessage, sendTextMessage } from '../../../utils';
import { db } from '../../../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
	open,
	setOpen,
	messageId,
	email,
	action,
	userId,
}) {
	const { enqueueSnackbar } = useSnackbar();
	const [fields, setFields] = React.useState(initialDenyFields);
	const [loading, setLoading] = React.useState(false);

	const handleSend = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			// send deny message
			const docRef = doc(db, 'reviewMessages', messageId);
			await updateDoc(docRef, {
				denyReason: fields.deny_reason ? fields.deny_reason : 'No reason provided',
			});
			const docSnap = await getDoc(docRef);
			const emailMessage = {
				title: 'Deal Response',
				html: `<p>your deal ${
					docSnap?.data()?.dealName
				} was denied</p><p><strong>Deny Reason:</strong> ${fields.deny_reason}</p>`,
			};
			// send email with deny reason
			await sendEmailMessage(
				emailMessage.title,
				'',
				email,
				userId,
				null,
				null,
				null,
				null,
				emailMessage.html
			);
			// send text with deny reason
			const messageRep = `Your deal was denied.\nDeal Name: ${
				docSnap?.data()?.dealName
			}\nDeny Reason: ${fields.deny_reason}`;
			await sendTextMessage(messageRep, docSnap.data()?.employeeEmail);
			enqueueSnackbar('deal denied!', { variant: 'error' });
			action();

			setLoading(false);
			setFields(initialDenyFields);
			setOpen(false);
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.message, { variant: 'error' });
		}
	};

	const handleFieldChange = (e) => {
		setFields((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	return (
		<div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setOpen(false)}
				aria-describedby="alert-dialog-slide-description"
				sx={{
					'& .MuiDialog-paper': {
						minWidth: '500px',
						minHeight: '230px',
					},
				}}
			>
				<DialogTitle>Deny Deal</DialogTitle>
				<form onSubmit={handleSend} autoComplete="off">
					<DialogContent>
						<Stack direction="column" spacing={2}>
							<TextField
								label="Deny Reason"
								variant="outlined"
								name="deny_reason"
								value={fields.deny_reason}
								onChange={handleFieldChange}
								multiline
								rows={4}
							/>
						</Stack>
					</DialogContent>
					<DialogActions sx={{ mr: 2 }}>
						<Button
							variant="outlined"
							type="button"
							onClick={() => {
								setLoading(false);
								setFields(initialDenyFields);
								setOpen(false);
							}}
						>
							Close
						</Button>
						<Button
							variant="contained"
							startIcon={loading && <CircularProgress size={18} />}
							disabled={loading}
							type={'submit'}
							endIcon={<SendIcon />}
						>
							SEND
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</div>
	);
}
