import { atom, useResetRecoilState } from 'recoil';

export const statesKeys = {
	AL: 'Alabama',
	AK: 'Alaska',
	AS: 'American Samoa',
	AZ: 'Arizona',
	AR: 'Arkansas',
	CA: 'California',
	CO: 'Colorado',
	CT: 'Connecticut',
	DE: 'Delaware',
	DC: 'District Of Columbia',
	FM: 'Federated States Of Micronesia',
	FL: 'Florida',
	GA: 'Georgia',
	GU: 'Guam',
	HI: 'Hawaii',
	ID: 'Idaho',
	IL: 'Illinois',
	IN: 'Indiana',
	IA: 'Iowa',
	KS: 'Kansas',
	KY: 'Kentucky',
	LA: 'Louisiana',
	ME: 'Maine',
	MH: 'Marshall Islands',
	MD: 'Maryland',
	MA: 'Massachusetts',
	MI: 'Michigan',
	MN: 'Minnesota',
	MS: 'Mississippi',
	MO: 'Missouri',
	MT: 'Montana',
	NE: 'Nebraska',
	NV: 'Nevada',
	NH: 'New Hampshire',
	NJ: 'New Jersey',
	NM: 'New Mexico',
	NY: 'New York',
	NC: 'North Carolina',
	ND: 'North Dakota',
	MP: 'Northern Mariana Islands',
	OH: 'Ohio',
	OK: 'Oklahoma',
	OR: 'Oregon',
	PW: 'Palau',
	PA: 'Pennsylvania',
	PR: 'Puerto Rico',
	RI: 'Rhode Island',
	SC: 'South Carolina',
	SD: 'South Dakota',
	TN: 'Tennessee',
	TX: 'Texas',
	UT: 'Utah',
	VT: 'Vermont',
	VI: 'Virgin Islands',
	VA: 'Virginia',
	WA: 'Washington',
	WV: 'West Virginia',
	WI: 'Wisconsin',
	WY: 'Wyoming',
};
export const inverseStatesKeys = {
	Alabama: 'AL',
	Alaska: 'AK',
	'American Samoa': 'AS',
	Arizona: 'AZ',
	Arkansas: 'AR',
	California: 'CA',
	Colorado: 'CO',
	Connecticut: 'CT',
	Delaware: 'DE',
	'District Of Columbia': 'DC',
	'Federated States Of Micronesia': 'FM',
	Florida: 'FL',
	Georgia: 'GA',
	Guam: 'GU',
	Hawaii: 'HI',
	Idaho: 'ID',
	Illinois: 'IL',
	Indiana: 'IN',
	Iowa: 'IA',
	Kansas: 'KS',
	Kentucky: 'KY',
	Louisiana: 'LA',
	Maine: 'ME',
	'Marshall Islands': 'MH',
	Maryland: 'MD',
	Massachusetts: 'MA',
	Michigan: 'MI',
	Minnesota: 'MN',
	Mississippi: 'MS',
	Missouri: 'MO',
	Montana: 'MT',
	Nebraska: 'NE',
	Nevada: 'NV',
	'New Hampshire': 'NH',
	'New Jersey': 'NJ',
	'New Mexico': 'NM',
	'New York': 'NY',
	'North Carolina': 'NC',
	'North Dakota': 'ND',
	'Northern Mariana Islands': 'MP',
	Ohio: 'OH',
	Oklahoma: 'OK',
	Oregon: 'OR',
	Palau: 'PW',
	Pennsylvania: 'PA',
	'Puerto Rico': 'PR',
	'Rhode Island': 'RI',
	'South Carolina': 'SC',
	'South Dakota': 'SD',
	Tennessee: 'TN',
	Texas: 'TX',
	Utah: 'UT',
	Vermont: 'VT',
	'Virgin Islands': 'VI',
	Virginia: 'VA',
	Washington: 'WA',
	'West Virginia': 'WV',
	Wisconsin: 'WI',
	Wyoming: 'WY',
};

export const firebaseKeys = {
	owner: 'Ownername',
	call: 'Station',
	dial: 'Freq',
	city: 'Mcity',
	state: 'Mstate',
	name: 'GM2',
	market: 'NDMAName',
	dma: 'NDMAName',
	format: 'FmtName1',
	metro: 'HomeMarket',
};

export const capitalize = (string) => {
	let value = string.split(' ');
	const capitalized = value.map(
		(v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase()
	);
	return capitalized.join(' ');
};

export const initialFiltersStations = {
	state: null,
	call: null,
	dial: null,
	name: null,
	city: null,
	format: null,
	owner: null,
	market: null,
	researchData: null,
	customStaGroup: null,
	previousDeal: null,
};

export const typeList = {
	owner: [],
	market: [],
	city: [],
	call: [],
	name: [],
};

export const initialFilterStationAtom = atom({
	key: 'initialFilterStations', // unique ID (with respect to other atoms/selectors)
	default: initialFiltersStations, // default value (aka initial value)
});

export const filterDataList = atom({
	key: 'filterDataList',
	default: [],
});

export const filterSelectedOwnerList = atom({
	key: 'filterSelectedOwnerList',
	default: [],
});

export const filterLoading = atom({
	key: 'filterLoading',
	default: false,
});

export const filterTypeList = atom({
	key: 'filteronTypeList',
	default: typeList,
});

export const customStaGroupIdsArr = atom({
	key: 'customStaGroupIdsArr',
	default: [],
});

export const dealCallsArr = atom({
	key: 'dealCallsArr',
	default: [],
});

export const isNewButtonDisabled = atom({
	key: 'isNewButtonDisablled',
	default: true,
});

export const previousDealsDataArray = atom({
	key: 'previosDealsDataArray',
	default: true,
});

export const filterAqhList = atom({
	key: 'filterAqhList',
	default: [],
});

export const createCreditsTableRowData = (name, credits, type) => {
	return { name, credits, type };
};

export const selectedPreviousDeal = atom({
	key: 'selectedPrevious',
	default: null,
});

export const checkedPreviousDeal = atom({
	key: 'checkedPrevious',
	default: true,
});

export const stationArray = atom({
	key: 'stationsArray',
	default: [],
});

export const customStaGroupDataArray = atom({
	key: 'customStaGRoupDataArray',
	default: [],
});

export const importCsvData = atom({
	key: 'importCsvArray',
	default: null,
});

export const selectedRowsArray = atom({
	key: 'selectedRownsArray',
	default: [],
});

export const staGroupsArray = atom({
	key: 'staGroupsArray',
	default: [],
});

export const salesArray = atom({
	key: 'salesArray',
	default: [],
});

// reset all the data in the filter page and aqh calculator page
export const useResetFilterPageData = () => {
	const resetFilters = useResetRecoilState(initialFilterStationAtom);
	const resetStations = useResetRecoilState(stationArray);
	const resetGroups = useResetRecoilState(customStaGroupDataArray);
	const resetImport = useResetRecoilState(importCsvData);
	const resetselectedRows = useResetRecoilState(selectedRowsArray);
	const resetStaGroups = useResetRecoilState(staGroupsArray);
	const resetSalesArray = useResetRecoilState(salesArray);
	const resetFilterDataList = useResetRecoilState(filterDataList);
	const resetFilterSelectedOwnerList = useResetRecoilState(
		filterSelectedOwnerList
	);
	const resetLoading = useResetRecoilState(filterLoading);
	const resetFilterTypeList = useResetRecoilState(filterTypeList);
	const resetCustomStaGroupIdsArr = useResetRecoilState(customStaGroupIdsArr);
	const resetDealCallsArr = useResetRecoilState(dealCallsArr);
	const resetIsNewButtonDisabled = useResetRecoilState(isNewButtonDisabled);
	const resetPreviousDealsDataArray = useResetRecoilState(
		previousDealsDataArray
	);
	const resetFilterAqhList = useResetRecoilState(filterAqhList);
	const resetSelectedPreviousDeal = useResetRecoilState(selectedPreviousDeal);
	const resetCheckedPreviousDeal = useResetRecoilState(checkedPreviousDeal);

	return () => {
		resetFilters();
		resetStations();
		resetGroups();
		resetImport();
		resetselectedRows();
		resetStaGroups();
		resetSalesArray();
		resetFilterDataList();
		resetLoading();
		resetFilterSelectedOwnerList();
		resetFilterTypeList();
		resetCustomStaGroupIdsArr();
		resetDealCallsArr();
		resetIsNewButtonDisabled();
		resetPreviousDealsDataArray();
		resetFilterAqhList();
		resetSelectedPreviousDeal();
		resetCheckedPreviousDeal();
	};
};

export const specialCities = [
	'WATERLOO',
	'BELLE PLAINE',
	'LOS ANGELES',
	'Los Angeles',
];
