import moment from 'moment';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/config';

export const optionsTableRewards = {
	filterType: 'dropdown',
	pagination: true,
	rowsPerPage: 50,
	rowsPerPageOptions: [25, 50, 100],
	print: false,
	download: false,
	viewColumns: false,
	search: false,
	filter: false,
	sort: true,
};

export const optionsOrderHistory = {
	filterType: 'dropdown',
	pagination: true,
	rowsPerPage: 5,
	print: false,
	download: false,
	viewColumns: false,
	search: false,
	filter: false,
	sort: true,
	selectableRows: 'none',
};

export const formatKey = (key) => {
	return key
		.replace(/([A-Z])/g, ' $1')
		.replace(/^./, (str) => str.toUpperCase());
};

export const createUniqueId = (record, index) => {
	// Generate a unique ID for each record
	return `${record.contractId}-${record.dealNumber}-${index}`;
};

export const handleCheckboxChange = async (
	record,
	isChecked,
	index,
	currentMonth,
	currentYear,
	setCheckedState
) => {
	const { contractsInfo } = record;
	const uniqueId = createUniqueId(record, index);
	try {
		const rewards = contractsInfo.mainObj?.rewards || {};
		const visaRewards = rewards.visa || [];

		if (Array.isArray(visaRewards)) {
			const updatedVisaRewards = visaRewards.map((reward) => {
				const rewardDate =
					reward.date && reward.date.seconds
						? new Date(reward.date.seconds * 1000)
						: null;
				// Check if the reward matches the current month and year
				const isMatch =
					rewardDate &&
					moment(rewardDate).month() === currentMonth &&
					moment(rewardDate).year() === currentYear &&
					reward.quantity === record.quantity &&
					reward.value === record.value;

				if (isMatch) {
					return { ...reward, manually: isChecked }; // Update the reward
				}
				return reward;
			});

			// Update the state with the new checked value
			setCheckedState((prevState) => ({
				...prevState,
				[uniqueId]: isChecked,
			}));

			// Update the rewards in the database
			await updateDoc(doc(db, 'contracts', record.contractId), {
				'mainObj.rewards.visa': updatedVisaRewards,
			});
		} else {
			console.error('The rewards are not an array');
		}
	} catch (error) {
		console.error('Error to update order', error);
	}
};
