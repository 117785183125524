/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
	Box,
	Typography,
	Grid,
	Autocomplete,
	TextField,
	Button,
	CircularProgress,
} from '@mui/material';
import {
	Add as AddIcon,
	SaveAs as SaveIcon,
	ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import { firebaseKeys } from '../../StationList/utils/constants';
import {
	collection,
	limit,
	onSnapshot,
	query,
	where,
	getDocs,
	FieldPath,
} from 'firebase/firestore';
import { db } from '../../../firebase/config';
import SaveModal from './SaveModal';
import { useNavigate } from 'react-router-dom';
import {
	aqhTotalByAddress,
	initialFilters,
	typeListPropspector,
} from '../utils/utils';
import {
	getUniqueArr,
	SF_Client_A_F,
	sortWithGroup,
	splitArrayIntoChunks,
} from '../../../utils';
import useProfileContext from '../../../hooks/useProfileContext';
import useAuthContext from '../../../hooks/useAuthContext';

const FilterBox = styled(Box)(({ theme }) => ({
	marginBottom: '15px',
	'.MuiTypography-root': {},
	'.action_btn': {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
		gap: '5px',
		'& button': {
			minWidth: '130px',
			marginRight: '5px',
			borderRadius: theme.spacing(1),
			padding: '3px 8px',
			'&:hover': {
				'&.add': {
					backgroundColor: '#0081ea',
				},
				'&.save': {
					backgroundColor: '#6ea671',
				},
				'&.ahqcalc': {
					backgroundColor: '#7e7d7d',
				},
			},
		},
	},
}));
const SearchForm = ({
	setSalesList,
	ownerList,
	DMAList,
	formatList,
	selectedRows,
	salesList,
	refDb,
}) => {
	const { profile } = useProfileContext();
	const { user } = useAuthContext();
	const navigate = useNavigate();
	const [filters, setFilters] = useState(initialFilters);
	const [selectedOwnersList, setSelectedOwnersList] = useState([]);
	const [openSaveModal, setOpenSaveModal] = useState(false);
	const [dataList, setDataList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [onTypeList, setOnTypeList] = useState(typeListPropspector);
	const seasonsRef = collection(db, 'seasons');
	const [currentRef, setCurrentRef] = useState(null);

	// get a current and past seasons collection
	useEffect(() => {
		const getSeasons = async () => {
			const q = query(seasonsRef, limit(1));
			const seasonsDocs = await getDocs(q);
			let seasonsResults = [];
			seasonsDocs.forEach((doc) => {
				seasonsResults.push({ ...doc.data(), seasonId: doc.id });
			});
			setCurrentRef(seasonsResults[0]?.currentSeason);
		};
		getSeasons();
	}, []);

	const handleChange = (name, value) => {
		// if (name === "owner" || name === "market") {
		//     setFilters({ ...filters, [name]: value ? value[firebaseKeys[name]] : null })
		//     return console.log({ ...filters, [name]: value ? value[firebaseKeys[name]] : null })
		// }
		setFilters({ ...filters, [name]: value });
	};

	const handleSearchStation = () => {
		// if (selectedOwnersList.includes()) return false
		let notNullValue = {};
		for (let prop in filters) {
			if (filters[prop] !== null) {
				notNullValue = { ...notNullValue, [prop]: filters[prop] };
			}
		}

		if (notNullValue && Object.values(notNullValue).length === 0)
			return console.log('nothing found!');

		const queryParams = [];
		Object.values(notNullValue).length > 0 && setLoading(true);
		let filterQuerry = [];
		filterQuerry = Object.keys(notNullValue).map((v) =>
			where(firebaseKeys[v], '==', notNullValue[v])
		);

		queryParams.push(...filterQuerry);

		let mergedResults = [];
		let splitClientQueryArr = splitArrayIntoChunks(10, SF_Client_A_F);

		splitClientQueryArr.map((queryArr, idx) => {
			let queries = [
				...queryParams,
				limit(5000),
				where(new FieldPath('Client_SunFun.A_F'), 'in', queryArr),
			];
			const q = query(refDb, ...queries);
			const unsub = onSnapshot(q, (snapshot) => {
				let results = [];
				snapshot.docs.forEach((doc) => {
					setSelectedOwnersList([...selectedOwnersList, doc.id]);
					results.push({ ...doc.data(), id: doc.id });
				});
				mergedResults = [...mergedResults, ...results];

				// only run when loop reaches to last index
				if (splitClientQueryArr.length - 1 <= idx) {
					let sortedArray =
						selectedRows.length > 0
							? mergedResults.sort((a, b) => {
									let isSelected = selectedRows.find(
										(row) => row.Station === a.Station && row.FmtName1 === a.FmtName1
									);
									if (isSelected) return -1;
									else return 0;
							  })
							: mergedResults;

					const finalResult = [...selectedRows, ...sortedArray];
					const unique = getUniqueArr(finalResult, ['id']);

					if (unique.length === 0) return setSalesList([]);
					setDataList(unique);
				}
			});
			return unsub;
		});
	};

	useEffect(() => {
		if (dataList.length > 0 && loading) {
			if (currentRef) {
				const refSpr = collection(db, currentRef);
				let resultsSPr = [];
				let allStations = dataList.map((s) => s.Station);
				let spliSalesIntoSmallArr = splitArrayIntoChunks(10, allStations);

				spliSalesIntoSmallArr.map(async (queryArr, idx) => {
					let result = [];

					const querySpr = query(refSpr, where('Station', 'in', queryArr));
					const querySnapshot = await getDocs(querySpr);
					querySnapshot.forEach((doc) => {
						result.push({
							Station: doc.data().Station,
							Aqh: doc.data()['MF 6a-7p'],
							dayParts: doc.data(),
						});
					});
					resultsSPr = [...resultsSPr, ...result];

					if (spliSalesIntoSmallArr.length === idx + 1) {
						let mergeData = dataList.map((data) => {
							let getAqh = resultsSPr?.find(
								(sprObj) => sprObj['Station'] === data['Station']
							);
							return {
								...data,
								Aqh: getAqh ? getAqh.Aqh : 0,
								dayParts: getAqh?.dayParts,
							};
						});

						setSalesList(sortWithGroup(mergeData, 'Aqh', 'Ownername'));
						setDataList([]);
						setLoading(false);
					}
				});
			}
		}
	}, [dataList, salesList, loading, currentRef]);

	useEffect(() => {
		if (loading) {
			setTimeout(() => {
				setLoading(false);
			}, 5000);
		}
	}, [loading]);

	const calculateCluster = () => {
		if (salesList.length > 0) {
			let getGroupedAqh = aqhTotalByAddress(salesList);
			let getSalesByAqhGroup = salesList.map((sale) => {
				return { ...sale, ClusterAqh: getGroupedAqh[sale.Maddr1] || 0 };
			});
			setSalesList(getSalesByAqhGroup);
		}
	};

	const hanldeAHQCalc = () => {
		navigate('/dashboard/aqhcalculator', { state: selectedRows });
	};

	return (
		<FilterBox>
			<Typography variant="h6" mb={1}>
				Search By
			</Typography>
			<Grid
				container
				spacing={2}
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
			>
				<Grid
					container
					spacing={2}
					item
					xs={7}
					sx={{ maxWidth: '450px !important' }}
				>
					<Grid item xs={6}>
						<Autocomplete
							options={ownerList}
							getOptionLabel={(option) => option}
							isOptionEqualToValue={(option, value) => option === value}
							id="owner"
							size="small"
							value={filters.owner || null}
							onChange={(_, newValue) => handleChange('owner', newValue)}
							renderInput={(params) => (
								<TextField {...params} label="Owner" variant="standard" />
							)}
						/>
					</Grid>
					<Grid item xs={6}>
						<Autocomplete
							options={formatList || ['']}
							getOptionLabel={(option) => option}
							isOptionEqualToValue={(option, value) => option === value}
							id="format"
							size="small"
							value={filters.format}
							onChange={(_, newValue) => handleChange('format', newValue)}
							renderInput={(params) => (
								<TextField {...params} label="Format" variant="standard" />
							)}
						/>
					</Grid>
					<Grid item xs={5}>
						<Autocomplete
							options={DMAList || []}
							getOptionLabel={(option) => option}
							isOptionEqualToValue={(option, value) => option === value}
							id="market"
							size="small"
							value={filters.market || null}
							onChange={(_, newValue) => handleChange('market', newValue)}
							renderInput={(params) => (
								<TextField {...params} label="DMA" variant="standard" />
							)}
						/>
					</Grid>
					<Grid item xs={7} className="action_btn">
						<Button
							className="add"
							size="small"
							variant="contained"
							onClick={(_) => {
								handleSearchStation();
							}}
							sx={{ backgroundColor: '#42A5F5' }}
							endIcon={<AddIcon />}
							disabled={loading}
							startIcon={loading && <CircularProgress size={12} />}
						>
							Search
						</Button>
						<Button
							className="save"
							size="small"
							variant="contained"
							onClick={(_) => {
								setOpenSaveModal(true);
							}}
							sx={{ backgroundColor: '#A5D6A7' }}
							endIcon={<SaveIcon />}
							disabled={selectedRows.length === 0}
						>
							Save
						</Button>
					</Grid>
				</Grid>

				<Grid container spacing={2} item xs={5} width="100%">
					<Grid
						item
						xs={12}
						className="action_btn"
						sx={{ justifyContent: 'flex-end !important', alignItems: 'flex-end' }}
					>
						<Button
							className="ahqcalc"
							size="small"
							variant="contained"
							sx={{ backgroundColor: '#9E9E9E' }}
							endIcon={<ArrowForwardIcon />}
							onClick={hanldeAHQCalc}
							disabled={selectedRows.length === 0}
						>
							AQH Calculator
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid container spacing={2} mt={1}>
				<Grid
					item
					xs={6}
					className="action_btn"
					sx={{ maxWidth: '350px !important' }}
				>
					<Button
						className="save"
						size="small"
						variant="contained"
						onClick={(_) => calculateCluster()}
						disabled={!salesList.length}
						sx={{ backgroundColor: '#A5D6A7' }}
					>
						Calculate Cluster AQH
					</Button>
				</Grid>
			</Grid>
			<SaveModal
				openModal={openSaveModal}
				setOpenModal={setOpenSaveModal}
				salesList={salesList}
				selectedRows={selectedRows}
				profile={profile || {}}
				user={user || {}}
			/>
		</FilterBox>
	);
};

export default SearchForm;
