/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {
	IconButton,
	Button,
	TableHead,
	TableRow,
	TableCell,
	TextField,
	Box,
	Paper,
	TableContainer,
	Table,
	TableBody,
	Typography,
	Stack,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
	visaRewards,
	amazonRewards,
	billpayRewards,
	columnsArray,
	columnsArrayAmazon,
	columnsArrayBillpay,
	handleObjectWithDay,
	handleDefaultDate,
} from '../utils/constants';
import { useRecoilValue } from 'recoil';
import {
	filterDataList,
	initialFilterStationAtom,
} from '../../StationList/utils/constants';
import {
	updateOrCreateOptionData,
	validateOptionErrors,
	validateVacationCredits,
	proposalStatusReview,
} from '../utils/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function RewardsModal({
	docId,
	optionId,
	setOptionId,
	setRewards,
	openPublishQuoteModal,
	allInputs,
	setOpenRewardsModal,
	openRewardsModal,
	dates,
	tableData,
	selectedRowsAqh,
	isAqhOption,
	isEditAqhOption,
	optionData,
	aqhSavedOptions,
	vacationCredits,
	isNewOne,
	setIsNewOne,
	proposalObj,
	setProposalId,
	setAqhSavedOptions,
	setOpenVacationTripsModal,
	isPublish,
	proposalState,
	openOverrideModal,
	setPublishProcess,
	setOpenAirportsModal,
	dealTerms,
	setOpenRolloverModal,
	setCurrentRewards,
}) {
	const [openVisaDate, setOpenVisaDate] = React.useState(-1);
	const [openAmazonDate, setOpenAmazonDate] = React.useState(-1);
	const [openBillpayDate, setOpenBillpayDate] = React.useState(-1);
	const [visaRows, setVisaRows] = useState([{}]);
	const [amazonRows, setAmazonRows] = useState([{}]);
	const [billpayRows, setBillpayRows] = useState([{}]);
	const [visaTotal, setVisaTotal] = useState(0);
	const [amazonTotal, setAmazonTotal] = useState(0);
	const [billpayTotal, setBillpayTotal] = useState(0);
	const [visaRevenue, setVisaRevenue] = useState(0);
	const [amazonRevenue, setAmazonRevenue] = useState(0);
	const [billpayRevenue, setBillpayRevenue] = useState(0);
	const [disabledBlockSubmit, setDisableBlockSubmit] = useState(false);
	const visaCalculator = Number(allInputs[1].cogs);
	const amazonCalculator = Number(allInputs[2].cogs);
	const billpayCalculator = Number(allInputs[3].cogs);
	const filtersAtom = useRecoilValue(initialFilterStationAtom);
	const filterDataListAtom = useRecoilValue(filterDataList);

	// Add a new row from each table
	const handleVisaAddRow = () => {
		const item = {};
		setVisaRows([...visaRows, item]);
	};

	const handleAmazonAddRow = () => {
		const item = {};
		setAmazonRows([...amazonRows, item]);
	};

	const handleBillpayAddRow = () => {
		const item = {};
		setBillpayRows([...billpayRows, item]);
	};

	// Remove a row from each table
	const handleRemoveSpecificRowVisa = (idx) => {
		if (idx !== 0) {
			const tempRows = [...visaRows];
			tempRows.splice(idx, 1);
			setVisaRows(tempRows);
		}
	};

	const handleRemoveSpecificRowAmazon = (idx) => {
		if (idx !== 0) {
			const tempRows = [...amazonRows];
			tempRows.splice(idx, 1);
			setAmazonRows(tempRows);
		}
	};

	const handleRemoveSpecificRowBillpay = (idx) => {
		if (idx !== 0) {
			const tempRows = [...billpayRows];
			tempRows.splice(idx, 1);
			setBillpayRows(tempRows);
		}
	};

	// update state for each table
	const updateStateVisa = (e, name, idx, column) => {
		if (name === 'date') {
			let prope = column;
			let index = idx;
			let fieldValue = e;

			const tempRows = [...visaRows];
			const tempObj = visaRows[index];
			tempObj[prope] = fieldValue;
			// It changes to the 15th of each month
			// return object to visaRows clone
			tempRows[index] = handleObjectWithDay(tempObj);
			setVisaRows(tempRows);
		} else {
			let prope = e.target.attributes.column.value;
			let index = e.target.attributes.index.value;
			let fieldValue = e.target.value;

			const tempRows = [...visaRows];
			const tempObj = visaRows[index];
			tempObj[prope] = fieldValue;
			// Only set the default date if it doesn't exist
			if (!tempObj.date) {
				tempObj.date = handleDefaultDate();
			}

			// return object to visaRows clone
			tempRows[index] = tempObj;
			setVisaRows(tempRows);
		}
	};

	const updateStateAmazon = (e, name, idx, column) => {
		if (name === 'date') {
			let prope = column;
			let index = idx;
			let fieldValue = e;
			const tempRows = [...amazonRows];
			const tempObj = amazonRows[index];
			tempObj[prope] = fieldValue;
			// It changes to the 15th of each month
			// return object to amazonRows clone
			tempRows[index] = handleObjectWithDay(tempObj);
			setAmazonRows(tempRows);
		} else {
			let prope = e.target.attributes.column.value;
			let index = e.target.attributes.index.value;
			let fieldValue = e.target.value;

			const tempRows = [...amazonRows];
			const tempObj = amazonRows[index];
			tempObj[prope] = fieldValue;
			// Only set the default date if it doesn't exist
			if (!tempObj.date) {
				tempObj.date = handleDefaultDate();
			}

			// return object to amazonRows clone
			tempRows[index] = tempObj;
			setAmazonRows(tempRows);
		}
	};

	const updateStateBillpay = (e, name, idx, column) => {
		if (name === 'date') {
			let prope = column;
			let index = idx;
			let fieldValue = e;

			const tempRows = [...billpayRows];
			const tempObj = billpayRows[index];
			tempObj[prope] = fieldValue;
			// It changes to the 15th of each month
			// return object to billpayRows clone
			tempRows[index] = handleObjectWithDay(tempObj);
			setBillpayRows(tempRows);
		} else {
			let prope = e.target.attributes.column.value;
			let index = e.target.attributes.index.value;
			let fieldValue = e.target.value;

			const tempRows = [...billpayRows];
			const tempObj = billpayRows[index];
			tempObj[prope] = fieldValue;
			// Only set the default date if it doesn't exist
			if (!tempObj.date) {
				tempObj.date = handleDefaultDate();
			}

			// return object to billpayRows clone
			tempRows[index] = tempObj;
			setBillpayRows(tempRows);
		}
	};

	// sum total of rewards function
	const result = (array) => {
		const sumOfValues = array.reduce((previous, current) => {
			if (current.quantity === '' || current.quantity === undefined) {
				return Number(previous);
			} else {
				return Number(previous) + Number(current.quantity);
			}
		}, 0);
		return sumOfValues;
	};

	// sum total of revenue
	const revenue = (array) => {
		const sumOfValues = array.reduce((previous, current) => {
			if (current.value === '' || current.value === undefined) {
				return Number(previous);
			} else {
				let revenueResult = Number(current.value) * Number(current.quantity);
				return Number(previous) + revenueResult;
			}
		}, 0);
		return sumOfValues;
	};
	// get total rewards and revenue
	useEffect(() => {
		const sumTotal = () => {
			// sum total of rewards
			const totalVisa = result(visaRows);
			const totalAmazon = result(amazonRows);
			const totalBillpay = result(billpayRows);
			const totalDeliverableVisa = revenue(visaRows);
			const totalDeliverableAmazon = revenue(amazonRows);
			const totalDeliverableBillpay = revenue(billpayRows);
			// set state for total rewards
			setVisaTotal(totalVisa);
			setAmazonTotal(totalAmazon);
			setBillpayTotal(totalBillpay);
			setVisaRevenue(totalDeliverableVisa);
			setAmazonRevenue(totalDeliverableAmazon);
			setBillpayRevenue(totalDeliverableBillpay);
			//validate block submit button
			if (
				totalDeliverableVisa === visaCalculator &&
				totalDeliverableAmazon === amazonCalculator &&
				totalDeliverableBillpay === billpayCalculator
			) {
				setDisableBlockSubmit(false);
			} else {
				setDisableBlockSubmit(true);
			}
		};
		sumTotal();
	}, [visaRows, amazonRows, billpayRows, openRewardsModal]);

	// close modal
	const handleCloseModal = (event, reason) => {
		// if click outside the modal or press esc key, don't close the modal
		if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
			return;
		}
		setOpenVacationTripsModal(false);
		setOpenRewardsModal(false);
		setVisaRows([{}]);
		setAmazonRows([{}]);
		setBillpayRows([{}]);
		setRewards(null);
	};

	// calculate remaining revenue
	const remainingRevenue = (total, deliverable) => {
		const remaining = Number(total) - Number(deliverable);
		if (isNaN(remaining)) return '';
		return remaining;
	};

	const validateRewards = (allInputs) => {
		if (
			(allInputs[1]?.revenue !== '' && allInputs[1]?.cogs !== '') ||
			(allInputs[2]?.revenue !== '' && allInputs[2]?.cogs !== '') ||
			(allInputs[3]?.revenue !== '' && allInputs[3]?.cogs !== '')
		) {
			return true;
		}
		return false;
	};

	const saveRewards = async () => {
		setOpenVacationTripsModal(false);
		try {
			let mainObj = {
				filters: {
					filters: filtersAtom,
					filterDataList: filterDataListAtom,
				},
			};
			// create data object to update proposal
			const data = {
				visa: visaRows,
				amazon: amazonRows,
				billpay: billpayRows,
			};
			setCurrentRewards(validateRewards(allInputs) ? data : null);
			setRewards(data);

			const generalObject = (optionId) => {
				return {
					mainObj,
					vacationCredits,
					rewards: data,
					optionData,
					isAqhOption,
					isEditAqhOption,
					setOptionId,
					aqhSavedOptions,
					docId,
					optionId,
					createNewOne: isNewOne,
					proposalObj,
					setProposalId,
					setAqhSavedOptions,
				};
			};

			handleCloseModal();

			// get proposal status to verify if the proposal is approved or denied
			const proposalStatus = docId ? await proposalStatusReview(docId) : 'unread';

			// get the actual proposal state
			const actualProposalState =
				proposalStatus && proposalStatus !== 'unread' // if proposal has a status, use query status
					? proposalStatus // if proposal has no status, use proposalState
					: proposalState; // state from data in father component

			if (actualProposalState === 'approved') {
				await updateOrCreateOptionData(generalObject(optionId));
				if (isPublish) {
					openPublishQuoteModal(true);
				}
				setIsNewOne(false);
			} else if (actualProposalState === 'denied') {
				// if proposal is denied, update proposal data if is necessary
				await updateOrCreateOptionData(generalObject(optionId));
				if (isPublish) {
					openOverrideModal(true);
				}
			} else {
				if (isPublish) {
					await updateOrCreateOptionData(generalObject(optionData?.id));
					const result = await validateOptionErrors(aqhSavedOptions);
					const vacationsResult = await validateVacationCredits(aqhSavedOptions);
					// if one saved option in the proposal has errors, show manager override modal
					if (vacationsResult.includes(true) && result.includes(true)) {
						setOpenAirportsModal(true);
					} else if (!vacationsResult.includes(true) && result.includes(true)) {
						// If dealTerms is true, show Rollover modal
						if (dealTerms !== null) {
							setOpenRolloverModal(true);
						} else {
							// if one saved option in the proposal has errors, show manager override modal
							openOverrideModal(true);
						}
					} else if (vacationsResult.includes(true) && !result.includes(true)) {
						setOpenAirportsModal(true);
					} else {
						// If dealTerms is true, show Rollover modal
						if (dealTerms !== null) {
							setOpenRolloverModal(true);
						} else {
							// open publish quote modal
							openPublishQuoteModal(true);
						}
					}
				} else {
					await updateOrCreateOptionData(generalObject(optionId));
				}
				setIsNewOne(false);
			}
		} catch (error) {
			console.log('Error updating proposal: ', error);
		}
	};

	const totalRow = (array, idx) => {
		if (array.length >= idx) {
			const result = Number(array[idx].quantity) * Number(array[idx].value);
			if (isNaN(result)) return '';
			return `$ ${result}`;
		} else {
			return '';
		}
	};

	return (
		<Dialog
			open={openRewardsModal}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseModal}
			aria-describedby="alert-dialog-slide-description"
			sx={{
				'& .MuiDialog-paper': {
					minWidth: '850px',
					minHeight: '200px',
				},
			}}
		>
			<DialogTitle sx={{ pb: 0 }}>Add Rewards</DialogTitle>
			<DialogContent sx={{ alignItems: 'center' }}>
				{
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						sx={{ width: '100%' }}
					>
						<Paper sx={{ width: '100%', mb: 2 }}>
							<TableContainer sx={{ maxWidth: 850, maxHeight: 550 }}>
								{visaCalculator && visaCalculator > 0 ? (
									<>
										<Table stickyHeader aria-label="sticky table">
											<TableHead>
												<TableRow>
													{visaRewards.map((column, idx) => (
														<TableCell
															sx={{
																backgroundColor: '#6C6767',
																color: 'white',
																paddingTop: 0.5,
																paddingBottom: 0.5,
															}}
															key={column.id}
															align={column.align}
															width={idx === 0 ? '20%' : idx === 1 ? '60%' : '20%'}
														>
															{column.label}
														</TableCell>
													))}
												</TableRow>
											</TableHead>
											<TableBody>
												{/* subtitle for visa reward cards */}
												<TableRow>
													<TableCell
														sx={{
															backgroundColor: '#d9d9d9',
															color: 'white',
															paddingTop: 0.5,
															paddingBottom: 0.5,
														}}
													/>
													<TableCell
														sx={{
															backgroundColor: '#d9d9d9',
															color: 'black',
															paddingTop: 0.5,
															paddingBottom: 0.5,
														}}
													>
														Visa Reward Cards must be used in full within 1 year from the date
														of issue. Any unused funds will expire after that.
													</TableCell>
													<TableCell
														sx={{
															backgroundColor: '#d9d9d9',
															color: 'black',
															paddingTop: 0.5,
															paddingBottom: 0.5,
														}}
													/>
													<TableCell
														sx={{
															backgroundColor: '#d9d9d9',
															color: 'black',
															paddingTop: 0.5,
															paddingBottom: 0.5,
														}}
														align="center"
													/>
												</TableRow>
												{visaRows.map((item, idx) => (
													<TableRow key={idx}>
														{columnsArray.map((column, index) => (
															<TableCell
																key={index}
																sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}
															>
																{index === 0 || index === 1 ? (
																	<Stack direction="row" spacing={1}>
																		<Typography>{index === 1 ? '$' : ''}</Typography>
																		<input
																			style={{ padding: 4, textAlign: 'center', width: 80 }}
																			type="number"
																			column={column}
																			value={visaRows[idx][column] || ''}
																			index={idx}
																			onChange={(e) => updateStateVisa(e)}
																		/>
																		<Typography>{index === 1 ? 'Cards' : ''}</Typography>
																	</Stack>
																) : index === 2 ? (
																	<Typography>{totalRow(visaRows, idx)}</Typography>
																) : (
																	<LocalizationProvider dateAdapter={AdapterDateFns}>
																		<DatePicker
																			open={openVisaDate === idx}
																			onOpen={() => setOpenVisaDate(idx)}
																			onClose={() => setOpenVisaDate(-1)}
																			components={{ OpenPickerIcon: CalendarMonthIcon }}
																			value={visaRows[idx][column]}
																			views={['month', 'year']}
																			onChange={(e) => updateStateVisa(e, 'date', idx, column)}
																			renderInput={(params) => (
																				<TextField
																					disabled={true}
																					{...params}
																					size="small"
																					sx={{ width: '180px' }}
																					onClick={() => setOpenVisaDate(idx)}
																				/>
																			)}
																		/>
																	</LocalizationProvider>
																)}
															</TableCell>
														))}

														<TableCell>
															<IconButton
																color="error"
																onClick={() => handleRemoveSpecificRowVisa(idx)}
															>
																<RemoveCircleIcon />
															</IconButton>
															<IconButton color="success" onClick={handleVisaAddRow}>
																<AddCircleIcon />
															</IconButton>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
										<Typography
											sx={{
												color:
													remainingRevenue(visaCalculator, visaRevenue) >= 0
														? 'green'
														: 'red',
											}}
											pl={2}
										>
											Total $ Remaining: {remainingRevenue(visaCalculator, visaRevenue)}
										</Typography>
									</>
								) : (
									''
								)}
								{amazonCalculator && amazonCalculator > 0 ? (
									<>
										<Table
											// Amazon gift cards table
											sx={{ marginTop: 5 }}
											stickyHeader
											aria-label="sticky table"
										>
											<TableHead>
												<TableRow>
													{amazonRewards.map((column, idx) => (
														<TableCell
															sx={{
																backgroundColor: '#6C6767',
																color: 'white',
																paddingTop: 0.5,
																paddingBottom: 0.5,
															}}
															key={column.id}
															align={column.align}
															width={idx === 0 ? '20%' : idx === 1 ? '60%' : '20%'}
														>
															{column.label}
														</TableCell>
													))}
												</TableRow>
											</TableHead>
											<TableBody>
												{amazonRows.map((item, idx) => (
													<TableRow key={idx}>
														{columnsArrayAmazon.map((column, index) => (
															<TableCell key={index}>
																{index === 0 || index === 1 ? (
																	<Stack direction="row" spacing={1}>
																		<Typography>{index === 1 ? '$' : ''}</Typography>
																		<input
																			style={{ padding: 4, textAlign: 'center', width: 80 }}
																			type="number"
																			column={column}
																			value={amazonRows[idx][column] || ''}
																			index={idx}
																			onChange={(e) => updateStateAmazon(e)}
																		/>
																		<Typography>{index === 1 ? 'Gift Cards' : ''}</Typography>
																	</Stack>
																) : index === 2 ? (
																	<Typography>{totalRow(amazonRows, idx)}</Typography>
																) : (
																	<LocalizationProvider dateAdapter={AdapterDateFns}>
																		<DatePicker
																			open={openAmazonDate === idx}
																			onOpen={() => setOpenAmazonDate(idx)}
																			onClose={() => setOpenAmazonDate(-1)}
																			components={{ OpenPickerIcon: CalendarMonthIcon }}
																			value={amazonRows[idx][column]}
																			views={['month', 'year']}
																			onChange={(e) => updateStateAmazon(e, 'date', idx, column)}
																			renderInput={(params) => (
																				<TextField
																					disabled={true}
																					{...params}
																					size="small"
																					sx={{ width: '180px' }}
																					onClick={() => setOpenAmazonDate(idx)}
																				/>
																			)}
																		/>
																	</LocalizationProvider>
																)}
															</TableCell>
														))}

														<TableCell>
															<IconButton
																color="error"
																onClick={() => handleRemoveSpecificRowAmazon(idx)}
															>
																<RemoveCircleIcon />
															</IconButton>
															<IconButton color="success" onClick={handleAmazonAddRow}>
																<AddCircleIcon />
															</IconButton>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
										<Typography
											sx={{
												color:
													remainingRevenue(amazonCalculator, amazonRevenue) >= 0
														? 'green'
														: 'red',
											}}
											pl={2}
										>
											Total $ Remaining:{' '}
											{remainingRevenue(amazonCalculator, amazonRevenue)}
										</Typography>
									</>
								) : (
									''
								)}
								{billpayCalculator && billpayCalculator > 0 ? (
									<>
										<Table
											// Billpay table
											sx={{ marginTop: 5 }}
											stickyHeader
											aria-label="sticky table"
										>
											<TableHead>
												<TableRow>
													{billpayRewards.map((column, idx) => (
														<TableCell
															sx={{
																backgroundColor: '#6C6767',
																color: 'white',
																paddingTop: 0.5,
																paddingBottom: 0.5,
															}}
															key={column.id}
															align={column.align}
															width={idx === 0 ? '20%' : idx === 1 ? '60%' : '20%'}
														>
															{column.label}
														</TableCell>
													))}
												</TableRow>
											</TableHead>
											<TableBody>
												<TableCell
													sx={{
														backgroundColor: '#d9d9d9',
														color: 'white',
														paddingTop: 0.5,
														paddingBottom: 0.5,
													}}
												/>
												<TableCell
													sx={{
														backgroundColor: '#d9d9d9',
														color: 'black',
														paddingTop: 0.5,
														paddingBottom: 0.5,
													}}
												>
													To redeem your billpay, please send an estimate, quote, or written
													request to your SFM representative.
												</TableCell>
												<TableCell
													sx={{
														backgroundColor: '#d9d9d9',
														color: 'black',
														paddingTop: 0.5,
														paddingBottom: 0.5,
													}}
												/>
												<TableCell
													sx={{
														backgroundColor: '#d9d9d9',
														color: 'black',
														paddingTop: 0.5,
														paddingBottom: 0.5,
													}}
													align="center"
												/>
												{billpayRows.map((item, idx) => (
													<TableRow key={idx}>
														{columnsArrayBillpay.map((column, index) => (
															<TableCell key={index}>
																{index === 0 || index === 1 ? (
																	<input
																		style={{ padding: 4, textAlign: 'center', width: 80 }}
																		type="number"
																		column={column}
																		value={billpayRows[idx][column] || ''}
																		index={idx}
																		onChange={(e) => updateStateBillpay(e)}
																	/>
																) : index === 2 ? (
																	<Typography>{totalRow(billpayRows, idx)}</Typography>
																) : (
																	<LocalizationProvider dateAdapter={AdapterDateFns}>
																		<DatePicker
																			open={openBillpayDate === idx}
																			onOpen={() => setOpenBillpayDate(idx)}
																			onClose={() => setOpenBillpayDate(-1)}
																			components={{ OpenPickerIcon: CalendarMonthIcon }}
																			value={billpayRows[idx][column]}
																			views={['month', 'year']}
																			onChange={(e) => updateStateBillpay(e, 'date', idx, column)}
																			renderInput={(params) => (
																				<TextField
																					disabled={true}
																					{...params}
																					size="small"
																					sx={{ width: '180px' }}
																					onClick={() => setOpenBillpayDate(idx)}
																				/>
																			)}
																		/>
																	</LocalizationProvider>
																)}
															</TableCell>
														))}

														<TableCell>
															<IconButton
																color="error"
																onClick={() => handleRemoveSpecificRowBillpay(idx)}
															>
																<RemoveCircleIcon />
															</IconButton>
															<IconButton color="success" onClick={handleBillpayAddRow}>
																<AddCircleIcon />
															</IconButton>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
										<Typography
											sx={{
												color:
													remainingRevenue(billpayCalculator, billpayRevenue) >= 0
														? 'green'
														: 'red',
											}}
											pl={2}
										>
											Total $ Remaining:{' '}
											{remainingRevenue(billpayCalculator, billpayRevenue)}
										</Typography>
									</>
								) : (
									''
								)}
							</TableContainer>
						</Paper>
						<Button
							size="medium"
							variant="contained"
							color="success"
							onClick={saveRewards}
							disabled={disabledBlockSubmit}
						>
							Submit
						</Button>
					</Box>
				}
			</DialogContent>
		</Dialog>
	);
}
