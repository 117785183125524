/* eslint-disable  react-hooks/exhaustive-deps*/
import { ref } from 'firebase/storage';
import { useCallback, useEffect, useState } from 'react';
import { storage } from '../../../firebase/config';
import { v4 } from 'uuid';
import { useUploadFile } from 'react-firebase-hooks/storage';
/**
 * @description Function that is in charge of uploading different files in the form of a queue,
 * @returns profess: Amount in percentage of file uploaded and metadata that was shared
 * @returns nameFiles: namo of files that were uploaded
 * @returns uploadFiles: Function to start uploading files
 */
const useUploadMultipleFile = (slug) => {
	const [progress, setProgress] = useState();
	const [files, setFiles] = useState([]); //{ files: File, metadata: any{}}
	const [nameFiles, setNameFiles] = useState([]);
	const [currentUploading, setCurrentUploading] = useState();
	const [uploadFile, loading, snapshot] = useUploadFile();
	/**
	 * @description useEffect which is in charge of loading the next file to be uploaded, it is not activated until no file is uploaded.
	 */
	useEffect(() => {
		if (files && files.length > 0 && !currentUploading) {
			const filesTemp = [...files];
			const currentUploading = filesTemp.shift();
			setFiles(filesTemp);
			setCurrentUploading(currentUploading);
		}
	}, [files, currentUploading]);
	/**
	 * @description useEffect which is responsible for uploading files in the form of a queue.
	 */
	useEffect(() => {
		if (currentUploading) {
			let name = '';
			if (currentUploading.metadata.name) name = currentUploading.metadata.name;
			else name = `${v4()}_${currentUploading.file.name}`;
			setNameFiles([...nameFiles, name]);
			const nameRef = ref(storage, `${slug}/${name}`);
			uploadFile(nameRef, currentUploading.file);
		}
	}, [currentUploading]);

	/**
	 * @description useEffect which is responsible for monitoring the progress of file uploads
	 */
	useEffect(() => {
		if (snapshot && currentUploading) {
			if (snapshot.bytesTransferred === snapshot.totalBytes) {
				setProgress({
					progress: 100,
					metadata: currentUploading.metadata,
				});
			} else {
				const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setProgress({
					progress: progress,
					metadata: currentUploading.metadata,
				});
			}
		}
	}, [snapshot, currentUploading]);

	useEffect(() => {
		if (currentUploading && progress) {
			if (!loading) {
				setProgress({
					progress: 100,
					metadata: currentUploading.metadata,
				});
			}
		}
	}, [loading, currentUploading]);
	/**
	 * @description useEffect which is responsible for observing when a file has been uploaded completely, and restarting the file upload procedure.
	 */
	useEffect(() => {
		if (progress) {
			if (progress.progress === 100) {
				setProgress(undefined);
				setCurrentUploading(undefined);
			}
		}
	}, [progress]);
	const reset = useCallback(() => {
		setNameFiles([]);
		setFiles([]);
		setProgress(undefined);
		setCurrentUploading(undefined);
	});
	return {
		uploadFiles: setFiles,
		progress,
		nameFiles,
		reset,
	};
};
export default useUploadMultipleFile;
