/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from 'react';
import AQHLiveDataTable from './LiveDataTable';
import {
	Box,
	Grid,
	Typography,
	colors,
	Stack,
	Paper,
	Divider,
	Button,
	CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { numberWithCommas } from '../../../utils';
import MostRecentDeal from './MostRecentDeal';
import PreviousDeal from './PreviousDeal';
import {
	collection,
	onSnapshot,
	query,
	where,
	getDoc,
	doc,
} from 'firebase/firestore';
import { db } from '../../../firebase/config';
import useAuthContext from '../../../hooks/useAuthContext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOptionModal from './DeleteOptionModal';
import {
	createOptionObjectData,
	compressDataIfNecessary,
	decompressDayParts,
	decompressDayPartsField,
	dateTransform,
	sortedOptions,
} from '../utils/utils';
import { useSnackbar } from 'notistack';
import ChangeOptionName from './ChangeOptionName';
import DeliverableData from './DeliverableData';
import AddTagProposal from './AddTagProposal';
const MainBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	background: '#FCFBFB',
	border: '1px solid rgb(0 0 0 / 22%)',
	boxShadow: 'rgb(99 99 99 / 20%) 0px 2px 8px 0px',
	borderRadius: '6px',
	minHeight: '440px',
	width: '100%',
	minWidth: '420px',
	padding: '10px',
}));

const MuiGridContainer = styled(Grid)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	'& .MuiGrid-item': {
		display: 'flex',
		flexDirection: 'column',
		with: '100%',
	},
	'& .saveButton': {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		marginTop: '0.5rem',
	},
	'& .infobox': {
		padding: '0px 30px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'& > .MuiTypography-root.main': {
			marginTop: '1rem',
			textAlign: 'center',
			color: colors.blue[800],
			fontWeight: 500,
			fontSize: '1.8rem',
			textShadow:
				'0px 0px 1px rgb(255 255 255 / 10%), 1px -1px 0px rgb(0 0 0 / 50%), 2px 0px 1px rgb(21 101 192 / 44%)',
			letterSpacing: '0.05rem',
			[theme.breakpoints.down('md')]: {
				fontSize: '1.5rem',
			},
		},
	},
	'& .tablebox': {
		// flex: 1,
		alignSelf: 'center',
		marginTop: '1rem',
	},
}));

const CalculationBox = styled(Paper)(({ theme }) => ({
	padding: '10px',
	marginTop: theme.spacing(2),
	'& .cal-stack > .MuiBox-root': {
		display: 'flex',
		width: '100%',
		marginBottom: theme.spacing(1),

		'&:nth-of-type(even)': {
			justifyContent: 'flex-end',
		},
		'& > p:first-of-type:not(.unnamed)': {
			fontWeight: 'bold',
			letterSpacing: '0.05rem',
			marginRight: theme.spacing(0.8),
			fontSize: 14,
		},
		'& > p:last-of-type': {
			fontSize: 15,
		},
		'& > p.unnamed': {
			'&.negative': {
				color: colors.red['400'],
			},
			'&.positive': {
				color: '#000',
			},
		},
	},
	'& .input-stack > .MuiBox-root': {
		display: 'flex',
		gap: '0 10px',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',

		'& > p': {
			minWidth: '80px',
			fontWeight: 'bold',
			textTransform: 'capitalize',
			letterSpacing: '0.05rem',
			fontSize: '14px',
		},
		'& > .MuiBox-root': {
			display: 'flex',
			alignItems: 'center',
			border: `1px solid gray`,
			borderRadius: '3px',
			gap: '5px',
			padding: '0 5px',
			maxWidth: '145px',
			width: '100%',
			minHeight: '28px',
			'&.focus': {
				border: `2px solid gray`,
			},
		},
		'& > .MuiBox-root > input': {
			width: '100%',
			textAlign: 'end',
			border: 'none',
			'&:focus-visible': {
				outline: 'none',
				borderColor: 'rgb(0, 0, 0)',
			},
		},
	},
}));

const InputBox = ({
	obj,
	name,
	isDollar,
	handleInputChange,
	min = 0,
	remainingTotal,
	disabled,
}) => {
	const [focus, setFocus] = useState(false);
	return (
		<>
			<Box className={focus ? 'focus' : ''}>
				<input
					type="text"
					style={{ padding: 4, maxWidth: '85%', textAlign: 'end' }}
					min={min}
					name={name}
					value={obj[name].toLocaleString(undefined, {
						maximumFractionDigits: 2,
					})}
					onChange={(e) => handleInputChange(e, obj.name)}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
					disabled={disabled ? disabled : false}
				/>
			</Box>
		</>
	);
};

const AQHStationContainer = ({
	getLiveTableData,
	tableData,
	setTableData,
	cptRatio,
	allInputs,
	setAllInputs,
	selectedRowsAqh,
	dates,
	setDateState,
	lowerDeal,
	handleLowDeal,
	maxValues,
	handleMaxValues,
	aqhSavedOptions,
	messageId,
	setGrossWeekly,
	wasApproved,
	proposalId,
	setAqhSavedOptions,
	setIsAqhOption,
	setIsEditAqhOption,
	setOptionId,
	setOpenVacationTripsModal,
	setOptionData,
	setIsPublish,
	bridgeGross,
	dealTerms,
	setRolloverCredits,
	bridgeObj,
	setBridgeObj,
	optionIndex,
	setOptionIndex,
	setOpenBridgeDealModal,
	openBridgeDealModal,
	setOpenDeleteBridgeModal,
	openDeleteBridgeModal,
	disabledOptions = false,
	limitsEdits = Infinity,
	tagProposal,
	setTagProposal,
	setVacationCredits,
	setRewards,
}) => {
	const { user } = useAuthContext();
	const aqhSavedOptionsRef = collection(db, 'aqhSavedOptions');
	const [openTagProposal, setOpenTagProposal] = useState(false);
	const [openDeleteOption, setDeleteOption] = useState(false);
	const [optionIdDelete, setOptionIdDelete] = useState('');
	const [editOptionId, setEditOptionId] = useState('');
	const [grossWeeklyImpressions, setGrossWeeklyImpressions] = useState(0);
	const [proposalOptionIndex, setProposalOptionIndex] = useState(-1);
	const { enqueueSnackbar } = useSnackbar();
	const [loadingOption, setLoadingOption] = useState(false);
	const [optionNameModal, setOptionNameModal] = useState(false);
	const [optionIdChange, setOptionIdChange] = useState('');
	const [alternativeName, setAlternativeName] = useState('');
	const [optionEditData, setOptionEditData] = useState(null);

	useEffect(() => {
		setAllInputs((prev) => {
			const updatedData = prev.map((obj) => {
				const updated = obj.name === 'Vacations' ? cptRatio[1] : cptRatio[2];
				return { ...obj, min: updated };
			});
			return updatedData;
		});
	}, [cptRatio]);
	const handleInputChange = (e, titleName) => {
		const { name, value } = e.target;
		const valid = /^\d*\.?(?:\d{1,2})?$/;
		const copyArray = allInputs.map((obj) =>
			obj.name === titleName
				? {
						...obj,
						// bv Number(/^.{0,13}$/.test(
						[name]: !valid.test(value) ? obj[name] : value,
						// ? /([0-9]+([.][0-9]*)?|[.][0-9]+)/g value.replace(/^((?!^\d*\.?(?:\d{1,2})?$).)*$/g, '')
						// : obj[name])
				  }
				: obj
		);
		setAllInputs(copyArray);
	};
	// calculate gross weekly impressions
	useEffect(() => {
		let totalWeeklyGross = [];
		Object.keys(getLiveTableData).filter((key) =>
			totalWeeklyGross.push(Number(getLiveTableData[key].impressions))
		);
		const totalWeeklyGrossSum = totalWeeklyGross.reduce(
			(partialSum, nextValue) => partialSum + nextValue,
			0
		);
		setGrossWeekly(totalWeeklyGrossSum);
		setGrossWeeklyImpressions(Number(totalWeeklyGrossSum));
	}, [getLiveTableData]);

	const grossTotal = useMemo(() => {
		let total = [];
		//get bridge gross of modal
		let bridgeGrossTotal = Number(bridgeGross);
		Object.keys(getLiveTableData).filter((key) =>
			total.push(Number(getLiveTableData[key].gross))
		);
		total = total.reduce((a, b) => a + b, 0);
		return Number(total + bridgeGrossTotal).toFixed(2);
	}, [getLiveTableData, bridgeGross]);

	// sum of AQH
	const sumOfAOH = useMemo(() => {
		let totalAQH = [];
		Object.keys(getLiveTableData).filter((key) =>
			totalAQH.push(Number(getLiveTableData[key].orignalValue))
		);
		totalAQH = totalAQH.reduce((a, b) => a + b, 0);
		return Number(totalAQH);
	}, [getLiveTableData]);

	// calculate cpt
	const cpt = useMemo(() => {
		return allInputs[0].cogs > 0
			? Number(allInputs[0].revenue / allInputs[0].cogs).toFixed(2)
			: 0;
	}, [allInputs[0]]);
	// calculate sum of ratio
	const sumOfRatio = useMemo(() => {
		let filterInputs = allInputs.filter((input) => input.name !== 'Vacations');
		let sumOfRevenues = filterInputs.reduce(
			(n, { revenue }) => n + Number(revenue),
			0
		);
		let sumOfCogs = filterInputs.reduce((n, { cogs }) => n + Number(cogs), 0);
		return sumOfCogs > 0 ? Number(sumOfRevenues / sumOfCogs).toFixed(2) : 0;
	}, [allInputs]);

	// discrepancy calculation
	const remainingTotal = useMemo(() => {
		let sumOfRevenues = allInputs.reduce(
			(n, { revenue }) => n + Number(revenue),
			0
		);
		return Number(grossTotal - sumOfRevenues).toFixed(2);
	}, [allInputs, grossTotal]);

	const setAllData = (resultDates, resultAqhData, resultAllInputs) => {
		const savedDates = {
			startDate: {
				value: new Date(resultDates.startDate.seconds * 1000),
				isChanged: true,
			},
			endDate: {
				value: new Date(resultDates.endDate.seconds * 1000),
				isChanged: true,
			},
			noOfWeeks: {
				value: resultDates.noOfWeeks,
				isChanged: true,
			},
		};
		setDateState(savedDates);

		var dataOptionTable = resultAqhData.map(function (obj) {
			return Object.keys(obj)
				.sort()
				.map(function (key) {
					return obj[key];
				});
		});
		setTableData(...dataOptionTable);
		setAllInputs(resultAllInputs);
	};

	const getSavedOptions = async (uid) => {
		setEditOptionId(uid);
		const savedDataQuery = doc(db, 'aqhSavedOptions', uid);
		const querySnapShot = await getDoc(savedDataQuery);
		const result = querySnapShot.data();

		if (result) {
			// set calc data from option data saved on user options
			const datesResult = result.dates;
			const resultAqhData =
				result?.compressed === true
					? [decompressDayPartsField(result.dayParts)]
					: [result.dayParts];
			setAllData(datesResult, resultAqhData, result.allInputs, result.bridgeDeal);
			setVacationCredits(result?.vacationCredits);
			setRewards(result?.rewards);
			setOptionEditData(result);
		} else if (proposalId) {
			// uncompress dayparts if necessary
			const aqhSavedOptionsUncompressed = decompressDayParts(aqhSavedOptions);
			const indexToEdit = aqhSavedOptionsUncompressed.findIndex(
				(obj) => obj.id === uid
			);
			setProposalOptionIndex(indexToEdit);

			if (indexToEdit !== -1) {
				// set calc data from option data saved on proposal and use uncompressed dayparts
				const resultData = aqhSavedOptionsUncompressed[indexToEdit];
				const resultAqhData = [resultData.dayParts];
				const datesResult = resultData.dates;
				setOptionEditData(resultData);
				setVacationCredits(resultData?.vacationCredits);
				setRewards(resultData?.rewards);
				if (datesResult.startDate.seconds !== undefined) {
					setAllData(datesResult, resultAqhData, resultData.allInputs);
				} else {
					const formattedDates = {
						startDate: {
							seconds: new Date(datesResult.startDate).getTime() / 1000,
						},
						endDate: {
							seconds: new Date(datesResult.endDate).getTime() / 1000,
						},
						noOfWeeks: datesResult.noOfWeeks,
					};
					setAllData(formattedDates, resultAqhData, resultData.allInputs);
				}
			}
		}
	};

	const createObject = (id, lowerDeal, maxValues) => {
		// create object to verify if data is greater than 1MB
		const dataObject = {
			id,
			userId: user.uid,
			tableData,
			dates,
			grossTotal,
			grossWeeklyImpressions,
			remainingTotal,
			cpt,
			sumOfRatio,
			selectedRowsAqh,
			cptRatio,
			allInputs,
			lowerDeal,
			maxValues,
			createdAt: optionEditData?.createdAt || null,
			alternativeName: optionEditData?.alternativeName || null,
			optionIndex: optionEditData?.optionIndex || optionIndex,
		};

		// send data to compress if necessary
		const compressedObject = compressDataIfNecessary(dataObject);
		// create object to save in database
		const createObject = createOptionObjectData(
			null,
			user.uid,
			compressedObject.tableData,
			dates,
			grossTotal,
			grossWeeklyImpressions,
			remainingTotal,
			cpt,
			sumOfRatio,
			selectedRowsAqh,
			allInputs,
			lowerDeal,
			maxValues,
			compressedObject.compressed,
			dealTerms,
			optionEditData?.createdAt || null,
			optionEditData?.alternativeName || null,
			optionEditData?.optionIndex || optionIndex
		);
		return createObject;
	};

	const handleSaveAction = async () => {
		try {
			setLoadingOption(true);
			setIsAqhOption(false);
			setIsEditAqhOption(false);
			setIsPublish(false);

			// update proposal option data in savedProposal options array
			if (editOptionId && proposalOptionIndex !== -1) {
				setOptionData(createObject(editOptionId, lowerDeal, maxValues));
				setOptionId(editOptionId);
				setLoadingOption(false);
				setOpenVacationTripsModal(true);
				setEditOptionId('');
				setOptionEditData(null);
				setProposalOptionIndex(-1);
			} else if (editOptionId !== '') {
				// update user option
				setOptionData(createObject(null, lowerDeal, maxValues));
				setOptionId(editOptionId);
				setIsEditAqhOption(true);
				setLoadingOption(false);
				setOpenVacationTripsModal(true);
				setOptionEditData(null);
				setEditOptionId('');
			} else if (proposalId) {
				setOptionData(createObject(null, lowerDeal, maxValues));
				setLoadingOption(false);
				setOpenVacationTripsModal(true);
				setEditOptionId('');
				setOptionId('');
				setOptionEditData(null);
			} else {
				// else create new option for user
				setOptionData(createObject(null, lowerDeal, maxValues));
				setIsAqhOption(true);
				setOptionId('');
				setLoadingOption(false);
				setOpenVacationTripsModal(true);
			}
		} catch (error) {
			setLoadingOption(false);
			console.log(error);
			enqueueSnackbar(error.message, { variant: 'error' });
		}
	};

	// Get saved options
	useEffect(() => {
		const getOptionsSaved = async () => {
			const optionsData = query(
				aqhSavedOptionsRef,
				where('userId', '==', user.uid)
			);

			const unsub = onSnapshot(optionsData, (snapshot) => {
				const result = snapshot.docs.map((doc) => {
					return { ...doc.data(), id: doc.id };
				});
				// order by createdAt if exists
				const sortedArray = sortedOptions(result);
				setAqhSavedOptions(sortedArray);
				// set option index to add new option
				const lastOptionIndex = sortedArray[sortedArray.length - 1]?.optionIndex;
				if (lastOptionIndex !== undefined && !isNaN(lastOptionIndex)) {
					setOptionIndex(lastOptionIndex + 1);
				}
			});

			return unsub;
		};

		if (aqhSavedOptions && aqhSavedOptions.length > 0) {
			// order by createdAt if exists
			const sortedArray = sortedOptions(aqhSavedOptions);
			setAqhSavedOptions(sortedArray);
			// get option index to add new option
			const lastOptionIndex = sortedArray[sortedArray.length - 1]?.optionIndex;
			if (lastOptionIndex !== undefined && !isNaN(lastOptionIndex)) {
				// set option index to add new option
				setOptionIndex(lastOptionIndex + 1);
			}
		} else {
			getOptionsSaved();
		}
	}, []);

	const [expanded, setExpanded] = React.useState('');

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const checkMaxValue = (revenue, min, cogs) => {
		const result = Math.floor(revenue / min);
		if (cogs > result) {
			// handleMaxValues(remainingTotal < 0 ? true : false);
			return 'error';
		} else {
			// handleMaxValues(remainingTotal < 0 ? true : false);
			return 'gray';
		}
	};

	//check max values
	useEffect(() => {
		if (remainingTotal < 0) {
			handleMaxValues(true);
		} else {
			handleMaxValues(false);
		}
	}, [remainingTotal]);

	// handle Bridge Deal
	const editBridgeDeal = () => {
		setOpenBridgeDealModal(true);
	};

	const deleteBridgeDeal = () => {
		setOpenDeleteBridgeModal(true);
	};

	// verify if dates exist
	const handleDates = () => {
		// if dates are not selected disable several options and inputs
		return !(dates.startDate && dates.endDate && dates.noOfWeeks);
	};
	return (
		<MainBox>
			<MuiGridContainer>
				<Grid item sx={{ width: '100%' }}>
					<Box className="infobox">
						<Typography variant="h3" className="main">
							Deal Calculator
						</Typography>
					</Box>
					{/* Live Data Table */}
					<Box className="tablebox">
						<AQHLiveDataTable
							tableData={tableData}
							getLiveTableData={getLiveTableData}
							selectedRowsAqh={selectedRowsAqh}
						/>
					</Box>
					<Box className="tablebox">
						{tagProposal.trim().length <= 0 ? (
							<Box sx={{ mt: '-15px', position: 'relative' }}>
								<Button
									sx={{
										backgroundColor: '#FFFFFF',
										color: 'black',
										p: 0.5,
										m: 0.5,
										':hover': {
											backgroundColor: '#1565C0',
											color: 'white',
										},
									}}
									variant="contained"
									onClick={(_) => {
										setOpenTagProposal(true);
									}}
									disabled={handleDates()}
								>
									<EditIcon fontSize="small" />
									Add Proposal Tag
								</Button>
							</Box>
						) : (
							<Box className="infobox bg-red-300">
								<div className="flex flex-col font-bold">
									<Divider>
										<Button
											sx={{ color: 'black', p: 0.4, m: 0, minWidth: 0 }}
											onClick={(_) => {
												setOpenTagProposal(true);
											}}
										>
											<EditIcon fontSize="small" />
										</Button>
										{tagProposal}
									</Divider>
								</div>
							</Box>
						)}
					</Box>
					{/* Calculation of gross, discrepancy, cpt, ratio, rewards, etc */}
					<CalculationBox>
						<Stack
							className="cal-stack"
							direction="row"
							spacing={2}
							justifyContent="space-between"
							flexWrap="wrap"
							sx={{
								'&>.MuiBox-root': {
									flex: '50%',
									marginLeft: 0,
								},
							}}
						>
							<Box>
								<Typography>Gross</Typography>
								<Typography>${numberWithCommas(grossTotal)}</Typography>
							</Box>
							<Box>
								<Typography>Total AQH</Typography>
								<Typography>{numberWithCommas(sumOfAOH)}</Typography>
							</Box>
							<Box>
								<Typography>Discrepancy</Typography>
								<Typography
									className={`unnamed ${remainingTotal < 0 ? 'negative' : 'positive'}`}
								>
									${numberWithCommas(remainingTotal)}
								</Typography>
							</Box>
							<Box>
								<Typography>CPT</Typography>
								<Typography>{numberWithCommas(cpt)}</Typography>
							</Box>
							<Box>
								<Typography>Gross Weekly Impressions</Typography>
								<Typography sx={{ width: '15%' }}>
									{numberWithCommas(grossWeeklyImpressions)}
								</Typography>
							</Box>
							<Box>
								<Typography>Ratio</Typography>
								<Typography>{numberWithCommas(sumOfRatio)}</Typography>
							</Box>
						</Stack>
						{/* when deal terms exist show previous deal and most recent deal */}
						{dealTerms ? (
							<>
								<Divider>
									<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
										Previous Deal
									</Typography>
								</Divider>
								<PreviousDeal dealTerms={dealTerms} />
								{/* This part is for the most recent deal terms */}
								<Divider sx={{ mt: 1, mb: 1 }}>
									<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
										Most Recent Deal Terms
									</Typography>
								</Divider>
								<MostRecentDeal
									sumOfRatio={sumOfRatio}
									grossTotal={grossTotal}
									cpt={cpt}
									handleLowDeal={handleLowDeal}
									dealTerms={dealTerms}
									setRolloverCredits={setRolloverCredits}
								/>
							</>
						) : (
							''
						)}
						<Stack
							className="input-stack"
							direction="column"
							spacing={2}
							mt={2}
							sx={{
								'&>.MuiBox-root': {
									marginLeft: 0,
									marginTop: '1px',
								},
							}}
						>
							{allInputs.map((obj) => (
								<Box key={obj.name}>
									<Typography sx={{ mt: '-10px', position: 'relative' }}>
										{obj.name}
									</Typography>
									<Stack direction="column" spacing={1}>
										<Stack direction="row" spacing={1}>
											<Typography sx={{ position: 'absolute', pl: 2, pt: '0.2%' }}>
												$
											</Typography>
											<InputBox
												disabled={wasApproved || handleDates()}
												obj={obj}
												name="revenue"
												isDollar={true}
												handleInputChange={handleInputChange}
											/>
										</Stack>
										<Typography
											color={remainingTotal < 0 ? 'error' : 'gray'}
											variant="subtitle2"
											sx={{ pl: 2 }}
										>
											{(obj['cogs'] || obj['revenue']) && obj['revenue'] > 0
												? `Max Value ${remainingTotal > 0 ? remainingTotal : grossTotal}`
												: ''}
										</Typography>
									</Stack>
									<Typography sx={{ mt: '-10px', position: 'relative' }}>
										{obj && obj.name === 'Vacations' ? 'Credits' : 'deliverable'}
									</Typography>
									<Stack direction="column" spacing={1}>
										<Stack direction="row" spacing={1}>
											{obj && obj.name !== 'Vacations' ? (
												<Typography sx={{ position: 'absolute', pl: 2, pt: '0.2%' }}>
													$
												</Typography>
											) : (
												<Typography sx={{ position: 'absolute', pl: 2, pt: '0.2%' }}>
													{' '}
												</Typography>
											)}
											<InputBox
												obj={obj}
												name="cogs"
												isDollar={false}
												handleInputChange={handleInputChange}
												min={obj.min}
												disabled={wasApproved || handleDates()}
											/>
										</Stack>
										<Typography
											color={checkMaxValue(+obj['revenue'], obj.min, +obj['cogs'])}
											variant="subtitle2"
											sx={{ pl: 3 }}
										>
											{obj['revenue'] > 0 ? (
												`Max Value ${Math.floor(+obj['revenue'] / obj.min)}`
											) : obj['cogs'] ? (
												<Typography color="white">`Max Value ${obj['cogs']}`</Typography>
											) : (
												''
											)}
										</Typography>
									</Stack>
								</Box>
							))}
						</Stack>
					</CalculationBox>
					<br />
					{/* Save or Edit option button */}
					{!messageId &&
					(editOptionId || aqhSavedOptions.length - 1 < limitsEdits) ? (
						<Box className="saveButton">
							<Button
								className="upload"
								size="small"
								variant="contained"
								color="info"
								disabled={wasApproved || loadingOption || handleDates()}
								startIcon={loadingOption && <CircularProgress size={18} />}
								onClick={(_) => {
									handleSaveAction();
								}}
							>
								{editOptionId ? 'Update Option' : 'Save Option'}
							</Button>
						</Box>
					) : (
						''
					)}
					<br />
					{/* Bridge Deal Component */}
					{bridgeObj && bridgeObj !== '' ? (
						<>
							<Accordion>
								<AccordionSummary
									sx={{ backgroundColor: '#0082A6', color: 'white' }}
									aria-controls="panel1d-content"
									id="panel1d-header"
									expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
								>
									<Typography>Bridge Component</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Stack
										className="cal-stack"
										direction="row"
										spacing={2}
										justifyContent="space-between"
										flexWrap="wrap"
										sx={{
											'&>.MuiBox-root': {
												flex: '50%',
												marginLeft: 0,
											},
										}}
									>
										<Box>
											<Stack direction="row" spacing={2}>
												<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
													Deal:
												</Typography>
												<Typography sx={{ fontSize: 14 }}>
													{bridgeObj?.dealNumber || ''}
												</Typography>
												<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
													Gross:
												</Typography>
												<Typography sx={{ fontSize: 14 }}>
													${' '}
													{bridgeObj.grossTotal
														? numberWithCommas(bridgeObj.grossTotal)
														: ''}
												</Typography>
											</Stack>
										</Box>
									</Stack>
									<Stack direction="column" spacing={2}>
										<Stack direction="row" spacing={2}>
											<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
												Start Date:
											</Typography>
											<Typography sx={{ fontSize: 14 }}>
												{' '}
												{bridgeObj.startDate ? dateTransform(bridgeObj.startDate) : ''}
											</Typography>
											<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
												No. of Weeks:
											</Typography>
											<Typography sx={{ fontSize: 14 }}>
												{' '}
												{bridgeObj.noOfWeeks ? bridgeObj.noOfWeeks : ''}
											</Typography>
											<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
												End Date:
											</Typography>
											<Typography sx={{ fontSize: 14 }}>
												{' '}
												{bridgeObj.endDate ? dateTransform(bridgeObj.endDate) : ''}
											</Typography>
										</Stack>
										<Stack direction="row" spacing={2}></Stack>
									</Stack>

									<Divider sx={{ mt: 1, mb: 1 }} />
									<Stack direction="row" justifyContent="flex-end" spacing={2}>
										<Button
											variant="contained"
											onClick={(_) => {
												editBridgeDeal();
											}}
											disabled={wasApproved}
										>
											<EditIcon fontSize="small" /> Edit
										</Button>
										<Button
											color="error"
											variant="contained"
											disabled={wasApproved}
											onClick={(_) => {
												deleteBridgeDeal();
											}}
										>
											<DeleteIcon fontSize="small" /> Delete
										</Button>
									</Stack>
								</AccordionDetails>
							</Accordion>
							<br />
						</>
					) : (
						''
					)}

					{aqhSavedOptions.length > 0 ? (
						<Box>
							{aqhSavedOptions.map((obj, idx) => (
								<Accordion
									key={idx}
									expanded={expanded === `panel${idx}`}
									onChange={handleChange(`panel${idx}`)}
								>
									<AccordionSummary
										sx={{ backgroundColor: '#1565C0', color: 'white' }}
										aria-controls="panel1d-content"
										id="panel1d-header"
										expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
									>
										<Stack direction={'row'} spacing={3} margin={0}>
											<Typography>
												{obj?.alternativeName || `Option ${obj?.optionIndex || idx + 1}`}
											</Typography>
											{/*  Edit name option button */}
											<Button
												sx={{ backgroundColor: '#1565C0', color: 'white', p: 0 }}
												variant="contained"
												onClick={(_) => {
													setOptionIdChange(obj.id);
													setOptionNameModal(true);
												}}
											>
												<EditIcon fontSize="small" />
												Edit name
											</Button>
										</Stack>
									</AccordionSummary>
									<AccordionDetails>
										<Stack direction="column" spacing={2}>
											<Box>
												<Stack direction="row" spacing={2}>
													<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
														Start Date:
													</Typography>
													<Typography sx={{ fontSize: 14 }}>
														{' '}
														{obj.dates?.startDate ? dateTransform(obj.dates?.startDate) : ''}
													</Typography>
													<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
														End Date:
													</Typography>
													<Typography sx={{ fontSize: 14 }}>
														{' '}
														{obj.dates?.endDate ? dateTransform(obj.dates?.endDate) : ''}
													</Typography>
												</Stack>
												<Stack direction="row" spacing={2}>
													<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
														No. of Weeks:
													</Typography>
													<Typography sx={{ fontSize: 14 }}>
														{' '}
														{obj.dates?.noOfWeeks ? obj.dates?.noOfWeeks : ''}
													</Typography>
												</Stack>
											</Box>
										</Stack>
										<Divider sx={{ mt: 1, mb: 1 }} />
										<Stack
											className="cal-stack"
											direction="row"
											spacing={2}
											justifyContent="space-between"
											flexWrap="wrap"
											sx={{
												'&>.MuiBox-root': {
													flex: '50%',
													marginLeft: 0,
												},
											}}
										>
											<Box>
												<Stack direction="row" spacing={2}>
													<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
														Gross
													</Typography>
													<Typography sx={{ fontSize: 14 }}>
														$ {obj.gross ? numberWithCommas(obj.gross) : ''}
													</Typography>
												</Stack>
											</Box>
											<Box>
												<Stack direction="row" spacing={2}>
													<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
														CPT
													</Typography>
													<Typography sx={{ fontSize: 14 }}>{obj.cpt}</Typography>
												</Stack>
											</Box>
											<Box>
												<Stack direction="row" spacing={2}>
													<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
														Discrepancy
													</Typography>
													<Typography sx={{ fontSize: 14 }}>$ {obj.discrepancy}</Typography>
												</Stack>
											</Box>
											<Box>
												<Stack direction="row" spacing={2}>
													<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
														Ratio
													</Typography>
													<Typography sx={{ fontSize: 14 }}>{obj.ratio}</Typography>
												</Stack>
											</Box>
											<Box>
												<Stack direction="row" spacing={2}>
													<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
														Gross Weekly Impressions
													</Typography>
													<Typography sx={{ fontSize: 14 }}>
														{obj.grossWeeklyImpressions
															? numberWithCommas(obj.grossWeeklyImpressions)
															: '0'}
													</Typography>
												</Stack>
											</Box>
										</Stack>
										<Divider sx={{ mt: 1, mb: 1 }} />
										{obj?.allInputs?.length > 0
											? obj.allInputs.map((obj, idx) => (
													<Box key={idx}>
														<Stack direction="row" spacing={2}>
															<Typography
																sx={{ fontSize: 14, fontWeight: 'bold', width: '100px' }}
															>
																{obj.name}
															</Typography>
															<Typography sx={{ fontSize: 14, width: '80px' }}>
																$ {obj.revenue ? obj.revenue : 0}
															</Typography>
															<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
																Value:{' '}
															</Typography>
															<Typography sx={{ fontSize: 14 }}>
																{obj.cogs ? obj.cogs : 0}
															</Typography>
														</Stack>
													</Box>
											  ))
											: ''}
										{/* DeliverableData in component */}
										{obj?.rewards || obj?.vacationCredits ? (
											<DeliverableData obj={obj} />
										) : (
											''
										)}
										<Divider sx={{ mt: 1, mb: 1 }} />
										{!messageId ? (
											<Stack direction="row" justifyContent="flex-end" spacing={2}>
												<Button
													variant="contained"
													onClick={(_) => {
														getSavedOptions(obj.id);
													}}
													disabled={
														wasApproved || (idx < limitsEdits && limitsEdits !== Infinity)
													}
												>
													<EditIcon fontSize="small" />
													Edit
												</Button>
												<Button
													color="error"
													variant="contained"
													disabled={
														wasApproved || (idx < limitsEdits && limitsEdits !== Infinity)
													}
													onClick={(_) => {
														setDeleteOption(true);
														setOptionIdDelete(obj.id);
													}}
												>
													<DeleteIcon fontSize="small" /> Delete
												</Button>
											</Stack>
										) : (
											<Stack direction="row" justifyContent="flex-end" spacing={2}>
												<Button
													variant="contained"
													onClick={(_) => {
														getSavedOptions(obj.id);
													}}
												>
													View
												</Button>
											</Stack>
										)}
									</AccordionDetails>
								</Accordion>
							))}
						</Box>
					) : !messageId ? (
						<Typography sx={{ fontSize: 13 }}>No Saved Options</Typography>
					) : (
						''
					)}
				</Grid>
			</MuiGridContainer>
			<ChangeOptionName
				optionNameModal={optionNameModal}
				setOptionNameModal={setOptionNameModal}
				alternativeName={alternativeName}
				optionId={optionIdChange}
				proposalId={proposalId}
				aqhSavedOptions={aqhSavedOptions}
				setAqhSavedOptions={setAqhSavedOptions}
				setEditOptionId={setEditOptionId}
				setProposalOptionIndex={setProposalOptionIndex}
				setAlternativeName={setAlternativeName}
			/>
			<DeleteOptionModal
				openDeleteOption={openDeleteOption}
				setDeleteOption={setDeleteOption}
				optionId={optionIdDelete}
				proposalId={proposalId}
				aqhSavedOptions={aqhSavedOptions}
				setAqhSavedOptions={setAqhSavedOptions}
				setEditOptionId={setEditOptionId}
				setProposalOptionIndex={setProposalOptionIndex}
			/>
			<AddTagProposal
				openTagProposal={openTagProposal}
				setTagProposal={setTagProposal}
				tagProposal={tagProposal}
				setOpenTagProposal={setOpenTagProposal}
				proposalId={proposalId}
			/>
		</MainBox>
	);
};

export default AQHStationContainer;
