/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
	Button,
	TextField,
	Grid,
	Box,
	Typography,
	Autocomplete,
	CircularProgress,
	Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { auth, db } from '../../../firebase/config';
import {
	addDoc,
	collection,
	doc,
	getDocs,
	limit,
	query,
	setDoc,
	updateDoc,
	where,
	onSnapshot,
} from 'firebase/firestore';
import { useRegister } from '../../../hooks/useRegister';
import { useSnackbar } from 'notistack';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import axios from 'axios';
import { baseURL, getUniqueArr } from '../../../utils';
import { capitalize } from '../../StationList/utils/constants';
import { typeUsers, handleUserTypeValue } from '../utils/constants';
import SelectedMainStation from './SelectMainStation';

const EmployeeBox = styled(Box)(({ theme }) => ({
	border: '1px solid rgb(128 128 128 / 33%)',
	background: '#F8F8F8',
	borderRadius: '8px',
	boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
	padding: '1.2rem',
	minHeight: '250px',
}));

const EmployeeData = () => {
	const initialUserState = {
		firstName: '',
		lastName: '',
		email: '',
		photoURL: '',
		employeeId: '',
		department: '',
		position: '',
		supervisor: '',
		password: '',
		empDbId: '',
		mainStation: {
			nameStation: '',
			stationLetters: '',
		},
		role: [],
	};
	const [userState, setUserState] = useState(initialUserState);
	const [filterUser, setFilterUser] = useState(null);
	const [isNew, setIsNew] = useState(false);
	const [loading, setLoading] = useState(false);
	const [mainStation, setMainStation] = useState();
	const { enqueueSnackbar } = useSnackbar();
	let { error: signupError, signup } = useRegister(enqueueSnackbar);

	const [onTypeList, setOnTypeList] = useState({
		user: [],
	});

	const usersRef = collection(db, 'users');
	const employeesRef = collection(db, 'employees');
	const userTypesOptions = [];
	const [userTypes, setUserTypes] = useState([...userTypesOptions]);

	const handleTypo = async (e) => {
		const name = e.target.id;
		const text = e.target.value;
		const startText = capitalize(text);
		if (!text) return false;
		const end = text.replace(/.$/, (c) =>
			String.fromCharCode(c.charCodeAt(0) + 1)
		);
		const endText = capitalize(end);
		// get all users with the same first name in real time
		const unsubscribe = onSnapshot(
			query(
				usersRef,
				where('firstName', '>=', startText),
				where('firstName', '<', endText),
				limit(8)
			),
			(snapshot) => {
				let userResults = [];
				snapshot.forEach((doc) => {
					userResults.push({ ...doc.data(), userId: doc.id });
				});
				const unique = getUniqueArr(userResults, ['email']);
				if (unique.length === 0) return false;
				let userIds = unique.map((user) => user.userId);
				const q2 = query(employeesRef, where('userId', 'in', userIds));
				getDocs(q2).then((employeDocs) => {
					let employeeResults = [];
					employeDocs.forEach((doc) => {
						employeeResults.push({ ...doc.data(), empDbId: doc.id });
					});
					let mergedArr = userResults.map((user) => ({
						...initialUserState,
						...user,
						...employeeResults.find((emp) => emp.userId === user.userId),
					}));
					mergedArr.length > 0 &&
						setOnTypeList({ ...onTypeList, [name]: mergedArr });
					if (
						filterUser &&
						!mergedArr.find((user) => user.userId === filterUser.userId)
					) {
						setFilterUser(null);
					}
				});
			}
		);
		// return
		return unsubscribe;
	};

	const handleChange = (e, value) => {
		if (!e) {
			if (!value) return false;
			setFilterUser(value);
			setUserState(value);
		} else {
			let { name, value } = e.target;
			setUserState({ ...userState, [name]: value });
		}
	};

	let employeeProp = [
		'employeeId',
		'employeeEmail',
		'userId',
		'department',
		'position',
		'supervisor',
		'employeePhone',
	];
	let userProp = [
		'firstName',
		'lastName',
		'email',
		'photoURL',
		'employeeId',
		'nickName',
		'role',
	];

	const validUserRole = (optionsRole, userRole) => {
		const roles = [...optionsRole];
		const userRoleArray = handleUserTypeValue(userRole);
		return [...roles, ...userRoleArray].some((item) => item.value === 'client');
	};

	const handleSave = async () => {
		let {
			userId,
			password,
			department,
			position,
			supervisor,
			firstName,
			lastName,
			email,
			role,
			...rest
		} = userState;
		let results = { employee: {}, user: {} };
		userProp.map((name) =>
			Object.assign(results, {
				user: { ...results.user, [name]: userState[name] || '' },
			})
		);
		employeeProp.map((name) =>
			Object.assign(results, {
				employee: {
					...results.employee,
					[name]: name === 'employeeEmail' ? email : userState[name] || '',
				},
			})
		);
		const isClient = results.user.role.some((item) => item.value === 'client');
		if (isClient) {
			if (!mainStation.nameStation || !mainStation.stationLetters) {
				return enqueueSnackbar('A Main Station must be added', {
					variant: 'error',
				});
			} else {
				results.user.mainStation = mainStation;
			}
		} else {
			results.user.mainStation = { nameStation: '', stationLetters: '' };
		}

		if (
			!email ||
			!firstName ||
			!lastName ||
			role.length === 0 ||
			(isNew ? !password : !userId)
		)
			return enqueueSnackbar(
				!isNew && !userId
					? 'No user select for edit'
					: 'Filled at least require fields',
				{ variant: 'error' }
			);
		else {
			setLoading(true);
			try {
				if (isNew) {
					const { user } = await createUserWithEmailAndPassword(
						auth,
						userState.email,
						userState.password
					);
					const userRef = doc(db, 'users', user.uid);
					const empRef = collection(db, 'employees');
					await setDoc(userRef, results.user);
					await addDoc(empRef, { ...results.employee, userId: user.uid });
					enqueueSnackbar('User created...', { variant: 'success' });
					setIsNew(false);
					setUserState(initialUserState);
				} else {
					const newPasswordData = {
						uid: userId,
						password: userState.password,
					};

					const userRef = doc(db, 'users', userId);
					await updateDoc(userRef, results.user);
					if (filterUser?.empDbId) {
						const empRef = doc(db, 'employees', filterUser.empDbId);
						await updateDoc(empRef, { ...results.employee, userId });
					} else {
						const empRef = collection(db, 'employees');
						await addDoc(empRef, { ...results.employee, userId });
					}
					//Only if the password field exists when editing
					if (newPasswordData.password) {
						//Retrieve ID Token
						const idToken = await auth.currentUser.getIdToken();
						try {
							await axios.post(baseURL + '/changeUserPassword', {
								...newPasswordData,
								idToken,
							});
						} catch (error) {
							console.log(error);
						}
					}

					enqueueSnackbar('User updated...', { variant: 'success' });
				}
				setFilterUser(null);
				setUserState(initialUserState);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				enqueueSnackbar(error.message, { variant: 'error' });
			}
		}
	};

	const handleUniqueOptions = (allOptions) => {
		const uniqueOptions = allOptions.filter(
			(option, index, self) =>
				index ===
				self.findIndex((o) => o.label === option.label && o.value === option.value)
		);
		return uniqueOptions;
	};
	// handle user type value from options and user role
	const handleUserTypes = (typeUsers, roleUsers) => {
		return typeof roleUsers === 'string'
			? typeUsers
			: handleUniqueOptions([...typeUsers, ...roleUsers]);
	};

	useEffect(() => {
		signupError && enqueueSnackbar(signupError.message, { variant: 'error' });
	}, [signupError]);

	return (
		<EmployeeBox sx={{ mx: 'auto', width: { md: '80%' }, p: 2 }}>
			<Grid container spacing={2}>
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Autocomplete
						options={onTypeList.user || []}
						getOptionLabel={(option) => {
							if (option.hasOwnProperty('firstName')) {
								return option.firstName + ' ' + option.lastName;
							}
							return option || ' ';
						}}
						isOptionEqualToValue={(option, value) => option.userId === value.userId}
						id="user"
						size="small"
						value={filterUser}
						onChange={(_, newValue) => handleChange(null, newValue)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Search"
								placeholder="By firstName"
								variant="outlined"
								onChange={handleTypo}
							/>
						)}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.userId}>
									{option.firstName + ' ' + option.lastName}
								</li>
							);
						}}
						disabled={isNew}
					/>
					<Button
						variant="outlined"
						color="primary"
						onClick={(_) => {
							if (!isNew) {
								setIsNew(true);
								setUserState(initialUserState);
							} else {
								setUserState(filterUser || initialUserState);
								setIsNew(false);
							}
						}}
					>
						{isNew ? 'Edit' : 'Add New'}
						{isNew ? <EditIcon sx={{ ml: 1 }} /> : <AddIcon sx={{ ml: 1 }} />}
					</Button>
				</Grid>
				<Grid item xs={12} mt={0} container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h6" fontWeight="600">
							{isNew ? 'Add new' : 'Update Existing'} user
						</Typography>
					</Grid>
					<Grid item container xs={12} spacing={2}>
						<Grid item xs={6} display="flex" gap="10px">
							{/* <Avatar src={userState.photoURL} sx={{ width: "50px", height: "50px" }} /> */}
							<TextField
								label="Email"
								required
								variant="outlined"
								size="medium"
								name="email"
								onChange={handleChange}
								value={userState.email}
								fullWidth
								// sx={{ minWidth: "300px" }}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="Password"
								type="Password"
								required={isNew ? true : false}
								variant="outlined"
								size="medium"
								name="password"
								onChange={handleChange}
								value={userState.password}
								fullWidth
							/>
						</Grid>
					</Grid>
					<Grid item container xs={12} spacing={2}>
						<Grid item xs={4}>
							<TextField
								label="First Name"
								required
								variant="outlined"
								size="medium"
								name="firstName"
								onChange={handleChange}
								value={capitalize(userState.firstName)}
								fullWidth
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								label="Last Name"
								required
								variant="outlined"
								size="medium"
								name="lastName"
								onChange={handleChange}
								value={capitalize(userState.lastName)}
								fullWidth
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								label="Position"
								variant="outlined"
								size="medium"
								name="position"
								onChange={handleChange}
								value={userState.position}
								fullWidth
							/>
						</Grid>

						<Grid item container xs={12} spacing={2}>
							<Grid item xs={4}>
								<TextField
									label="Department"
									variant="outlined"
									size="medium"
									name="department"
									onChange={handleChange}
									value={userState.department}
									fullWidth
								/>
							</Grid>

							<Grid item xs={4}>
								<TextField
									label="Supervisor"
									variant="outlined"
									size="medium"
									name="supervisor"
									onChange={handleChange}
									value={userState.supervisor}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<Tooltip title="Example: 18667705560" placement="top" arrow>
									<TextField
										label="Phone Number"
										variant="outlined"
										size="medium"
										name="employeePhone"
										onChange={handleChange}
										value={userState?.employeePhone || ''}
										fullWidth
									/>
								</Tooltip>
							</Grid>
							<Grid item container xs={16} spacing={2}>
								<Grid item xs={6}>
									<Autocomplete
										multiple
										id="role"
										name="role"
										options={handleUserTypes(typeUsers, userState?.role)}
										getOptionLabel={(option) => option.label}
										value={handleUserTypeValue(userState?.role || [])}
										onChange={(e, newValue) => {
											setUserTypes([
												...userTypesOptions,
												...newValue.filter(
													(option) => userTypesOptions.indexOf(option) === -1
												),
											]);
											setUserState((prevState) => ({
												...prevState,
												role: newValue,
											}));
										}}
										style={{ width: '100%' }}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Type of user *"
												variant="outlined"
												placeholder="Type of user"
												width="100%"
											/>
										)}
									/>
								</Grid>
							</Grid>
							{validUserRole(userTypes, userState.role) && (
								<Grid item xs={4}>
									<SelectedMainStation
										setMainStation={setMainStation}
										mainStation={userState.mainStation}
									></SelectedMainStation>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						color="primary"
						variant="contained"
						onClick={handleSave}
						disabled={loading}
						sx={{ minWidth: '100px' }}
					>
						{loading && (
							<CircularProgress
								style={{ width: '20px', height: '20px', marginRight: '10px' }}
							/>
						)}
						Save
					</Button>
				</Grid>
			</Grid>
		</EmployeeBox>
	);
};

export default EmployeeData;
