/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
	Box,
	Typography,
	Button,
	TextField,
	Grid,
	Link,
	CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../../hooks/useLogin';
import { useSnackbar } from 'notistack';
import Logo from '../../assets/images/sunfunmediaLogo.png';

const FormBox = styled(Box)(({ theme }) => ({
	backgroundColor: '#EFF1F4',
	borderRadius: '10px',
	border: '1px solid #B0B4B4',
	boxSizing: 'border-box',
	marginTop: `${theme.spacing(2)}`,
	padding: `${theme.spacing(3)}`,
	boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 15px 0px',

	'& .MuiFormControl-root': {
		margin: '15px 0',
	},
	'& .MuiTypography-root': {
		fontWeight: 'bold',
		margin: `${theme.spacing(2)} 0`,
	},
	'& button': {
		fontWeight: 'bold',
		color: '#fff',
		'&:last-of-type': {
			marginTop: '12px',
		},
	},
	'& .forget.MuiTypography-root': {
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: '12px',
		transition: 'all 0.5s ease',
		'& > a': {
			cursor: 'pointer',
			textDecoration: 'none',
			'&:hover': {
				color: theme.palette.primary.light,
			},
		},
	},
	'& .logoContainer': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		marginBottom: theme.spacing(3),
		userSelect: 'none',

		'& > img.logo': {
			width: '144px',
			height: '144px',
			userSelect: 'none',
		},
	},
}));

const Login = () => {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { login, error, loading } = useLogin(enqueueSnackbar);

	const initialState = {
		email: '',
		password: '',
	};

	const [state, setState] = useState(initialState);

	const handleChange = (evt) => {
		const value =
			evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
		setState({
			...state,
			[evt.target.name]: value,
		});
	};

	const hanldeLogin = () => {
		login(state.email, state.password);
	};

	useEffect(() => {
		error && enqueueSnackbar(error.message, { variant: 'error' });
	}, [error]);

	return (
		<Grid
			container
			spacing={2}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: '80px',
			}}
		>
			<Grid item xs={12} sm={8} md={6}>
				<FormBox>
					<Box className="logoContainer">
						<img src={Logo} className="logo" />
					</Box>
					<TextField
						id="loginEmail"
						label="Email"
						variant="outlined"
						size="small"
						name="email"
						value={state.email}
						onChange={handleChange}
						fullWidth
					/>
					<TextField
						id="loginPass"
						label="Password"
						variant="outlined"
						size="small"
						type="password"
						name="password"
						value={state.password}
						onChange={handleChange}
						fullWidth
					/>
					<Button
						variant="contained"
						fullWidth
						color="primary"
						onClick={hanldeLogin}
						disabled={loading}
					>
						{loading && (
							<CircularProgress
								style={{ width: '20px', height: '20px', marginRight: '10px' }}
							/>
						)}
						Login
					</Button>
					<Typography className="forget">
						<Link
							onClick={() => {
								navigate('/password-reset');
							}}
						>
							Forgot Password?
						</Link>
					</Typography>
				</FormBox>
			</Grid>
		</Grid>
	);
};

export default Login;
