import { createTheme } from '@mui/material/styles';

export const getTheme = (theme) =>
	createTheme({
		components: {
			MuiTable: {
				styleOverrides: {
					root: {
						textTransform: 'capitalize',
						borderCollapse: 'inherit',
						borderSpacing: '0px 5px',
					},
					paper: {
						backgroundColor: 'green',
					},
				},
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					root: {
						color: '#000',
						textTransform: 'capitalize',
						minWidth: '60px',
					},
				},
			},
			MuiTableFooter: {
				styleOverrides: {
					root: {
						'& .MuiToolbar-root': {
							color: '#000',
						},
					},
				},
			},
			MuiTableHead: {
				styleOverrides: {
					root: {
						'& > tr > th': {
							border: 0,
							color: '#fff',
							height: 50,
							backgroundColor: '#1976D2 !important',
							// border top only first and last
							'&:first-of-type': {
								borderTopLeftRadius: 10,
							},
							'&:last-of-type': {
								borderTopRightRadius: 10,
							},
						},
						'& > tr > th:first-of-type': {
							paddingLeft: '10px',
							'& span.MuiCheckbox-root': {
								color: 'rgb(246 246 246)',
								padding: '5px 4px',
								'>svg': {
									width: '1.2rem',
									height: '1.1rem',
								},
							},
						},
						'& > tr > th:last-of-type': {
							paddingRight: '10px',
						},
						'& th:nth-of-type(n+9)': {
							minWidth: '80px',
							textAlign: 'center',
						},
					},
				},
			},
			MuiTableBody: {
				styleOverrides: {
					root: {
						'& > tr': {
							marginBottom: '5px',
							height: 60,
							'&:nth-of-type(even)': {
								backgroundColor: 'rgba(0, 0, 0, 0.05)',
							},
							':nth-of-type(odd):hover': {
								backgroundColor: '#fff',
							},
							'&.Mui-selected': {
								backgroundColor: '#96BE98',
								color: '#fff',
								':hover': {
									backgroundColor: '#96BE98',
								},
								'> td': {
									color: '#fff',
								},
							},
							'& > td:nth-of-type(n+9)': {
								textAlign: 'center',
							},
						},
					},
				},
			},
			MuiToolbar: {
				defaultProps: {
					style: { paddingLeft: '8px' },
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						'& h6': {
							paddingLeft: '10px',
							fontWeight: 'bold',
						},
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						padding: '1px 4px',
						borderBottom: 0,
					},
					head: {
						paddingTop: '10px',
						paddingBottom: '10px',
					},
					body: {
						fontSize: '12.5px',
						paddingLeft: '5px',
						'& p': {
							fontSize: '12.5px',
							cursor: 'pointer',
						},
					},
				},
			},
		},
	});
