import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
/**
 * @description Hook that creates a list of files with quick access, allowing to access the files as if it were an array.
 * @returns add: Method to add a item
 * @returns remove: Method to remove a item
 * @returns update: Method to update a item
 * @returns clear: Method to empty list
 * @returns files: List of items
 * @returns filesMap: List in the form of a map
 */
const useFiles = () => {
	const [filesMap, setFiles] = useState(new Map());
	const [files, setFilesArray] = useState([]);
	const add = (file, metdata = {}) => {
		const key = v4();
		setFiles((prevMap) => {
			const newMap = new Map(prevMap);
			newMap.set(key, {
				file,
				metadata: {
					id: key,
					...metdata,
				},
			});
			return newMap;
		});
	};
	const remove = (key) => {
		setFiles((prevMap) => {
			const newMap = new Map(prevMap);
			newMap.delete(key);
			return newMap;
		});
	};

	const update = (key, value) => {
		setFiles((prev) => {
			const newMap = new Map(prev);
			const oldValue = newMap.get(key);
			newMap.set(key, { ...oldValue, ...value });
			return newMap;
		});
	};

	useEffect(() => {
		if (filesMap) {
			const filesArray = Array.from(filesMap.values());
			setFilesArray(filesArray);
		}
	}, [filesMap]);

	const clear = () => {
		setFiles(new Map());
	};

	return {
		filesMap,
		files,
		add,
		remove,
		update,
		clear,
	};
};
export default useFiles;
