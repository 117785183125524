import { useState } from 'react';

const useModal = () => {
	const [status, setStatus] = useState(false);
	const openModal = () => {
		setStatus(true);
	};
	const closeModal = () => {
		setStatus(false);
	};
	return {
		status,
		openModal,
		closeModal,
	};
};
export default useModal;
