export const aqhTotalByAddress = (data) => {
	return data.reduce((totalAqh, salesObj) => {
		if (salesObj.Aqh)
			if (totalAqh[salesObj.Maddr1]) totalAqh[salesObj.Maddr1] += salesObj.Aqh;
			else totalAqh[salesObj.Maddr1] = salesObj.Aqh;
		return totalAqh;
	}, {});
};

export const initialFilters = {
	format: null,
	owner: null,
	market: null,
};

export const typeListPropspector = {
	owner: [],
	market: [],
};

export const formatSeason = (season) => {
	const seasonType = season.startsWith('fall') ? 'Fall' : 'Spring';
	// Get the year
	const year = season.slice(-4); // Get the last 4 characters
	// return the season with the correct format
	return `${seasonType} ${year}`;
};

export const formatSeasonName = (season) => {
	return season
		.replace('spr', 'spring')
		.replace('fall', 'fall')
		.replace('-', '');
};
