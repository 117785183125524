import { useEffect, useState } from 'react';
import { getTravelerById } from '../utils/utils';
import { useDocument } from 'react-firebase-hooks/firestore';

/**
 * Hook used to return a traveler to one certificate
 * @returns traveler: Traveler info
 */
const useTraveler = (travelerId) => {
	const [query, setQuery] = useState();
	const [document] = useDocument(query);
	const [traveler, setTraveler] = useState();

	useEffect(() => {
		if (travelerId) {
			const ref = getTravelerById(travelerId);
			setQuery(ref);
		} else {
			setQuery(undefined);
		}
	}, [travelerId]);
	useEffect(() => {
		if (document && document.exists()) {
			const data = document.data();
			setTraveler({ ...data, id: document.id });
		} else {
			setTraveler(undefined);
		}
	}, [document]);
	return { traveler };
};
export default useTraveler;
