import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { useTheme, ThemeProvider, Typography, Box } from '@mui/material';
import { MainBox, getTheme } from '../../utils/themes';
import useClients from '../../hooks/useClients';
import { optionsTableRewards } from './utils';
import ClientInformation from './ClientInformation';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExportClients from './ExportClients';

const Clients = () => {
	const clients = useClients(); // Hook to get clients from firebase
	const [clientSelected, setClientSelected] = useState(null);
	const [openModalDetails, setOpenModalDetails] = useState(false);

	const theme = useTheme();

	const openModal = (client) => {
		setOpenModalDetails(true);
		setClientSelected(client);
	};
	const closeModalDetails = () => {
		setOpenModalDetails(false);
		setClientSelected(null);
	};

	const columns = [
		{
			name: 'firstName',
			label: 'First Name',
		},
		{
			name: 'lastName',
			label: 'Last Name',
		},
		{
			name: 'contactEmail',
			label: 'Email',
			options: {
				customBodyRender: (value) => {
					return value;
				},
			},
		},
		{
			name: 'state',
			label: 'State',
		},
		{
			name: 'city',
			label: 'City',
		},

		{
			name: 'address1',
			label: 'Address',
			options: {
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const rowData = clients[rowIndex];
					return `${rowData.address1}, ${rowData.address2}`;
				},
			},
		},
		{
			name: 'zip',
			label: 'Zip',
		},
		{
			name: '',
			label: 'View',
			options: {
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const rowData = clients[rowIndex];
					return (
						<VisibilityIcon
							sx={{ cursor: 'pointer' }}
							onClick={() => {
								openModal(rowData);
							}}
						></VisibilityIcon>
					);
				},
			},
		},
	];

	return (
		<>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				my={2}
			>
				<Typography variant="h6">Clients</Typography>
				<ExportClients data={clients} />
			</Box>
			<MainBox
				sx={{
					boxShadow: (theme) => theme.shadows[4],
					borderRadius: 2,
					paddingX: 1.2,
					marginX: -1,
				}}
			>
				<ThemeProvider theme={getTheme(theme)}>
					<MUIDataTable
						data={clients || []}
						columns={columns}
						options={{
							...optionsTableRewards,
							selectToolbarPlacement: 'replace',
							responsive: 'standard',
							selectableRows: 'none',
						}}
					></MUIDataTable>
				</ThemeProvider>
			</MainBox>
			<ClientInformation
				client={clientSelected}
				open={openModalDetails}
				onClose={closeModalDetails}
			/>
		</>
	);
};
export default Clients;
