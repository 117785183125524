import { useState } from 'react';
import useAuthContext from '../hooks/useAuthContext';
import { auth } from '../firebase/config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import useProfileContext from './useProfileContext';
import { isClientVerify } from '../utils';

export const useLogin = (enqueueSnackbar) => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const { dispatch } = useAuthContext();
	const { dispatch: profileDispatch } = useProfileContext();

	const login = async (email, password) => {
		setError(null);
		setLoading(true);
		try {
			const res = await signInWithEmailAndPassword(auth, email, password);
			const user = res.user;
			const userSnapshot = await getDoc(doc(db, 'users', user.uid)); // get user data
			const userType = userSnapshot.data()?.role; // get user type
			// if user is client, logout and clear profile
			if (isClientVerify(userType)) {
				await auth.signOut();
				dispatch({ type: 'LOGOUT' });
				profileDispatch({ type: 'clearProfile' });
				enqueueSnackbar('You do not have permission to access.', {
					variant: 'error',
				});
				setLoading(false);
			} else {
				// if user is rep or manager, login and set profile
				dispatch({ type: 'LOGIN', payload: res.user });
				enqueueSnackbar('Successfully Login', { variant: 'success' });
				setLoading(false);
			}
		} catch (error) {
			setError(error);
			setLoading(false);
			setTimeout(() => {
				setError(null);
			}, 6000);
		}
	};

	return { error, login, loading };
};
